import React, {useEffect} from "react";
import {Route, Redirect} from "react-router-dom";
import {useAuth} from "../../context";
import {AuthAPI} from "../../services";

function ProtectedRoute({roles = [1], children, ...rest}) {
    const {user} = useAuth();
    useEffect(() => {
        if (!user) {
            AuthAPI.checkLoggedIn().then((result) => {
                if (result?.isLoggedIn) {
                    AuthAPI.setUser(result.user);
                } else {
                    AuthAPI.logout().then(() => {
                        return <Redirect to={{pathname: '/'}}/>
                    });
                }
            });
        }
    }, []);
    const renderHandler = () => {
        if (!user.role || !user) {
            return (<Redirect
                to={{
                    pathname: "/",
                }}
            />);
        }
        if (!roles.includes(user.role)) {
            switch (user.role) {
                case 1:
                    return (<Redirect
                        to={{
                            pathname: "/student", //   state: { from: location },
                        }}
                    />);
                case 2:
                    return (<Redirect
                        to={{
                            pathname: "/teacher", //   state: { from: location },
                        }}
                    />);
                case 3:
                    return (<Redirect
                        to={{
                            pathname: "/principal", //   state: { from: location },
                        }}
                    />);
                default:
                    return (<Redirect
                        to={{
                            pathname: "/", //   state: { from: location },
                        }}
                    />);
            }
        }
        return children;
    };
    return <Route {...rest} render={renderHandler}/>;
}

export default ProtectedRoute;
