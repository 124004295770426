import {POST, GET, DELETE} from "..";

export default class StudentAPI {

    static async create(payload = new FormData()) {
        try {
            const {data} = await POST(`/students/create`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async edit(payload = new FormData()) {
        try {
            const {data} = await POST(`/students/editStudent`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async get() {
        try {
            const {data} = await GET(`/students/get`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async getSlider() {
        try {
            const {data} = await GET(`/students/getSlider`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async sendHandbook(payload) {
        try {
            const {data} = await POST(`/students/sendHandbook`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async sendLoginDetails(payload) {
        try {
            const {data} = await POST(`/students/sendLoginDetails`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async getInfoById(id) {
        try {
            const {data} = await GET(`/students/info/${id}`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async getMyInfoById(id) {
        try {
            const {data} = await GET(`/students/info/me/${id}`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async deleteById(id) {
        try {
            const {data} = await DELETE(`/students/delete/${id}`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async changePassword({currentPassword, newPassword}) {
        try {
            const {data} = await POST(`/students/changePassword`, {
                password: currentPassword,
                newPassword:newPassword,
            });
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async getPerformance(id, week = 1) {
        try {
            const {data} = await POST(`/students/performance`, {
                studentId: id,
                weekNumber: week
            });
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }
}
