import React from "react";

function QuestionWithOptions({prompt, options, index, checked, checkAnswer, bgColor, selectedStatus}) {

    return (
        <div className="flex flex-col space-y-2"

        style={{maxWidth:'95%'}}>
            <span>{prompt}</span>
            <span style={{margin: 0}}>
        <select
            onChange={(e) => {
                checkAnswer(e.target.value, index);
            }}
            // className={checked ? "bg-green-300" : ""}
            style={{
                backgroundColor: bgColor ? bgColor : "#f7f7f7",
                color: (checked === 0 || checked === 1) ? "#fff" : "",
                padding: 5,
                borderRadius: 5,
                borderColor:"transparent"
            }}
        >
          <option value="">Select an answer</option>
            {options.map((option, i) => {
                return (
                    <option value={option} key={i}
                            style={{backgroundColor: bgColor ? bgColor : "#f7f7f7", border: "none"}}>
                        {option}
                    </option>
                );
            })
            }
                </select>
                </span>
        </div>
    );
}

export default QuestionWithOptions;
