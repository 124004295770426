import React from "react";

import {ProfileIconMenu} from "../..";
import {MessengerSvgIcon} from "../../../assets/svg/";

// import logo from "../../../assets/";
import yellow from "../../../assets/img/horlogo.png";
import green from "../../../assets/img/gb2.png";
import access from "../../../assets/img/EAP SPACE ACCESS WHITE.png";
import space from "../../../assets/img/ab2.png";

import "./headerbar.css";
import {useAuth} from "../../../context";
import {useHistory, Link} from "react-router-dom";

const HeaderBar = ({homeUrl = "/", userName = "Anonymous"}) => {

    const {user, logout} = useAuth();
    const history = useHistory();
    const showLogo = () => {
        const {
            programId = {
                programName: "",
                logoUrl: ""
            }
        } = user;
        if (user.role === 1) {
            return programId.logoUrl || yellow;
        } else {
            return space;
        }
    }
    return (<div className="eap__headerbar">
        <div className="eap__headerbar-items">

            <div className="eap__headerbar-items-logo">
                <Link to={`${homeUrl}`}>
                    {" "}
                    <img src={showLogo()} alt="eap logo" style={{objectFit: 'contain'}}/>
                </Link>
                <p>Hello, {user.studentName ?? user.teacherName ?? userName}</p>
            </div>
            <div className="eap__spacer" />
            <div className="eap__message-btn-holder">
                <Link to={`${homeUrl}/messenger`}>
                    {" "}
                    <MessengerSvgIcon/>
                </Link>
            </div>
            <div className="eap__headerbar-items-container eap__profile-icon-holder">
                <ProfileIconMenu userName={user.studentName ?? user.teacherName}/>
            </div>
        </div>
        {/* <div className="eap__headerbar-items-mobile">
          </div> */}
    </div>);
};

export default HeaderBar;
