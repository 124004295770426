import React from "react";
import "./assets/css/tailwind.css";
import "./assets/css/animation.css";
import "./assets/css/font.css";
import "./assets/css/output.css";
import {ErrorBoundary} from "./components";

import RouterView from "./router/";
import {AuthProvider} from "./context";

function App() {
    return (
        <ErrorBoundary>
            <React.StrictMode>
                <AuthProvider>
                    <RouterView/>
                </AuthProvider>
            </React.StrictMode>
        </ErrorBoundary>
    );
}

export default App;
