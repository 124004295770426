import JWT from "jsonwebtoken";

const JWT_SECRET = "this-could-be-my-secret-made-by-cat";

export function signData(data = {}) {
  try {
    return JWT.sign(data, JWT_SECRET);
  } catch (err) {
    return null;
  }
  // return JWT.sign(data, JWT_SECRET);
}

export function decodeData(token = "jwt string") {
  try {
    return JWT.verify(token, JWT_SECRET);
  } catch (err) {
    return null;
  }
}
