import React, {useEffect} from "react";
import "./create_group.css";
// import SimpleBar from "simplebar-react";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {ClassSvgIcon, PersonSvgIcon} from "../../../assets/svg";
import {TeacherStudentSearchItem as StudentSearchItem} from "../../../components";
import {
    Button, // PhoneTextField,
    TextField, // Dropdown,
    Pagination, SearchBox, // IconWithRightText,
    TeacherStudentWrapper,
} from "../../../components";
import {useAuth} from "../../../context";
import {uuid4, moveElements} from "../../../utils";
import {StudentAPI, GroupAPI} from "../../../services";

function CreateGroup() {
    const {showModal} = useAuth();
    const [fetchedList, setFetchedList] = React.useState([]);
    const [searchList, setSearchList] = React.useState([]);
    const [selectedList, setSelectedList] = React.useState([]);
    const [groupName, setGroupName] = React.useState("");
    const [imageFile, setImageFile] = React.useState(null);

    useEffect(() => {
        StudentAPI.get().then(({students}) => {
            setFetchedList(students);
            setSearchList(students);
        });
    }, []);
    const submitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile);
        }
        const groupData = {
            name: groupName, students: selectedList,
        };

        formData.append("data", JSON.stringify(groupData));
        const response = await GroupAPI.create(formData);

        showModal(response.code===200 ? "Success" : "Error", response.msg, () => {
            window.location.reload();
        }, false);

        // alert(JSON.stringify(response));
    };
    const processSelectedList = () => {
        const tempList = [];
        for (const item of selectedList) {
            tempList.push({
                name: item.studentName,
                handler: () => moveElements(selectedList, setSelectedList, searchList, setSearchList, item._id),
            });
        }
        return tempList;
    };

    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload").click();
    };
    return (<form action="" onSubmit={submitHandler}>
            <CreateLayout title="Create Group" href="/teacher">
                <div className="eap__create-student-main-panel">
                    <div className="group-management-elem1">
                        <div className="eap__create-student-avatar-wrapper eap__shadow ">
                            {imageFile ? (<img
                                    id="uploadPreview"
                                    alt=""
                                    style={{
                                        width: 128 + "px",
                                        height: 128 + "px",
                                        borderRadius: 128 + "px",
                                        objectFit: "contain",
                                    }}
                                />) : (<ClassSvgIcon/>)}
                        </div>
                    </div>
                    <div className="group-management-elem2" onClick={uploadHandler}>
                        <Button text="Upload Picture"/>
                        <input
                            type="file"
                            id="upload"
                            hidden
                            onChange={(e) => {
                                setImageFile(e.target.files[0]);
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(e.target.files[0]);
                                fileReader.onload = function (evt) {
                                    document.getElementById("uploadPreview").src = evt.target.result;
                                };
                            }}
                        />
                    </div>
                    <div className="group-management-elem3">
                        <label>Select member</label>
                    </div>
                    <div className="group-management-elem4">
                        <SearchBox
                            inputHandler={(e) => {
                                setSearchList([...fetchedList].filter((elem) => String(elem.studentName).includes(e.target.value)));
                            }}
                        />
                    </div>
                    <div className="group-management-elem15">
                        <Pagination/>
                    </div>
                    <div className="group-management-elem5"></div>
                    <div className="group-management-elem7">
                        <label>Group member</label>
                    </div>
                    <div className="group-management-elem6">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: 100 + "%",
                                gap: "18px",
                                minHeight: 350 + "px",
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                        >
                            {searchList.length < 1 ? (<>Empty</>) : searchList.length > 4 ? ([...searchList]
                                    .slice(0, 4)
                                    .map((elem) => (<StudentSearchItem
                                            key={uuid4()}
                                            name={elem.studentName}
                                            click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}
                                        />))) : ([...searchList].map((elem) => (<StudentSearchItem
                                        key={uuid4()}
                                        name={elem.studentName}
                                        click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}
                                    />)))}
                        </div>
                    </div>
                    <div className="group-management-elem9">
                        <label>Name</label>
                    </div>
                    {" "}
                    <div className="group-management-elem16">
                        <TextField
                            required
                            value={groupName}
                            inputHandler={(e) => setGroupName(e.target.value)}
                        />
                    </div>
                    <div className="group-management-elem12">
                        <TeacherStudentWrapper list={processSelectedList()}/>
                    </div>
                    <div className="group-management-elem14">
                        <Button
                            text="Create"
                            color="#eabc7f"
                            textColor="#ffffff"
                            primary
                            click={() => {
                                document.getElementById("create-group-submit").click();
                            }}
                        />
                        <input type="submit" hidden id="create-group-submit"/>
                    </div>
                </div>
            </CreateLayout>
        </form>);
}

export default CreateGroup;
