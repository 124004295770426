import {POST, GET, DELETE} from "../";

export default class PrincipalAPI {

    static async createTeacher(payload = new FormData()) {
        try {
            for (const pair of payload.entries()) {
                console.log(pair[0] + ", " + pair[1]);
            }
            const {data} = await POST(`/principals/createTeacher`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message
            };
        }
    }

    static async editTeacher(payload = new FormData()) {
        try {
            const {data} = await POST(`/principals/editTeacher`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message
            };
        }
    }

    static async deleteTeacher(id) {
        try {
            const {data} = await DELETE(`/principals/deleteTeacher/${id}`);
            return data;
        } catch (err) {
            return {
                msg: err.message
            };
        }
    }

    static async getLandingTeachers() {
        try {
            const {data} = await GET(`/principals/landingTeachers`);
            return data;
        } catch (err) {
            return {
                msg: err.message
            };
        }
    }

    static async createStudent(payload = new FormData()) {
        try {
            const {data} = await POST(`/students/create`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

}
