import React, {useEffect, useState, useLayoutEffect} from "react";
import {GET} from "../services";
import {paginationHelper} from "../utils";

const PER_PAGE = 5;
export default function useWeeklyContentTeacher() {
    const [weeklyContent, setWeeklyContent] = React.useState([]);
    const [currentPageNum, setCurrentPageNum] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState([]);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentProgram, setCurrentProgram] = React.useState([]);
    const [programs, setPrograms] = React.useState([]);
    const [currentProgramIndex, setCurrentProgramIndex] = useState(0);
    const [loading, setLoading] = React.useState(false);

    useLayoutEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => GET("/teachers/weeklyContent"))
            .then(({data}) => {
                setPrograms(data.data);
                setCurrentProgram(data.data[0]);
                setCurrentProgramIndex(0);
                setCurrentPage(
                    paginationHelper(data.data[0]["weeks"], PER_PAGE, currentPageNum)
                );
                setTotalPages(Math.ceil(data.data[0]["weeks"].length / PER_PAGE));
            })
            .then(() => setLoading(false));

    }, []);

    useEffect(() => {
        setCurrentPage(
            paginationHelper(currentProgram["weeks"], PER_PAGE, currentPageNum)
        );
    }, [currentPageNum]);

    useEffect(() => {
        setCurrentProgram(programs[currentProgramIndex]);
    }, [currentProgramIndex])

    const programMapper = () => {
        return programs.map((program, index) => {
            return {
                keyname: program._id,
                index,
            };
        });
    };

    const setCurrentProgramHandler = (index) => {
        setCurrentProgram(programs[index]);
        setCurrentPageNum(1);
        setCurrentProgramIndex(index);
    };

    // const getCurrentProgramName = () => currentProgram._id || "Please select program";

    return {
        // getCurrentProgramName,
        loading,
        currentProgramIndex,
        weeklyContent,
        currentPage,
        setCurrentPageNum,
        totalPages,
        programs,
        programMapper,
        setCurrentProgramHandler
    };
}
