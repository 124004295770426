import { POST, GET } from "../";
export default class ProgressionAPI {
  static async getModuleProgressions(id) {
    try {
      const { data } = await GET(`/progression/modules/${id}`);
      return data;
    } catch (err) {
      return [];
    }
  }
  
  static async getAccountInfo() {
    try {
      const { data } = await GET(`/students/getAccountInfo`);
      return  data;

    } catch (err) {
      return [];
    }
  }

  static async getLandingPage() {
    try {
      const { data } = await GET(`/students/getLanding`);
      return  data;
    } catch (err) {
      return [];
    }
  }
}
