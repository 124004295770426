import React from "react";

import HorizontalCard from "../Card/Horizontal/HorizontalCard";
import styles from "./horizontal_content.module.css";
// import ImageCard from "../Card/Image/ImageCard";
function HorizontalContent() {
  return (
    <div className={styles.container} style={{  }}>
      <HorizontalCard index={0} />
      <HorizontalCard index={1} />
      <HorizontalCard index={2} />
    </div>
  );
}

export default HorizontalContent;
