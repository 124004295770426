import React, {useEffect, useState} from 'react';
import {useAuth} from "../context";
import {AnnouncementAPI} from "../services";

function useAnnouncements() {
    const {user, showModal} = useAuth();
    const [showAnnounceModalAdd, setAnnounceModalAdd] = useState(false);
    const [currentAnnounce, setCurrentAnnounce] = useState({});
    const [announcements, setAnnouncements] = useState([{
        _id: 1, topic: "title", content: "announce content", from: "A", to: "B",
    }, {
        _id: 2, topic: "title2", content: "announce content2", from: "A", to: "B",
    }, {
        _id: 3, topic: "title3", content: "announce content3", from: "A", to: "B",
    },]);
    const [topic, setTopic] = useState("");
    const [message, setMessage] = useState("");

    const [search, setSearch] = useState([]);

    const [classList, setClassList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [classIndex, setClassIndex] = useState(-1);
    const [groupIndex, setGroupIndex] = useState(-1);

    const sorter = (last, next) => {

    }

    const fetchAnnouncements = () => {
        user.role === 1 && AnnouncementAPI.forStudent().then(({data = []}) => {
            // console.log("Student announcements: ", data);
            setAnnouncements([...data]);
            setSearch([...data]);
        });

        user.role === 2 && AnnouncementAPI.forTeacher().then(({data = []}) => {
            // console.log("Teacher announcements:", data);
            setAnnouncements([...data]);
            setSearch([...data]);
        });

        user.role === 2 && AnnouncementAPI.getDataForCreation().then(({data = []}) => {
            const {groups = [], classes = []} = data;
            setClassList(classes);
            setGroupList(groups);
        });
    }

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    useEffect(() => {
        fetchAnnouncements();
    }, [showAnnounceModalAdd]);


    const createAnnouncementHandler = () => {
        const payload = {
            title: topic,
            message,
            timestamp: new Date().toLocaleDateString('en-AU'),
            groupId: groupIndex === -1 ? null : groupList[groupIndex]._id,
            classId: classIndex === -1 ? null : classList[classIndex]._id,
        }
        // console.log("new announcement:", payload);

        AnnouncementAPI.create(payload).then(res => {
            console.log(res);
            showModal(res.code === 200 ? "Success" : "Error", res.message, () => {
            }, false);
        });
    }

    return {
        user, showModal,
        showAnnounceModalAdd,
        setAnnounceModalAdd,
        currentAnnounce, setCurrentAnnounce,
        announcements, setAnnouncements,
        topic, setTopic,
        message, setMessage,
        search, setSearch,
        classList, setClassList,
        groupList, setGroupList,
        classIndex, setClassIndex,
        groupIndex, setGroupIndex,
        createAnnouncementHandler
    }
}

export default useAnnouncements;