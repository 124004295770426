import React from "react";

// import styles from "./chat.bubble.module.css";
import "./chatbubble.css";
import moment from "moment";
function ChatBubble({ message, ref, me, name, timestamp }) {
  return (
    <>
      <div
        ref={ref}
        className={
          me
            ? "talk-bubble tri-right round  right-in"
            : "talk-bubble tri-right round  left-in"
        }
      >
        <div className="talktext">
          {me ? (
            <></>
          ) : (
            <>
              <small>
                <p style={{ transform: "translateY(-10px)" }}>{name}</p>
              </small>
            </>
          )}
          <p></p>
          <p style={{ fontWeight: 500 }}>{message}</p>
          <small
            style={{
              textAlign: "end",
              display: "flex",
              justifyContent: "flex-end",
              color: me ? "#ffffff" : "#707070",
            }}
          >
            {moment(timestamp).format("DD/MM/YYYY hh:mm:ss")}
          </small>
        </div>
      </div>
    </>
  );
}

export default ChatBubble;
