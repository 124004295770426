import React, {useEffect, useLayoutEffect, useState} from "react";
import "./dropdown.css";
import {SmallDownArrowDropdown as DownArrowSvgIcon} from "../../../assets/svg";

function Dropdown({
                      placeHolder,
                      options = ["A", "B", "C"],
                      disabled,
                      indexHandler = (index) => console.log(index),
                  }) {

    const [toggle, setToggle] = useState(false);
    const [current, setCurrent] = useState(placeHolder || "");
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [myOptions, setMyOptions] = useState([...options]);
    // useEffect(() => {
    //     if (initialIdx !== -1) setCurrent(options[initialIdx]);
    //     else setCurrent(placeHolder);
    // }, []);

    // useEffect(()=>{},[]);
    // useEffect(() => {
    //     setCurrent(placeHolder || "");
    //     setMyOptions([...options]);
    // }, []);

    // useEffect(() => {
    //     setCurrent(myOptions[currentIndex]);
    // }, [currentIndex]);

    return (

        <div className="dropdown_1">

            <div
                className="dropdown-wrapper_1 dropbtn_1"
                onClick={disabled ? null : () => setToggle(!toggle)}
            >
                <div className="dropdown-text_1">{current}</div>
                {disabled ? (
                    <></>
                ) : (
                    <DownArrowSvgIcon style={{pointerEvents: "none"}}/>
                )}
            </div>
            <div className={"dropdown-content_1 show"}>
                {toggle && [...options].map((elem, index) => (
                    <a
                        key={"small_drop_" + index}
                        onClick={() => {
                            setCurrent(elem);
                            setCurrentIndex(index);
                            indexHandler(index);
                            setToggle(false);
                        }}
                    >
                        {elem}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default Dropdown;
