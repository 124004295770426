export default function moveElements(
    source,
    setSource,
    target,
    setTarget,
    _id
) {
    const tempList = [];
    for (const item of source) {
        if (target.map((el) => el._id).includes(_id)) continue;
        if (item._id === _id) {
            setTarget([...target, item]);
        } else {
            tempList.push(item);
        }
    }
    setSource([...source.filter(el => el._id !== _id)]);
}

export const moveElementByIndex = (
    sourceArr = [],
    destinationArr = [],
    sourceElemIdx) => {

    const elem = sourceArr[sourceElemIdx];
    destinationArr.push(elem);
    const sourceArrCopy = [...sourceArr.filter((el, idx) => idx !== sourceElemIdx)];
    sourceArr.splice(0, sourceArr.length);

    for (const item of sourceArrCopy) {
        sourceArr.push(item);
    }
}