import React, {useEffect, useLayoutEffect, useState} from 'react';
import {BasicAdminLayout} from "../../../layouts";
import {WideButtonWithIcon} from "../../../components";
import {Table} from 'react-bootstrap';
import {useHistory, useParams, useLocation} from "react-router-dom";
import styles from './main.module.css';
import {AdminAPI} from "../../../services";
import {BeatLoader} from "react-spinners";
import {JsonEditor} from "jsoneditor-react";
import CommonButton from "../../../components/Common/Button/CommonButton/CommonButton";

import {QUESTION_TYPES} from "../../../constants/question_sample";

function AddQuestionPage(props) {
    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const {moduleId, weekId, week, programId} = useParams();

    const [type, setType] = useState(params.get('type') || "1");
    const [question, setQuestion] = useState(QUESTION_TYPES.find((elem) => elem.type === (params.get('type') || "1"))?.question || {});
    const [loading, setLoading] = useState(false);


    const getQuestionInfo = () => {
    }

    const handleJsonChange = (key, value, parent, data) => {
        console.table({key, value, parent, data})
    }
    const handleChange = (newJson = {}) => {
        setQuestion(newJson);
    }

    const handleCreate = () => Promise.resolve()
        .then(() => AdminAPI.createQuestion({
            question,
            week, weekId,
            displayName: type,
            type,
            programId
            , moduleId
        }))
        .then((response) => window.alert(JSON.stringify(response, null, 2)))
        .then(() => window.location.reload());

    useEffect(() => {
        if (type === params.get('type')) return;
        window.location.href = (`/admin/question/add/${moduleId}/${weekId}/${week}/${programId}?type=${type}`);
    }, [type]);

    const MyEditor = () => (
        <JsonEditor value={question} onChange={handleChange}/>
    )


    if (loading) return <div
        style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        <BeatLoader color={"#1b3e4d"}/></div>;


    return (
        <BasicAdminLayout>
            <div style={{height: 105}}/>

            <div className={styles['title']}>Add Question</div>

            <div className={styles['main-panel']}>
                <Table responsive>
                    <thead>
                    <tr>
                        <td colSpan={1}>Key Name</td>
                        <td colSpan={5}>Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={1}>type</td>
                        <td colSpan={5}>
                            <select onChange={event => setType(event.target.value)} value={type}>
                                {QUESTION_TYPES.map((elem, index) => (
                                    <option key={elem.type} value={elem.type}>{elem.type} {elem.desc}</option>
                                ))}
                            </select>

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>question</td>
                        <td colSpan={5}>
                            <JsonEditor value={question} onChange={handleChange}/>
                            {/*{MyEditor(question)}*/}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>displayName</td>
                        <td colSpan={5}>

                            {type}

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>programId</td>
                        <td colSpan={5}>
                            {programId}


                        </td>
                    </tr>


                    <tr>
                        <td colSpan={1}>week</td>
                        <td colSpan={5}>

                            {week}

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>weekId</td>
                        <td colSpan={5}>
                            {weekId}
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>

            <div/>
            <div><CommonButton text={'Create'} click={handleCreate}/></div>
        </BasicAdminLayout>
    );
}

export default AddQuestionPage;