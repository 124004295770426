import React, {useEffect, useState} from 'react';
import {TeacherCreateLayout} from "../../../layouts";
import styles from "./creative.module.css";
import SimpleBar from "simplebar-react";
import {StudentVideoComment} from "../../../components";
import {ReactComponent as LikeSvgIcon} from "./like_icon.svg";
import {ReactComponent as CommentSvgIcon} from "./comment_icon.svg";
import {ReactComponent as SmallLockSvgIcon} from "./small_lock_icon.svg";
import {Link, useParams} from "react-router-dom";
import {VideoContentAPI} from "../../../services";
import {useHistory} from "react-router";

function CreativeVideoPage() {
    const {vid} = useParams();
    const history = useHistory();
    const [videoInfo, setVideoInfo] = useState({});
    const [relatedVideos, setRelatedVideos] = useState([]);

    const [comments, setComments] = useState([]);

    useEffect(() => {
        Promise.resolve()
            .then(() => VideoContentAPI.getById(vid))
            .then(({
                       data = {}, relatedVideos = []
                   }) => {
                setVideoInfo(data);
                setRelatedVideos(relatedVideos);
            })
    }, [vid]);

    const gotoOriginalVideoPage = (videoInfo) => {
        return (videoInfo?.useBB ? `${videoInfo?.bbLink}` : `${videoInfo.ytLink}`);
    }

    return (
        <TeacherCreateLayout title={'Creative Video'}>
            <div className={styles.container}>
                <div className={styles.video_container}>
                    <iframe className={styles.video_iframe}
                            src={videoInfo?.useBB ? videoInfo.bbEmbed : videoInfo.ytEmbed}
                            title="video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={styles.info_container}>
                    <div>
                        <h1>{videoInfo.title}</h1>
                        <p>{videoInfo.description}</p>
                    </div>
                    <div style={{display: "flex", flex: 1}}>{""}</div>
                    <a style={{display: 'flex', gap: 20, alignItems: 'center'}}
                       href={gotoOriginalVideoPage(videoInfo)} target={'_blank'}
                    >
                        <LikeSvgIcon/>
                    </a>
                    <a style={{display: 'flex', gap: 20, alignItems: 'center'}}
                       href={gotoOriginalVideoPage(videoInfo)} target={'_blank'}
                    >
                        <CommentSvgIcon/> Comment
                    </a>
                </div>
                <div className={styles.related_video_container}>
                    <h1>Related videos</h1>
                    <SimpleBar style={{maxWidth: "100%", height: 275}}>
                        <div style={{
                            display: 'flex', gap: 50, height: 265,
                        }}>
                            {[...relatedVideos].map((el, idx) => (
                                <div key={"relatedVideos_" + idx} className={styles.small_video_container}
                                     onClick={
                                         el.locked ? () => {
                                             } :
                                             () => history.push({pathname: `/student/videos/${el._id}`})}
                                >
                                    <div

                                        className={el.locked ? styles.disabled_related_video : styles.enabled_related_video}
                                        style={{
                                            position: 'relative',
                                            textAlign: 'center',
                                        }}>
                                        <img className={styles.small_video_iframe}
                                             style={{backgroundColor: "#707070"}}
                                             src={el.thumbnail} alt={""}/>
                                        {el.locked && <div style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            top: "50%",
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                            backgroundColor: "rgba(80,80,80,0.5)",
                                            color: '#fff',
                                            fontSize: 20,
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: 20
                                        }}>COMING SOON
                                        </div>}
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
                                        <h3>{el.title} </h3> {el.locked && <SmallLockSvgIcon/>}
                                    </div>
                                    <p>{el.description}</p>
                                </div>))}
                        </div>
                    </SimpleBar>
                </div>
                {/*<div className={styles.comment_container}>*/}
                {/*    <h1>Comments</h1>*/}
                {/*    <StudentVideoComment/>*/}
                {/*</div>*/}
            </div>
        </TeacherCreateLayout>
    )
}

export default CreativeVideoPage;