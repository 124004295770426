import React, {useEffect, useRef, useState} from 'react';
import {BasicAdminBackBtnLayout as BasicLayout} from "../../../layouts";
import styles from "./create_school.module.css";
import {SchoolSvgIcon} from "../../../assets/svg";
import {AdminAPI} from "../../../services";
import {useParams} from "react-router-dom";

const INPUT_STYLES = {
    borderRadius: 5,
}

const SUBMIT_STYLES = {
    borderRadius: 5,
    textIndent: 0,
    padding: "0 25px"
}

function EditSchoolPage() {

    const [schoolName, setSchoolName] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");

    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const programListRef = useRef([]);

    const [logoFile, setLogoFile] = useState();
    const [logoDataUrl, setLogoDataUrl] = useState();

    const imageInputRef = useRef();

    const {id = ""} = useParams();
    const init = () => {

        Promise.resolve()
            .then(() => AdminAPI.getPrograms())
            .then(({data = []}) => {
                programListRef.current = [...data];
                initSelectedPrograms(data.length);
            }).then(() => AdminAPI.getSchoolById(id))
            .then(({data = {}}) => {
                setCity(data.city);
                setSchoolName(data.schoolName);
                setCountry(data.country);
                setLogoDataUrl(data.avatar);

                const selectedProgramsRaw = programListRef.current.map((elem) => {
                    return data?.subscribedPrograms?.includes(elem._id);
                });

                setSelectedPrograms([...selectedProgramsRaw]);
            })
    }

    const initSelectedPrograms = (length = Number()) => {
        const arr = new Array(length);
        arr.fill(false);
        setSelectedPrograms([...arr]);
    }

    const infoJson = () => {

        const programIds = selectedPrograms.map((elem, index) => {
            if (elem === true) return programListRef.current?.[index]._id;
        });
        console.log(programIds);
        const processedIds = programIds.filter(elem => {
            if (elem) return elem;
        });
        console.log(processedIds);
        return {
            _id:id,
            schoolName,
            city,
            country,
            subscribedPrograms: [...processedIds]
        }
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const noSelection = selectedPrograms.every(el => el === false);
        if (noSelection) return window.alert("Please select at least one program");
        const formData = new FormData();
        formData.append('data', JSON.stringify(infoJson()));
        logoFile && formData.append('image', logoFile);

        Promise.resolve().then(() => AdminAPI.editSchool(formData))
            .then((res) => window.alert(res.msg));
    }

    useEffect(() => {
        init()
    }, []);

    return (
        <BasicLayout title={'Edit School'}>
            <div className={styles['container']}>
                <div className={styles['left-panel']}>

                    <div className={styles['logo-holder']}
                         onClick={() => {
                             if (imageInputRef.current) imageInputRef.current.click();
                         }}
                    >
                        {logoDataUrl && <img src={logoDataUrl} alt={"school logo preview"}/>}
                        {!logoDataUrl && <SchoolSvgIcon/>}
                    </div>
                </div>
                <div className={styles['right-panel']}>
                    <form className={styles['form']} onSubmit={submitHandler}>
                        <input ref={imageInputRef} type={'file'} hidden
                               accept={"image/*"}
                               onChange={e => {
                                   const file = e.target.files[0];
                                   setLogoFile(file);
                                   const reader = new FileReader();
                                   reader.addEventListener('load', () => {
                                       setLogoDataUrl(reader.result);
                                   }, false);
                                   if (file) reader.readAsDataURL(file);
                               }}
                        />
                        <input type="text"
                               required
                               onInput={e => setSchoolName(e.target.value)}
                               value={schoolName}
                               placeholder={"School Name..."} style={INPUT_STYLES}/>
                        <input
                            required
                            onInput={e => setCity(e.target.value)}
                            value={city}
                            type="text" placeholder={"School City..."} style={INPUT_STYLES}/>
                        <input
                            required
                            onInput={e => setCountry(e.target.value)}
                            value={country}
                            type="text" placeholder={"School Country..."} style={INPUT_STYLES}/>
                        <div>
                            <h3>Subscribe Programs</h3>
                            {programListRef.current.map((elem, index) => (
                                <div key={"p_s_" + index}
                                     style={{display: "flex", alignItems: "center", gap: "20px"}}
                                >

                                    <input type={'checkbox'} name={'programs'}
                                           onChange={() => {
                                               const newList = JSON.parse(JSON.stringify(selectedPrograms));
                                               newList[index] = !newList[index];
                                               setSelectedPrograms([...newList]);
                                           }}
                                           checked={selectedPrograms[index]}
                                    /> <label>{elem.programName}</label>
                                </div>))}
                        </div>
                        <input type="submit" value={'Submit Edited School'} style={SUBMIT_STYLES}/>
                    </form>
                </div>
            </div>
        </BasicLayout>
    );
}

export default EditSchoolPage;