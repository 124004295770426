import React from "react";
import "./wide_btn_with_icon.css";
import {Link, useHistory} from "react-router-dom";

function WideButtonWithIcon({
                                text = "Not set",
                                svg,
                                btnW = "291",
                                iconW = "33",
                                iconH = "33",

                            }) {
    const history = useHistory();
    return (
        // <div className="eap__wide-btn-container" onClick={() => history.push(href)}>

            <div className="eap__wide-btn-container">
                <div style={{maxWidth: iconW + "px", maxHeight: iconH + "px"}}>
                    {svg}
                </div>
                <div>
                    <span>{text}</span>
                </div>
            </div>

    );
}

export default WideButtonWithIcon;
