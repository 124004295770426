import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ProgressionAPI } from "../../../services";
import { useParams } from "react-router-dom";
import styles from "./progression.module.css";
import moment from "moment";
function ModuleProgression() {
  const { id } = useParams();
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [avg1, setAvg1] = useState(0);
  const [avg2, setAvg2] = useState(0);

  const calcDiffDays = (start, end) => {
    const startDate = moment(start);
    const endDate = moment(end);
    return endDate.diff(startDate, "days");
  };
  useEffect(() => {
    ProgressionAPI.getModuleProgressions(id).then((data) => {
      setState(data);
  
      const arr1 = data.moduleProgression.map((m) => {
        return (
          m.questionProgression.reduce((prev, curr, index, arr) => {
            if (prev.timeTaken) return prev.timeTaken + curr.timeTaken;
            else return prev + curr.timeTaken;
          }) / 10
        );
      });
  
      const arr2 = data.moduleProgression.map((m) => {
        return m.questionProgression.reduce((prev, curr, index, arr) => {
          if (prev.score) return prev.score + curr.score;
          else return prev + curr.score;
        });
      });
      let sum1 = 0;
      for (let i = 0; i < arr1.length; i++) {
        sum1 += parseInt(arr1[i], 10); //don't forget to add the base
      }

      setAvg1(sum1 / arr1.length);
      let sum2 = 0;
      for (let j = 0; j < arr2.length; j++) {
        sum2 += arr2[j]; //don't forget to add the base
      }
      setAvg2(sum2 / arr2.length);
      setLoading(false);
    });
  }, []);

  return loading === true ? null : (
    <div className={styles.container}>
      <Table>
        <thead>
          <tr>
            <td>Student Name</td>
            <td>Week</td>
            <td>Module</td>
            <td>Total Time Taken</td>
            <td>Total Mistakes Made</td>
            <td>Number of questions in module</td>
            <td>Average time taken</td>
            <td>Average Score</td>
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          {state.moduleProgression.map((m) => {
            return (
              <tr key={m._id}>
                <td>{m.belongsTo?.studentName}</td>
                <td>{state.week.weekName}</td>
                <td>{m.moduleId.moduleName}</td>
                <td>
                  {m.questionProgression.reduce((prev, curr, index, arr) => {
                    if (prev.timeTaken) return prev.timeTaken + curr.timeTaken;
                    else return prev + curr.timeTaken;
                  }) / 10}{" "}
                  seconds
                </td>
                <td>
                  {m.questionProgression.reduce((prev, curr, index, arr) => {
                    if (prev.score) return prev.score + curr.score;
                    else return prev + curr.score;
                  })}
                </td>
                <td>{m.questionProgression.length}</td>
                <td>{avg1}</td>
                <td>{avg2}</td>
                <td>
                  {(() => {
                    const timestamp = m.belongsTo?._id.substring(0, 8);
                    const date1 = new Date(parseInt(timestamp, 16) * 1000);

                    const date2 = new Date(Date.now());
                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(
                      diffTime / (1000 * 60 * 60 * 24)
                    );
                    return diffDays;
                  })()}
                 
                  Day(s)
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ModuleProgression;
