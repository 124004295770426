import React from "react";
import {IconButton} from "../../";
import messenger from "../../../assets/img/messenger.png";
import notice from "../../../assets/img/notice.png";
import teacher from "../../../assets/img/teacher.png";
import weekly from "../../../assets/img/weekly.png";
import vocab from "../../../assets/img/vocab.png";
function ContentBar() {
  return (
    <div className="flex flex-row space-x-6">
      <IconButton icon={weekly} url="/student/weekly-content" text="Weekly Content" />
      <IconButton icon={vocab} text="Vocabulary" />
      <IconButton icon={messenger} text="Messenger" url="/student/messenger" />
      <IconButton icon={teacher} text="Teacher Announcement" />
      <IconButton icon={notice} text="Notice Board" />
    </div>
  );
}

export default ContentBar;