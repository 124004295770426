import { POST, GET } from "../";
export default class WeekAPI {
  static async getAll() {
    try {
      const { data } = await GET(`/weeks/getAll`);
      return data;
    } catch (err) {
      return [];
    }
  }
}
