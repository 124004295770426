import React from "react";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import styles from "./styles.module.css";

function ContinueModule({mods, label, future, week}) {
    return (
        <div className="flex flex-col space-y-3 eap__shadow p-8" style={{borderRadius: 20, backgroundColor: "#fff"}}>
            <span style={{fontSize: 25}}>{label}</span>
            <SimpleBar style={{maxWidth: '100%'}}>
                <div className="flex flex-row space-x-8">
                    {mods.length > 0 && mods.map((mod, index) => {
                        return (
                            <Link key={index + "module"} to={`/modules/${mod._id}`}>
                                <div
                                    className={future === true ? styles.card_future : styles.card}
                                >
                                    <span style={{fontSize: 20, fontWeight: 'bold'}}>Week {week}</span>
                                    <span style={{fontSize: 20, textTransform: 'uppercase'}}>{mod.moduleName}</span>
                                </div>
                            </Link>
                        );
                    })}
                    {
                        mods.length < 1 && <div

                            className={`flex flex-col space-y-3 p-4 w-32 rounded-md`}

                            style={{
                                minHeight: 175, maxHeight: 175, minWidth: 175, maxWidth: 175,
                                alignItems: 'center', justifyContent: 'center',
                                color: '', borderRadius: 14,
                                // backgroundColor: "#707070"
                            }}
                        >
                            {/*<span style={{fontSize: 20, fontWeight: 'bold'}}>No Content</span>*/}
                            <span style={{fontSize: 20, textTransform: 'capitalize'}}>Empty</span>
                        </div>
                    }
                </div>
            </SimpleBar>
        </div>
    );
}

export default ContinueModule;
