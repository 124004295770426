import React from "react";
import "./chat_avatar.css";
import { PersonSvgIcon } from "../../../assets/svg";

function ChatAvatar({ online, src }) {
  return (
    <div className="chat-avatar-wrapper eap__shadow">
      <div className="chat-avatar-img">
        {src ? <img style={{borderRadius:"50%"}} src={src} alt="" /> : <PersonSvgIcon />}
      </div>
      <div
        className={
          online ? "chat-avatar-status online" : "chat-avatar-status offline"
        }
      ></div>
    </div>
  );
}

export default ChatAvatar;
