import React, { useEffect, useState } from "react";
import { TeacherCreateLayout } from "../../../layouts";
import styles from "./profile.module.css";
import { useAuth } from "../../../context";
import { FillTextField, PercentDonut } from "../../../components";
import { StudentAPI } from "../../../services";
import { mapperStudentEvaluation } from "../../../utils";

import { donatChart, radarChart, originDataSet } from './chart.helper'
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as SmallDropdownArrow } from "./small_down_arrow_icon.svg";
import { BeatLoader } from "react-spinners";

function ProfilePage() {

    const [rawStudent, setRawStudent] = React.useState({});
    const history = useHistory();

    const { user, showModal } = useAuth();

    const [name, setName] = React.useState(user.studentName || "No Data");
    const [mobile, setMobile] = React.useState(user.mobile ?? "4123123123");
    const [email, setEmail] = React.useState(user.email ?? "teacher@email.com");
    const [avatar, setAvatar] = React.useState(user.avatar || "");

    const [timeSpend, setTimeSpend] = React.useState([]);
    const [className, setClassName] = React.useState(user.className ?? "Class Name");
    const [schoolName, setSchoolName] = React.useState(user.schoolName ?? "School Name");
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [editMode, setEditMode] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const [showWeekDropdown, setShowWeekDropdown] = useState(false);


    const editPassword = () => {
        if (currentPassword.length === 0 || newPassword.length === 0 || confirmPassword.length === 0) {
            showModal("Warning", "No empty password allowed", () => {
            }, false);
            return;
        }
        if (newPassword !== confirmPassword) {
            showModal("Warning", "New password and confirm password are not match", () => {
            }, false);
            return;
        }
        StudentAPI.changePassword({ currentPassword, newPassword }).then((res) => {
            showModal("Info", res.msg, () => {
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
            }, false);
        });
    };

    const [currentWeek, setCurrentWeek] = useState(1);
    const [completedNum, setCompletedNum] = useState(0);
    const [totalNum, setTotalNum] = useState(1);
    const [progressDesc, setProgressDesc] = useState("");
    const [weeklyProgress, setWeeklyProgress] = React.useState({});

    const [currentClassWeek, setCurrentClassWeek] = useState(1);
    const [totalClassWeek, setTotalClassWeek] = useState(1);

    const [performance, setPerformance] = useState([]);
    const [performanceWeekCounts, setPWeekCounts] = useState(1);
    const [performanceWeek, setPerformanceWeek] = useState(1);


    useEffect(() => {

        Promise.resolve(() => setLoading(true))
            .then(() => StudentAPI.getMyInfoById(user._id))
            .then(({
                student,
                stats
            }) => {
                // console.log(student, stats);
                initStudent(student);
                initStats(stats);
            }).finally(() => setLoading(false));

        Promise.resolve(() => setLoading(true))
        .then(() => StudentAPI.getPerformance(user._id, 1))
        .then(({
            data,
            numberOfWeeks
        }) => {
            // console.log(data, numberOfWeeks);
            setPWeekCounts(numberOfWeeks);
            initPerformance(data);
        }).finally(() => setLoading(false));
    }, []);

    const initStudent = (student = {}) => {
        setRawStudent(student);
        setName(student?.studentName || "Anonymous");
        setMobile(student?.mobile || "Not Found");
        setEmail(student?.email || "Not Found");
        setAvatar(student?.avatar);
        setClassName(student?.class || "Not Assigned");
        setSchoolName(student?.school || "Student School Name");
        setWeeklyProgress(student?.weeklyProgress);

        setCurrentClassWeek(student?.classCurrentWeek || 1);
        setTotalClassWeek(student?.totalWeeks || 1);

        const { weeklyProgress } = student || {};
        setCurrentWeek(weeklyProgress?.currentWeek || 1);
        setProgressDesc(weeklyProgress?.evaluation || "No data");
        setCompletedNum(weeklyProgress?.questionsCompletedForWeek || 100);
        setTotalNum(weeklyProgress?.questionIncurrentWeek || 100);
    }

    const initPerformance = (performance) => {
        setPerformance(performance || []);
        radarChart(performance || []);
        // donatChart(performance);
    }

    useEffect(() => {
        Promise.resolve(() => setLoading(true))
            .then(() => StudentAPI.getPerformance(user._id, performanceWeek))
            .then(({ data, numberOfWeeks }) => {
                // console.log(data, numberOfWeeks);
                initPerformance(data);
            }).finally(() => setLoading(false));
    }, [performanceWeek]);

    const initStats = (stats = []) => {
        // stats = stats || [];
        const dataArray = stats.data || [];
        // const processed = [...stats.data].map((el) => {
        const processed = [...dataArray].map((el) => {
            return {
                moduleName: el._id,
                percentage: Number(el.totalTime / 60).toFixed(2)
            }
        })
        donatChart(processed);
    }

    return (<TeacherCreateLayout title="Student profile" schoolAvatar={user?.schoolId?.avatar}>
        <div className={styles.container}>
            <div className={styles.avatar}>
                <div className={styles.avatar_container}>
                    <div className={styles.avatar_holder}>
                        <img
                            src={avatar}
                            alt="portrait"
                            onError={(e) => (e.target.src = "/img/broken-image.png")}
                        />
                        {/* <PersonSvgIcon /> */}
                    </div>
                    {" "}
                    <div className={styles.name}>{name}</div>
                </div>
            </div>
            <label className={styles.phone_label}>Phone(ID)</label>
            <div className={styles.phone}>
                <FillTextField
                    value={mobile}
                    readOnly
                    onInput={editMode ? (e) => setMobile(e.target.value) : () => {
                    }}
                />{" "}
            </div>
            <label className={styles.email_label}>Email</label>
            <div className={styles.email}>
                {" "}
                <FillTextField
                    value={email}
                    readOnly
                    onInput={editMode ? (e) => setEmail(e.target.value) : () => {
                    }}
                />
            </div>
            <label className={styles.class_label}>Class</label>
            <div className={styles.class_name}>
                {" "}
                <FillTextField
                    value={className}
                    readOnly
                    onInput={editMode ? (e) => setClassName(e.target.value) : () => {
                    }}
                />
            </div>
            <label className={styles.school_label}>School</label>
            <div className={styles.school_name}>
                {" "}
                <FillTextField value={schoolName} readOnly />
            </div>

            <label className={styles.change_pass_label}>Change password</label>

            <label className={styles.current_pass_label}>Current password</label>

            <div className={styles.current_pass}>
                <FillTextField
                    type="password"
                    value={currentPassword}
                    onInput={(e) => setCurrentPassword(e.target.value)}
                />
            </div>
            <label className={styles.new_pass_label}> New password</label>
            <div className={styles.new_pass}>
                <FillTextField
                    type="password"
                    value={newPassword}
                    onInput={(e) => setNewPassword(e.target.value)}
                />{" "}
            </div>
            <label className={styles.re_new_pass_label}>
                Re-enter new password
            </label>
            <div className={styles.re_new_pass}>
                <FillTextField
                    type="password"
                    value={confirmPassword}
                    onInput={(e) => setConfirmPassword(e.target.value)}
                />{" "}
            </div>
            <div className={styles.confirm_btn}>
                <div className={styles.confirm_btn_body} onClick={editPassword}>
                    Confirm
                </div>
            </div>
            <label className={styles.class_overview_label}>Time spent</label>
            <label className={styles.class_overview_label_1}>
                Reflecting your time working on each content section.
            </label>
            <label className={styles.student_progression_label}>
                Student progression
            </label>
            <label className={styles.student_score_label}>Student score</label>

            <div className={styles.class_overview}></div>

            <div className={styles.time_spend}>
                <div style={{ display: "flex" }}>
                    <canvas id="time_pie_chart" style={{ width: 180 + "px" }} />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexFlow: "column wrap-reverse",
                        gap: 15 + "px",
                        columnGap: "85px",
                        height: "230px",
                        padding: "20px 0",
                    }}
                >
                    {[...originDataSet].map((item, index) => (<div
                        key={index + "_data_label"}
                        style={{ display: "flex", alignItems: "center", gap: "20px" }}
                    >
                        <div
                            style={{
                                width: 35 + "px", height: 35 + "px", backgroundColor: item.color, borderRadius: "10px",
                            }}
                        >{" "}</div>

                        <span>{item.label}</span>
                    </div>))}
                </div>
            </div>
            <div className={styles.student_progression}>
                <div className={styles.progress1}>
                    Student progression:{"    "}
                    <span style={{ color: "#FCB216", marginLeft: "10%" }}>
                        Week {currentWeek}
                    </span>
                </div>
                <div className={styles.progress2}>
                    Current class progression: Week {currentClassWeek}/{totalClassWeek}
                </div>
                <div className={styles.progress_line} />
                <div className={styles.progress3}>
                    Weekly learning progression:{" "}
                    <PercentDonut
                        percent={Math.floor((completedNum / totalNum) * 100)}
                    />
                </div>
                <div className={styles.progress4}> {progressDesc}</div>
            </div>

            <div className={styles.student_score}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: 'flex', gap: 50 }}>
                        <div>
                            WEEK {performanceWeek}
                        </div>
                        <div style={{ position: "relative" }}>
                            <div onClick={() => setShowWeekDropdown(!showWeekDropdown)}><SmallDropdownArrow /></div>
                            {showWeekDropdown && <div
                                className={styles.week_dropdown_item_list}
                            >
                                {[...new Array(performanceWeekCounts)]
                                    .map((el, idx) => (<div key={"week_drop_" + idx}
                                        className={styles.week_dropdown_item}

                                        onClick={() => {
                                            setPerformanceWeek(idx + 1);
                                            setShowWeekDropdown(false);
                                        }}
                                    >Week {idx + 1}</div>))}
                            </div>}
                        </div>
                    </div>
                    <div style={{ paddingRight: "100px" }}>
                        {!loading && <canvas id={'radar_chart'} style={{ width: 211 + "px", height: "321px" }} />}
                        {loading && <BeatLoader />}
                    </div>
                </div>
                <div style={{ borderBottom: "1px solid #000", width: '100%', margin: "50px 0" }} />
                <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%" }}>
                    {performance.map((t, index) => (<details key={index + t.moduleName}>
                        <summary style={{ position: "relative" }}>

                            <span>
                                {(t.moduleName)}
                            </span>

                            <span style={{
                                color: mapperStudentEvaluation(t.evaluation).color,
                                position: "absolute",
                                left: 20 + "%"
                            }}>{mapperStudentEvaluation(t.evaluation).msg}</span>
                        </summary>
                        <div style={{ marginBottom: "1em" }}><p style={{ marginTop: "1em" }}> {t.comment}</p>
                            <p style={{ marginTop: "1em" }}>{t.longComment}</p></div>
                    </details>))}
                </div>
            </div>

        </div>
    </TeacherCreateLayout>);
}

export default ProfilePage;
