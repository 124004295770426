import React, {useEffect, useState} from "react";
import {StudentAudioPlayer} from "../../../../index";

const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];

function Type3({
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }, auto = true
               }) {
    const [answer, setAnswer] = useState([]);
    const [scoreLocal, setScoreLocal] = useState(0);

    useEffect(() => {
        const answers = question.options.map((o) => {
            return {...o, attempted: false, correct: false};
        });
        setAnswer(answers);
    }, []);
    const handleLock = (copy) => {
        let lockValue = false;
        for (const x of copy) {
            if (x.isTrue) {
                if (x.attempted !== true) lockValue = true;
            }
        }
        console.log(lockValue);
        setLock(lockValue);
    };
    const checkAnswer = (index) => {
        const copy = [...answer];
        copy[index] = {
            ...copy[index], attempted: true, correct: copy[index].isTrue,
        };

        if (copy[index].isTrue) handleLock(copy); else {
            setScoreLocal(scoreLocal + 1);
            setScore(scoreLocal + 1);
        }

        setAnswer(copy);
    };


    return (<>
        <div className="w-full flex flex-row">
            <div className="w-1/12"/>
            <div className="w-10/12 bg-white p-4 flex flex-col  space-y-8">
                <div>{question.prompt}</div>
                <div className="flex flex-row w-full h-full divide-x">
                    <div className="flex flex-col w-1/2 p-2 space-y-5">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            height: "100%",
                            width: "100%"
                        }}>
                            <StudentAudioPlayer audioSrc={question.recordingUrl} auto={auto}/>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2 p-2 space-y-4 justify-center">

                            {answer.map((o, i) => (<div className="flex flex-row space-x-5" key={i}>
                                <span>{alphabet[i]}.</span>
                                {!o.attempted && (
                                    <span className={"p-2 rounded-md "} style={{backgroundColor: "#f7f7f7"}}
                                    >
                      <button onClick={() => checkAnswer(i)}>{o.label}</button>
                    </span>)}
                                {o.attempted && !o.correct && (<span
                                    className={"p-2 rounded-md text-white"}
                                    style={{backgroundColor: "#e4b243"}}
                                >
                      <button onClick={() => checkAnswer(i)}>{o.label}</button>
                    </span>)}
                                {o.attempted && o.correct && (<span
                                    className={"p-2 rounded-md  text-white"}
                                    style={{backgroundColor: "#88b380"}}
                                >
                      <button onClick={() => checkAnswer(i)}>{o.label}</button>
                    </span>) }
                            </div>))}

                    </div>
                </div>
            </div>
            <div className="w-1/12"/>
        </div>
    </>);
}

export default Type3;
