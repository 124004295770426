import React from "react";
import styles from "./content.module.css";
import SimpleBar from "simplebar-react";
import {Link} from "react-router-dom";

function ContentComponent({active, modules = []}) {
    return (
        <SimpleBar style={{maxWidth: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", gap: 2}}>
                {modules && modules.length > 0
                    ? [...modules].map((elem) => {
                        return elem.moduleName ? (
                                active ? (
                                        <Link key={elem._id + "link"} to={`/modules/${elem._id}`}>
                                            <div
                                                key={elem._id}
                                                className={elem.completed === true ? styles.active_completed : styles.active_uncompleted}
                                            >
                                                {elem.moduleName}
                                            </div>
                                        </Link>
                                    ) :
                                    (
                                        <div
                                            key={elem._id}
                                            className={styles.inactive}
                                        >
                                            {elem.moduleName}
                                        </div>
                                    )
                            ) :
                            null;
                    })
                    : //   <div className={styles.inactive}>No data</div>
                    null}
            </div>
        </SimpleBar>
    );
}

export default ContentComponent;
