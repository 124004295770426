import React, {useEffect, useRef, useState} from "react";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {ReactComponent as MinusSvgIcon} from "./minus_icon.svg";
import {ReactComponent as PlusSvgIcon} from "./plus_icon.svg";
import styles from './viewer.module.css';

function AssetViewer({url}) {
    const [showImg, setShowImg] = useState(false);
    const coordinateRef = useRef({
        selected: null,
        x_pos: 0,
        y_pos: 0,
        x_elem: 0,
        y_elem: 0
    })

    const imgRef = useRef(null);

    function _drag_init(elem) {
        // Store the object of the element which needs to be moved
        coordinateRef.current.selected = elem;
        coordinateRef.current.x_elem = coordinateRef.current.x_pos - coordinateRef.current.selected.offsetLeft;
        coordinateRef.current.y_elem = coordinateRef.current.y_pos - coordinateRef.current.selected.offsetTop;
    }

    // Will be called when user dragging an element
    function _move_elem(e) {
        coordinateRef.current.x_pos = e.pageX;
        coordinateRef.current.y_pos = e.pageY;
        if (coordinateRef.current.selected !== null) {
            coordinateRef.current.selected.style.left = (coordinateRef.current.x_pos - coordinateRef.current.x_elem) + 'px';
            coordinateRef.current.selected.style.top = (coordinateRef.current.y_pos - coordinateRef.current.y_elem) + 'px';
        }
    }

    // Destroy the object when we are done
    function _destroy() {
        coordinateRef.current.selected = null;
    }

    useEffect(() => {

// Bind the functions...
        if (imgRef.current) {
            imgRef.current.onmousedown = () => {
                _drag_init(imgRef.current);
                return false;
            };
            document.onmousemove = _move_elem;
            document.onmouseup = _destroy;
            imgRef.current.onmouseup = _destroy;
            // document.ondragend = _destroy;
        }

    }, [imgRef.current]);

    useEffect(() => {
        if (imgRef.current) {
            imgRef.current.onmousedown = () => {
                _drag_init(imgRef.current);
                return false;
            };
            document.onmousemove = _move_elem;
            document.onmouseup = _destroy;
            imgRef.current.onmouseup = _destroy;
            // document.ondragend = _destroy;
        }
    },[])


    return (
        <div className="w-full h-full" style={{display: 'flex', alignItems: 'center'}}>
            {showImg && <div
                className={styles['close-pointer']}
                style={{
                backgroundColor: "rgba(70,70,70,0.8)",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                position: 'fixed',
                    overflow: 'scroll',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
            }} onClick={() => setShowImg(false)}
            onScroll={(event)=> event.stopPropagation()}
            >
                <img ref={imgRef} src={url} alt={'question img'}
                     style={{
                         width: "50%",
                         height: "auto",
                         position: "absolute",
                         top: 200,
                         // left: "25%",
                         // right: "25%",
                         left:'auto',
                         right:'auto',
                         bottom: 200,
                         zIndex: 3000,
                         objectFit:'cover',
                         // marginLeft:'auto',
                         // marginRight:'auto'
                         // transitionDuration: 0
                     }}
                     className={styles.float_img}
                     onClick={(event) => event.stopPropagation()}
                />
            </div>}
            {/*{showImg && <div*/}
            {/*    className={styles.float_close_btn}*/}
            {/*    style={{*/}
            {/*        display: 'flex',*/}
            {/*        alignItems: 'center',*/}
            {/*        justifyContent: 'center',*/}
            {/*        padding: "10px 20px",*/}
            {/*        position: "fixed",*/}
            {/*        bottom: 200,*/}
            {/*        left: "50%",*/}
            {/*        transform: 'translateX(-50%)',*/}
            {/*        textAlign: 'center',*/}
            {/*        // borderRadius:"50%",*/}
            {/*        borderRadius: 15,*/}
            {/*        zIndex: 4,*/}
            {/*        border: "5px solid #ea7777",*/}
            {/*        color: "#ea7777",*/}
            {/*        fontSize: 20,*/}
            {/*        // transitionDuration: 0*/}
            {/*    }} onClick={() => setShowImg(false)}>Close image</div>}*/}
            <TransformWrapper

                // centerOnInit={true}
                // initialScale={1}
                // initialPositionX={0}
                // initialPositionY={0}

            >
                {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                    <div className="flex flex-col space-y-3 w-full">
                        <div
                            className={"w-full"}
                            // onClick={() => window.open(url, 'name', 'width="auto",height=800')}
                            onClick={() => setShowImg(true)}
                        >
                            <div
                                className={"w-full flex"}
                                style={{height: 180, overflow: "hidden"}}>
                                <TransformComponent
                                    wrapperStyle={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: "100%"
                                    }}>

                                    {!showImg &&
                                        <img src={url}
                                             style={{width: "100%", height: "auto"}}
                                             alt="test"/>}

                                </TransformComponent></div>
                        </div>
                        <div className={styles.btn_row}>
                            <button
                                className={styles.btn}
                                onClick={() => zoomIn()}
                            >
                                <PlusSvgIcon/>
                            </button>
                            <button
                                className={styles.btn}

                                onClick={() => zoomOut()}
                            >
                                <MinusSvgIcon/>
                            </button>
                            {/*<button*/}
                            {/*    className={styles.btn}*/}

                            {/*    onClick={() => resetTransform()}*/}
                            {/*>*/}
                            {/*   Reset*/}
                            {/*</button>*/}
                        </div>
                    </div>
                )}
            </TransformWrapper>
        </div>
    );
}

export default AssetViewer;
