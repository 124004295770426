import React from "react";
// import "./text_field.css";
import "../common.css";
function TextField({
  // label = "Name",
  type = "text",
  value = "",
  placeholder = "Input here...",
  required,
  inputHandler = () => {},
  keydownHandler = () => {},
  // changeHandler = () => {},
}) {
  return (
    <>
      {/* <label>{label}</label> */}
      {required ? (
        <input
          type={type}
          required
          value={value}
          onInput={inputHandler}
          // onChange={changeHandler}
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          value={value}
          onInput={inputHandler}
          onKeyDown={keydownHandler}
          // onChange={changeHandler}
          placeholder={placeholder}
        />
      )}
    </>
  );
}

export default TextField;
