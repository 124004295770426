import React from "react";
// import "./management_card.css";
import {TeacherClassItem} from "../..";
import {ScrollCardView} from "../../../layouts";
import {TeacherManagementCardTemplate} from "../..";
import {Loading} from "../..";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../context";

function ManagementCard({
                            loading = false,
                            href = "#",
                            classList = [],
                        }) {

    const history = useHistory();
    const {roleMapper} = useAuth();

    const getContents = (classList) => {
        if (!classList || classList.length < 1) {
            return <div>No classes.</div>
        }
        return (<ScrollCardView
            style={{display: "flex", flexDirection: "column", gap: 36 + "px"}}
        >
            {[...classList].map((elem, index) => (
                <div key={"class_manage_item_" + index} onClick={() => history.push(`/${roleMapper()}/edit/class/${elem._id}`)}>
                    <TeacherClassItem
                        name={elem.name}
                        src={elem.src}
                        week={elem.currentUnlocked ? elem.currentWeekStats?.weekNumber : "Loading"}
                        percent={elem ? (elem?.currentWeekStats ? (elem.currentWeekStats.percentage ? elem.currentWeekStats.percentage : 0) : 0) : 0}
                        // TODO: add reminder link here
                        // href={`/teacher/edit/class/${elem._id}`}
                        _id={elem._id}
                    />
                </div>
            ))}
        </ScrollCardView>)
    }

    return (
        <TeacherManagementCardTemplate
            title={"Class Management"}
            createBtnText={"Create class"}
            createBtnHref={`/${roleMapper()}/create/class`}
            loading={loading}
        >
            {getContents(classList)}
        </TeacherManagementCardTemplate>
    );
}

export default ManagementCard;
