import React from "react";
import { BasicLayout } from "../";
import { BackButton, SubHeaderBar } from "../../components";
import styles from "./create_template.modules.css";
function CreatePageTemplate({
  title,
  children,
  href = "/teacher",
  pagination,
}) {
  return (
    <BasicLayout >
      <div className={".eap__management-container-col"}>
        <div className={"teacher_create_template_line_1"}>
          <BackButton />
          <h1>{title}</h1>
          <div style={{ display: "flex", flex: "1" }}/>
          {pagination ?? null}
        </div>
        <div className="teacher_create_template_line_2">{children}</div>
      </div>
    </BasicLayout>
  );
}

export default CreatePageTemplate;
