import React from "react";

// import styles from "./style.module.css";
function EmptyOption({label, handler, index, shake, bgColor}) {
    // const withShake = "p-2 bg-gray-200 rounded-md m-2";
    // const withoutShake = "p-2 bg-gray-200 rounded-md m-2";

    return (
        <span
            className={`p-2 bg-gray-200 rounded-md m-2`}
            style={{
                backgroundColor: bgColor ? bgColor : "#f6f6f6",
                color: bgColor ? "#fff" : "",
                minWidth: "100%"
            }}
        >
      <button onClick={() => handler(index)}>{label}</button>
    </span>
    );
}

export default EmptyOption;
