import { AdminModuleProgression } from "../../../components";

import React from 'react'

function ModuleProgression() {
    return (
        <AdminModuleProgression />
    )
}

export default ModuleProgression
