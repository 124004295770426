import CODES from "./codes";

export default function getCountryCode(countryName = "") {
    const {countries = []} = CODES;
    // for (const country of countries) {
    //   if (String(country.name).toUpperCase().includes(String(countryName).toUpperCase())) {
    //     return country.code;
    //   }
    //   return "+61";
    // }
    return countries?.find((elem) =>
            String(elem.name).toLowerCase()
                .includes(String(countryName).toLowerCase()))?.code || "+61";
}
