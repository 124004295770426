import React, {useEffect, useState} from 'react';
import {BasicAdminLayout} from "../../../layouts";
import {WideButtonWithIcon} from "../../../components";
import {Table} from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import styles from './main.module.css';
import {AdminAPI} from "../../../services";
import {BeatLoader} from "react-spinners";

function AdminMainPage(props) {
    const history = useHistory();

    const Card = ({

                      title = "Management",
                      createBtnText = "Create",
                      createUrl = "/admin",
                      children
                  }) => {
        return (<div className={styles['card']}>
            <div style={{display: 'flex'}}>
                <h3>{title}</h3>
                <div style={{display: 'flex', flex: 1}}/>
                <div style={{display: 'flex', marginLeft: "auto"}}
                     onClick={() => history.push(createUrl)}
                ><WideButtonWithIcon
                    text={createBtnText}/></div>
            </div>
            <div>{children}</div>
        </div>)
    }

    const SchoolListComponent = () => {
        return (<div className={styles['school-list']}>
            {schoolList.map((elem, index) => (
                <div key={'school_l_' + index}
                     className={styles['school-item']}
                >
                    <div className={styles['school-logo-holder']}>
                        <img className={styles['school-logo']} src={elem.avatar} alt={'school avatar'}/>
                    </div>
                    <div className={styles['school-text']}><span>Name</span><span>{elem.schoolName}</span></div>
                    <div className={styles['school-text']}><span>City</span> <span>{elem.city}</span></div>
                    <div className={styles['school-text']}><span>Country</span><span>{elem.country}</span></div>
                    <div className={elem.isDisabled ? styles['school-inactive'] : styles['school-active']}>
                        <span>{elem.isDisabled ? "Disabled" : "Active"}</span></div>
                    <div className={styles['add-principal-btn']}
                         onClick={() => history.push(`admin/create/principal/${elem._id}`)}
                    >Add Principal
                    </div>

                    <div className={styles['add-principal-btn']}
                         onClick={() => history.push(`admin/edit/school/${elem._id}`)}
                    >Edit School
                    </div>

                    <div className={styles['add-principal-btn']}
                         onClick={() => alterDisabled({id: elem._id, isDisabled: elem.isDisabled})}
                    >disable/enable
                    </div>
                </div>
            ))}
        </div>);
    }

    const alterDisabled = ({id, isDisabled}) => {
        const prompt = isDisabled ? "Do you want to enable school?" : "Do you want to disabled school?";
        window.confirm(prompt) && Promise.resolve()
            .then(() => setLoading(true))
            .then(() => AdminAPI.disableSchool({id, isDisabled: !isDisabled}))
            .then((res) => {
                setLoading(false);
                window.confirm(res.msg) && window.location.reload();
            });
    }

    const [schoolList, setSchoolList] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => AdminAPI.getSchools())
            .then(({data = []}) => {
                setSchoolList(data);
            })
            .then(() => AdminAPI.getAllStudents())
            .then(({results}) => setStudentList(results || []))
            .then(() => AdminAPI.getAllTeachers())
            .then(({results}) => setTeacherList(results || []))
            .finally(() => setLoading(false))

    }, []);

    const [studentList, setStudentList] = useState([]);

    const [teacherList, setTeacherList] = useState([]);

    const forceChangeStudentPassword = ({id, email}) => {
        const password = window.prompt(`Please enter the new password for ${email}`);
        password && password !== "" && Promise.resolve()
            .then(() => AdminAPI.forceChangeStudentPass({id, password}))
            .then((res) => window.alert(res.msg));
    }

    const forceChangeStudentEmail = ({id, email}) => {
        const _email = window.prompt(`Please enter the new email for ${email}`, email);
        _email && _email !== "" && Promise.resolve()
            .then(() => AdminAPI.forceChangeStudentEmail({id, email: _email}))
            .then((res) => window.alert(res.msg))
            .then(() => window.location.reload());
    }

    const forceChangeTeacherPassword = ({id, email}) => {
        const password = window.prompt(`Please enter the password for ${email} you want to change into`);
        password && password !== "" && Promise.resolve()
            .then(() => AdminAPI.forceChangeTeacherPass({id, password}))
            .then((res) => window.alert(res.msg));
    }
    if (loading) return <div
        style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        <BeatLoader color={"#1b3e4d"}/></div>;
    return (
        <BasicAdminLayout>
            <div style={{height: 105}}/>
            <div className={styles['title']}>Admin Panel</div>

            <div className={styles['main-panel']}>
                {/*{Card({*/}
                {/*    title: "Principle Management",*/}
                {/*    createUrl:"/admin/create/principal",*/}
                {/*    createBtnText: "Create Principal"*/}
                {/*})}*/}
                {Card({
                    title: "School Management",
                    createUrl: "/admin/create/school",
                    createBtnText: "Create School",
                    children: SchoolListComponent()
                })}
                {Card({
                    title: "Question",
                    createBtnText: "Manage",
                    createUrl: '/admin/questions'
                })}
                {Card({
                    title: "Files",
                    createBtnText: "Manage",
                    createUrl: '/admin/file'
                })}
                {Card({
                    title: "Video",
                    createBtnText: "Manage"
                })}
            </div>
            <div>
                <h1>Student List</h1>
                <Table>
                    <thead>
                    <tr>
                        <td>id</td>
                        <td>email</td>
                        <td>edit</td>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        [...studentList].map((el, index) => (
                            <tr
                                className={styles['tr']}
                                key={'student_row_' + index}>
                                <td>{el._id}</td>
                                <td>{el.email}</td>
                                <td>
                                   <span className={styles['forceset-button']}
                                         onClick={() => forceChangeStudentEmail({id: el._id, email: el.email})}
                                   >Force Set Email</span>
                                    <span className={styles['forceset-button']}
                                          onClick={() => forceChangeStudentPassword({id: el._id, email: el.email})}
                                    >Force Set Password</span>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
            </div>
            <div>
                <h1>Teacher/Principal List</h1>
                <Table>
                    <thead>
                    <tr>
                        <td>id</td>
                        <td>role</td>
                        <td>email</td>
                        <td>edit</td>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        [...teacherList].map((el, index) => (
                            <tr
                                className={styles['tr']}
                                key={'teacher_row_' + index}>
                                <td>{el._id}</td>
                                <td>{el.role}</td>
                                <td>{el.email}</td>
                                <td>
                                   <span className={styles['forceset-button']}
                                         onClick={() => forceChangeTeacherPassword({id: el._id, email: el.email})}
                                   >Force Set Password</span>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
            </div>

        </BasicAdminLayout>
    );
}

export default AdminMainPage;