import React, {useEffect, useState} from "react";
import styles from "./my_slider.module.css";
import {LockSvgIcon} from "../../../assets/svg";
import {Link, useHistory} from "react-router-dom";
import {StudentAPI} from "../../../services";
import {Loading} from "../../index";

const cons = [
        {
            "questions": [
                "61aeccc4b4bd0b41e4514fdf",
                "61aeccc4b4bd0b41e4514fe0",
                "61aeccc4b4bd0b41e4514fe1",
                "61aeccc4b4bd0b41e4514fe2",
                "61aeccc4b4bd0b41e4514fe3",
                "61aeccc4b4bd0b41e4514fe4",
                "61aeccc4b4bd0b41e4514fe5"
            ],
            "_id": "61aeccc4b4bd0b41e4514fe6",
            "moduleName": "Reading",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "learningContent": "ordering sentences",
            "locked": false
        },
        {
            "questions": [
                "61aeccc4b4bd0b41e4514fe7",
                "61aeccc4b4bd0b41e4514fe8",
                "61aeccc4b4bd0b41e4514fe9"
            ],
            "_id": "61aeccc4b4bd0b41e4514fea",
            "moduleName": "Listening",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "learningContent": "multiple choice from various recordings",
            "locked": false
        },
        {
            "questions": [
                "61aeccc4b4bd0b41e4514feb",
                "61aeccc4b4bd0b41e4514fec"
            ],
            "_id": "61aeccc4b4bd0b41e4514fed",
            "moduleName": "Speaking",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "learningContent": "read a text out loud",
            "locked": false
        },
        {
            "questions": [
                "61aeccc4b4bd0b41e4514fee",
                "61aeccc4b4bd0b41e4514fef",
                "61aeccc4b4bd0b41e4514ff0",
                "61aeccc4b4bd0b41e4514ff1",
                "61aeccc4b4bd0b41e4514ff2"
            ],
            "_id": "61aeccc4b4bd0b41e4514ff3",
            "moduleName": "Writing",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "learningContent": "clearly presenting ideas",
            "locked": false
        },
        {
            "questions": [
                "61aeccc4b4bd0b41e4514ff4",
                "61aeccc4b4bd0b41e4514ff5",
                "61aeccc4b4bd0b41e4514ff6"
            ],
            "_id": "61aeccc4b4bd0b41e4514ff7",
            "moduleName": "Grammar",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "learningContent": "cohesive devices - linking words",
            "locked": false
        },
        {
            "questions": [
                "61aeccc4b4bd0b41e4514ff8",
                "61aeccc5b4bd0b41e4514ff9",
                "61aeccc5b4bd0b41e4514ffa",
                "61aeccc5b4bd0b41e4514ffb",
                "61aeccc5b4bd0b41e4514ffc"
            ],
            "_id": "61aeccc5b4bd0b41e4514ffd",
            "moduleName": "Review",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "locked": false
        },
        {
            "questions": [
                "61aeccc5b4bd0b41e4514ffe",
                "61aeccc5b4bd0b41e4514fff",
                "61aeccc5b4bd0b41e4515000"
            ],
            "_id": "61aeccc5b4bd0b41e4515001",
            "moduleName": "Vocabulary",
            "programId": "60c097b8b14f2e0d239c305e",
            "week": 1,
            "__v": 0,
            "learningContent": "common collocations",
            "locked": false
        }
    ];

function Slider() {
    const history = useHistory();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sliderMods, setSliderMods] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Promise.resolve()
            .then(()=>setIsLoading(true))
            .then(() => StudentAPI.getSlider()).then((res) => {
            // console.log("slider", res)
            setSliderMods(res.data||[]);
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        const timer = sliderMods && setInterval(() => {
            if (currentIndex === sliderMods.length - 1) {
                setCurrentIndex(0);
            } else {
                const temp = currentIndex + 1;
                setCurrentIndex(temp);
            }
        }, 5000);
        return () => {
            clearInterval(timer);
        };
    }, [currentIndex]);



    if (isLoading) return  <Loading />

    if(sliderMods && sliderMods.length > 0) return (
        <div className={styles.slider_container}>
            <div
                className={styles.slider_content}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "30px",
                    whiteSpace: "nowrap",
                }}
            >
                <div style={{fontSize: "40px", display: "flex"}}>
                    <p>Week {sliderMods?.[currentIndex]?.week}</p>
                    <span style={{marginLeft: "20px"}}>{sliderMods?.[currentIndex]?.moduleName}</span>
                </div>
                <div>
                    <h3 style={{fontSize: "30px", whiteSpace: "normal", textTransform: 'capitalize'}}>
                        {sliderMods?.[currentIndex]?.learningContent}
                    </h3>
                </div>
                <div style={{display: "flex"}}>

                    <button
                        className={sliderMods?.[currentIndex].locked ? styles.forbidden : ""}
                        style={{
                            display: "flex",
                            padding: "12px 40px",
                            flexShrink: "2",
                            borderRadius: "10px",
                            backgroundColor: "#CBCBCB",
                            gap: "10px",
                        }}
                        onClick={sliderMods?.[currentIndex]?.locked ? () => {
                        } : () => history.push(`/modules/${sliderMods?.[currentIndex]?._id}`)}
                    >
                        {sliderMods?.[currentIndex]?.locked && <LockSvgIcon/>}
                        START LEARNING NOW
                    </button>

                </div>
            </div>
            <div className={styles.spacer}/>
            <div className={styles.mid}>
                <div
                    className={styles.slider_title}
                    style={{
                        display: "flex",
                    }}
                >
                    {sliderMods?.[currentIndex]?.moduleName}
                </div>
            </div>
            {" "}
            <div className={styles.dot_container}>
                {[...Array(sliderMods.length)].map((_, i) => (
                    <div key={i + "slider_dot"}
                         onClick={() => setCurrentIndex(i)}
                         className={i === currentIndex ? styles.dot_active : styles.dot}/>
                ))}
            </div>
        </div>
    );

    return (
        <div className={styles.slider_container}>
            <div
                className={styles.slider_content}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "30px",
                    whiteSpace: "nowrap",
                }}
            >
                <div style={{fontSize: "40px", display: "flex"}}>
                    <p>No class assigned</p>
                    <span style={{marginLeft: "20px"}} />
                </div>
                <div>
                    <h3 style={{fontSize: "30px", whiteSpace: "normal", textTransform: 'capitalize'}}>
                        Please enroll in a class first.
                    </h3>
                </div>
                <div style={{display: "flex"}}>

                    <button
                        className={ styles.forbidden }
                        style={{
                            display: "flex",
                            padding: "12px 40px",
                            flexShrink: "2",
                            borderRadius: "10px",
                            backgroundColor: "#CBCBCB",
                            gap: "10px",
                        }}
                        onClick={ () => {
                        }}
                    >
                        <LockSvgIcon/>
                        PLEASE ENROLL IN CLASS
                    </button>
                </div>
            </div>
            <div className={styles.spacer}/>
            <div className={styles.mid}>
                <div
                    className={styles.slider_title}
                    style={{
                        display: "flex",
                    }}
                >
                    No data
                </div>
            </div>
            {" "}
            <div className={styles.dot_container}>
                <div className={styles.dot} />
            </div>
        </div>
    );
}

export default Slider;
