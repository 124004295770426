import React from "react";
// import "./management_card.css";
import {Loading, TeacherCreateButton, TeacherManagementCardTemplate} from "../..";
import {IconWithRightText} from "../..";
import {ReactComponent as GroupIcon} from "../../../assets/svg/group_icon.svg";
import {useHistory} from "react-router-dom";


function ManagementCard({
                            href = "#",
                            loading = false,
                            groupList = [
                                // {name: "Group Name 1", src: "https://picsum.photos/300/300"},
                                // {name: "Group Name 2", src: "https://picsum.photos/300/300"},
                            ],
                        }) {

    const history = useHistory();

    const getContents = (groupList) => {

        if (!groupList || groupList.length < 1) {
            return <div>No groups.</div>
        }

        return (<div className="eap__scroll-view-horizontal">
            {[...groupList].map((elem) => (
                <div key={elem._id ?? elem.groupId} onClick={()=>history.push(`/teacher/edit/group/${elem.href}`)}>
                    <IconWithRightText
                        text={elem.name}
                        svg={<GroupIcon/>}
                        src={elem.src}
                    />
                </div>
            ))}
        </div>)
    }
    // return (
    //     <div className="eap__management-card-container eap__shadow">
    //         <div className="eap__management-card-title">
    //             <span className="h5">Group management</span>
    //             <TeacherCreateButton text="Create group" href="/teacher/create/group"/>
    //         </div>
    //         <div className="eap__management-body">
    //             {getContents(groupList)}
    //         </div>
    //         <div className="eap__management-action">
    //             {/* <a href={href}>View all &gt;</a> */}
    //         </div>
    //     </div>
    // );

    return (<TeacherManagementCardTemplate
        title={"Group Management"}
        createBtnText={"Create group"}
        createBtnHref={"/teacher/create/group"}
        loading={loading}
    >
        {getContents(groupList)}
    </TeacherManagementCardTemplate>)
}

export default ManagementCard;
