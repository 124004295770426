import React, { useEffect } from "react";

import { StudentQuestionWrapper } from "../../layouts";
import {
  // Follow,
  // Option,
  Type1,
  Type2,
  Type3,
  Type4,
  Type5,
  Type6,
  Type7,
  Type8,
  Type9,
  Type10,
  Type11,
  Type12,
  Type13,
  Type14,
} from "../../components/Student/Questions/Types";

import { question } from "./question";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { useModuleQuestions } from "../../hooks";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";

function QuestionWrapper() {
  const history = useHistory();
  const { roleMapper, showModal } = useAuth();
  const { id } = useParams();
  const {
    loading,
    setLock,
    lock,
    score,
    setScore,
    questions,
    questionsRef,
    setQuestions,
    current,
    setCurrent,
    start,
    pause,
    reset,
    moduleName,
    weekModules,
    weekPercentage,
    currentWeekNum,
    gotoNextQuestionHandler,
    isCurrentQuestionListening,
    getCurrentQuestionId,
  } = useModuleQuestions({
    id: id,
    goBackHandler: () => {
      showModal(
        " ",
        "Completed this module.",
        () => {
          history.push(`/${roleMapper()}`);
        },
        false
      );
    },
  });

  useEffect(() => {
    start();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <h3>Type 11</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {/* {loading ? <BeatLoader /> : questions[current]} */}

        <Type11 question={question.question11} />
      </StudentQuestionWrapper>
      
      <h3>Type 14</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {/* {loading ? <BeatLoader /> : questions[current]} */}
        <Type14 question={question.question14} />
      </StudentQuestionWrapper>
      
      
      <h3>Type 1</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type1 question={question.question1} />
      </StudentQuestionWrapper>
      
      
      <h3>Type 2</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type2 question={question.question2} />
      </StudentQuestionWrapper>
      
      
      <h3>Type 3</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}

        <Type3 question={question.question3} />
      </StudentQuestionWrapper>
      
      
      <h3>Type 4</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type4 question={question.question4} />
      </StudentQuestionWrapper>
      
      
      <h3>Type 5</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type5 question={question.question5} />
      </StudentQuestionWrapper>
      
      
      <h3>Type 6</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type6 question={question.question6} />
      </StudentQuestionWrapper>
      
      <h3>Type 7</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type7 question={question.question7} />
      </StudentQuestionWrapper>
      
      <h3>Type 8</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type8 question={question.question8} />
      </StudentQuestionWrapper>
      
      <h3>Type 9</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type9 question={question.question9} />
      </StudentQuestionWrapper>
      
      <h3>Type 10</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type10 question={question.question10} />
      </StudentQuestionWrapper>
 
      <h3>Type 12</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type12 />
      </StudentQuestionWrapper>

      <h3>Type 13</h3>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <Type13 />
      </StudentQuestionWrapper>
      <StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
      >
        {loading ? <BeatLoader /> : questions[current]}
        <h3>Type 14</h3>
        <Type14 question={question.question14} />

        <h3>Type 11</h3>
        <Type11 question={question.question11} />

        <h3>Type 1</h3>
        <Type1 question={question.question1} />

        <h3>Type 2</h3>
        <Type2 question={question.question2} />

        <h3>Type 3</h3>
        <Type3 question={question.question3} />

        <h3>Type 4</h3>
        <Type4 question={question.question4} />

        <h3>Type 5</h3>
        <Type5 question={question.question5} />

        <h3>Type 6</h3>
        <Type6 question={question.question6} />

        <h3>Type 7</h3>
        <Type7 question={question.question7} />

        <h3>Type 8</h3>
        <Type8 question={question.question8} />

        <h3>Type 9</h3>
        <Type9 question={question.question9} />

        <h3>Type 10</h3>
        <Type10 question={question.question10} />

        <h3>Type 12</h3>
        <Type12 />

        <h3>Type 13</h3>
        <Type13 />

        <h1>Testing end</h1>
      </StudentQuestionWrapper>
    </div>
  );
}

export default QuestionWrapper;
