import React from "react";
import styles from "./horizontal_card.module.css";
import GreenLogo from "../../../../assets/img/GREEN.png";
import YellowLogo from "../../../../assets/img/YELLOW.png";
// import AccessLogo from "../../../../assets/img/ACCESS.png";
import AccessLogo from "../../../../assets/img/ACCESS.png";
import AccessLeft from "../../../../assets/img/ab.png";
import YellowLeft from "../../../../assets/img/yb.jpg";
import GreenLeft from "../../../../assets/img/gb.jpg";

let content1 = (
  <>
    Preparotory and functional mode to develop a foundation
    <br />
    for academic English skills
    <br />
    <ul className={styles.list}>
      <li>Minimum study duration: 12 weeks</li>
      <li>Maximum study duration: 40 weeks</li>
      <br />
      <li>Entry with IELTS 3.5 equivalent or above</li>
      <li>Exit with IELTS 4.5 equivalent</li>
    </ul>
  </>
);
let content2 = (
  <>
    A more advanced academic English level competence to
    <br />
    further develop academic English skills
    <br />
    <ul className={styles.list}>
      <li>Minimum study duration: 12 weeks</li>
      <li>Maximum study duration: 40 weeks</li>
      <br />
      <li>Entry with IELTS 4.5 equivalent or above</li>
      <li>Exit with IELTS 5.5 equivalent</li>
    </ul>
  </>
);
let content3 = (
  <>
    Special techniques and strategies plus academic English <br />
    skills targeted at students soon to be launching into a <br />
    university environment
    <br />
    <br />
    EAP Access comprehensively prepares students for the <br />
    final assessment with practical exercises and strategies <br />
    before acheiving their CEFR certification
    <br />
    <ul className={styles.list}>
      <li>Minimum study duration: 8 weeks</li>
      <li>Maximum study duration: 20 weeks</li>
      <br />
      <li>Entry with IELTS 5.5 equivalent or above</li>
      <li>Exit with IELTS 6.0 or above</li>
      <br />
      <li>Grants CEFR certificate</li>
    </ul>
  </>
);
let textContents = [content1, content2, content3];
let logos = [YellowLogo, GreenLogo, AccessLogo];
let logosLeft = [YellowLeft, GreenLeft, AccessLeft];
function HorizontalCard({ index }) {
  return (
    // <div className={index === 2 ? styles.cardStyle2 : styles.cardStyle}>
    <div className={ styles.cardStyle}>
      <div className={ styles.leftContainer}>
      {/* <div className={index === 2 ? styles.leftContainer2 : styles.leftContainer}> */}
        <img src={logosLeft[index]} alt="left-logo" className={styles.left} />
      </div>
      <div className={styles.midContainer}>{textContents[index]}</div>
      <div className={styles.rightContainer}>
        <img src={logos[index]} alt="right-logo" className={styles.right} />
      </div>
    </div>
  );
}

export default HorizontalCard;
