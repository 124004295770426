import React, {useEffect} from "react";
import SimpleBar from "simplebar-react";

function QuestionPreview({content}) {

    useEffect(() => {
        document.getElementById("danger").innerHTML = content;
    }, [content]);

    return (
        <div className="bg-white w-4/6 p-6 my-5" style={{borderRadius: 20, height: 500, overflow:'hidden'}}>
            <SimpleBar style={{maxHeight: 100 + '%'}}>
                <div id={"danger"}/>
            </SimpleBar>
        </div>
    );
}

export default QuestionPreview;
