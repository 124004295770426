import {useAuth} from "../context";
import {useEffect, useRef, useState} from "react";
import {useTimer} from "react-timer-hook";
import {useHistory} from "react-router-dom";
import {PlacementTestAPI} from "../services";

const schoolListExamples = [
    // {name: "Box Hill Institute", value: 37},
    // {name: "CIEC", value: 4},
    // {name: "CIEC01", value: 28},
    // {name: "CIEC-210311", value: 39},
    // {name: "CIEC-210423", value: 41},
    // {name: "CIEC-210423-01", value: 45},
    // {name: "Longyou Kema International school", value: 2},
    // {name: "Dao Duy Tu High School", value: 34},
    // {name: "Guangzhou Art Insitution", value: 5},
    // {name: "Huizhou", value: 47},
    // {name: "NCUK", value: 26},
    // {name: "Nanning", value: 43},
    // {name: "Nguyen Sieu School", value: 53},
    // {name: "Origo", value: 20},
    // {name: "Shengzhen xxx School", value: 22},
    // {name: "Trial for Taiwan", value: 54},
    // {name: "University of Economics HCM", value: 3},
    // {name: "Yes International College", value: 24},
]

function usePlacementHook({expiryTimestamp}) {
    const {user, showModal} = useAuth();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [learningState, setLearningState] = useState(1);
    const [showMenuList, setShowMenuList] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");

    const [schoolList, setSchoolList] = useState([]);

    const [searchResult, setSearchResult] = useState([...schoolList]);
    const [selectSchoolCode, setSelectSchoolCode] = useState(
      "60c09abd6e1e360db761de97"
    );

    const [keyword, setKeyword] = useState("");
    const [checked, setChecked] = useState(false);
    const [showTest, setShowTest] = useState(false);


    const [current, setCurrent] = useState(0);
    const [lock, setLock] = useState(true);
    // const [questions, setQuestions] = useState([]);
    const questions = useRef([]);
    const {
        seconds, minutes, hours, days, isRunning, start, pause, reset, restart
    } = useTimer({expiryTimestamp: expiryTimestamp, autoStart: false, onExpire: () => submitTest("Timeout")});

    const submitHandler = (e) => {
        e.preventDefault();
        showModal(" ", "You have 20 minutes to complete 20 questions.\n" +
            "Please ensure you read and answer the questions carefully.\n" +
            "Once you have answered a question you cannot go back.", () => {
            console.log("Start");
            setShowTest(true);
            start();
        }, false);
    }

    const submitTest = (title = "Congrats") => {
        const info = {
            firstName,
            lastName,
            email,
            mobile,
            schoolCode: selectSchoolCode,
            schoolName: keyword,
            answerSheet: questions.current,
            minutesLeft: minutes,
            secondsLeft: seconds
        };
        console.table(info);
        PlacementTestAPI.submit(info)
            .then(console.log)
            .then(() => showModal(title, "Thank you for completing the EAP Space Placement Test.\nYou will receive the test outcome and an offer letter within the next 2 business days.", () => {
                history.push("/");
            }, false));
    }

    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => PlacementTestAPI.getAllSchools())
            .then((results) => {
                setSchoolList([...results]);
                setSearchResult([...results]);
            })
            .then(() => setLoading(false));
    }, [])

    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => PlacementTestAPI.getAll())
            .then(({results}) => questions.current = (results || []))
            .then(() => setLoading(false))
    }, []);

    useEffect(() => {
        if (keyword === "") return setSearchResult([...schoolList]);
        return setSearchResult([...schoolList]
            .filter((el) => String(el.name)
                .toLowerCase().includes(String(keyword).toLowerCase())));
    }, [keyword]);

    const inputSearchHandler = () => {

    }

    const inputStyle = {borderRadius: 10, backgroundColor: "#164559", position: "relative", color: "#fff"};

    const isValid = () => {
        const arr = [firstName, lastName, email, mobile];
        const arrValid = arr.every(el => el.length > 0);
    }

    const gotoNextQuestion = () => {
        setLock(true);
        if (current + 1 === questions.current.length) {
            // setCurrent(current + 1);
            pause();
            return submitTest();
        }
        if (current + 1 > questions.current.length) {
            return; // outbound;
        }
        return setCurrent(current + 1);
    }

    const getCurrentQuestionProgress = () => {
        return Number((current + 1) / questions.current.length).toFixed(2);
    }

    const getCurrentQuestionId = () => questions.current[current] ? questions.current[current]._id : "";

    const answerQuestionHandler = (answer) => {
        questions.current[current].answer = answer;
        setLock(false);
    }

    return {
        lock, setLock,
        start, restart,
        getCurrentQuestionProgress,
        getCurrentQuestionId,
        gotoNextQuestion,
        answerQuestionHandler,
        questions, current,
        seconds, minutes,
        user, showModal,
        loading, setLoading,
        learningState, setLearningState,
        showMenuList, setShowMenuList,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        mobile, setMobile,
        searchResult, setSearchResult,
        selectSchoolCode, setSelectSchoolCode,
        keyword, setKeyword,
        checked, setChecked,
        showTest, setShowTest,
        inputSearchHandler,
        inputStyle,
        isValid,
        submitHandler
    }
}

export default usePlacementHook;