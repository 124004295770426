import React from "react";
import {StudentAudioPlayer} from "../../index";
import AssetViewer from "../AssetViewer/AssetViewer";

function MediaHandler({url, type, auto}) {
    return (<div className="w-full">
        {/*{type === "image" && <img src={url} alt="" className="h-full"/>}*/}
        {type === "" && <></>}
        {type === "image" && <AssetViewer url={url}/>}
        {type === "audio" && <StudentAudioPlayer audioSrc={url} auto={auto}/>}
    </div>);
}

export default MediaHandler;
