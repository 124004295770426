export function mapperStudentEvaluation(val) {
    switch (val) {
        case 1 :
            return {msg: "EXCELLENT", color: "#36B24E"};
        case 2 :
            return {msg: "GOOD", color: "#36B24E"};
        case 3 :
            return {msg: "AVERAGE", color: "#F0BD4A"};
        case 4 :
            return {msg: "KEEP GOING", color: "#F0BD4A"};
        case 5 :
            return {msg: "DON'T GIVE UP!", color: "#C42828"};
        default :
            return {msg: "Incorrect Data", color: "#C42828"};
    }
}