import React from "react";
import { TeacherManagementCardTemplate} from "../..";
import {TeacherStudentItem as StudentItem} from "../..";
import {ScrollCardView} from "../../../layouts";

function ManagementCard({
                            loading = false,
                            href = "/teacher/manage/student",
                            studentList = [],
                        }) {

    const getContents = (studentList) => {

        if (!studentList || studentList.length < 1) {
            return <div>No students.</div>
        }

        return (<ScrollCardView
            style={{display: "flex", flexDirection: "column", gap: 36 + "px"}}
        >
            {[...studentList].map((elem, index) => (
                <div
                    key={"student_management_card_" + index}>
                    <StudentItem
                        name={elem.name}
                        src={elem.src}
                        week={elem?.metadata?.currentWeek ?? "1"}
                        percent={
                            Math.round(
                                elem?.metadata?.questionsCompletedForWeek /
                                elem?.metadata?.questionsIncurrentWeek
                            ) * 100
                        }
                        href={`/teacher/edit/student/${elem._id}`}
                    />
                </div>
            ))}
        </ScrollCardView>)
    }

    return (<TeacherManagementCardTemplate
        loading={loading}
        title={"Student management"}
        createBtnText={"Create student"}
        createBtnHref={href}
        href={href}
        // href={"/teacher/manage/student"}
    >{getContents(studentList)}</TeacherManagementCardTemplate>)
}

export default ManagementCard;
