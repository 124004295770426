import React from "react";

import bigAccess from "../../../../assets/img/ab2.png";
import bigGreen from "../../../../assets/img/gb2.png";
import bigYellow from "../../../../assets/img/yb2.png";
const mapping = {
  green: bigGreen,
  yellow: bigYellow,
  access: bigAccess,
};
function LogoComponent({ emodule }) {
  return (
    <div className="w-1/2">
      <img src={mapping[emodule]} alt="Access" />
    </div>
  );
}

export default LogoComponent;
