import React, {useEffect, useState} from 'react';
import {BasicAdminLayout} from "../../../layouts";
import {WideButtonWithIcon} from "../../../components";
import {Table} from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import styles from './main.module.css';
import {AdminAPI} from "../../../services";
import {BeatLoader} from "react-spinners";
import CommonButton from "../../../components/Common/Button/CommonButton/CommonButton";

function QuestionManagePage(props) {
    const history = useHistory();

    const Card = ({
                      title = "Management",
                      createBtnText = "Create",
                      createUrl = "/admin",
                      children
                  }) => {
        return (<div className={styles['card']}>
            <div style={{display: 'flex'}}>
                <h3>{title}</h3>
                <div style={{display: 'flex', flex: 1}}/>
                <div style={{display: 'flex', marginLeft: "auto"}}
                     onClick={() => history.push(createUrl)}
                ><WideButtonWithIcon
                    text={createBtnText}/></div>
            </div>
            <div>{children}</div>
        </div>)
    }

    const [questions, setQuestions] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [pagesize, setPagesize] = useState(5);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => AdminAPI.getAllQuestions({page, pagesize}))
            .then(({results, page, pages, pagesize}) => {
                setQuestions(results || []);
                // setPage(page);
                setPages(pages);
                setPagesize(pagesize)
            })
            .then(() => setLoading(false))
    }, [page, pagesize]);

    if (loading) return <div
        style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        <BeatLoader color={"#1b3e4d"}/></div>;

    return (
        <BasicAdminLayout>
            <div style={{height: 105}}/>
            <div className={styles['title']}>Question Panel </div>
            <div className={styles['main-panel']}>
                <Table responsive>
                    <thead>
                    <tr>
                        <td/>
                    </tr>
                    </thead>
                    <tbody>
                    {[...questions].map((elem, index) => (
                        <tr key={'question_' + index}>
                            <td>{
                                [...elem.modules].map((mod) => (
                                    <div key={mod._id}>
                                        <Table>
                                            <thead>
                                            <tr>
                                                <td>Mod Name</td>
                                                {/*<td>Id</td>*/}
                                                <td>Week</td>
                                                <td>Program</td>
                                                <td>Question</td>
                                                <td>
                                                    <a target={'_blank'} rel={'noreferrer'}
                                                    href={`/admin/question/add/${mod._id}/${elem?._id}/${elem?.weekNumber}/${elem?.belongsTo?._id}`}
                                                    >Add Question</a>
                                                    {/*path={'/admin/question/add/:moduleId/:weekId/:week/:programId'}*/}
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {[...mod?.questions].map((question, index) => (
                                                <tr key={question._id + `${index * index}`}>
                                                    <td>{mod?.moduleName}</td>
                                                    {/*<td>{elem?._id}</td>*/}
                                                    <td>{(elem?.weekNumber)}</td>
                                                    <td>{elem?.belongsTo?.programName}
                                                        <img src={elem?.belongsTo?.logoUrl}
                                                             style={{
                                                                 width: 100,
                                                                 padding: 5,
                                                                 backgroundColor: 'rgb(27,62,77)'
                                                             }} alt={""}/>
                                                    </td>
                                                    <td>Question {('0' + (index + 1)).slice(-2)} </td>
                                                    <td> <a  target={'_blank'} rel={'noreferrer'} href={(`/admin/question/edit/${question}/${mod._id}`)} >{'View/Edit'}</a> </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                ))
                            }</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

            </div>

           <div style={{display:'flex'}}>
               <div style={{
                   width: "100%",
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center'
               }}>{[...new Array(pages)].map((_, index) => (
                   <span key={index} style={{
                       cursor:page === (index + 1) ? 'not-allowed': 'pointer',
                       minWidth: 25,
                       textAlign:'center',
                       backgroundColor: page === (index + 1) ? '#4d8bae': '#164559',
                       color: '#ffffff',
                       margin: '0 3px',
                       padding: 3,
                       borderRadius: 5
                   }}
                         onClick={() => setPage(index + 1)}
                   >{index + 1}</span>
               ))}</div>
               <label>Page Size:</label>
               <input type={"number"} value={pagesize} onInput={(e)=>setPagesize(e.target.value)} />
           </div>

            <div/>
        </BasicAdminLayout>
    );
}

export default QuestionManagePage;