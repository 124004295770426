import { GET } from "../";
export default class ProgramsAPI {
  

  static async getAll() {
    try {
      const { data } = await GET(`/programs/get`);
      return data ? data : [];
    } catch (err) {
      return [];
    }
  }
}
