import React, {useState, useEffect} from "react";
import "./create_student.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {PersonSvgIcon} from "../../../assets/svg";
import {
    Button, PhoneTextField, TextField, Dropdown,
} from "../../../components";
import {useAuth} from "../../../context";
import {getCountryCodeByName} from "../../../utils";
import {ClassAPI, GroupAPI, StudentAPI, ProgramsAPI} from "../../../services";

function CreateStudent() {
    const {user, showModal} = useAuth();
    const [imageFile, setImageFile] = useState(null);
    const [studentName, setStudentName] = useState("");
    const [studentEmail, setStudentEmail] = useState("");
    const [studentPhone, setStudentPhone] = useState("");
    const [classList, setClassList] = useState([]);
    const [classIndex, setClassIndex] = useState(-1);
    const [groupList, setGroupList] = useState([]);
    const [groupIndex, setGroupIndex] = useState(-1);
    const [programList, setProgramList] = useState([{_id: "fdsafd"}]);
    const [programIndex, setProgramIndex] = useState(-1);
    const countryCode = getCountryCodeByName(user.country);
    useEffect(() => {
        const asyncCall = async () => {
            const fetchedClass = await ClassAPI.getAll();
            setClassList(fetchedClass.data || []);
            const fetchedGroup = await GroupAPI.getAll();
            setGroupList(fetchedGroup.data || []);
            const fetchedPrograms = await ProgramsAPI.getAll();
            setProgramList(fetchedPrograms.data || []);
        };
        asyncCall();
    }, []);

    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload_student_avatar").click();
    };

    const studentInfoHandler = () => {
        /**
         *
         {
    "studentName":"Test Student",
    "city":"Test S City",
    "country":"Test Country",
    "schoolId":"60c09abd6e1e360db761de97",
    "programId":"60c097b8b14f2e0d239c305e",
    "password":"testPassword",
    "email":"student@gmail.com",
    "mobile":"+61424341505"
    }
         */
        const info = {
            studentName: studentName,
            city: user.city,
            country: user.country,
            schoolId: user.schoolId._id,
            programId: programList[programIndex]._id,
            password: "testPassword",
            email: studentEmail,
            mobile: `${countryCode}${studentPhone}`,
        };
        if (classIndex !== -1) {
            info["classId"] = classList[classIndex]["_id"];
        }
        if (groupIndex !== -1) {
            info["groupId"] = groupList[groupIndex]["_id"];
        }
        console.log(info);
        return JSON.stringify(info);
    };

    const onFileSelected = (e) => {
        setImageFile(e.target.files[0]);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = function (evt) {
            document.getElementById("uploadPreview").src = evt.target.result;
        };
    };

    const createHandler = async (e) => {
        e.preventDefault();
        if( programIndex===-1) return showModal("Error","The program is required.", ()=>{}, false);
        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile, imageFile.name);
        }
        const info = studentInfoHandler();
        formData.append("data", info);

        for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
        }

        const response = await StudentAPI.create(formData);
        console.log(response);

        showModal(" ", response.msg, () => {
            window.location.reload();
        }, false);

        // e.target.reset();
    };

    return (<form onSubmit={createHandler}>
        <CreateLayout title="Create Student Account" href="/teacher">
            <div className="eap__create-student-main-panel-1">
                <div className="student-manage-elem1">
                    <div className="eap__create-student-avatar-wrapper eap__shadow ">
                        {imageFile ? (<img
                            id="uploadPreview"
                            style={{
                                width: 128 + "px", height: 128 + "px", // overflow: "hidden"
                                borderRadius: 128 + "px",
                            }}
                        />) : (<PersonSvgIcon/>)}
                    </div>
                </div>
                <div className="student-manage-elem2">
                    <input
                        type="file"
                        id="upload_student_avatar"
                        onChange={onFileSelected}
                        hidden
                    />
                    <Button text="Upload Picture" click={uploadHandler}/>
                </div>
                <div className="student-manage-elem3">
                    <label>Name</label>
                </div>
                <div className="student-manage-elem4">
                    <TextField
                        required
                        value={studentName}
                        inputHandler={(e) => setStudentName(e.target.value)}
                    />
                </div>
                <div className="student-manage-elem5">
                    <label>Email</label>
                </div>
                <div className="student-manage-elem6">
                    <TextField
                        required
                        type="email"
                        value={studentEmail}
                        inputHandler={(e) => setStudentEmail(e.target.value)}
                    />
                </div>
                <div className="student-manage-elem7">
                    <label>Phone</label>
                </div>
                <div className="student-manage-elem8">
                    <PhoneTextField
                        required
                        value={studentPhone}
                        inputHandler={(e) => setStudentPhone(e.target.value)}
                        countryCode={countryCode}
                    />
                </div>
                <div className="student-manage-elem9">
                    <Dropdown
                        placeHolder={user ? user.country : "Select Country"}
                        disabled="true"
                    />
                </div>
                <div className="student-manage-elem10">
                    <Dropdown
                        placeHolder={user ? user.city : "Select City"}
                        disabled="true"
                    />
                </div>
                <div className="student-manage-elem11">
                    <Dropdown
                        placeHolder={user ? user.schoolId.schoolName : "Select School"}
                        disabled
                    />
                </div>
                <div className="student-manage-elem12">
                    <Dropdown
                        placeHolder="Select Class"
                        options={[...classList].map((elem) => elem.name)}
                        indexHandler={(index) => setClassIndex(index)}
                    />
                </div>
                <div className="student-manage-elem13">
                    <Dropdown
                        placeHolder="Select Group"
                        options={[...groupList].map((elem) => elem.name)}
                        indexHandler={(index) => setGroupIndex(index)}
                    />
                </div>


                <div className="student-manage-program-dropdown">
                    <Dropdown
                        placeHolder="Select Program"
                        options={[...programList].map((elem) => elem.programName)}
                        indexHandler={(index) => setProgramIndex(index)}
                    />

                </div>


                <div
                    className="student-manage-elem14"
                >
                    <Button text="Create" color="#eabc7f" textColor="#ffffff" click={() => {
                        document.getElementById("create_student_btn").click();
                    }}/>
                    <input
                        type="submit"
                        id="create_student_btn"
                        value="Submit"
                        hidden
                    />
                </div>
                {/* <div className="eap__create-student-left-panel">
          <div className="eap__create-student-avatar-wrapper eap__shadow">
            <PersonSvgIcon />
          </div>  
          <Button text="upload picture" />
        </div>
        <div className="eap__create-student-right-panel">col2</div> */}
            </div>
        </CreateLayout>
    </form>);
}

export default CreateStudent;
