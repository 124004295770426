import React from "react";
import "./not_found_page.css";
import { useHistory } from "react-router-dom";

function NotFoundPage() {
  const history = useHistory();
  return (
    <div className="eap__not-found-page">
      <h1>Sorry, this page seems to be under maintenance or not exist.</h1>
      <div className="eap__link" onClick={() => history.goBack()}>Go back</div>
    </div>
  );
}

export default NotFoundPage;
