// import React from "react";
// import styles from "./dropdown.module.css";
// import {DownArrowSvgIcon} from "../../../assets/svg";
// import {uuid4} from "../../../utils";
//
// function Dropdown({
//                       placeHolder = "Please Select",
//                       options = ["A", "B", "C"],
//                       disabled,
//                       indexHandler = (index) => console.log(index),
//                   }) {
//
//     const [toggle, setToggle] = React.useState(false);
//     const [current, setCurrent] = React.useState(placeHolder);
//     // window.onclick = function (event) {
//     //     if (!event.target.matches(".dropbtn")) {
//     //         let dropdowns = document.getElementsByClassName("dropdown-content");
//     //         let i;
//     //         for (i = 0; i < dropdowns.length; i++) {
//     //             let openDropdown = dropdowns[i];
//     //             if (openDropdown.classList.contains("show")) {
//     //                 openDropdown.classList.remove("show");
//     //                 setToggle(false);
//     //             }
//     //         }
//     //     }
//     // };
//     return (
//
//         <div className={styles["dropdown"]}>
//             <div
//                 className={styles.dropdown_wrapper_btn}
//                 onClick={disabled ? null : () => setToggle(!toggle)}
//             >
//                 <div className={styles["dropdown-text"]}>{current}</div>
//                 {disabled ? (
//                     <></>
//                 ) : (
//                     <DownArrowSvgIcon style={{pointerEvents: "none"}}/>
//                 )}
//             </div>
//             <div className={styles["dropdown-content"]}>
//                 {toggle && [...options].map((elem, index) => (
//                     <a
//                         key={uuid4()}
//                         onClick={() => {
//                             setCurrent(elem);
//                             indexHandler(index);
//                             setToggle(false);
//                         }}
//                     >
//                         {elem}
//                     </a>
//                 ))}
//             </div>
//         </div>
//     );
// }
//
// export default Dropdown;
import React, {useEffect, useState} from "react";
import styles from "./dropdown.module.css";
import {DownArrowSvgIcon} from "../../../assets/svg";
import {uuid4} from "../../../utils";

function Dropdown({
                      placeHolder = "Please Select",
                      options = ["A", "B", "C"],
                      disabled,
                      indexHandler = (index) => console.log(index),
                  }) {

    const [toggle, setToggle] = React.useState(false);
    const [current, setCurrent] = React.useState("");
    // window.onclick = function (event) {
    //     if (!event.target.matches(".dropbtn")) {
    //         let dropdowns = document.getElementsByClassName("dropdown-content");
    //         let i;
    //         for (i = 0; i < dropdowns.length; i++) {
    //             let openDropdown = dropdowns[i];
    //             if (openDropdown.classList.contains("show")) {
    //                 openDropdown.classList.remove("show");
    //                 setToggle(false);
    //             }
    //         }
    //     }
    // };

    useEffect(() => {
        setCurrent(placeHolder);
    },[placeHolder])

    return (
        <div className={styles['dropdown']}>
            <div className={styles['dropdown_wrapper_btn']}
                 onClick={disabled ? null : () => setToggle(!toggle)}
            >

                <div className={styles['dropdown-text']}>{current}</div>
                {disabled ? (
                    <></>
                ) : (
                    <DownArrowSvgIcon style={{pointerEvents: "none"}}/>
                )}
            </div>

            <div className={styles['dropdown-content']}>
                {toggle && [...options].map((elem, index) => (
                    <a
                        className={styles['btn']}
                        key={uuid4()}
                        onClick={() => {
                            setCurrent(elem);
                            indexHandler(index);
                            setToggle(false);
                        }}
                    >
                        {elem}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default Dropdown;
