import React from "react";
import "./phone_text_field.css";
import "../common.css";
function TextField({
  handler,
  countryCode = "Country Code",
  required,
  value = "",
  inputHandler = () => {},
}) {
  // function setInputFilter(textbox, inputFilter) {
  //   [
  //     "input",
  //     "keydown",
  //     "keyup",
  //     "mousedown",
  //     "mouseup",
  //     "select",
  //     "contextmenu",
  //     "drop",
  //   ].forEach(function (event) {
  //     textbox.addEventListener(event, function () {
  //       if (inputFilter(this.value)) {
  //         this.oldValue = this.value;
  //         this.oldSelectionStart = this.selectionStart;
  //         this.oldSelectionEnd = this.selectionEnd;
  //       } else if (this.hasOwnProperty("oldValue")) {
  //         this.value = this.oldValue;
  //         this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
  //       } else {
  //         this.value = "";
  //       }
  //     });
  //   });
  // }
  // setInputFilter(document.querySelectorAll('input[type="number"]'), (value) => {
  //   return /^\d*\.?\d*$/.test(value);
  // });

  return (
    <div className="phone-textfield-wrapper">
      <span>{countryCode}</span>
      {required ? (
        <input
          value={value}
          onInput={inputHandler}
          required
          type="text"
          placeholder="Input phone number..."
          // inputFilter={(value) => {
          //   return /^\d*\.?\d*$/.test(value);
          // }}
        />
      ) : (
        <input
          type="text"
          value={value}
          placeholder="Input phone number..."
          onInput={inputHandler}
          // inputFilter={(value) => {
          //   return /^\d*\.?\d*$/.test(value);
          // }}
        />
      )}
    </div>
  );
}

export default TextField;
