import React, {useEffect, useRef} from "react";
import styles from "./editgroup.module.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {GroupSvgIcon, PersonSvgIcon} from "../../../assets/svg";

import {
    TeacherStudentSearchItem as StudentSearchItem, Button, TextField, Pagination, SearchBox, TeacherStudentWrapper,
} from "../../../components";
import {uuid4, moveElements} from "../../../utils";
import {useStudentManagement} from "../../../hooks";
import {useParams} from "react-router-dom";
import {GroupAPI, StudentAPI} from "../../../services";
import cs from "classnames";
import {useAuth} from "../../../context";

function EditGroup() {
    const {showModal} = useAuth();
    const [imageFile, setImageFile] = React.useState(null);
    const [fetchedList, setFetchedList] = React.useState([]);
    const {id = ""} = useParams();
    const [groupName, setGroupName] = React.useState(id);
    const [searchList, setSearchList] = React.useState([]);
    const [selectedList, setSelectedList] = React.useState([]);
    const {sendLoginDetailsHandler, sendHandbookHandler} = useStudentManagement();
    const urlData = useRef(null);
    const processSelectedList = () => {
        const tempList = [];
        for (const item of selectedList) {
            tempList.push({
                name: item.studentName,
                handler: () => moveElements(selectedList, setSelectedList, searchList, setSearchList, item._id),
            });
        }
        return tempList;
    };

    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload").click();
    };

    useEffect(() => {
        asyncCall();
        document.getElementById("uploadPreview").src = "";
    }, []);

    const asyncCall = async () => {
        const {students = []} = await StudentAPI.get();
        setFetchedList(students);
        setSearchList(students);
        const {data = {}} = await GroupAPI.getById(id);
        setSelectedList(data.students ?? []);

        setGroupName(data.name ?? "No Data");

        setImageFile(data.avatar);
        document.getElementById("uploadPreview").src = data.avatar;
    };

    const getStudentIds = () => {
        return selectedList.map(elem => elem._id);
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile);
        }
        const groupData = {
            name: groupName, students: selectedList, _id: id,
        };

        formData.append("data", JSON.stringify(groupData));
        const response = await GroupAPI.edit(formData);
        if (response.code === 200) {
            // alert("Group Created Successfully");
            showModal(" ", "Group Updated Successfully", () => {
            }, false);
            return;
        }
        // alert(JSON.stringify(response));
    };

    return (<form onSubmit={submitHandler}>
            {" "}
            <CreateLayout title="Group Detail" href="/teacher">
                <div className={styles.eap__create_student_main_panel}>
                    <div className={styles.elem1}>
                        <div
                            className={cs(styles.eap__create_student_avatar_wrapper, "eap__shadow")}
                        >
                            {<img
                                id="uploadPreview"
                                alt=""
                                style={{
                                    width: 128 + "px",
                                    height: 128 + "px",
                                    borderRadius: 128 + "px",
                                    objectFit: "contain",
                                    display: imageFile ? "" : "none",
                                }}
                            />}
                            {imageFile ? null : <GroupSvgIcon/>}
                        </div>
                    </div>
                    <div className={styles.elem2} onClick={uploadHandler}>
                        <Button text="Upload Picture"/>
                        <input
                            type="file"
                            id="upload"
                            hidden
                            onChange={(e) => {
                                setImageFile(e.target.files[0]);
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(e.target.files[0]);
                                fileReader.onload = function (evt) {
                                    document.getElementById("uploadPreview").src = evt.target.result;
                                };
                            }}
                        />
                    </div>
                    <div className={styles.elem3}>
                        <label>Select member</label>
                    </div>
                    <div className={styles.elem4}>
                        <SearchBox inputHandler={(e) => {
                            setSearchList([...fetchedList].filter((elem) => String(elem.studentName).includes(e.target.value)));
                        }}/>
                    </div>
                    <div className={styles.elem15}>
                        <Pagination/>
                    </div>
                    <div className={styles.elem5}></div>
                    <div className={styles.elem6}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: 100 + "%",
                                gap: "18px",
                                minHeight: 350 + "px",
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                        >
                            {searchList.length < 1 ? (<>Empty</>) : searchList.length > 4 ? ([...searchList]
                                    .slice(0, 4)
                                    .map((elem) => (<StudentSearchItem
                                            ekey={uuid4() + "s1"}
                                            name={elem.studentName}
                                            status={elem.groupId.length > 0 ? "Has group" : "No group"}
                                            click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}
                                        />))) : ([...searchList].map((elem) => (<StudentSearchItem
                                        ekey={uuid4() + "s2"}
                                        status={elem.groupId.length > 0 ? "Has group" : "No group"}
                                        name={elem.studentName}
                                        click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}
                                    />)))}
                        </div>
                    </div>
                    <div className={styles.button_wrapper}>
                        {/*<Button text="Message" color="#1B3D4D" textColor="#fff" />*/}
                        <Button
                            text="Sent student handbook"
                            color="#1B3D4D"
                            textColor="#fff"
                            click={() => {
                                sendHandbookHandler({name: groupName, studentIds: getStudentIds()})
                            }}
                        />
                        <Button
                            text="Send login details"
                            color="#1B3D4D"
                            textColor="#fff"
                            click={() => {
                                sendLoginDetailsHandler({name: groupName, studentIds: getStudentIds()})
                            }}
                        />
                    </div>
                    <div className={styles.elem7}>
                        <label>Group member</label>
                    </div>

                    <div className={styles.elem9}>
                        <label>Name</label>
                    </div>
                    <div className={styles.elem12}>
                        <div className={styles.member_wrapper}>
                            <TeacherStudentWrapper list={processSelectedList()}/>
                        </div>
                    </div>
                    <div className={styles.elem14}></div>
                    <div className={styles.elem16}>
                        <TextField
                            value={groupName}
                            required
                            inputHandler={(e) => {
                                setGroupName(e.target.value);
                            }}
                        />
                    </div>
                    <div className={styles.elem17}>{/* <label>Durantion</label> */}</div>
                    <div className={styles.elem18}>
                        <Button
                            text="Done"
                            primary
                            click={() => {
                                document.getElementById("edit-group-submit").click();
                            }}
                        />
                        <input type="submit" hidden id="edit-group-submit"/>
                    </div>
                </div>
            </CreateLayout>
        </form>);
}

export default EditGroup;
