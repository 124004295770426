import {POST, GET} from "../";

export default class ClassesAPI {
    static async getAll() {
        try {
            const {data} = await GET(`/class/get`);
            return data;
        } catch (err) {
            console.log(err);
            return [];
        }
    }
    static async getByTeacher() {
        try {
            const {data} = await GET(`/class/teacher`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async sendReminder(classId) {
        try {
            const {data} = await GET(`/class/sendReminder/${classId}`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getById(id) {
        try {
            const {data} = await GET(`/class/get/${id}`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getClassForCreation(programId) {
        try {
            const {data} = await GET(`/class/getDataForCreation/${programId}`);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async create(payload = new FormData()) {
        try {
            const {data} = await POST(`/class/createClass`, payload);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async edit(payload = new FormData()) {
        try {
            const {data} = await POST(`/class/edit`, payload);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getScoreById(id) {
        try {
            const {data} = await GET(`/class/getScore/${id}`);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getScoresForAll() {
        try {
            const {data} = await GET(`/class/getScoreForAll`);
            return data;
        } catch (err) {
            return [];
        }
    }
}
