import React, {useEffect} from "react";
import {TeacherCreateLayout} from "../../../layouts";
import styles from "./profile.module.css";
import {useAuth} from "../../../context";
import {FillTextField} from "../../../components";
import {TeacherAPI, ClassAPI} from "../../../services";

function ProfilePage() {

    const {user, showModal} = useAuth();
    const [name, setName] = React.useState(user.teacherName ?? user.studentName);
    const [mobile, setMobile] = React.useState(user.mobile ?? "4123123123");
    const [email, setEmail] = React.useState(user.email ?? "teacher@email.com");
    const [avatar, setAvatar] = React.useState(user.avatar);
    const [classInfo, setClassInfo] = React.useState([]);
    const [className, setClassName] = React.useState(user.className ?? "Class Name");
    const [schoolName, setSchoolName] = React.useState(user?.schoolId?.schoolName ?? "School Name");
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [editMode, setEditMode] = React.useState(false);

    useEffect(() => {
        ClassAPI.getScoresForAll().then(({data = []}) => {
            // console.log(data);
            setClassInfo([...data]);
        })
    }, []);

    const editPassword = () => {
        if (
            currentPassword.length === 0 ||
            newPassword.length === 0 ||
            confirmPassword.length === 0
        ) {
            showModal("Warning", "No empty password allowed", () => {
            }, false);
            return;
        }
        if (newPassword !== confirmPassword) {
            showModal(
                "Warning",
                "New password and confirm password are not match",
                () => {
                },
                false
            );
            return;
        }
        TeacherAPI.changePassword({currentPassword, newPassword}).then((res) => {
            showModal(
                "Info",
                res.msg,
                () => {
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                },
                false
            );
        });
    };
    return (
        <TeacherCreateLayout title="Teacher profile" schoolAvatar={user?.schoolId?.avatar}>
            <div className={styles.container}>
                <div className={styles.avatar}>
                    <div className={styles.avatar_container}>
                        <div className={styles.avatar_holder}>
                            <img
                                src={avatar}
                                alt="portait"
                                onError={(e) => (e.target.src = "/img/broken-image.png")}
                            />
                            {/* <PersonSvgIcon /> */}
                        </div>
                        {" "}
                        <div className={styles.name}>{name}</div>
                    </div>
                </div>

                <label className={styles.phone_label}>Phone(ID)</label>
                <div className={styles.phone}>
                    <FillTextField
                        value={mobile}
                        readOnly
                        onInput={editMode ? (e) => setMobile(e.target.value) : () => {
                        }}
                    />{" "}
                </div>
                <label className={styles.email_label}>Email</label>
                <div className={styles.email}>
                    {" "}
                    <FillTextField
                        value={email}
                        readOnly
                        onInput={editMode ? (e) => setEmail(e.target.value) : () => {
                        }}
                    />
                </div>
                <label className={styles.class_label}>Class</label>
                <div className={styles.class_name}>
                    {" "}
                    <FillTextField
                        value={className}
                        readOnly
                        onInput={editMode ? (e) => setClassName(e.target.value) : () => {
                        }}
                    />
                </div>
                <label className={styles.school_label}>School</label>
                <div className={styles.school_name}>
                    {" "}
                    <FillTextField value={schoolName} readOnly/>
                </div>
                <label className={styles.change_pass_label}>Change password</label>
                <label className={styles.current_pass_label}>Current password</label>
                <div className={styles.current_pass}>
                    <FillTextField
                        type="password"
                        value={currentPassword}
                        onInput={(e) => setCurrentPassword(e.target.value)}
                    />{" "}
                </div>
                <label className={styles.new_pass_label}> New password</label>
                <div className={styles.new_pass}>
                    <FillTextField
                        type="password"
                        value={newPassword}
                        onInput={(e) => setNewPassword(e.target.value)}
                    />{" "}
                </div>
                <label className={styles.re_new_pass_label}>
                    Re-enter new password
                </label>
                <div className={styles.re_new_pass}>
                    <FillTextField
                        type="password"
                        value={confirmPassword}
                        onInput={(e) => setConfirmPassword(e.target.value)}
                    />{" "}
                </div>
                <div className={styles.confirm_btn}>
                    <div className={styles.confirm_btn_body} onClick={editPassword}>
                        Confirm
                    </div>
                </div>
                <label className={styles.class_overview_label}>Class overview</label>
                <div className={styles.class_overview}>
                    <div className={styles.class_overview_container}>
                        {classInfo.length > 0 ? <table>
                            <thead>
                            <tr>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td/>
                                <td>Average Score</td>
                                <td>Current Week</td>
                            </tr>
                            {[...classInfo].map((item, index) => {
                                return (
                                    <tr
                                        key={index + item.name + item._id}
                                        style={{
                                            borderBottom:
                                                index === classInfo.length - 1
                                                    ? ""
                                                    // : "1px solid #1B3D4D",
                                                    : "",
                                        }}
                                    >
                                        <td>{item.name ?? ""}</td>
                                        <td>{item.score ?? ""}</td>
                                        <td>{item.week ?? ""}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table> : <table>
                            <tbody>
                            <tr>
                                <td>
                                    <h1>No Data Yet</h1>
                                </td>
                            </tr>
                            </tbody>
                        </table>}
                    </div>
                </div>
            </div>
        </TeacherCreateLayout>
    );
}

export default ProfilePage;
