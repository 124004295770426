import React from "react";
import styles from "../../../pages/TeacherPages/ManageStudent/manage_students.module.css";
import personLogoSrc from "../../../assets/svg/person_icon.svg";
import {Link} from "react-router-dom";

function ManagementStudentItem({student, pKey}) {
    return <div
        key={pKey}
        className={styles.search_result}
        style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            flex: 1,
            justifyContent: "space-between",
        }}
    >
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: 20,
            }}
        >
            <div>
                <div
                    className="eap__shadow"
                    style={{
                        minWidth: 58,
                        maxWidth: 58,
                        minHeight: 58,
                        maxHeight: 58,
                        borderRadius: "50%",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <img src={student.avatar ?? personLogoSrc} alt=""/>
                </div>
            </div>
            <div
                style={{display: "flex", flexDirection: "column", flex: 1}}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",

                    }}
                >
                    <div>{student.studentName}</div>
                    <div>{student?.classId?.name}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 10
                    }}
                >
                    <div>{student.mobile ?? "Student mobile"}</div>
                    <div>{student.email ?? "Student email"}</div>
                </div>
            </div>
        </div>
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <div>
                Last login time: {new Date().toLocaleDateString("en-AU")}
            </div>
            <Link to={`/teacher/edit/student/${student._id}`}>
                <div className={styles.view_btn}>
                    View
                </div>
            </Link>
        </div>
    </div>

}

export default ManagementStudentItem;