import React from "react";
import i1 from "../../../../assets/img/i1.png";
import i2 from "../../../../assets/img/i2.png";
import i3 from "../../../../assets/img/i3.png";
import styles from "./image_card.module.css";
// import { useWindowDimensions } from "../../../../utils";
const images = [i1, i2, i3];
function ImageCard({ index }) {
  // const dimensions = useWindowDimensions();
  let content1 = (
    <div className={styles.container}>
      <img alt="i1" src={images[index]} className={styles.image} />

      <p className={styles.text}>
        Academic English language training tool to better prepare students for
        academic studies at the university level.
      </p>
    </div>
  );
  let content2 = (
    <div className={styles.container}>
      {/* <div   className={dimensions.width < 800 ? styles.container : styles.container2}> */}
      <p className={styles.text}>
        In cooperation with 35+institutions spanning across the United Kingdom,
        Australia, Canada, Malaysia, and Taiwan.
      </p>
      <img alt="i1" src={images[index]} className={styles.image} />
    </div>
  );
  let content3 = (
    <div className={styles.container}>
      {/* <div     className={dimensions.width < 800 ? styles.container : styles.container3} > */}
      <img alt="i1" src={images[index]} className={styles.image} />
      <p className={styles.text}>
        Integrated learning utilizing digital teaching and learning methods, and
        self-managed system for enhanced interaction between students and
        teachers.
      </p>
    </div>
  );
  let contents = [content1, content2, content3];
  return contents[index];
}

export default ImageCard;
