import React from "react";
import { HeaderBar } from "../../components";
import "./basic_layout.css";

import { useAuth } from "../../context";
function BasicLayout({ children }) {
  const { user } = useAuth();
  const { name, email } = user;

  return (
    <>
      <HeaderBar homeUrl={'/admin'} userName={email} />
      <div className="main__container">
        <div>{children}</div>
      </div>
    </>
  );
}

export default BasicLayout;
