import React from "react";
import "./management_card.css";
import {IconWithRightText, TeacherCreateButton} from "../..";
import {TeacherClassItem as StudentItem} from "../..";
import {ScrollCardView} from "../../../layouts";

import {Loading} from "../..";
import {ReactComponent as GroupIcon} from "../../../assets/svg/group_icon.svg";
import {Link} from "react-router-dom";

function ManagementCard({
                            title = "Examples Management",
                            createBtnText = "Create example",
                            createBtnHref = "/teacher/create/class",
                            loading = false,
                            href = "#",
                            children
                        }) {

    if (loading) return <div className="eap__management-card-container eap__shadow">
        <Loading/>
    </div>


    return (
        <div className="eap__management-card-container eap__shadow">
            <div className="eap__management-card-title">
                <span className="h5">{title}</span>
                <TeacherCreateButton text={createBtnText} href={createBtnHref}/>
            </div>
            <div className="eap__management-body">
                {children}
            </div>
            <div className="eap__management-action">
                {href !== "#" && <Link to={href}>View all &gt;</Link>}
            </div>
        </div>
    );
}

export default ManagementCard;
