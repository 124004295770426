import React from "react";
import {BasicLayout} from "../../";
import {BackButton, SubHeaderBar} from "../../../components";
import styles from "./create_template.module.css";

function CreatePageTemplate({
                                title,
                                children,
                                schoolAvatar,
                                pagination,
                                dropdown,
                            }) {
    return (
        <BasicLayout subBar={<SubHeaderBar/>}>
            <div className={".eap__management-container-col"}>
                <div className={styles["teacher_create_template_line_1"]}>
                  <div className={styles['back-container']}>
                      <BackButton/>
                      <h1>{title}</h1>
                  </div>
                    {/*<div style={{ display: "flex", flex: "1" }}/>*/}
                    {schoolAvatar && <img
                        style={{height: 150, width: 'auto'}}
                        src={schoolAvatar} alt={'school avatar'}/>}
                    {dropdown ?? null}
                    {pagination ?? null}
                </div>
                <div className={styles["teacher_create_template_line_2"]}>{children}</div>
            </div>
        </BasicLayout>
    );
}

export default CreatePageTemplate;
