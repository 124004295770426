import React, {useState, useEffect} from "react";
// import QuestionWithOptions from "../Option/QuestionWithOptions";
import SimpleBar from "simplebar-react";

const example = {
    "questionTitle": "You will read some sentences and decide if they are simple sentences or not. ",
    "answerTitle": "Click the on the gap for the (correct) simple sentences.",
    "tip": "Remember simple sentence structures are (Subject + verb),  (Subject + verb + object) or  (Subject + verb + complement) ",
    "type": "13",
    "questions": [
        {
            "text": "Simone edited her work carefully.",
            "isTrue": true
        },
        {
            "text": "Garry inspired many people with his seminars. ",
            "isTrue": true
        },
        {
            "text": "Garry and Simone various other elements of the course. ",
            "isTrue": false
        },
        {
            "text": "Concerns itself with diagnosis, treatment, and prevention of disease.",
            "isTrue": false
        },
        {
            "text": "Geologists study and analyse the physical structure and substance of the earth.",
            "isTrue": true
        }
    ],
    "numberOfCorrect": 3
}


function Type13({
                    question = example, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
                }) {
    const [answer, setAnswer] = useState([]);
    const [loading, setLoading] = useState(true);
    const [localScore, setLocalScore] = useState(0);

    useEffect(() => {
        setAnswer(question.questions?.map(el => -1));
        setLoading(false);
    }, []);

    const incrementScore = () => {
        setLocalScore(localScore + 1);
        setScore(localScore + 1);
    }

    const giveBgColor = (index) => {
        switch (answer[index]) {
            case 1:
                return '#89B481';
            case 0:
                return '#E5B343';
            default:
                return '';
        }
    }

    const TITLE_STYLES = {
        height: 80,
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20
    };

    const PANEL_STYLES = {
        width: "50%",
        padding: 10
    };

    const answerMapper = (index) => {
        switch (answer?.[index]) {
            case 0:
                return "incorrect";
            case -1:
                return "_______";
            default:
                return "correct";
        }
    }

    const answerHandler = (index) => {
        let clonedAnswer = JSON.parse(JSON.stringify(answer));
        if (clonedAnswer[index] !== -1) {
            clonedAnswer[index] = -1;
            setAnswer([...clonedAnswer]);
            checkLock(clonedAnswer);
            return;
        }

        if (question.questions?.[index]?.isTrue) {
            clonedAnswer[index] = 1;
        } else {
            clonedAnswer[index] = 0;
            incrementScore();
        }

        setAnswer([...clonedAnswer]);
        checkLock(clonedAnswer);
    }

    const checkLock = (answer = []) => {
        const allCorrectFound = answer.every((el, index) => ((el === 1) === (question.questions[index].isTrue)));
        const chosenAnswers = (answer.filter(el => el !== -1)).length;
        const allCorrect = allCorrectFound && (chosenAnswers === question.numberOfCorrect);
        // if (allCorrect === true) window.alert("all correct!");
        setLock(!allCorrect);
    }

    return (
      loading === false &&(
                <>
                    <div className="w-full flex flex-row ">
                        <div className="w-1/12"/>
                        <div className="w-10/12 bg-white p-4 ">
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={PANEL_STYLES}>
                                    {/*    left */}
                                    <SimpleBar style={{height:100, marginBottom:20}}>
                                    <h1 style={TITLE_STYLES}>{question.questionTitle}</h1>
                                    </SimpleBar>
                                    <div>
                                        {question.questions?.map((elem, index) => {
                                            if (elem.isBold) return (<h3 key={"type12_l_" + index}
                                                                         style={{
                                                                             fontWeight: "bold",
                                                                             marginBottom: 20
                                                                         }}>{elem.text}</h3>);
                                            return (<p key={"type12_l_" + index}
                                                       style={{marginBottom: 20}}>{index + 1}. {elem.text}</p>)
                                        })}
                                    </div>

                                </div>
                                <div style={{width: 1, backgroundColor: "#efefef", height: "100%"}}/>
                                <div style={PANEL_STYLES}>
                                    <SimpleBar style={{height:100, marginBottom:20}}>
                                    <h1 style={TITLE_STYLES}>{question.answerTitle}</h1>
                                    </SimpleBar>
                                    {/*    right*/}
                                    <div style={{height:'calc(100% - 120px)',
                                        display:'flex',flexDirection:'column',
                                        justifyContent:'flex-start'}}>
                                        {answer?.map((elem, index) => (
                                            <div key={'type13_r_' + index}
                                                 onClick={() => answerHandler(index)}
                                                 style={{backgroundColor: giveBgColor(index), marginBottom:20}}
                                            >{index + 1}. {answerMapper(index)}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/12"/>
                    </div>
                </>
            )
    );
}

export default Type13;
