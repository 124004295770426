import React, {useState, useEffect} from "react";
// import QuestionWithOptions from "../Option/QuestionWithOptions";
import SimpleBar from "simplebar-react";

const example = {
    "questionTitle": "Descriptions of places can include some topics listed below. ",
    "answerTitle": "",
    "question": [
        {
            "text": "Unique and interesting sights:Most locations have something special about them. Unique and interesting sights are particular to a place. An example could be a museum, building, or ground. An example of a unique and interesting sight could be the Eiffel Tower in Paris. ",
            "isBold": true
        },
        {
            "text": "The landscape:The landscape of a place relates to what we can see around us in a location. Landscapes can include hills, mountains, forests, roads, buildings, oceans, rivers, and lakes. Places with unique landscapes can include rivers such as the Amazon River in South America. ",
            "isBold": false
        }
    ],
    "answer": [
        {
            "text": "The people:People make a place more interesting. When you talk about people you can define which type of people live in a particular place. In addition to this, you can mention their jobs or backgrounds. An example of people in a particular place are students who go to a school.",
            "isBold": true
        }
    ],
    "type": "12"
}


function Type12({
                    question = example, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
                }) {
    const [answer, setAnswer] = useState([]);
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(true);
    const [localScore, setLocalScore] = useState(0);

    useEffect(() => {
        setLock(false);
        setAnswer(question);
        setChecked(question?.questions?.map((q) => -1));
        setLoading(false);
    }, []);
    // const checkAnswer = (option, indexOfQuestion) => {
    //     if (answer.questions[indexOfQuestion].correctOption === option) {
    //         // 1 correct, then
    //         const copy = [...checked];
    //         copy[indexOfQuestion] = 1;
    //         setChecked([...copy]);
    //     } else {
    //         // 2 incorrect , then
    //         incrementScore();
    //         const copy = [...checked];
    //         copy[indexOfQuestion] = 0;
    //         setChecked([...copy]);
    //     }
    // };

    // const incrementScore = () => {
    //     setLocalScore(localScore + 1);
    //     setScore(localScore + 1);
    // }

    // const giveBgColor = (index) => {
    //     switch (checked[index]) {
    //         case 1:
    //             return '#89B481';
    //         case 0:
    //             return '#E5B343';
    //         default:
    //             return '#f7f7f7';
    //     }
    // }

    // const checkFinished = () => {
    //
    //     const allFinished = checked.every((el) => el === 1);
    //     console.log("answer questions >", allFinished, checked);
    //     setLock(!allFinished);
    //
    // }

    // useEffect(() => {
    //     checkFinished()
    // }, [checked]);


    const TITLE_STYLES = {
        height: 80,
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20
    };


    const PANEL_STYLES = {
        width: "50%",
        padding: 10
    };

    const SCROLL_STYLES = {
        height: 300
    }

    return (

        loading === false && (

            <div className="w-full flex flex-row ">
                <div className="w-1/12"/>
                <div className="w-10/12 bg-white p-4 flex flex-col ">
                    <div style={{display: 'flex', flexDirection: 'row'}}>

                        <div style={PANEL_STYLES}>
                            {/*    left */}
                            <SimpleBar style={{height: 100, marginBottom: 20}}>

                                <h1 style={TITLE_STYLES}>{question.questionTitle}</h1>
                            </SimpleBar>
                            <SimpleBar style={SCROLL_STYLES}>
                                <div>
                                    {question.question?.map((elem, index) => {
                                        if (elem.isBold) return (<h3 key={"type12_l_" + index}
                                                                     style={{
                                                                         fontWeight: "bold",
                                                                         marginBottom: 20
                                                                     }}>{elem.text}</h3>);
                                        return (<p key={"type12_l_" + index} style={{marginBottom: 20}}>{elem.text}</p>)
                                    })}
                                </div>
                            </SimpleBar>
                        </div>
                        <div style={{width: 1, backgroundColor: "#efefef", height: "100%"}}/>
                        <div style={PANEL_STYLES}>
                            <SimpleBar style={{height: 100, marginBottom: 20}}>
                                <h1 style={TITLE_STYLES}>{question.answerTitle}</h1>
                            </SimpleBar>
                            {/*    right*/}
                            <SimpleBar style={SCROLL_STYLES}>
                                <div >
                                    {question.answer?.map((elem, index) => {
                                        if (elem.isBold) return (<h3 key={"type12_a_" + index}
                                                                     style={{
                                                                         fontWeight: "bold",
                                                                         marginBottom: 20
                                                                     }}>{elem.text}</h3>);
                                        return (<p key={"type12_a_" + index} style={{marginBottom: 20}}>{elem.text}</p>)
                                    })}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
                <div className="w-1/12"/>
            </div>
        )

    );
}

export default Type12;
