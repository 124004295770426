import React, {useEffect, useState} from "react";
import {
    StudentBackDivision as BackDiv,
    StudentContentBar as ContentBar,
    HeaderBar,
    SubHeaderBar,
    StudentWeekComponent as Week,
    StudentQuestionPreview as QuestionPreview,
    WeeklyContentComponent, Loading, WeeklyContentHead,
} from "../../../components";
import {BasicLayout} from "../../../layouts";
import {useParams} from "react-router-dom";
import {ModuleAPI} from "../../../services";
import {Link} from "react-router-dom";
import styles from "./modulepage.module.css";
import {BeatLoader} from "react-spinners";

function ModulePage() {
    const {id} = useParams();
    const [eModule, setModule] = useState(null);
    const [week, setWeek] = useState(null);
    const [currentModules, setCurrentModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [learningState, setLearningState] = useState(1);
    const [description, setDescription] = useState("Loading introduction...");
    const [weekDesc, setWeekDesc] = useState("Loading description...");

    useEffect(() => {
        ModuleAPI.getById(id).then(({data = {}}) => {
            setModule(data?.module);
            setWeek(data?.week);
            setCurrentModules(data?.week?.modules || []);
            setDescription(data?.module?.description || "");
            setWeekDesc(data?.module?.learningContent || "");
            setLearningState(data?.state || 1);
            setLearningState(data?.state);
            setLoading(false);
        });
    }, [id]);

    const mapLearningStatus = (val) => {
        switch (val) {
            case 1:
                return {color: "#1B3E4D", text: "Start learning"};
            case 2:
                return {color: "#FCB216", text: "Continue learning"};
            case 3:
                return {color: "#89B481", text: "REVISIT"};
            default:
                return {color: "#1B3E4D", text: "Start learning"};
        }
    }


    return (
        <BasicLayout subBar={<SubHeaderBar baseUrl="/student"/>} title="   ">
            {loading === false ? (
                <div>
                    <div
                        style={{
                            width: 100 + "%",
                            margin: "0 auto",
                            padding: "0 0 0 0",
                            alignItems: "center",
                        }}
                    >
                        <BackDiv moduleName={eModule.moduleName} week={week} weekDesc={weekDesc}/>
                    </div>
                    <div className="flex flex-col justify-start items-center">
                        <QuestionPreview content={description}/>
                        <div style={{display: "flex", justifyContent: 'flex-start', width: 820, marginTop: 20}}>
                            <Link to={`/questions/${eModule._id}`}>
                                <div className={styles.button} style={{
                                    backgroundColor: mapLearningStatus(learningState).color
                                }}>
                                    {mapLearningStatus(learningState).text}
                                </div>
                            </Link>
                        </div>
                        <div style={{marginTop: 50}}>
                            <div style={{
                                display: 'grid', gridAutoFlow: 'row', width: 820, alignItems: 'center',
                                backgroundColor: "#fff",
                                padding: 20,
                                borderRadius: 20
                            }}>
                                <div style={{gridColumn: "1 /span 1"}}>
                                    <WeeklyContentHead weekNum={week.weekNumber ?? "0"}
                                                       percent={week.percerntage ?? 0}/>
                                </div>
                                <div style={{gridColumn: "2 /span 10"}}>
                                    <WeeklyContentComponent active={true} modules={currentModules}/>
                                </div>
                            </div>
                        </div>
                        {/* <ContentBar /> */}
                    </div>
                </div>
            ) : <div style={{
                display: 'flex',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: 'absolute',
                alignItems: "center",
                justifyContent: "center"
            }}><BeatLoader/></div>}
        </BasicLayout>
    );
}

export default ModulePage;
