import {
  Chart,
  BarController,
  BubbleController,
  LineController,
  PieController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  TimeSeriesScale,
} from "chart.js";
import { instances } from "simplebar-react";

Chart.register(
  BarController,
  BubbleController,
  LineController,
  PieController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  TimeSeriesScale
);
export const originDataSet = [
  {
    label: "Writing",
    val: 0,
    color: "#E46262",
  },
  { label: "Grammar", val: 0, color: "#6FB8D5" },
  {
    label: "Review",
    val: 0,
    color: "#3A9C6E",
  },
  { label: "Vocabulary", val: 0, color: "#F27F4E" },
  {
    label: "Reading",
    val: 0,
    color: "#516CBE",
  },
  { label: "Listening", val: 0, color: "#8BC370" },
  { label: "Speaking", val: 0, color: "#F0C055" },
];

function generateData(raw) {
  const timeSpend = [...raw];
  const rawSet = [...originDataSet];

  // const dataSet = timeSpend.map((item) => item.percentage);
  for (const item of timeSpend) {
    for (const d of rawSet) {
      if (
        String(item.moduleName).toLowerCase() === String(d.label).toLowerCase()
      ) {
        d.val = item.percentage;
      }
    }
  }

  const dataSet = rawSet.map((el) => el.val);
  const labels = rawSet.map((item) => item.label);
  const backgroundColors = rawSet.map((el) => el.color);
  return { dataSet, labels, backgroundColors };
}

function refreshHTMLElementById(id) {
  const element = document.getElementById(id);
  const newElement = document.createElement("canvas");

  if (element.hasAttributes()) {
    for (const attr of element.attributes) {
      newElement.setAttribute(attr.name, attr.value);
    }
  }
  element.parentElement.replaceChild(newElement, element);
}

export function donatChart(timeSpend) {
  const { dataSet, labels, backgroundColors } = generateData(timeSpend);

  const ctx = document.getElementById("time_pie_chart");
  /** const myChart = */
  new Chart(ctx, {
    type: "doughnut",
    data: {
      datasets: [
        {
          label: "My First Dataset",
          data: [...dataSet],
          backgroundColor: [...backgroundColors],
          hoverOffset: 4,
        },
      ],
    },
    options: {
      scales: {},
    },
  });
}

export function radarChart(timeSpend) {
  const { dataSet, labels } = generateData(timeSpend);
  // const backgroundColors = timeSpend.map((item) => item.color);
  refreshHTMLElementById("radar_chart");
  const ctx = document.getElementById("radar_chart");
  // const labels = timeSpend.map(item => item.moduleName);

  const data = {
    labels: [...labels],
    datasets: [
      {
        label: "score",
        data: [...dataSet],
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        pointBackgroundColor: "rgb(54, 162, 235)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
      },
    ],
  };

  const myChart = new Chart(ctx, {
    type: "radar",
    data: data,
    options: {
      elements: {
        line: {
          borderWidth: 0.5,
        },
        point: {
          radius: 1.5,
        },
        label: {},
      },
    },
  });
}
