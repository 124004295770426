import React from "react";
import SimpleBar from "simplebar-react";
import "./scroll_card_view.css";

function ScrollCardView({ children }) {
  return (
    <>
      <SimpleBar style={{ maxHeight: 440 + "px" }}>
        <div
          className="eap__scroll-view-vertical"
          style={{ padding: 10 + "px" }}
        >
          {/* {[...children].map((el, idx) => el)} */}
          {children}
        </div>
      </SimpleBar>
    </>
  );
}

export default ScrollCardView;
