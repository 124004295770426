import React, {useEffect, useRef} from "react";
import styles from "./weekly_content.module.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {
    Pagination,
    WeeklyContentHead,
    WeeklyContentComponent,
    SmallDropdown,
} from "../../../components";
import {useWeeklyContentTeacher} from "../../../hooks";
import {BeatLoader} from "react-spinners";

function WeeklyContent() {
    const {
        currentProgramIndex,
        currentPage,
        setCurrentPageNum,
        totalPages,
        loading,
        programs,
        programMapper,
        setCurrentProgramHandler,
    } = useWeeklyContentTeacher();


    const processedModules = (index) => {
        if (!currentPage[index]) return [];
        const modules = currentPage[index].modules;
        if (!modules) return [];
        return modules.map((el) => {
            return {...el, completed: true}
        })
    }


   return (
        <CreateLayout
            title="Weekly Content"
            href="/teacher/"
            dropdown={
                <div>
                    <SmallDropdown
                        placeHolder={programs.length > 0 ? programs[0]._id + " " : "Please select "}
                        // placeHolder={currentProgramIndex > -1 ? (programMapper().map((el) => el.keyname)[currentProgramIndex]):"Please select program"}
                        // options={programMapper().map((el) => el.keyname)}
                        // options={programs}
                        options={[...programs].map((el) => el._id)}
                        indexHandler={setCurrentProgramHandler}
                    />
                </div>
            }
            pagination={<Pagination max={totalPages} setPage={setCurrentPageNum}/>}
        >
            {loading && <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}><BeatLoader/></div>}
            <div className={styles.container}>
                {currentPage.length > 0 && [
                    {
                        line: styles.line1,
                        label: styles.first_week_label,
                        week: styles.first_week,
                    },
                    {
                        line: styles.line2,
                        label: styles.second_week_label,
                        week: styles.second_week,
                    },

                    {
                        line: styles.line3,
                        label: styles.third_week_label,
                        week: styles.third_week,
                    },
                    {
                        line: styles.line4,
                        label: styles.four_week_label,
                        week: styles.four_week,
                    },
                    {
                        line: styles.line5,
                        label: styles.five_week_label,
                        week: styles.five_week,
                    },
                ].map((el, index) => {
                    return (
                        currentPage[index] && (
                            <div className={el.line} key={index}>
                                <div className={el.label}>
                                    <WeeklyContentHead
                                        lock={currentPage[index]["locked"]}
                                        weekNum={currentPage[index]["weekNumber"]}
                                        percent={currentPage[index]["percentage"]}
                                        hide={true}
                                    />
                                </div>
                                <div className={el.week}>
                                    <WeeklyContentComponent key={el._id}
                                                            active={!currentPage[index]["locked"]}
                                                            modules={
                                                                processedModules(index)
                                                            }
                                    />
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
        </CreateLayout>
    );
}

export default WeeklyContent;
