import moment from "moment";

export default function addDays(startDate, days) {
    console.log('moment: start date', startDate);
    return moment(startDate, "YYYY-MM-DD").add(days, 'days');
}


export function dateFormatter(date = new Date()) {
    // const day = ("0" + date.getDate()).slice(-2);
    // const month = ("0" + (date.getMonth() + 1)).slice(-2);
    // return date.getFullYear() + "-" + month + "-" + day;
    // return date.toISOString().split('T')[0];
    return moment(date).format("YYYY-MM-DD");
}
