import React, {useContext, createContext, useEffect, useReducer} from "react";
import {signData, decodeData, AuthAPI} from "../../services";
import styles from "../../assets/css/modal.module.css";

const authContext = createContext(undefined);

function AuthProvider({children}) {
    const auth = useProvideAuth();
    const {
        // user,
        // login,
        // logout,
        // checkAuth,
        toggleModal,
        // setToggleModal,
        modalTitle,
        // setModalTitle,
        modalContent,
        // setModalContent,
        modalHandler,
        // setModalHandler,
        isDeleteModal,
        closeModal,
    } = auth;
    return (
        <authContext.Provider value={auth}>
            {" "}
            {children}
            <div>
                {/* <!-- The Modal --> */}
                <div
                    className={
                        toggleModal === true ? styles["modal"] : styles["modal_hide"]
                    }
                >
                    {/* <!-- Modal content --> */}
                    <div className={styles["modal_content"]}>
                        {modalTitle && modalTitle.length > 0 ? <h1>{modalTitle}</h1> : null}
                        {modalContent && modalContent.length > 0 ? (
                            <p>{modalContent}</p>
                        ) : null}
                        <div className={styles["modal_action"]}>
              <span
                  className={styles["modal_close"]}
                  onClick={() => {
                      // setToggleModal(false);
                      closeModal();
                  }}
              >
                Cancel
              </span>
                            <span
                                className={
                                    isDeleteModal
                                        ? styles["modal_text_delete"]
                                        : styles["modal_text"]
                                }
                                onClick={() => {
                                    modalHandler();
                                    // setToggleModal(false);
                                    closeModal();
                                }}
                            >
                {isDeleteModal ? "Delete" : "Confirm"}
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </authContext.Provider>
    );
}

export default AuthProvider;

export const useAuth = () => useContext(authContext);
/** */
const initialState = {};

const AUTH_Key = "eap_auth";

const authReducer = (currentState, newState) => {
    if (newState === null) {
        localStorage.removeItem(AUTH_Key);
        return initialState;
    }
    return {...currentState, ...newState};
};

const localState = decodeData(localStorage.getItem(AUTH_Key));

/** */
function useProvideAuth() {
    const [user, setUser] = useReducer(authReducer, localState || initialState);

    const [toggleModal, setToggleModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalContent, setModalContent] = React.useState("");
    const [modalHandler, setModalHandler] = React.useState(null);
    const [isDeleteModal, setIsDeleteModal] = React.useState(false);

    const showModal = (title, content, handler, isDelete) => {
        console.log("showModal", title, content, handler, isDelete);
        setModalTitle(title);
        setModalContent(content);
        setModalHandler(() => handler);
        setIsDeleteModal(isDelete);
        setToggleModal(true);
    };
    const closeModal = () => {
        setModalTitle("");
        setModalContent("");
        setModalHandler(() => {
        });
        setIsDeleteModal(false);
        setToggleModal(false);
    };
    useEffect(() => {
        localStorage.setItem(AUTH_Key, signData(user));
    }, [user]);

    const login = (cb = () => {
    }, {username, password, role, errorHandler}) => {
        const asyncLogin = async () => {
            const userData = await AuthAPI.login({
                username,
                password,
                role,
                errorHandler,
            });
            setUser(userData);
            cb(userData);
        };
        return asyncLogin();
    };

    const logout = (cb = () => {
    }) => {
        const asyncLogout = async () => {
            await AuthAPI.logout();
            setUser(null);
            cb();
            //   localStorage.removeItem(AUTH_Key);
        };
        return asyncLogout();
    };

    const checkAuth = (cb = () => {
    }) => {
        const asyncCheck = async () => {
            const checkResult = await AuthAPI.checkLoggedIn(user.role);
            cb();
            return checkResult;
        };
        return asyncCheck();
    };

    const roleMapper = () => {
        switch (user.role) {
            case 1:
                return "student";
            case 2:
                return "teacher";
            case 3:
                return "principal"
            default:
                return "";
        }
    };

    return {
        user,
        setUser,
        roleMapper,
        login,
        logout,
        checkAuth,
        toggleModal,
        setToggleModal,
        modalTitle,
        setModalTitle,
        modalContent,
        setModalContent,
        modalHandler,
        setModalHandler,
        isDeleteModal,
        setIsDeleteModal,
        showModal,
        closeModal,
    };
}
