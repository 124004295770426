import React from "react";
import "./text_field.css";
import "../common.css";
import { SearchSvgIcon } from "../../../../assets/svg";

function TextField({
  type = "text",
  inputHandler = () => {},
  onBlurhandler = () => {},
  onFocushandler = () => {},
}) {
  return (
    <div className="eap__search-box-wrapper">
      {/* <label>{label}</label> */}
      <span>
        <SearchSvgIcon />{" "}
      </span>
      <input
        type={type}
        placeholder="Search"
        onInput={inputHandler}
        onBlur={onBlurhandler}
        onFocus={onFocushandler}
      />
    </div>
  );
}

export default TextField;
