import React, {useEffect} from "react";
import "./profile_menu.css";
import {PersonSvgIcon,} from "../../../assets/svg";
import {Button} from "../../";
import {useAuth} from "../../../context";
import {Link} from "react-router-dom";

function ProfileIconMenu({ handler = () => {} }) {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const { logout, user, roleMapper } = useAuth();

  const toggleHandler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  const eventHandler =(e) => {
    if (
        toggleMenu &&
        e.target.classList.length > 0 &&
        e.target.classList[0].value !== "eap__profile-menu-container"
    ) {
      setToggleMenu(false);
    }
  }

  const bindListener = (e) => {
    window.addEventListener("click", eventHandler);
  };

  useEffect(() => {
    bindListener();
    return () => {
      window.removeEventListener("click",eventHandler)
    };
  }, []);
  return (
    <div onClick={toggleHandler}>
      <div className="eap__profile-container">
        <div className="eap__profile-avatar">
          {user.avatar ? <img src={user.avatar} alt={'avatar image'} style={{objectFit:'cover'}} /> : <PersonSvgIcon/>}
        </div>
        <div
          className="eap__profile-name mobile-profile"
          style={{ paddingRight: "20px" }}
          onClick={toggleHandler}
        >
          {user.studentName ?? user.teacherName}
        </div>
   
        {toggleMenu ? (
          <div className="eap__profile-menu-container eap__shadow">
            <div>
              <Link to={`/${roleMapper()}/profile`}>
                <Button text="Profile" color="#1b3e4d" textColor="#ffffff" />
              </Link>
            </div>

            <div>
              <Button
                text="Logout"
                textColor="#1b3e4d"
                click={() => logout(console.log)}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileIconMenu;
