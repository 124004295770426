import React from "react";
// import styles from "./text_banner.module.css";
// import { Col } from "react-bootstrap";
// import TextBannerCard from "../Card/TextBanner/TextBannerCard";
function TextBanner() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center",
          fontFamily:"Futura-Regular!important" }}
    >
      {/* <Col xs={12}>
        <TextBannerCard index={0} />
      </Col>
      <Col xs={12}>
        <TextBannerCard index={1} />
      </Col> */}
      <h2
        style={{
          maxWidth: 900 + "px",
          textAlign: "center",
          fontSize: 30 + "px",
          marginTop: 200 + "px",
          color: "#4d4d4d"
        }}
      >
        First digital English for Academic Purpose(EAP) programme with worldwide
        recognition.
      </h2>
      <h2
        style={{
          maxWidth: 900 + "px",
          textAlign: "center",
          fontSize: 20 + "px",
          marginTop: 54 + "px",
          color: "#000000",
        }}
      >
        Progression to 100+ universities worldwide
      </h2>
      <h2
        style={{
          maxWidth: 900 + "px",
          textAlign: "center",
          fontSize: 30 + "px",
          marginTop: 167 + "px",
          color: "#4d4d4d",
        }}
      >
        Mission of EAP Space
      </h2>
      <h2
        style={{
          maxWidth: 900 + "px",
          textAlign: "center",
          fontSize: 20 + "px",
          marginTop: 42 + "px",
          color: "#000000",
        }}
      >
        To transform the conventional methods of delivering Academic English
        with a fully digital training platform that is comprehensive,
        interactive, and cost-effective, designed for students aiming to study
        higher education in an English language setting.
      </h2>
    </div>
  );
}

export default TextBanner;
