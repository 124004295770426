import React, {useEffect, useState} from "react";
import "./create_class.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {
    ClassSvgIcon, // PersonSvgIcon
} from "../../../assets/svg";
import {Dropdown, TeacherStudentSearchItem as StudentSearchItem} from "../../../components";

import {
    Button, // PhoneTextField,
    // IconWithRightText,
    TextField, // Dropdown,
    Pagination, SearchBox, TeacherStudentWrapper,
} from "../../../components";
import {ClassAPI, ProgramsAPI} from "../../../services";
import {
    addDays, dateFormatter, uuid4, moveElements, paginationHelper,
} from "../../../utils";
import {useAuth} from "../../../context";

function CreateClass() {
    const {showModal} = useAuth();
    const [selectedList, setSelectedList] = React.useState([]);
    const [fetchedList, setFetchedList] = React.useState([]);
    const [tempResult, setTempResult] = React.useState([]);
    const [searchList, setSearchList] = React.useState([]);
    const [duration, setDuration] = React.useState(0);
    const [durationList, setDurationList] = React.useState([{
        name: "Fast - Every 4 Days ", val: 4
    }, {
        name: "Normal - Every 7 Days ", val: 7
    }, {
        name: "Flexible - Every 14 Days ", val: 14
    }]);
    const [startDate, setStartDate] = React.useState(dateFormatter(new Date()));
    const [endDate, setEndDate] = React.useState(dateFormatter(new Date()));
    const [className, setClassName] = React.useState("");
    const [currentPageNum, setCurrentPageNum] = React.useState(1);
    const processSelectedList = () => {
        const tempList = [];
        for (const item of selectedList) {
            tempList.push({
                name: item.studentName, handler: () => moveElements(selectedList, setSelectedList,
                    // searchList,
                    // setSearchList,
                    tempResult,
                    setTempResult,
                    item._id),
            });
        }
        return tempList;
    };


    const durationHandler = (idx) => {
        setDuration(durationList[idx].val);
        setEndDate(dateFormatter(addDays(startDate, durationList[idx].val, duration)));
    }


    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload").click();
    };
    const [imageFile, setImageFile] = useState(null);


    /**
     * Before create class, need to choose which program it is.
     * */

    const [showProgramSelectionDialog, setShowProgramSelectionDialog] = useState(true);
    const [programOptions, setProgramOptions] = useState([]);
    const [programSelectionOptions, setProgramSelectionOptions] = useState([]);
    const [programSelectedIndex, setProgramSelectedIndex] = useState(-1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Promise.resolve(() => setLoading(true))
            .then(() => ProgramsAPI.getAll())
            .then(({data} = []) => {
                // alert(JSON.stringify(data));
                data = data || [];
                data instanceof Array && setProgramOptions(data);
                data instanceof Array && setProgramSelectionOptions([...data].map(el => el.programName));
            })
    }, []);

    React.useEffect(() => {
        if (programSelectedIndex === -1) return;
        const callApi = async () => {
            const {data = []} = await ClassAPI
                .getClassForCreation(
                    programOptions?.[programSelectedIndex]?._id
                );
            setFetchedList(data);
            setSearchList(data);
            setTempResult(data);
        };
        callApi().then();
    }, [programSelectedIndex]);


    if (showProgramSelectionDialog || programSelectedIndex === -1) {
        return (
            <CreateLayout title={'Create Class'} href={"/principal"}>
                <div style={{
                    minHeight: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1em",
                }}>
                    <h1>Select program before proceeding</h1>
                    <Dropdown options={programSelectionOptions}
                              placeHolder={'Please select a program'}
                              indexHandler={setProgramSelectedIndex}
                    />
                    <Button text={"Confirm"} click={() => setShowProgramSelectionDialog(false)}/>
                </div>
            </CreateLayout>
        );
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile);
        }
        const classData = {
            name: className,
            students: selectedList,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            duration,
            programId: programOptions[programSelectedIndex]
        };

        formData.append("data", JSON.stringify(classData));
        const response = await ClassAPI.create(formData);

        // alert(JSON.stringify(response));
        showModal(response.code === 200 ? "Success" : "Error", response.message, () => {
            window.location.reload();
        }, false);
    };

    if (!showProgramSelectionDialog && programSelectedIndex !== -1) return (<form onSubmit={submitHandler}>
        <CreateLayout title="Create Class" href="/principal">
            <div className="eap__create-student-main-panel">
                <div className="class-management-elem1">
                    <div className="eap__create-student-avatar-wrapper eap__shadow ">
                        {imageFile ? (<img
                            id="uploadPreview"
                            alt=""
                            style={{
                                width: 128 + "px",
                                height: 128 + "px",
                                borderRadius: 128 + "px",
                                objectFit: "cover",
                            }}
                        />) : (<ClassSvgIcon/>)}
                    </div>
                </div>
                <div className="class-management-elem2" onClick={uploadHandler}>
                    <Button text="Upload Picture"/>
                    <input
                        type="file"
                        id="upload"
                        hidden
                        onChange={(e) => {
                            setImageFile(e.target.files[0]);
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.target.files[0]);
                            fileReader.onload = function (evt) {
                                document.getElementById("uploadPreview").src = evt.target.result;
                            };
                        }}
                    />
                </div>
                <div className="class-management-elem3">
                    <label>Select member</label>
                </div>
                <div className="class-management-elem4">
                    <SearchBox
                        inputHandler={(e) => {
                            setSearchList([...fetchedList].filter((elem) => String(elem.studentName).includes(e.target.value)));
                        }}
                    />
                </div>
                <div className="class-management-elem15">
                    <Pagination
                        min={1}
                        max={tempResult ? Math.ceil(tempResult?.length / 4) : 1}
                        setPage={(page) => {
                            setCurrentPageNum(page);
                            setTempResult(paginationHelper(fetchedList, 4, page));
                        }}
                    />
                </div>
                <div className="class-management-elem5"/>
                {" "}
                <div className="class-management-elem7">
                    <label>Class member</label>
                </div>
                <div className="class-management-elem6">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 100 + "%",
                            gap: "18px",
                            minHeight: 350 + "px",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        {tempResult.length < 1 ? (<>Empty</>) : tempResult.length > 4 ? ([...tempResult]
                            .slice(0, 4)
                            .map((elem) => (<StudentSearchItem
                                key={uuid4()}
                                name={elem.studentName}
                                click={() => moveElements(tempResult, setTempResult, selectedList, setSelectedList, elem._id)}
                            />))) : ([...tempResult].map((elem) => (<StudentSearchItem
                            key={uuid4()}
                            name={elem.studentName}
                            click={() => moveElements(tempResult, setTempResult, selectedList, setSelectedList, elem._id)}
                        />)))}
                        {/*{searchList.length < 1 ? (<>Empty</>) : searchList.length > 4 ? ([...tempResult]*/}
                        {/*        .slice(0, 4)*/}
                        {/*        .map((elem) => (<StudentSearchItem*/}
                        {/*                key={uuid4()}*/}
                        {/*                name={elem.studentName}*/}
                        {/*                click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}*/}
                        {/*            />))) : ([...searchList].map((elem) => (<StudentSearchItem*/}
                        {/*            key={uuid4()}*/}
                        {/*            name={elem.studentName}*/}
                        {/*            click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}*/}
                        {/*        />)))}*/}
                    </div>
                </div>
                <div className="class-management-elem9">
                    <label>Start from</label>
                </div>
                <div className="class-management-elem12">
                    <TeacherStudentWrapper list={processSelectedList()}/>
                </div>
                <div className="class-management-elem14-create-class">
                    <Button
                        text="Create"
                        color="#eabc7f"
                        textColor="#ffffff"
                        primary
                        click={() => {
                            document.getElementById("create-class-submit").click();
                        }}
                    />
                    <input type="submit" hidden id="create-class-submit"/>
                </div>
                <div className="class-management-elem16">
                    <input
                        type="date"
                        required
                        onInput={(e) => {
                            setStartDate(dateFormatter(e.target.value));
                        }}
                        value={startDate}
                    />
                    To <input type="date" readOnly value={endDate}/>
                </div>
                <div className="class-management-elem17">
                    <label>Duration</label>
                </div>
                <div className="class-management-elem18">

                    <Dropdown placeHolder={"Select Class Duration"} options={durationList.map(el => el.name)}
                              indexHandler={durationHandler}/>
                    {/*<TextField*/}
                    {/*  required*/}
                    {/*  value={duration}*/}
                    {/*  inputHandler={(e) => {*/}
                    {/*    const v = e.target.value;*/}
                    {/*    console.log(v);*/}
                    {/*    try {*/}
                    {/*      if (isNaN(v)) {*/}
                    {/*        setDuration(0);*/}
                    {/*        alert("Invalid input, should be number");*/}
                    {/*        return;*/}
                    {/*      }*/}
                    {/*      setDuration(Number(v));*/}
                    {/*      setEndDate(dateFormatter(addDays(startDate, Number(v))));*/}
                    {/*    } catch (err) {*/}
                    {/*      alert(err.message);*/}
                    {/*      setDuration(0);*/}
                    {/*    }*/}
                    {/*  }}*/}
                    {/*/>*/}
                </div>
                <div className="class-management-elem19">
                    <label>Name</label>
                </div>
                <div className="class-management-elem20">
                    <TextField
                        required
                        value={className}
                        inputHandler={(e) => setClassName(e.target.value)}
                    />
                </div>
            </div>
        </CreateLayout>
    </form>);
}

export default CreateClass;
