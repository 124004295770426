import React, {useEffect, useState} from 'react';
import {BasicAdminLayout} from "../../../layouts";
import {WideButtonWithIcon} from "../../../components";
import {Table} from 'react-bootstrap';
import {useHistory, useParams} from "react-router-dom";
import styles from './main.module.css';
import {AdminAPI} from "../../../services";
import {BeatLoader} from "react-spinners";
import {JsonEditor} from "jsoneditor-react";
import CommonButton from "../../../components/Common/Button/CommonButton/CommonButton";

function FileManagerPage(props) {

    const [files, setFiles] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleUpload = event => {
        event.preventDefault();
        if (!files) return;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name)
        }
        Promise.resolve()
            .then(()=>setLoading(true))
            .then(() => AdminAPI.uploadFile(formData))
            .then(res => {
                setLoading(false);
                window.alert(JSON.stringify(res))
            })
            .then(() => window.location.reload())
        ;
    }

    useEffect(() => {

        Promise.resolve()
            .then(() => setLoading(true))
            .then(() => AdminAPI.getAllFiles())
            .then(({results, page, pages, pagesize}) => {
                setFileList(results)
            })
            .then(() => setLoading(false))
    }, []);

    const deleteHandler = ({key}) => {
        const confirm = window.confirm(`Do you want to delete file ${key}? This action is NOT reversible.`);
        confirm && Promise.resolve()
            .then(() => setLoading(true))
            .then(() => AdminAPI.deleteFile({key}))
            .then((res) => window.alert(JSON.stringify(res, null, 2)))
            .then(() => setLoading(false))
            .then(()=> window.location.reload())
    }

    if (loading) return <div
        style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        <BeatLoader color={"#1b3e4d"}/></div>;

    return (
        <BasicAdminLayout>
            <div style={{height: 105}}/>

            <div className={styles['title']}>Files Panel</div>

            <form>
                <input type={'file'} onChange={event => setFiles(event.target.files)} multiple/>
                <br/>
                <CommonButton text={'Upload'} click={handleUpload}/>
            </form>

            <div className={styles['main-panel']}>
                <Table responsive>
                    <thead>
                    <tr>
                        <td colSpan={1}>File Key Name</td>
                        <td colSpan={1}>Size</td>
                        <td colSpan={1}>Modified Date</td>
                        <td colSpan={5}>URL</td>
                        <td colSpan={1}>Action</td>
                    </tr>
                    </thead>
                    <tbody>
                    {[...fileList].map((elem, index) => (
                        <tr key={'file_' + index}>
                            <td colSpan={1}>{elem.key}</td>
                            <td colSpan={1}>{elem.size} bytes</td>
                            <td colSpan={1}>{elem.lastModified}</td>
                            <td colSpan={5}><a target={'_blank'} rel={'noreferrer'} href={elem.url}>{elem.url}</a>
                            </td>
                            <td><CommonButton text={'Delete'} color={'red'} textColor={'#ffffff'} click={()=> deleteHandler({key:elem.key})}/> </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            <div/>


        </BasicAdminLayout>
    );
}

export default FileManagerPage;