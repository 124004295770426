import React, {useEffect, useState} from 'react';
import {useStopwatch} from "react-timer-hook";
import {BookmarkAPI} from "../services";
import {renderQuestions} from "../components/Student/Questions/QuestionWrapper/question";
import {useAuth} from "../context";

function useBookmarkQuestionHooks() {
    const {showModal} = useAuth();
    // loading flag
    const [loading, setLoading] = useState(true);

    // module question
    const [questions, setQuestions] = useState([]);
    // module question refs
    const [questionsRef, setQuestionsRef] = useState([]);


    //initialize
    useEffect(() => {
        Promise.resolve(() => {
            setLoading(true);
        })
            .then(() => BookmarkAPI.getAll())
            .then((data) => {

                const {questions} = data;
                initQuestions(questions);


            })
            .catch((err) => {
                console.log(`err > ${err}`)
            }).finally(() => {
            setLoading(false);
        });
    }, []);

    const initQuestions = (questions) => {
        setQuestionsRef([...questions]);
        renderQuestions({
            q: questions, setQuestions,
            setLock: (v) => {
            }, lock: false,
            score: 0, setScore: (v) => {
            },
            current: 0, setCurrent: (V) => {
            },
            auto: false
        });
    }



    const removeBookmark = (id) => {
        showModal(" ", `Sure to delete?`, () =>
          BookmarkAPI.remove(id).then(({ msg }) => {
            showModal(" ", msg, () => {}, false);
          }).then(()=> window.location.reload())
        );
    }

    return {
        loading,
        questions,
        questionsRef,
        removeBookmark
    }
}

export default useBookmarkQuestionHooks;