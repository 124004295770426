import React, {useEffect, useState} from "react";
import styles from "./type7.module.css";

function shuffleArray(incomingArray) {
    let array = [...incomingArray];
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

function Type7({
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
               }) {
    const [loading, setLoading] = useState(true);
    const [answer, setAnswer] = useState([]);
    const [selected, setSelected] = useState(-1);
    const [words, setWords] = useState([]);
    useEffect(() => {
        const copy = {...question};
        const answers = question.words.map((w) => {
            return {label: "               ", required: w.meaning, state: 0};
        });
        copy.words = shuffleArray(copy.words);
        copy.answers = answers;
        setAnswer(copy);
        setWords(copy.words);
        setLoading(false);
    }, []);
    const handleSelect = (index) => {
        if (selected === index) return setSelected(-1);
        return setSelected(index);
    };
    const checkAnswer = (index) => {
        if (selected === -1) return;

        const selectedOption = answer.words[selected];
        const toCheck = answer.answers[index];
        if (toCheck.state === 1) return;
        if (selectedOption.meaning === toCheck.required) {
            /** correctly match */
                // set flag in answer
            const copy = {...answer};
            copy.answers[index].state = 1;
            copy.answers[index].label = selectedOption.word;
            setAnswer(copy);
            // set flag in words
            const copiedWords = [...words];
            copiedWords[selected]["correct"] = true;
            setWords([...copiedWords]);

            setSelected(-1);
        } else {
            /** incorrectly match */
            const copy = {...answer};
            copy.answers[index].state = 2;
            setAnswer(copy);
            setTimeout(() => {
                const copy = {...answer};
                copy.answers[index].state = 0;
                setAnswer(copy);
            }, 1000);
        }
    };

    const getColors = (index) => {
        switch (index) {
            case 0:
                return "#f7f7f7";
            case 1:
                return "#88b380";
            case 2:
                return "#e4b243";
            default:
                return "#333"
        }
    }

    return (
        <div>
            {loading === false ? (
                <>
                    <div className="w-full flex flex-row ">
                        <div className="w-1/12"/>
                        <div className="w-10/12 bg-white p-4 flex flex-col ">
                            <div>{question.prompt}</div>
                            <div className="flex flex-row w-full h-full divide-x">
                                <div className="flex flex-col w-1/2 p-2 space-y-5 mt-4">
                                    <div>{question.sentence}</div>
                                    {words.map((a, i) => (
                                        <div key={i}
                                             style={{
                                                 display: "flex",
                                                 padding: "8px 20px",
                                                 maxWidth: "100%",
                                                 backgroundColor: selected === i ? "#e4b243" : "#f7f7f7",
                                                 borderRadius: 10,
                                                 color: selected === i ? "#fff" : "",
                                                 visibility: words[i].correct ? "hidden" : ""
                                             }}
                                             className={styles.btn}
                                             onClick={() => handleSelect(i)}
                                        >
                                            {a.word}
                                        </div>
                                    ))}
                                </div>

                                <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center mt-4">
                                    <div style={{visibility: "hidden"}}>{question.sentence}</div>
                                    {answer.answers.map((a, i) => (
                                        <div key={i + "type7_a"}
                                             style={{
                                                 display: 'flex',
                                                 flexDirection: 'row',
                                                 width: "100%",
                                                 gap: 5,
                                                 alignItems: "center"
                                             }}

                                        >
                                            <span>{i + 1}.</span>

                                            <div
                                                className={styles.btn}
                                                onClick={() => checkAnswer(i)}
                                                style={{
                                                    display: "flex",
                                                    backgroundColor: getColors(a.state),
                                                    color: a.state === 0 ? "" : "#fff",
                                                    padding: "8px 20px",
                                                    width: "100%",
                                                    borderRadius: 10,
                                                }}>
                                                {a.label}
                                            </div>


                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="w-1/12"/>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default Type7;
