import React from "react";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import sample from "./pic_sample.svg";
import styles from "./style.module.css";
import {LockSvgIcon} from "../../../assets/svg";

function CreativeVideoCard({
                               videoContents = [{
                                   _id: "6vVkY0mfGx8",
                                   thumbnail: 'https://www.eap-space.com//uploads/20200905/41d7cd46bab11e2c6048892219dba0be.png',
                                   week: 1,
                                   description: "Jeff is doing his punishment and \n" +
                                       "went on an university campus to \n" +
                                       "interview random student…"
                               },
                                   {
                                       _id: "TKui-FQtwok",
                                       thumbnail: 'https://www.eap-space.com//uploads/20200905/41d7cd46bab11e2c6048892219dba0be.png',
                                       week: 2,
                                       description: "Meet the challenge team, could you do " +
                                           "The Charades challenge better?"
                                   }


                               ], label
                           }) {
    return (
        <div className="flex flex-col space-y-3 eap__shadow p-8" style={{borderRadius: 20, backgroundColor: "#fff"}}>
            <span style={{fontSize: 25}}>{label}</span>
            <SimpleBar style={{maxWidth: '100%'}}>
                <div className="flex flex-row space-x-8">
                    {videoContents.map((mod, index) => {
                        return (
                         <div key={index + "creative_video"} className={mod.locked ? styles['disabled-link']: ""}>
                             <Link
                                   to={mod.locked ? `/student` : `/student/videos/${mod._id}`}>
                                 <div
                                     className={styles.card}
                                     style={{
                                         display: "flex", flexDirection: 'column', columnGap: 30, rowGap: 18, margin: 5,
                                         paddingBottom: 20,
                                         minHeight: 314, maxHeight: 314, minWidth: 339, maxWidth: 339,
                                         alignItems: 'flex-start', justifyContent: 'flex-start',
                                         borderRadius: 14, overflow: 'hidden', textOverflow: "ellipsis"
                                     }}
                                 >
                                     <img src={mod.thumbnail} alt={"video "}
                                          className={mod.locked ? styles['disabled-link-pic']:""}
                                          style={{borderRadius: 14, height: 175, width: "100%", objectFit: "cover"}}/>
                                     <div style={{
                                         fontSize: 16,
                                         fontWeight: 'bold',
                                         padding: "0 10px",
                                         display: "flex",
                                         gap: 10
                                     }}>{mod.title} {mod.locked && <LockSvgIcon/>}</div>

                                     <div style={{
                                         fontSize: 16,
                                         padding: "0 10px",
                                         textOverflow: 'ellipsis',
                                     }}>{mod.description.slice(0, 120) + "..."}</div>

                                 </div>
                             </Link>
                         </div>
                        );
                    })}
                </div>
            </SimpleBar>
        </div>
    );
}

export default CreativeVideoCard;
