import { POST, GET } from "../";
export default class PlacementTestAPI {
    static async getAll() {
        try {
            const { data } = await GET(`/placement-test/`);
            return data;
        } catch (err) {
            return [];
        }
    }
    // static async getById(id) {
    //     try {
    //         const { data } = await GET(`/class/get/${id}`);
    //         return data;
    //     } catch (err) {
    //         return [];
    //     }
    // }

    static async upsert(payload ={}) {
        try {
            const { data } = await POST(`/placement-test/`, payload);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getAllSchools(payload ={}) {
        try {
            const { data } = await GET(`/placement-test/schoolList`, payload);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async submit(payload = {}){
        try {
            const { data } = await POST(`/placement-test/submit`, payload);
            console.log(data);
            return data;
        } catch (err) {
            return [];
        }
    }
}
