import React from "react";
import {Follow, Option, Type1, Type2, Type3, Type4, Type5, Type6, Type7, Type8, Type9, Type10, Type11} from "../../components/Student/Questions/Types";

export const question = {
  question1: {
    prompt: "This is a prompt",
    mediaUrl: "https://picsum.photos/200/300",
    mediaType: "image",
    questions: [
      {
        question: "This is a question1",
        options: [
          { label: "Location", isTrue: false },
          { label: "History", isTrue: false },
          { label: "Culture", isTrue: true },
          { label: "Food", isTrue: false },
          { label: "Water", isTrue: false },
        ],
      },
      {
        question: "This is a question2",
        options: [
          { label: "Location2", isTrue: false },
          { label: "History", isTrue: false },
          { label: "Culture", isTrue: true },
          { label: "Food", isTrue: false },
          { label: "Water", isTrue: false },
        ],
      },
      {
        question: "This is a question3",
        options: [
          { label: "Location3", isTrue: false },
          { label: "History", isTrue: false },
          { label: "Culture", isTrue: true },
          { label: "Food", isTrue: false },
          { label: "Water", isTrue: false },
        ],
      },
    ],
  },
  question3: {
    prompt: "This is a question prompt",
    recordingUrl:
      // "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
    "https://campaign-system-bucket-1910.s3.ap-southeast-2.amazonaws.com/assets/test.mp3",

    options: [
      { label: "Location", isTrue: true },
      { label: "History", isTrue: false },
      { label: "Culture", isTrue: true },
      { label: "Food", isTrue: false },
      { label: "Water", isTrue: true },
      { label: "Earth", isTrue: false },
    ],
    score: 1,
  },
  question2: {
    prompt: "This is a question prompt",
    passage: ` On the other hand, we denounce with righteous indignation and
        dislike men who are so beguiled and demoralized by the charms of
        pleasure of the moment, so blinded by desire, that they cannot
        foresee the pain and trouble that are bound to ensue; and equal
        blame belongs to those who fail in their duty through weakness
        of will, which is the same as saying through shrinking from toil
        and pain. These cases are perfectly simple and easy to
        distinguish. In a free hour, when our power of choice is
        untrammelled and when nothing prevents our being able to do what
        we like best, every pleasure is to be welcomed and every pain
        avoided. But in certain circumstances and owing to the claims of
        duty or the obligations of business it will frequently occur
        that pleasures have to be repudiated and annoyances accepted.
        The wise man therefore always holds in these matters to this
        principle of selection: he rejects pleasures to secure other
        greater pleasures, or else he endures pains to avoid worse
        pains.`,
    words: [
      { word: "An organization such as a company", meaning: "Company" },
      { word: "Something that is not apparent or clear", meaning: "Unclear" },
      { word: "Something that attracts or interest you", meaning: "Interest" },
      {
        word: "To put something into something else per se should make sense",
        meaning: "Include",
      },
      { word: "A written document describing something", meaning: "Report" },
      {
        word: "To do something while something else is happening",
        meaning: "During",
      },
    ],
    options: [
      { label: "Location", isTrue: false },
      { label: "History", isTrue: false },
      { label: "Culture", isTrue: true },
      { label: "Food", isTrue: false },
      { label: "Water", isTrue: false },
    ],
    score: 1,
  },
  question4: {
    prompt: "Rearrange the words correctly",
    options: [
      { word: "For", index: 3, setting: 1 },
      { word: "Brodie", index: 0, setting: 0 },
      { word: "To School", index: 2, setting: 4 },
      { word: "Doesn't Walk", index: 1, setting: 2 },
      { word: "Has a car", index: 5, setting: 3 },
      { word: "He", index: 4, setting: 5 },
    ],
  },
  question8: {
    prompt: "Match to the correct word",
    words: [
      { word: "An organization such as a company", meaning: "Company" },
      { word: "Something that is not apparent or clear", meaning: "Unclear" },
      { word: "Something that attracts or interest you", meaning: "Interest" },
      {
        word: "To put something into something else per se should make sense",
        meaning: "Include",
      },
      { word: "A written document describing something", meaning: "Report" },
      {
        word: "To do something while something else is happening",
        meaning: "During",
      },
    ],
  },
  question9: {
    prompt: "Match to the correct word",
    words: [
      { word: "He", meaning: "Pronoun" },
      { word: "Tree", meaning: "Noun" },
      { word: "Heavy", meaning: "Adjective" },
      {
        word: "calmly",
        meaning: "Adverbs",
      },
      {
        word: "But",
        meaning: "Preposition",
      },
      {
        word: "The",
        meaning: "Articles",
      },
    ],
  },
  question7: {
    prompt: "Find the 1) subject, 2) verb, and 3) object in the sentences",
    sentence: "Pathway programs can help students enter university.",
    words: [
      { word: "Pathway programs", meaning: "Subject" },
      { word: "Can help", meaning: "Verb" },
      { word: "Student enter university", meaning: "Object" },
    ],
  },
  question5: {
    prompt: "Read the passage and answer the following questions",
    passage:
      "Lorem ipsum dolor sit amet, consecrate disciplining elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    questions: [
      {
        prompt: "What is the subject?",
        options: ["Option 1", "Option 2", "Option 3"],
        correctOption: "Option 1",
      },
      {
        prompt: "What is the Object?",
        options: ["Option 1", "Option 2", "Option 3"],
        correctOption: "Option 2",
      },
    ],
  },
  question6: {
    prompt: "Read the passage and answer the following questions",
    asset:
      "https://www.eap-space.com//uploads/20200709/2d4b47e5bfb6162d711a6212e274db08.png",
    question: [
      {
        prompt: "What is the subject?",
        options: ["Option 1", "Option 2", "Option 3"],
        correctOption: "Option 1",
      },
      {
        prompt: "What is the Object?",
        options: ["Option 1", "Option 2", "Option 3"],
        correctOption: "Option 2",
      },
    ],
  },
  question10: {
    prompt: "Read the passage and answer the following questions",
    questions: [
      {
        question: "What is the subject?",
        options: ["Option 1", "Option 2", "Option 3"],
        correctOption: "Option 1",
      },
      {
        question: "What is the subject2?",
        options: ["Option 4", "Option 5", "Option 6"],
        correctOption: "Option 5",
      },
      {
        question: "What is the subject3?",
        options: ["Option 7", "Option 8", "Option 9"],
        correctOption: "Option 9",
      },
    ],
  },
  question11: {
    prompt: "Read these words that you saw earlier out loud and record yourself.",
    audioSrc:"https://origo-backup-hk.s3.ap-east-1.amazonaws.com/eap-yellow/week01/speaking/Speaking+Audio+Week+2.mp3",
    questions: [
      {
        question: "Again",
        options: ["Option 1", "Option 2", "Option 3"],
        correctOption: "Option 1",
      },
      {
        question: "About",
        options: ["Option 4", "Option 5", "Option 6"],
        correctOption: "Option 5",
      },
      {
        question: "Sofa",
        options: ["Option 7", "Option 8", "Option 9"],
        correctOption: "Option 9",
      },
    ],
  },
  question14 :{
    "_id" : "61e0a1982ce59c54948c1bc3",
    "questions" : [
      "61f8b7871722411944fec30e",
      "639ab34a6bfc81eb4bb0659d",
      "61e0a1972ce59c54948c1bbf",
      "61e0a1972ce59c54948c1bc0",
      "61e0a1972ce59c54948c1bc1",
      "61e0a1972ce59c54948c1bc2",
      "61f8b7871722411944fec301",
      "61f8b7871722411944fec2ff"
    ],
    "description" : "<p class=\"MsoNormal\" style=\"white-space:normal;\">Speaking is an essential skill to practice and learn in both a general and an academic setting. In EAP Space course you will practice speaking in a different setting than your usual classroom. We will go through content first, comprehend and then put them in practice in a monthly webinar.</p><p class=\"MsoNormal\" style=\"white-space:normal;\"><br /></p><p class=\"MsoNormal\" style=\"white-space:normal;\">This week we will look at <strong>the whole Password speaking test</strong> and also focus on <strong>what you will do in Section 1</strong> and practise the two different parts of it.</p><p class=\"MsoNormal\" style=\"white-space:normal;\"><br /></p><p class=\"MsoNormal\" style=\"white-space:normal;\"><p class=\"MsoNormal\" style=\"white-space:normal;\">Remember to sign up for the weekly webinar to practice 1 on 1 with your English teacher. Go to:&nbsp;<a href=\"https://www.origo-education.info/events\" target=\"_blank\">ORIGO EVENTS</a></p><p class=\"MsoNormal\" style=\"white-space:normal;\"><br /></p><span style=\"white-space:normal;\">Let’s start learning now, click on the button below.</span></p>",
    "moduleName" : "Speaking",
    "programId" : "60c097a9b14f2e0d239c305c",
    "week" : 1,
    "learningContent" : "introduction to academic spoken English",
    "__v" : 2,
    "vocab" : ""
  }

};

export const renderQuestions = (

  { q,
    setLock = (val) => {},
    lock,
    score,
    setScore = (val) => {},
    setQuestions =(values) => {}
  }
) => {
  const qComponents = q.map((question) => {
    if (question.type === "1") {
      return (
        <Type1
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "2") {
      return (
        <Type2
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "3") {
      return (
        <Type3
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "4") {
      return (
        <Type4
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "5") {
      return (
        <Type5
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "6") {
      return (
        <Type6
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "7") {
      return (
        <Type7
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "8") {
      return (
        <Type8
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "9") {
      return (
        <Type9
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "10") {
      return (
        <Type10
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
    if (question.type === "10") {
      return (
        <Type10
          question={question.question}
          key={question._id}
          setLock={setLock}
          lock={lock}
          score={score}
          setScore={setScore}
        />
      );
    }
  });
  setQuestions(qComponents);
};
