import { POST, GET } from "../";
export default class ClassesAPI {
  static async getById(id) {
    try {
      const { data } = await GET(`/modules/get/${id}`);
      return data;
    } catch (err) {
      return [];
    }
  }

  static async getDetailById(id) {
    try {
      const { data } = await GET(`/modules/getDetail/${id}`);
      return data;
    } catch (err) {
      return [];
    }
  }

  static async getQuestions(id) {
    try {
      const { data } = await GET(`/modules/getQuestions/${id}`);
      return data;
    } catch (err) {
      return [];
    }
  }
}
