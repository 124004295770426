import {POST, GET} from "../";

export default class ClassesAPI {
    static async forStudent() {
        try {
            const {data} = await GET(`/announcements/student`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async create(payload = {}) {
        try {
            const {data} = await POST(`/announcements/create`, payload);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async forTeacher() {
        try {
            const {data} = await GET(`/announcements/teacher`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getDataForCreation() {
        try {
            const {data} = await GET(`/announcements/getDataForCreation`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getById(id) {
        try {
            const {data} = await GET(`/announcements/get/${id}`);
            return data;
        } catch (err) {
            return [];
        }
    }

}
