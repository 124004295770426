import { POST, GET } from "../";
export default class ChatAPI {
  
  static async getGroupChatById(id) {
    try {
      const { data } = await GET(`/chat/getGroupChat/${id}`);
      return data ? data : [];
    } catch (err) {
      return [];
    }
  }

  static async getTeacherChatGroups() {
    try {
      const { data } = await GET(`/teachers/getTeacherChatGroups`);
      return data ? data : [];
    } catch (err) {
      return [];
    }
  }

  static async getStudentChatGroups() {
    try {
      const { data } = await GET(`/students/getStudentChatGroups`);
      return data ? data : [];
    } catch (err) {
      return [];
    }
  }
  
  static async getIndividualChatById(id) {
    try {
      const { data } = await GET(`/chat/getIndividualChat/${id}`);
      return data ? data : [];
    } catch (err) {
      return [];
    }
  }

  static async searchStudentsByName(name) {
    try {
      const { data } = await GET(`/chat/searchStudents/${name}`);
      return data ? data : [];
    } catch (err) {
      return [];
    }
  }

  static async getIndividualChatList() {
    try {
      const { data } = await GET(`/chat/getChatList`);
      return data;
    } catch (err) {
      return [];
    }
  }

  static async getRecent() {
    try {
      const { data } = await GET(`/chat/getRecent`);
      return data;
    } catch (err) {
      return [];
    }
  }
}
