import React from "react";
import { Link } from "react-router-dom";

function ModuleButtons({ text, link }) {
  return (
    <Link to={link} className="h-16 w-1/6">
      <button className="bg-eapBtn h-full w-full rounded-md text-white">
        {text}
      </button>
    </Link>
  );
}

export default ModuleButtons;
