import React from "react";
import "./class_item.css";
import {IconWithRightText} from "../..";
import {PercentDonut as PercentageRingIcon} from "../..";
import {useAuth} from "../../../context";
import {uuid4} from "../../../utils";
import {ClassAPI} from "../../../services";
import {ClassSvgIcon} from "../../../assets/svg";

function ClassItem({
                       name = "Sam",
                       week = "1",
                       percent = Math.round(Math.random() * 100),
                       // href = "#",
                       src,
                       // key,
                       _id = ""
                   }) {
    // const history = useHistory();
    const {showModal} = useAuth();
    const sendReminderHandler = () => {
        showModal("Send Reminder", `Reminder the class ${name} with the progress?`, () => {
            return ClassAPI.sendReminder(_id)
        }, false)
    }
    return (
        <div className="eap__teacher-student-item-container" key={uuid4()}>
            <div className="eap__student-item-head">
                <IconWithRightText
                    key={uuid4()}
                    svg={<ClassSvgIcon/>}
                    src={src}
                    text={name}
                />
            </div>
            <div className="eap__student-item-body">
                <div className="eap__student-item-week-component">
                    {/*<div>Week {week}</div>*/}
                    {/*<PercentageRingIcon percent={percent}/>*/}
                    <span style={{wordWrap: "normal"}}>{percent}% students finished Week {week}</span>
                </div>
            </div>
            <div className="eap__student-item-tail">
                <div
                    className="eap__student-item-view-btn"
                    onClick={(e) => {
                        e.stopPropagation();
                        sendReminderHandler()
                    }}
                >
                    <span style={{width: 200 + "px", fontSize: `16px`}}>Send reminder</span>
                </div>
            </div>
        </div>
    );
}

export default ClassItem;
