import React from "react";
import "./management_card.css";
import styles from './styles.module.css';
import {Loading, TeacherCreateButton, TeacherStudentItem as StudentItem} from "../..";
import {Link} from "react-router-dom";

function TeacherManagementCard({
                                   href,
                                   loading = false,
                                   teacherList = [
                                       {name: "Group Name 1", src: "https://picsum.photos/300/300"},
                                       {name: "Group Name 2", src: "https://picsum.photos/300/300"},
                                   ],
                               }) {
    if (loading) return <div className="eap__management-card-container eap__shadow">
        <Loading/>
    </div>

    const getContents = (teacherList) => {

        if (!teacherList || teacherList.length < 1) {
            return <div>No teachers.</div>
        }

        return (<div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
            {[...teacherList].map((elem) => (
                // <IconWithRightText
                //     key={elem._id ?? elem.groupId}
                //     text={elem.name ?? elem.teacherName}
                //     svg={<PersonSvgIcon/>}
                //     src={elem.src}
                //     href={`/principal/edit/teacher/${elem.href}`}
                // />
                <Link to={`/principal/edit/teacher/${elem._id}`} key={elem._id}>
                    <div
                        className={styles['teacher-item']}
                    >
                        <img
                            className={styles.avatar}
                            src={elem.src} alt={"avatar"}/>
                        <div>
                            {elem.teacherName}
                        </div>
                    </div>
                </Link>
            ))}
        </div>)
    }

    return (
        <div className="eap__management-card-container eap__shadow">
            <div className="eap__management-card-title">
                <span className="h5">Teacher management</span>
                <TeacherCreateButton text="Create teacher" href="/principal/create/teacher"/>
            </div>
            <div className="eap__management-body">
                {getContents(teacherList)}
            </div>
            <div className="eap__management-action">
                {href && <a href={href}>View all &gt;</a>}
            </div>
        </div>
    );
}

export default TeacherManagementCard;
