import React from "react";
import {
  LandingHeader,
  LandingTextBanner,
  LandingVerticalContent,
  LandingHorizontalContent,
  LandingFooter,
} from "../../components";
function LandingLayout() {
  return (
    <div>
      <LandingHeader />
      <LandingTextBanner />
      <LandingVerticalContent />
      <LandingHorizontalContent />
      <LandingFooter />
    </div>
  );
}

export default LandingLayout;
