import {POST, GET} from "../";

export default class BookmarkAPI {
    static async getAll() {
        try {
            const {data} = await GET(`/bookmarks`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async getById(id) {
        try {
            const {data} = await GET(`/bookmarks/get/${id}`);
            return data;
        } catch (err) {
            return [];
        }
    }

    static async add(id) {
        try {
            const {data} = await POST(`/bookmarks/add`, {
                "questionId": id
            });
            return data;
        } catch (err) {
            return [];
        }
    }

    static async remove(id) {
      try {
        const {data} = await POST(`/bookmarks/delete`, {
          "questionId": id
        });
        return data;
      } catch (err) {
        return [];
      }
    }
}
