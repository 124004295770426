import React from 'react';
import type1Pic from '../../../assets/q_instructions/1.png';
import type2Pic from '../../../assets/q_instructions/2.png';
import type3Pic from '../../../assets/q_instructions/3.png';
import type4Pic from '../../../assets/q_instructions/4.png';
import type5Pic from '../../../assets/q_instructions/5.png';
import type6Pic from '../../../assets/q_instructions/6.png';
import type7Pic from '../../../assets/q_instructions/4_1.png';
import type8Pic from '../../../assets/q_instructions/8.png';
import type9Pic from '../../../assets/q_instructions/9.png';
import type10Pic from '../../../assets/q_instructions/10.png';
import type11Pic from '../../../assets/q_instructions/11.png';
import type12Pic from '../../../assets/q_instructions/12.png';

const picMap = Object.freeze({
    "1": type1Pic,
    "2": type2Pic,
    "3": type3Pic,
    "4": type4Pic,
    "5": type5Pic,
    "6": type6Pic,
    "7": type7Pic,
    "8": type8Pic,
    "9": type9Pic,
    "10": type10Pic,
    "11": type11Pic,
    "12": type12Pic,
});

export function instructionPicMapper(
    type
) {
    return picMap[type] || type1Pic;
}