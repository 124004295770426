import React from "react";
import { Link } from "react-router-dom";
import "./right_text_avatar.css";
function RightTextAvatar({
  svg,
  text,
  handler = () => {},
  iconW,
  iconH,
  src,
  mkey,
  href,
}) {
  return (
    // <a className="eap__icon-link" href={href}>
    href ? (
      // <Link style={{ textDecoration: "none" }} to={href}>
        <div className="eap__icon-btn-container" onClick={handler} key={mkey}>
          <div className="eap__icon-btn eap__shadow eap__icon-btn-holder">
            {src ? (
              <img
                alt="icon"
                className="eap__svg-icon-img"
                src={src}
                style={{ objectFit: "contain" }}
                onError={(e) => {
                  e.target.src = "/img/broken-image.png";
                }}
                //   onError={(e) => {(e.target.src = "https://picsum.photos/seed/picsum/300/300");}}
              />
            ) : (
              <div
                className="eap__svg-icon"
                style={{ width: iconW + "px", height: iconH + "px" }}
              >
                {svg}
              </div>
            )}
          </div>
          <div className="eap__icon-btn-text">{text}</div>
        </div>
      // </Link>
    ) : (
      <div className="eap__icon-btn-container" onClick={handler} key={mkey}>
        <div className="eap__icon-btn eap__shadow eap__icon-btn-holder">
          {src ? (
            <img
              alt="icon"
              className="eap__svg-icon-img"
              src={src}
              style={{ objectFit: "contain" }}
              onError={(e) => {
                e.target.src = "/img/broken-image.png";
              }}
              //   onError={(e) => {(e.target.src = "https://picsum.photos/seed/picsum/300/300");}}
            />
          ) : (
            <div
              className="eap__svg-icon"
              style={{ width: iconW + "px", height: iconH + "px" }}
            >
              {svg}
            </div>
          )}
        </div>
        <div className="eap__icon-btn-text">{text}</div>
      </div>
    ));
}

export default RightTextAvatar;
