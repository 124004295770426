import React from "react";
// import { Button } from "react-bootstrap";
import FormAlert from "../FormAlert/FormAlert";
import SmallLogoComponent from "../Logo/Small/SmallLogo";

function Form({
  emodule,
  userName,
  password,
  setUserName,
  setPassword,
  handleLogin,
  loading,
  error,
}) {
  return (
    <div className="w-2/6 divide-y-2 space-y-10">
      <div className="flex flex-col space-y-4 w-full items-start justify-center">
        <h1 className="text-white font-semibold text-2xl">
          Sign In To your Account
        </h1>
        {error ? <FormAlert message="Invalid Credentials" /> : null}

        <div className="flex flex-col space-y-4 justify-center items-center w-full">
          <span className="text-white self-start">User name</span>
          <input
            type="text"
            className="rounded-2xl h-10 p-2"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center w-full">
          <span className="text-white self-start">Password</span>
          <input
            type="password"
            className="rounded-2xl h-10 p-2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="self-end text-white underline">
            Forgot password?
          </span>
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center w-full">
          {loading ? (
            <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-purple-500"></div>
          ) : (
            <button
              className="bg-eapBtn3 w-full h-10 rounded-3xl"
              onClick={handleLogin}
            >
              Sign In
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <span className="text-white mt-4">Other Level? Please Select</span>
        <SmallLogoComponent emodule={emodule} />
      </div>
    </div>
  );
}

export default Form;
