import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
} from "react-router-dom";
import {
    /** public pages */
        LoginPage,
    LandingPage,
    NotFoundPage,
    TestPage,
    /** auth common pages */
        MessengerPage,
    /** teacher pages */
        TeacherMainPage,
    TeacherManageStudentPage,
    TeacherCreateStudentPage,
    TeacherCreateClassPage,
    TeacherCreateGroupPage,
    TeacherEditGroupPage,
    TeacherEditClassPage,
    TeacherStudentInfoPage,
    TeacherWeeklyContent,
    /** student pages */
        StudentMainPage,
    StudentProfilePage,
    StudentModulePage,
    // StudentProgressionPage,
    // StudentQuestionPage,
    StudentWeeklyContentPage,
    /** admin pages */
        AdminModuleProgressionPage,
    TeacherProfilePage,
    BookmarkPage,
    PrincipalMainPage,
    StudentCreativeVideoPage,
    PlacementTestPage,
    PrincipalCreateClassPage,
    PrincipalCreateTeacherPage,
    AdminLoginPage,
    AdminMainPage,
    AdminCreateSchoolPage,
    AdminCreatePrincipalPage,
    PrincipalEditTeacherPage,
    AdminEditSchoolPage,
    AdminQuestionManagePage,
    AdminEditQuestionPage,
    AdminAddQuestionPage,
    AdminFileManagerPage,
} from "../pages";

import {StudentQuestionWrapper} from "../components";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import AdminRoute from "./AdminRoute/ProtectedRoute";
import {ChatProvider} from "../context";

function RouterView() {
    return (
        <>
            <ChatProvider>
                <Router>
                    <Switch>
                        <Route path="/" exact>
                            <LandingPage/>
                        </Route>

                        <Route path="/login" exact>
                            <LoginPage/>
                        </Route>

                        <Route path="/admin/login" exact>
                            <AdminLoginPage/>
                        </Route>

                        <Route path="/placement-test" exact>
                            <PlacementTestPage/>
                        </Route>

                        {/* Principle Routes Start*/}
                        <ProtectedRoute roles={[3]} path="/principal" exact>
                            <PrincipalMainPage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[3]} path="/principal/create/class" exact>
                            <PrincipalCreateClassPage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[3]} path="/principal/create/teacher" exact>
                            <PrincipalCreateTeacherPage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[3]} path="/principal/edit/teacher/:id" exact>
                            <PrincipalEditTeacherPage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[3]} path="/principal/create/class" exact>
                            <TeacherCreateClassPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[3]} path="/principal/edit/class/:id" exact>
                            <TeacherEditClassPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[3]} path="/principal/create/group" exact>
                            <TeacherCreateGroupPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[3]} path="/principal/edit/group/:id" exact>
                            <TeacherEditGroupPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[3]} path="/principal/manage/student" exact>
                            <TeacherManageStudentPage/>
                        </ProtectedRoute>
                        {/* Principle Routes End*/}

                        {/* Teacher Routes Start */}
                        <ProtectedRoute roles={[2, 3]} path="/teacher" exact>
                            <TeacherMainPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/create/student" exact>
                            <TeacherCreateStudentPage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[2, 3]} path="/teacher/edit/student/:id" exact>
                            <TeacherStudentInfoPage/>
                        </ProtectedRoute>

                        <ProtectedRoute
                            roles={[2, 3]}
                            path="/teacher/management/student"
                            exact
                        >
                            <TeacherManageStudentPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/create/class" exact>
                            <TeacherCreateClassPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/edit/class/:id" exact>
                            <TeacherEditClassPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/create/group" exact>
                            <TeacherCreateGroupPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/edit/group/:id" exact>
                            <TeacherEditGroupPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/profile" exact>
                            <TeacherProfilePage/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            roles={[2, 3]}
                            path="/teacher/edit/student/:name"
                            exact
                        >
                            <TeacherStudentInfoPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/manage/student" exact>
                            <TeacherManageStudentPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/weekly-content" exact>
                            <TeacherWeeklyContent/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/modules/:id" exact>
                            <StudentModulePage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/questions/:id" exact>
                            <StudentQuestionWrapper/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[2, 3]} path="/teacher/bookmark" exact>
                            <BookmarkPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[2, 3]} path="/teacher/messenger" exact>
                            <MessengerPage homeUrl="/teacher"/>
                        </ProtectedRoute>
                        {/* Teacher Routes End */}

                        {/* Student Routes Start */}
                        <ProtectedRoute roles={[1]} path="/student" exact>
                            <StudentMainPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[1]} path="/student/profile" exact>
                            <StudentProfilePage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[1]} path="/student/writing/:id" exact>
                            <StudentQuestionWrapper/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[1]} path="/student/weekly-content" exact>
                            <StudentWeeklyContentPage/>
                        </ProtectedRoute>
                        <ProtectedRoute roles={[1]} path="/student/bookmark" exact>
                            <BookmarkPage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[1, 2]} path="/modules/:id" exact>
                            <StudentModulePage/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[1, 2]} path="/questions/:id" exact>
                            <StudentQuestionWrapper/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[1]} path="/student/messenger" exact>
                            <MessengerPage homeUrl="/student"/>
                        </ProtectedRoute>

                        <ProtectedRoute roles={[1]} path="/student/videos/:vid" exact>
                            <StudentCreativeVideoPage/>
                        </ProtectedRoute>
                        {/* Student Routes End */}

                        {/* Admin Routes Start */}
                        <AdminRoute
                            path={'/admin'}
                            exact
                        >
                            <AdminMainPage/>
                        </AdminRoute>

                        <AdminRoute
                            path={'/admin/create/school'}
                            exact
                        >
                            <AdminCreateSchoolPage/>
                        </AdminRoute>

                        <AdminRoute
                            path={'/admin/questions'}
                            exact
                        >
                            <AdminQuestionManagePage/>
                        </AdminRoute>

                        <AdminRoute
                            path={'/admin/question/edit/:id/:moduleId'}
                            exact
                        >
                            <AdminEditQuestionPage/>
                        </AdminRoute>

                        <AdminRoute
                            path={'/admin/question/add/:moduleId/:weekId/:week/:programId'}
                            exact
                        >
                            <AdminAddQuestionPage/>
                        </AdminRoute>
                        <AdminRoute
                            path={'/admin/file'}
                            exact
                        >
                            <AdminFileManagerPage/>
                        </AdminRoute>

                        <AdminRoute
                            path={'/admin/edit/school/:id'}
                            exact
                        >
                            <AdminEditSchoolPage/>
                        </AdminRoute>

                        <AdminRoute
                            path={'/admin/create/principal/:id'}
                            exact
                        >
                            <AdminCreatePrincipalPage/>
                        </AdminRoute>

                        <AdminRoute
                            path="/progression/modules/:id"
                            exact
                        >
                            <AdminModuleProgressionPage/>
                        </AdminRoute>
                        <ProtectedRoute roles={[1, 2, 3]} path="/test" exact>
                            <TestPage/>
                        </ProtectedRoute>
                        {/* Admin Routes End */}

                        {/* Mixed Roles Routes start*/}
                        {/* <ProtectedRoute roles={[1,2]} path="/quesitons/:id" exact>
              <StudentQuestionWrapper />
            </ProtectedRoute> */}
                        {/* <ProtectedRoute roles={[1,2]} path="/modules/:id" exact>
              <PlacementTestPage />
            </ProtectedRoute> */}
                        {/* Mixed Roles Routes end*/}

                        {/* Test Route */}

                        {/* Not Found Route */}
                        <Route>
                            <NotFoundPage/>
                        </Route>
                    </Switch>
                </Router>
            </ChatProvider>
        </>
    );
}

export default RouterView;
