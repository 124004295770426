import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
// import {Table} from "react-bootstrap";
// import QuestionOption from "../Option";
import styles from './type9.module.css';
import GrammarOption from "../Option/GrammarOption";
import VocabOption from "../Option/VocabOption";
import {Loading} from "../../../../index";

// import SwitchButton from "./svg";
function shuffleArray(incomingArray) {
    let array = [...incomingArray];
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

function Type9({
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
               }) {

    const [loading, setLoading] = useState(true);
    // const [answerCopy, setAnswerCopy] = useState([]);
    // const [selected, setSelected] = useState(-1);
    // const [current, setCurrent] = useState(0);
    const [localScore, setLocalScore] = useState(score);

    const [questionWords, setQuestionWords] = useState([]);
    const [answerMeanings, setAnswerMeanings] = useState([]);
    const [currentWordIdx, setCurrentWordIdx] = useState(-1);
    const [currentMeaningIdx, setCurrentMeaningIdx] = useState(-1);
    const answerSheetRef = useRef([]);
    const questionRef = useRef([]);
    // const rawQuestionRef = useRef(question);

    useEffect(() => {

        console.log(question);

        // left questions
        const _questionWords = question.words.map((el) => el.word);
        setQuestionWords([..._questionWords]);

        // right options
        const _answerMeanings = question.words.map((el) => el.meaning);
        setAnswerMeanings(shuffleArray([..._answerMeanings]));

        // answer sheet
        answerSheetRef.current = _answerMeanings.map((el) => -1);
        questionRef.current = _questionWords.map((el) => 0);

        setLoading(false);
    }, []);

    const checkAnswer = () => {
        if(answerSheetRef.current[currentMeaningIdx] === 1) return;

        const meaning = answerMeanings[currentMeaningIdx];
        const index = question.words.map((el) => String(el.meaning).toLowerCase())
            .indexOf(String(meaning).toLowerCase());
        if (currentWordIdx === index) {
            // correct
            answerSheetRef.current[currentMeaningIdx] = 1;
            questionRef.current[currentWordIdx] = 1;
            return;
        }
        // incorrect
        answerSheetRef.current[currentMeaningIdx] = 0;
        // setTimeout(() => answerSheetRef.current[currentMeaningIdx] = -1, 2000);
        setLocalScore(localScore + 1);
        setScore(localScore + 1);
        setCurrentWordIdx(-1);
        setCurrentMeaningIdx(-1);
    }

    useEffect(() => {
        if (currentWordIdx === -1 || currentMeaningIdx === -1) return;
        checkAnswer();
        checkLock();
        setCurrentWordIdx(-1);
        setCurrentMeaningIdx(-1);
    }, [currentMeaningIdx, currentWordIdx]);


    const bgColorMapper = (val) => {
        switch (val) {
            case -1:
                return "#f7f7f7";
            case 1:
                return "#88b380";
            case 0:
                return "#e4b243";
            default:
                return "#f7f7f7";
        }
    }

    const checkLock = () => {
        const completed = answerSheetRef.current.every((el) => el === 1);
        setLock(!completed);
    }

    if (loading) return <Loading/>;


    return (<div>
        <div className="w-full flex flex-row ">
            <div className="w-1/12"/>
            <div className="w-10/12 bg-white p-4 flex flex-col h-full space-y-2">
                <div>{question.prompt}</div>
                <div className="flex flex-row w-full h-full divide-x">
                    <div className="flex flex-col w-1/2 p-2 space-y-5 items-center justify-center">
                        {/* Question */}
                        <div className={styles.flex_container_2}>

                            {questionWords.map((el, idx) => (<div
                                className={styles.flex_item_2}
                                key={"type_9_q_" + idx}
                                onClick={() => setCurrentWordIdx(idx)}
                                style={{
                                    backgroundColor: currentWordIdx === idx ? "#e4b243" : "",
                                    color: currentWordIdx === idx ? "#fff" : "",
                                    visibility: questionRef.current[idx] === 0 ? "visible" : "hidden"
                                }}
                            >{el}</div>))}
                        </div>

                    </div>

                    <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">

                        <div className={styles.flex_container_2}>
                            {answerMeanings.map((el, idx) => (<div
                                key={"type9_a_" + idx}
                                className={styles.flex_item_2}
                                style={{
                                    backgroundColor: bgColorMapper(answerSheetRef.current[idx]),
                                    color: answerSheetRef.current[idx] === -1 ? "" : "#fff"
                                }}
                                onClick={() => setCurrentMeaningIdx(idx)}
                            >{el}</div>))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-1/12"/>
        </div>
    </div>);
}

export default Type9;
