import React, {useEffect, useRef, useState} from "react";
import styles from "./header.module.css";
import {LandingLogoSvgIcon} from "../../../assets/svg";
import {useHistory} from "react-router-dom";

function Header() {
    const history = useHistory();
    const [scroll, setScroll] = React.useState(false);
    const [layoutChange, setLayoutChange] = React.useState(false);
    const [myHeight, setMyHeight] = useState("90vh");
    const headerRef = useRef();

    // const breakpoint = 900;

    useEffect(() => {

        window.scroll(0, 0);
        window.onscroll = function () {

            const scrollValue = document.documentElement.scrollTop;
            // console.log(scrollValue);
            setScroll(scrollValue > 525);
            setLayoutChange(scrollValue > 250);
            if (scrollValue > 0) {
                setMyHeight(`max(calc(90vh - ${scrollValue}px), 105px)`);
                // headerElem.style.height = `calc(70em - ${scrollValue}px)`;
            }
        };

        return () => {
            window.onscroll = null;
        }
    }, []);

    return (
        <>
            <div className={styles.container} id="header" ref={headerRef}
                 style={{maxHeight: myHeight, minHeight: myHeight}}>
                <div className={styles.contentContainer}>
                    {layoutChange ? (
                        <div className={styles.shrinkLogo}>
                            <LandingLogoSvgIcon/>
                        </div>
                    ) : (
                        <div className={styles.image}>
                            <LandingLogoSvgIcon id="eap-landing-logo"/>
                        </div>
                    )}
                    <div className={layoutChange ? styles.secondVertical : styles.second}>
                        {layoutChange ? null : (
                            <p className={styles.eap_title}>Launch into orbit with EAP space</p>
                        )}

                        <div
                            className={styles.btn1}
                            onClick={() => {
                                history.push("/placement-test");
                            }}
                        >
                            GET STARTED
                        </div>

                        <div
                            onClick={() => history.push("/login")}
                            className={styles.btn2}
                        >
                            I HAVE AN ACCOUNT
                        </div>
                        {!layoutChange && (
                            <div
                                onClick={() =>
                                    (window.location.href =
                                        "https://www.onestudyspace.com/events")
                                }
                                className={styles.btn3}
                            >
                                EAP SPACE WORKSHOP
                            </div>
                        )}
                    </div>
                </div>

                {scroll ? null : (
                    <div className={styles.custom}>
                        <svg
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                className={styles.shape}
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div style={{height: "90vh"}}/>
        </>
    );
}

export default Header;
