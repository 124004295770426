import {GET} from "../";

export default class VideoContentAPI {
    static async getLanding() {
        try {
            const {data} = await GET(`/content/landingVideo`);
            return data;
        } catch (error) {
            return {msg: error.message}
        }
    }

    static async getById(id) {
        try {
            const {data} = await GET(`/content/video/${id}`);
            return data;
        } catch (error) {
            return {msg: error.message}
        }
    }

}