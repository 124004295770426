import Axios from "axios";
import {baseURL}  from '../../config';
const axiosInstance = Axios.create({
  // baseURL: "http://localhost:4000/api/",
  // baseURL: "https://eap-space.herokuapp.com/api/",
  // baseURL: "http://16.163.0.11/api/",
  baseURL,
  timeout: 30 * 1000,
  headers: {},
  withCredentials: true,
});

export default axiosInstance;

export const POST = axiosInstance.post;
export const GET = axiosInstance.get;
export const PUT = axiosInstance.put;
export const PATCH = axiosInstance.patch;
export const DELETE = axiosInstance.delete;