import React from "react";
import "./percent_donut.css";

function PercentDonut({ percent = "60", width = "65" }) {
  return (
    <>
      <div className="flex-wrapper" style={{ maxWidth: width + "px" }}>
        {/* <div className="single-chart"> */}
        <svg
          viewBox="0 0 36 36"
          className={
            percent < 100 ? "circular-chart orange" : "circular-chart green"
          }
        >
          <path
            className="circle-bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle"
            strokeDasharray={`${percent}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className="percentage">
            {percent}%
          </text>
        </svg>
        {/* </div> */}
      </div>
    </>
  );
}

export default PercentDonut;
