import React from "react";
import { BackSvgIcon } from "../../../../assets/svg";
import "./back_button.css";
import {useHistory}from"react-router-dom"
function BackButton() {
const history = useHistory();
  return (
    
      <div className="eap__back-btn" onClick={()=>history.goBack()}>
        <BackSvgIcon />
      </div>
    
  );
}

export default BackButton;
