export const QUESTION_TYPES = [

    {
        type: "1",
        question: {
            "prompt": "Let’s review some points so far. ",
            "type": "1",
            "mediaUrl": "https://a.eap-space.com",
            "mediaType": "",
            "questions": [
                {
                    "question": "1. What is academic reading?",
                    "options": [
                        {
                            "label": "It is reading that you do in your own free time",
                            "isTrue": false
                        },
                        {
                            "label": "It is reading that you usually do for university and has a specific purpose",
                            "isTrue": true
                        },
                        {
                            "label": "It is reading that you do when you are trying to make a decision to buy something",
                            "isTrue": false
                        },
                        {
                            "label": "It is reading that you usually do without a specific purpose  ",
                            "isTrue": false
                        }
                    ]
                },
                {
                    "question": "2. What sorts of things do you usually NOT read for academic purposes?",
                    "options": [
                        {
                            "label": "You may read a journal article when you read for academic purposes",
                            "isTrue": false
                        },
                        {
                            "label": "You may read a novel when you read for academic purposes",
                            "isTrue": true
                        },
                        {
                            "label": "You may read a newspaper article when you read for academic purposes",
                            "isTrue": false
                        },
                        {
                            "label": "You may read a report when you read for academic purposes ",
                            "isTrue": false
                        }
                    ]
                },
                {
                    "question": "3. Why is academic reading different to normal reading?",
                    "options": [
                        {
                            "label": "It is done with a specific purpose in mind",
                            "isTrue": true
                        },
                        {
                            "label": "It is done without a specific purpose in mind",
                            "isTrue": false
                        },
                        {
                            "label": "It is done for pleasure or entertainment",
                            "isTrue": false
                        },
                        {
                            "label": "It is done to relax and clear your mind of thoughts ",
                            "isTrue": false
                        }
                    ]
                },
                {
                    "question": "4. What is skimming? ",
                    "options": [
                        {
                            "label": "It is a slow reading technique to help you find the main idea of a text",
                            "isTrue": false
                        },
                        {
                            "label": "It is a fast reading technique to help you find the main idea of a text",
                            "isTrue": true
                        },
                        {
                            "label": "It is a slow reading technique to help you find specific details in a text",
                            "isTrue": false
                        },
                        {
                            "label": "It is a fast reading technique to help you find specific details in a text",
                            "isTrue": false
                        }
                    ]
                },
                {
                    "question": "5. What are some key methods to skim?",
                    "options": [
                        {
                            "label": "Read key parts of a text and then decide what the overall or general topic is",
                            "isTrue": true
                        },
                        {
                            "label": "Read key parts of a text and then think about what the next topic is",
                            "isTrue": false
                        },
                        {
                            "label": "Read the key parts of a text and then decide who the text is for",
                            "isTrue": false
                        },
                        {
                            "label": "Read the key parts of a text and then decide what idea came before",
                            "isTrue": false
                        }
                    ]
                },
                {
                    "question": "6. What its scanning? ",
                    "options": [
                        {
                            "label": "It is a slow reading technique to help you find the main idea of a text",
                            "isTrue": false
                        },
                        {
                            "label": "It is a fast reading technique to help you find the main idea of a text",
                            "isTrue": false
                        },
                        {
                            "label": "It is a slow reading technique to help you find specific details in a text",
                            "isTrue": false
                        },
                        {
                            "label": "It is a fast reading technique to help you find specific details in a text",
                            "isTrue": true
                        }
                    ]
                },
                {
                    "question": "7. What is NOT a key method to scan?",
                    "options": [
                        {
                            "label": "Think clearly about what you are searching for ",
                            "isTrue": false
                        },
                        {
                            "label": "Think of paraphrases or synonyms of what you are searching for",
                            "isTrue": false
                        },
                        {
                            "label": "Look at the first body paragraph of the text to find what you are searching for",
                            "isTrue": true
                        },
                        {
                            "label": "Look quickly over the whole text from left to right, and up and down to find what you’re searching for",
                            "isTrue": false
                        }
                    ]
                },
                {
                    "question": "8. What is close reading? ",
                    "options": [
                        {
                            "label": "Close reading is a strategy that involves reading quickly to find the topic of something",
                            "isTrue": false
                        },
                        {
                            "label": "Close reading is a strategy that involves reading slowly to find deeper meaning and making connections between ideas or words in a text",
                            "isTrue": true
                        },
                        {
                            "label": "Close reading is a strategy that involves reading quickly to find a specific detail ",
                            "isTrue": false
                        },
                        {
                            "label": "Close reading is a strategy that involves reading quickly to find the main idea of a text",
                            "isTrue": false
                        }
                    ]
                }
            ]
        }
    },
    {
        type: "2",
        question: {
            "type": "2",
            "prompt": "Now choose the answer that best explains the whole paragraph",
            "passage": "When food is freeze-dried, every bit of water is removed from it. Without a water content, and the microorganisms that go along with it, food is less likely to spoil, even if it remains at room temperature for many years. Freeze drying also allows the food being preserved to keep its original texture, flavour, and smell, which gives it an advantage over other food preservation methods. ",
            "words": [
                {
                    "word": "Freeze-dried (adj)",
                    "meaning": " preserve (something) by rapidly freezing it"
                },
                {
                    "word": "Microorganisms (n)",
                    "meaning": " a microscopic organism, especially a bacterium, virus, or fungus."
                },
                {
                    "word": "Spoil (v)",
                    "meaning": " (of food) become unfit for eating."
                },
                {
                    "word": "Preserve (v)",
                    "meaning": " treat (food) to prevent its decomposition."
                },
                {
                    "word": "Method (n)",
                    "meaning": "a procedure for accomplishing or approaching something, especially a systematic or established one."
                }
            ],
            "options": [
                {
                    "label": "Food is freeze-dried",
                    "isTrue": false
                },
                {
                    "label": "Freeze-drying removes water and preserves food",
                    "isTrue": true
                },
                {
                    "label": "Freeze-drying removes water",
                    "isTrue": false
                },
                {
                    "label": "Freeze-drying allows food to keep its original texture",
                    "isTrue": false
                }
            ]
        }
    },
    {
        type: "3",
        question: {
            "prompt": "Listen to the recording, you have 10 seconds to practice each sentence after the narrator.",
            "type": "3",
            "recordingUrl": "https://eapspace.s3.ap-southeast-2.amazonaws.com/uploads/green/20200830/469737707530e2e2d4e55bdfd447f3a6.mp3",
            "options": [
                {
                    "label": "Today’s topic is multinational corporations.",
                    "isTrue": true
                },
                {
                    "label": "I will be giving the history of three small firms which managed to become multinationals.",
                    "isTrue": true
                },
                {
                    "label": "Then in 2017 it gained permission from the government to begin testing. sights",
                    "isTrue": true
                },
                {
                    "label": "It now has factories and sales systems in over 70 nations.",
                    "isTrue": true
                },
                {
                    "label": "It also works with universities both in its home country of Sweden and overseas.",
                    "isTrue": true
                },
                {
                    "label": "… and that is the end of today’s talk.",
                    "isTrue": true
                }
            ],
            "temp": "https://eapspace.s3.ap-southeast-2.amazonaws.com/uploads/61e0a1972ce59c54948c1bc2_469737707530e2e2d4e55bdfd447f3a6.mp3"
        }
    },
    {
        type: "4", "question": {
            "prompt": "Below are some corrected sentences from the previous activity. Put the words in the correct order to make a complete sentence.",
            "type": "4",
            "options": [
                {
                    "word": "multinational company ",
                    "index": 1,
                    "setting": 0
                },
                {
                    "word": "The largest",
                    "index": 0,
                    "setting": 1
                },
                {
                    "word": " from the US.",
                    "index": 3,
                    "setting": 2
                },
                {
                    "word": " is Walmart ",
                    "index": 2,
                    "setting": 3
                }
            ]
        },
    },
    {type: "5", "question": {}},
    {
        type: "6", "question": {
            "type": "6",
            "prompt": "Read this text about Lego and answer the questions that follow.",
            "asset": "https://eapspace.s3.ap-southeast-2.amazonaws.com/uploads/green/20200824/e089cde8e5e903be30f74e537c0e515b.png",
            "question": [
                {
                    "prompt": "1.\tWhich list of words from paragraph 1 have the most important words to understand the main idea?",
                    "options": ["Danish, start, its, and, affect, total amount, we’ve", "Has, it, will, of, this, in ", "Lego, plant-based, plastic, tackle, waste, environmental, damage", "Several, year, bid, but, amount, elements, story"],
                    "correctOption": "Lego, plant-based, plastic, tackle, waste, environmental, damage"
                },
                {
                    "prompt": "2.\tWhat is the best main idea of the whole text (from paragraph 1)?",
                    "options": ["The company Lego will use plant-based material to fight pollution", "The company Lego will only affect 1-2 per cent of plastic it makes", "The company Lego has made a new announcement ", "The company Lego will change its products "],
                    "correctOption": "The company Lego will use plant-based material to fight pollution"
                },
                {
                    "prompt": "3.\tWhich list of words from paragraph 2 have the most important words to understand the main idea?",
                    "options": ["Polyethylene, partnered, non-biodegradable, sugar", "Polyethylene, bio-degradable, plastic bottles, ocean", "Sustainable, polyethylene, replace, materials, fossil fuels, sourced sustainably", "Fossil fuels, known, does, meaning, packaging, ocean, injure "],
                    "correctOption": "Sustainable, polyethylene, replace, materials, fossil fuels, sourced sustainably"
                }
            ]
        },
    },
    {type: "7", question: {}},
    {
        type: "8", "question": {
            "prompt": "Match the words below with their meaning on the left side",
            "type": "8",
            "words": [
                {
                    "word": "Announce (v)",
                    "meaning": "make a formal public statement about a fact, occurrence, or intention."
                },
                {
                    "word": "Tackle (v)",
                    "meaning": "make determined efforts to deal with (a problem or difficult task)."
                },
                {
                    "word": "Damage (v)",
                    "meaning": "physical harm that impairs the value, usefulness, or normal function of something."
                },
                {
                    "word": "Element (n)",
                    "meaning": "an essential or characteristic part of something abstract."
                },
                {
                    "word": "Partner with (v)",
                    "meaning": "be the partner of."
                },
                {
                    "word": "Source (v) ",
                    "meaning": "obtain from a particular source."
                },
                {
                    "word": "Renewable (adj)",
                    "meaning": "(of a natural resource or source of energy) not depleted when used."
                },
                {
                    "word": "Biodegradable (adj)",
                    "meaning": "(of a substance or object) capable of being decomposed by bacteria or other living organisms and thereby avoiding pollution."
                },
                {
                    "word": "Dump (v)",
                    "meaning": "deposit or dispose of (rubbish, waste, or unwanted material), typically in a careless or hurried way."
                },
                {
                    "word": "Botanical (adj) ",
                    "meaning": "relating to botany or something obtained from a plant "
                }
            ]
        },
    },
    {
        type: "9", "question": {
            "prompt": "Read the sentences below and choose which type of error from the box is used. You should use each option once (1-12)",
            "type": "9",
            "words": [
                {
                    "word": "Not objective / neutral",
                    "meaning": "Google is not a good company. "
                },
                {
                    "word": "No hedging language used",
                    "meaning": " Apple always releases new products around the second week of September."
                },
                {
                    "word": "Informal vocabulary",
                    "meaning": " A person can buy tons of things on Alibaba. "
                },
                {
                    "word": "Synonyms not used",
                    "meaning": " Some people think multinational companies have too much economic power. They also think multinational companies have too much political power. "
                },
                {
                    "word": "Contraction used",
                    "meaning": "Developing countries often can’t avoid being exploited by multinational companies sometimes. "
                },
                {
                    "word": "Abbreviation used",
                    "meaning": "Some multinational companies started in a small room with only a PC. "
                },
                {
                    "word": "Personal pronoun used",
                    "meaning": "Although US companies used to be very successful in the past, I think people are starting to prefer brands from other international companies. "
                },
                {
                    "word": "Ambiguous language used",
                    "meaning": " Other countries that have the largest multinational companies are from the Netherlands, China, Japan and so on."
                },
                {
                    "word": "Phrasal verb used",
                    "meaning": "Multinational companies from Asia and Latin America are catching up with US companies."
                },
                {
                    "word": "Idiom used",
                    "meaning": " Toyota is from Japan. And Volkswagen is from Germany."
                },
                {
                    "word": "Sentence starts with coordinating conjunction",
                    "meaning": "  What is the largest multinational company? It is Walmart from the US. "
                }
            ]
        },
    },
    {
        type: "10", "question": {
            "prompt": "In the next activity you will practice editing the sentences for any errors.  There are 8 mistakes, select which sentences have errors",
            "type": "10",
            "questions": [
                {
                    "question": "1. Many businesses have set up their operations in other countries.  ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "No errors"
                },
                {
                    "question": "2.  Cmpanies that operate in many different countries are called multinational corporations (MNC’s) or transnational corporations (TNC’s) ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "3. One example of a large multinational corporation Samsung. ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "4. Many transnational corporations come from more economically developed countries such as South Korea and the United States and they are often a powerful force in the host country which is usually a less economically developed country. ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "5.  A positive impact of globalization are the creation of new jobs and developing people’s skills. ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "6. Transnational corporations can contribute to the wealth of a host country, they can invest in education and other facilities. ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "7. Another positive impact of globalization are being that ideas and lifestyles are shared between people of different cultures.",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "8.  which can shape and influence people’s lives.",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "9. People can experience different things, tasting different foods, and buy new products due to globalization. ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "Contains errors"
                },
                {
                    "question": "10.  Many people are made more aware of global events and issues such as pollution, climate change, and poverty. ",
                    "options": ["Contains errors", "No errors"],
                    "correctOption": "No errors"
                }
            ]
        },
    },
    {
        type: "11",
        desc:"speaking",
        question:
            {
                prompt: '"Practice  saying these sentences from the script you just read. ',
                questions: [[{}], [{}], [{}]],
                audioSrc: 'https://origo-backup-hk.s3.ap-east-1.amazonaws.com/eap-yellow/week01/speaking/Speaking+Audio+Week+2.mp3',
                script: 'Why don’t you tell me more about them? \n' +
                    '\n' +
                    'That’s a nice name. How do you know him? \n' +
                    '\n' +
                    'He has short hair and high cheekbones. \n' +
                    '\n' +
                    'What does he look like? \n' +
                    '\n' +
                    'And what is he like? '
            }
    },
    {
        type: "12", "question": {
            "qIndex": 0,
            "questionTitle": "Descriptions of places can include some topics listed below. ",
            "answerTitle": "",
            "question": [
                {
                    "text": "Unique and interesting sights:Most locations have something special about them. Unique and interesting sights are particular to a place. An example could be a museum, building, or ground. An example of a unique and interesting sight could be the Eiffel Tower in Paris. ",
                    "isBold": true
                },
                {
                    "text": "The landscape:The landscape of a place relates to what we can see around us in a location. Landscapes can include hills, mountains, forests, roads, buildings, oceans, rivers, and lakes. Places with unique landscapes can include rivers such as the Amazon River in South America. ",
                    "isBold": true
                }
            ],
            "answer": [
                {
                    "text": "The people:People make a place more interesting. When you talk about people you can define which type of people live in a particular place. In addition to this, you can mention their jobs or backgrounds. An example of people in a particular place are students who go to a school.",
                    "isBold": true
                }
            ],
            "type": "12"
        },
    },
    {
        type: "13", "question": {
            "qIndex": 7,
            "questionTitle": "You will read some sentences and decide if they are simple sentences or not. ",
            "answerTitle": "Click the on the gap for the (correct) simple sentences.",
            "tip": "Remember simple sentence structures are (Subject + verb),  (Subject + verb + object) or  (Subject + verb + complement) ",
            "type": "13",
            "questions": [
                {
                    "text": "Simone edited her work carefully.",
                    "isTrue": true
                },
                {
                    "text": "Garry inspired many people with his seminars. ",
                    "isTrue": true
                },
                {
                    "text": "Garry and Simone various other elements of the course. ",
                    "isTrue": false
                },
                {
                    "text": "Concerns itself with diagnosis, treatment, and prevention of disease.",
                    "isTrue": false
                },
                {
                    "text": "Geologists study and analyse the physical structure and substance of the earth.",
                    "isTrue": true
                }
            ],
            "numberOfCorrect": 3
        },
    }
]
