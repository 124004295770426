import React, {useEffect, useState} from "react";
import styles from "./announce.module.css";
import {BackSvgIcon, CrossSvgIcon} from "../../../assets/svg";
import {SmallDropdown, SearchBox} from "../../";
import {useAuth} from "../../../context";
import {AnnouncementAPI} from "../../../services";
import SimpleBar from "simplebar-react";
import {useAnnouncementHooks} from "../../../hooks";

function AnnounceModal({
                           close = () => {
                           }
                       }) {
    //   const [showAnnounceModal, setAnnounceModal] = useState(false);
    const {
        user, showModal,
        showAnnounceModalAdd,
        setAnnounceModalAdd,
        currentAnnounce, setCurrentAnnounce,
        announcements, setAnnouncements,
        topic, setTopic,
        message, setMessage,
        search, setSearch,
        classList, setClassList,
        groupList, setGroupList,
        classIndex, setClassIndex,
        groupIndex, setGroupIndex,
        createAnnouncementHandler
    } = useAnnouncementHooks();


    return (<div className={styles.announce_modal}>
        {showAnnounceModalAdd ? (<div className={styles.announce_modal_content}>
            <div className={styles.announce_modal_content_add}>
                <div
                    style={{
                        gap: "10px", flexDirection: "column", display: "flex", width: "30%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "20px",
                        }}
                    >
                        {" "}
                        <div
                            onClick={() => {
                                setAnnounceModalAdd(false);
                            }}
                        >
                            <BackSvgIcon/>
                        </div>
                        {" "}


                        <div style={{fontSize: "30px", textTransform: "capitalize", whiteSpace: 'nowrap'}}>
                            {" "}
                            new announcement
                        </div>

                    </div>
                </div>

                <div className={styles.add_announce_content}>
                    <label
                        style={{whiteSpace: "nowrap"}}
                        className={styles.a_topic_label}
                    >
                        Announcement Topic :
                    </label>
                    <input
                        className={styles.a_topic_input}
                        style={{borderRadius: 20, backgroundColor: "#EDEDED"}}
                        type="text"
                        onInput={event => {
                            setTopic(event.target.value);
                        }}
                    />
                    <label className={styles.a_target_label}>
                        Announcement Target:
                    </label>
                    <div
                        className={styles.a_target_dropdown}
                        style={{
                            display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"
                        }}
                    >
                        <SmallDropdown placeHolder="Class" options={classList.map(el => el.name)}
                                       indexHandler={setClassIndex}/>{" "}
                        <SmallDropdown placeHolder="Group" options={groupList.map(el => el.name)}
                                       indexHandler={setGroupIndex}/>
                    </div>
                    {" "}
                    <label className={styles.a_message_label}>
                        Announcement Message:
                    </label>
                    <textarea
                        className={styles.a_message_input}
                        rows="6"
                        style={{
                            borderRadius: 20, backgroundColor: "#EDEDED", width: "100%", padding: 20,
                        }}
                        onInput={(e) => {
                            setMessage(e.target.value);
                        }}
                    />
                    <div className={styles.a_message_submit} onClick={createAnnouncementHandler}>
                        Publish an announcement
                    </div>
                </div>
            </div>
        </div>) : (<div className={styles.announce_modal_content}>
            <div className={styles.announce_modal_content_left}>
                <div
                    style={{
                        padding: "0 20px", gap: 20, flexDirection: "column", display: "flex",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "20px",
                        }}
                    >
                        <div>
                            <div
                                onClick={() => {
                                    // setAnnounceModal(false);
                                    close();
                                }}
                            >
                                <BackSvgIcon/>
                            </div>
                        </div>
                        <div className={styles.title1} >Announcement Management</div>
                        {" "}
                    </div>
                    {user.role !== 1 ? (<div style={{display: 'flex'}}>
                        {" "}
                        <div
                            className={styles.announce_add_btn}
                            onClick={() => {
                                setAnnounceModalAdd(true);
                            }}
                        >
                            <CrossSvgIcon/>
                            new announcement
                        </div>
                        <div style={{display: 'flex', flex: 1}}/>
                    </div>) : (<div
                        style={{
                            display: "flex", gap: 20, textTransform: "capitalize", fontSize: 20,
                            padding: "10px 20px", borderRadius: "20px", height: "100%",
                        }}
                    >
                        {" "}
                    </div>)}
                    <div className={styles.searchbar}>
                        <SearchBox
                            inputHandler={(e) => {
                                setSearch(announcements.filter((announce) => {
                                    return (JSON.stringify(announce)
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase()));
                                }));
                            }}
                        />{" "}
                    </div>
                </div>
                <div  className={styles.message_list}  style={{paddingTop: 10}}>
                    <div  style={{height: "100%", paddingTop: 10}}>
                        <SimpleBar style={{maxHeight: "100%"}}>
                            <div
                                style={{
                                    display: "flex", flexDirection: "column", gap: 20,
                                }}
                            >
                                {[...search].map(({_id, title, message, teacherId, timestamp = "dd/mm/yyyy"}) => (<div
                                    key={_id}
                                    id={_id}
                                    onClick={() => {
                                        setCurrentAnnounce({
                                            _id, title, message, teacherId, timestamp
                                        });
                                    }}
                                    className={styles.message_item}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 5,
                                        padding: 10,
                                        margin: "0 10px ",
                                        borderRadius: "20px",
                                        color: _id === currentAnnounce._id ? "#fff" : "",
                                        backgroundColor: _id === currentAnnounce._id ? "#1B3D4D" : "",
                                        overflow:"hidden"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex", justifyContent: "space-between",
                                        }}
                                    >
                                        <div style={{fontSize: 20}}>{title ?? "Not data"}</div>
                                        <div style={{fontSize: 20}}>{timestamp ?? "dd/mm/yyyy"}</div>
                                    </div>
                                    <div>{String(message).substring(0, 50) + "..."}</div>
                                    {/*<div>message: message</div>*/}
                                </div>))}
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className={styles.announce_modal_content_right}>
                <div
                    style={{
                        display: "flex", flexDirection: "column", padding: "0 10px", gap: 10,
                    }}
                >

                    <div>
                        <span style={{
                            backgroundColor: "#1B3D4D",
                            color: "#fff",
                            padding: "2px 10px",
                            borderRadius: 5,
                            maxWidth: 50,
                            minWidth: 50,
                            marginRight: 15
                        }}>Datetime</span>
                        {currentAnnounce.timestamp ?? "dd/mm/yyyy"}</div>
                    <div>
                        <span style={{
                            backgroundColor: "#1B3D4D",
                            color: "#fff",
                            padding: "2px 10px",
                            borderRadius: 5,
                            maxWidth: 50,
                            minWidth: 50,
                            marginRight: 37
                        }}>Topic</span> {currentAnnounce.title ?? "Topic"}</div>
                    {/*<div>From:{currentAnnounce.from ?? ""}</div>*/}
                    {/*<div>To:{currentAnnounce.to ?? user.StudentName ?? user.TeacherName ?? user.name}</div>*/}
                </div>
                <textarea
                    readOnly={true}
                    style={{
                        flex:1,
                        display: "flex", borderRadius: 20, backgroundColor: "#fff", padding: 20,
                        resize:"none"
                    }}
                    value={currentAnnounce.message ?? ""}
                >
                    {currentAnnounce.message ?? ""}
                </textarea>
            </div>
        </div>)}
    </div>);
}

export default AnnounceModal;
