import { POST, GET } from "../";
export default class TelemetryAPI {
  static async sendTelemetry(payload) {
    try {
      const { data } = await POST(`/students/telemetry`, payload);
      return data;
    } catch (err) {
      return [];
    }
  }


}
