import React from "react";
import smallAccess from "../../../../assets/img/ACCESS.png";
import smallGreen from "../../../../assets/img/GREEN.png";
import smallYello from "../../../../assets/img/YELLOW.png";
import { Link } from "react-router-dom";
const mapping = {
  green: [
    { src: smallAccess, alt: "smallAccess", to: "/login/access" },
    { src: smallYello, alt: "smallYello", to: "/login/yellow" },
  ],
  access: [
    { src: smallGreen, alt: "smallGreen", to: "/login/green" },
    { src: smallYello, alt: "smallYello", to: "/login/yellow" },
  ],
  yellow: [
    { src: smallGreen, alt: "smallGreen", to: "/login/green" },
    { src: smallAccess, alt: "smallAccess", to: "/login/access" },
  ],
};
function SmallLogo({ emodule }) {
  return (
    <div className="flex flex-row space-x-3">
      <Link to={mapping[emodule][0].to}>
        <img src={mapping[emodule][0].src} alt={mapping[emodule][0].alt}></img>
      </Link>
      <span className="text-white">Or</span>
      <Link to={mapping[emodule][1].to}>
        <img src={mapping[emodule][1].src} alt={mapping[emodule][1].alt}></img>
      </Link>
    </div>
  );
}

export default SmallLogo;
