import React from "react"; // { useState } // ,
import styles from "./login_page.module.css";
// import { useAuth } from "../../context";
// import { useHistory, useParams } from "react-router-dom";

import {
    // LoginRegularLogo,
    // LoginForm,
    // Loading,
    LoginFormV2,
} from "../../components";
// import { mappingLoginRole } from "../../utils";
import {EAPSpaceLogoWhiteSvgIcon} from "../../assets/svg";
import {Link} from "react-router-dom";

function LoginPage() {
    /** */

    return (
        // <div className="w-full h-screen bg-eapLogin flex flex-row justify-center items-center bg-login bg-cover sm:flex-col">
        <div className={styles.container}>
            {/* <div className="flex items-center justify-center w-1/2 h-full slide-left" style={{marginTop:-140+ "px"}}> */}

            <Link className={styles.logo} to="/">
                <div className="slide-left"><EAPSpaceLogoWhiteSvgIcon/></div>
            </Link>

            <div className={styles.login_form}>
                <LoginFormV2/>
            </div>
        </div>
    );
}

export default LoginPage;
