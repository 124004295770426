import React from "react";
import "./student_search_item.css";
import { PersonSvgIcon } from "../../../assets/svg";

function StudentSearchItem({
  name = "Name",
  status = "Not yet assigned",
  click = () => {},
  ekey="",
}) {
  return (
    <div
      key={ekey}
      className="eap__student_search_item-wrapper "
      onClick={click}
    >
      <div className="avatar eap__shadow">
        <PersonSvgIcon />
      </div>
      <div className="name">{name}</div>
      <div />
      <div className="status">{status}</div>
    </div>
  );
}

export default StudentSearchItem;
