import React from "react";
import styles from "./textfile.module.css";
function FillTextField({
  value,
  readOnly,
  type = "text",
  onInput = () => {},
  required,
}) {
  return (
    <>
      {required ? (
        <input
          required={required}
          className={styles.fill_textfield}
          type={type}
          value={value}
          readOnly={readOnly}
          disabled={readOnly}
          onInput={onInput}
        />
      ) : (
        <input
          className={styles.fill_textfield}
          type={type}
          value={value}
          readOnly={readOnly}
          disabled={readOnly}
          onInput={onInput}
        />
      )}
    </>
  );
}

export default FillTextField;
