import React, { useEffect, useState } from "react";
import { TextField, Button } from "../../../components";
import { useAuth } from "../../../context";
import { useHistory, useParams } from "react-router-dom";
import { mappingLoginRole } from "../../../utils";

import "./form_v2.css";
import dropStyles from "./dropdown.module.css";
import { ReactComponent as DropArrow } from "./downarrow.icon.svg";
import { AuthAPI } from "../../../services";

function FormV2() {
  const { login, showModal } = useAuth();

  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const options = [
    { label: "Student", role: -1 },
    // { label: "Student", role: 1 },
    { label: "Teacher", role: 2 },
    { label: "principal", role: 3 },
  ];
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toggleOption, setToggleOption] = useState(false);
  const errorHandler = (errorMsg) => {
    setShowErrorMsg(true);
    setErrorMsg(errorMsg);
    setTimeout(() => {
      setShowErrorMsg(false);
    }, 3000);
    setTimeout(() => {
      setErrorMsg(null);
    }, 3500);
  };

  const loginHandler = () => {
    if (selectIndex === -1)
      return showModal(
        "Warning",
        "Please select the role you want to login.",
        () => {},
        false
      );
    setIsLoading(true);
    login((userData) => {
      setIsLoading(false);
      if (!userData) return;
      if (userData.status === "error") {
        errorHandler(userData.msg);
        return;
      }
      switch (userData.role) {
        case 1:
          return history.push("/student");
        case 2:
          return history.push("/teacher");
        case 3:
          return history.push("/principal");
        default:
          return history.push("/");
      }
    }, loginParameter);
  };

  const forgotPasswordAction = ({ email, role }) => {
    AuthAPI.forgotPassword({ email, role }).then((res) => console.log(res));
  };

  const forgotPasswordHandler = () => {
    if (username.length < 1 || !options[selectIndex])
      return showModal(
        " ",
        "Please input the email(username) and choose login role of the account you want to find password in username box.",
        () => {},
        false
      );
    showModal(
      "Forgot Password",
      `We will reset password and sent it to ${username} an email with new login credentials.`,
      () => {
        return forgotPasswordAction({
          email: username,
          role: options[selectIndex]?.role,
        });
      },
      false
    );
  };

  const loginParameter = {
    username: username,
    password: password,
    role: options[selectIndex] ? options[selectIndex].role : 1,
    errorHandler: errorHandler,
  };

  useEffect(() => {
    options[selectIndex] &&
      options[selectIndex].role === -1 &&
      showModal(
        "Notice",
        "Please use new student system in new website. Confirm to proceed.",
        () => {
          window.open("https://www.eap-space.com.au/sign-in").focus();
        },
        false
      );
  }, [selectIndex]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        loginHandler();
      }}
      className="eap__landing-login-form"
    >
      <input type="submit" hidden id="landing-login-submit" />
      <div>
        <span className="eap__landing-login-title">Sign in EAP Space</span>
      </div>

      <div
        className="eap__landing-login-error"
        style={{ visibility: showErrorMsg ? "visible" : "hidden" }}
      >
        {errorMsg}.
      </div>

      {/* <div className="eap__landing-login-error">{errorMsg?errorMsg:("$nbsp;")}</div> */}
      <div className="eap__landing-login-form-body">
        <div className="eap__landing-login-form-input">
          <div
            className={dropStyles.wrapper}
            onClick={() => setToggleOption(!toggleOption)}
          >
            <div>
              {selectIndex === -1
                ? "Choose login role"
                : "Login as " + options[selectIndex].label.toLowerCase()}
            </div>
            <div className={dropStyles.droparrow}>
              <DropArrow />
            </div>

            {toggleOption && (
              <div className={dropStyles.option_wrapper}>
                {options.map((el, idx) => (
                  <div
                    key={"option_" + idx}
                    className={dropStyles.option}
                    onClick={() => {
                      setToggleOption(false);
                      setSelectIndex(idx);
                    }}
                  >
                    {el.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="eap__landing-login-form-input">
          <TextField
            placeholder="Username"
            value={username}
            required
            inputHandler={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="eap__landing-login-form-input password">
          <TextField
            type="password"
            placeholder="Password"
            value={password}
            required
            inputHandler={(e) => setPassword(e.target.value)}
          />

          <span
            className="eap__landing-login-form-forgot-password"
            onClick={forgotPasswordHandler}
          >
            Forgot password
          </span>
        </div>

        <div
          style={{
            marginTop: -20 + "px",
            pointerEvents:
              options[selectIndex] && options[selectIndex].role === -1
                ? "none"
                : null,
          }}
          onClick={() => {
            document.getElementById("landing-login-submit").click();
          }}
          className="eap__landing-login-form-submit"
          id="landing-login-submit"
          disabled={options[selectIndex] && options[selectIndex].role === -1}
        >
          <Button
            isLoading={isLoading}
            text="SIGN IN"
            color="#33c1d6"
            textColor="#fff"
          />
        </div>
      </div>
    </form>
  );
}

export default FormV2;
