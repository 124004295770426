import React from "react";
import styles from "./modal.module.css";
function Modal({
  text = "Open Modal",
  title = "Title here",
  content = "Content here",
  isDelete,
  handler = () => {},
}) {
  const [toggleModal, setToggleModal] = React.useState(false);
  return (
    <div>
      {/* <!-- Trigger/Open The Modal --> */}
      <button className={styles.button} 
        onClick={() => {
          setToggleModal(true);
          console.log("Clicked!");
        }}
      >
        {text}
      </button>

      {/* <!-- The Modal --> */}
      <div
        className={
          toggleModal === true ? styles["modal"] : styles["modal_hide"]
        }
      >
        {/* <!-- Modal content --> */}
        <div className={styles["modal_content"]}>
          <h1>{title}</h1>
          <p>{content}</p>
          <div>
            <span
              onClick={() => {
                setToggleModal(false);
              }}
            >
              Cancel
            </span>
            <span
              className={
                isDelete ? styles["modal_text_delete"] : styles["modal_text"]
              }
              onClick={() => {
                handler();
                setToggleModal(false);
              }}
            >
              Delete
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
