// import { fb } from 'service';
import React, {
  createContext,
  useContext,
  /* useEffect,*/ useState,
} from "react";
import { newChat, leaveChat, deleteChat, getMessages } from "react-chat-engine";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [myChats, setMyChats] = useState(null);
  const [chatConfig, setChatConfig] = useState({
    projetID: "4fce0bb9-6433-4e54-88a7-066bea64323b",
    userName: "test_chat",
    userSecrect: "test_chat",
  });
  const [selectedChat, setSelectedChat] = useState();

  const createChatClick = () => {
    newChat(chatConfig, { title: "" });
  };
  const deleteChatClick = (chat) => {
    const isAdmin = chat.admin === chatConfig.userName;

    if (
      isAdmin &&
      window.confirm("Are you sure you want to delete this chat?")
    ) {
      deleteChat(chatConfig, chat.id);
    } else if (window.confirm("Are you sure you want to leave this chat?")) {
      leaveChat(chatConfig, chat.id, chatConfig.userName);
    }
  };
  const selectChatClick = (chat) => {
    getMessages(chatConfig, chat.id, (messages) => {
      setSelectedChat({
        ...chat,
        messages,
      });
    });
  };

  // Set the chat config once the
  // authUser has initialized.
  //   useEffect(() => {
  //     if (authUser) {
  //       fb.firestore
  //         .collection('chatUsers')
  //         .doc(authUser.uid)
  //         .onSnapshot(snap => {
  //           setChatConfig({
  //             userSecret: authUser.uid,
  //             avatar: snap.data().avatar,
  //             userName: snap.data().userName,
  //             projectID: 'edc224a1-feb2-4032-8894-32afb2ec31ba',
  //           });
  //         });
  //     }
  //   }, [authUser, setChatConfig]);

  return (
    <ChatContext.Provider
      value={{
        myChats,
        setMyChats,
        chatConfig,
        selectedChat,
        setChatConfig,
        setSelectedChat,
        selectChatClick,
        deleteChatClick,
        createChatClick,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const {
    myChats,
    setMyChats,
    chatConfig,
    selectedChat,
    setChatConfig,
    setSelectedChat,
    selectChatClick,
    deleteChatClick,
    createChatClick,
  } = useContext(ChatContext);

  return {
    myChats,
    setMyChats,
    chatConfig,
    selectedChat,
    setChatConfig,
    setSelectedChat,
    selectChatClick,
    deleteChatClick,
    createChatClick,
  };
};
