import React, {useState, useEffect} from "react";
// import OptionsForType10 from "../Option/OptionsForType10";
import QuestionWithOptions from "../Option/QuestionWithOptions";

// import QuestionWithOptions from "../Option/QuestionWithOptions";

function Type10({
                    question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
                }) {
    const [answer, setAnswer] = useState([]);
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(true);
    const [scoreLocal, setScoreLocal] = useState(0);
    // console.log(lock);
    // console.log(score);

    useEffect(() => {
        setAnswer(question);
        setChecked(question.questions.map((q) => false));
        setLoading(false);
    }, []);

    const handleLock = (copy) => {
        const flag = [...copy].every((el) => el === 1);
        setLock(!flag);
    };

    const checkAnswer = (option, indexOfQuestion) => {
        if (answer.questions[indexOfQuestion].correctOption === option) {
            const copy = [...checked];
            copy[indexOfQuestion] = 1;
            setChecked(copy);
            handleLock(copy);
        } else {
            const copy = [...checked];
            copy[indexOfQuestion] = 0;
            setChecked(copy);
            setScoreLocal(scoreLocal + 1);
            setScore(scoreLocal + 1);
        }
    };

    useEffect(() => {
        handleLock(checked)
    }, [checked]);

    const getBgColor = (index) => {
        switch (checked[index]) {
            case 1 :
                return "#89B481";
            case 0 :
                return "#E5B343";
            default:
                return "#f7f7f7";
        }
    }
    return (
        <div>
            {loading === false ? (
                <>
                    <div className="flex flex-row "

                         style={{width:'100%',padding:10}}>
                        <div className="w-1/12"/>
                        <div className="w-10/12  bg-white p-4 flex flex-col "

                        >
                            <div>{question.prompt}</div>
                            <div className="flex flex-row  h-full divide-x">
                                <div className="flex flex-col w-1/2
                                 space-y-5 justify-center">
                                    {question.questions.map((q, index) => {
                                        return <span key={index}>{q.question}</span>;
                                    })}
                                </div>

                                <div className="flex flex-col w-1/2
                                 justify-center"
                                     style={{justifyContent: 'space-between'}}>
                                    {answer.questions.map((item, index) => {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                gap: 10,
                                                alignItems: 'center'}}
                                                 key={index + "_type10"}
                                            >
                                                {index + 1}.<QuestionWithOptions
                                                // prompt={`${index + 1}.`}
                                                options={item.options}
                                                index={index}
                                                checked={checked[index]}
                                                checkAnswer={checkAnswer}
                                                bgColor={getBgColor(index)}
                                            />
                                            </div>
                                            // <OptionsForType10
                                            //         key={index}
                                            //         prompt={`${index + 1}.`}
                                            //         options={item.options}
                                            //         index={index}
                                            //         checked={checked[index]}
                                            //         checkAnswer={checkAnswer}
                                            //         bgColor={getBgColor(index)}
                                            //     />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="w-1/12"/>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default Type10;
