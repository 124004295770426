import React, {useEffect} from "react";
// import styles from "./styles.module.css";
import {PlacementQuestionWrapper} from "../../../layouts";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../context";
import {useModuleQuestions} from "../../../hooks";
import {useHistory} from "react-router-dom";
import {BeatLoader} from "react-spinners";
import usePlacementHook from "../../../hooks/placement.test.hook";
import {PlacementQuestionBody} from "../../index";

function QuestionWrapper({hooks}) {
    const history = useHistory();
    const {roleMapper, showModal} = useAuth();


    return (<PlacementQuestionWrapper
        // title={moduleName}
        gotoNextHandler={hooks.gotoNextQuestion}
        setLock={hooks.setLock}
        lock={hooks.lock}
        pause={hooks.pause}
        // currentWeek={currentWeekNum}
        // weekModules={weekModules}
        // weekPercentage={weekPercentage}
        questionId={hooks.getCurrentQuestionId()}
        currentQuestionProgress={hooks.getCurrentQuestionProgress()}
        start={hooks.start}
        restart={hooks.restart}
        minutes={hooks.minutes}
        seconds={hooks.seconds}
    >
        {hooks.loading ? <BeatLoader/> :
            // JSON.stringify( hooks.questions.current[hooks.current])
        <PlacementQuestionBody
            current={hooks.current}
            question={hooks.questions.current[hooks.current]}
            answerQuestionHandler={hooks.answerQuestionHandler} />
        }
    </PlacementQuestionWrapper>)
}

export default QuestionWrapper;
