import React from "react";
import { HeaderBar } from "../../components";
import "./basic_layout.css";

import { useAuth } from "../../context";
function BasicLayout({ children, subBar }) {
  const { user } = useAuth();
  const { teacherName, studentName } = user;
  const nameHelper = () => (user ? teacherName || studentName : "Anonymous");
  const homeUrlHelper = () => {
    const { role = 1 } = user;
    switch (role) {
      case 1:
        return "/student";
      case 2:
        return "/teacher";
      default:
        return "/";
    }
  };
  return (
    <>
      <HeaderBar homeUrl={homeUrlHelper()} userName={nameHelper()} />
      {subBar}
      <div className="main__container">
        {subBar ? <></> : <div style={{ height: 105 + "px" }} />}
        <div>{children}</div>
      </div>
      {/* <TeacherSubHeaderBar /> */}
    </>
  );
}

export default BasicLayout;
