import {POST, GET} from "../";

export default class AuthAPI {
    static async login({username, password, role, errorHandler}) {
        try {
            const {data, status} = await POST(`/auth/login`, {
                username, password, role,
            });
            if (status === 401) return data;
            return data;
        } catch (err) {
            return errorHandler(err.message);
        }
    }

    static async loginAdmin({username, password, errorHandler}) {
        try {
            const {data, status} = await POST(`/auth/admin/login`, {
                username, password
            });
            if (status === 401) return data;
            return data;
        } catch (err) {
            return errorHandler(err.message);
        }
    }

    static async logout() {
        return await GET(`/auth/logout`);
    }

    static async checkLoggedIn(role = 1) {
        const mapping = () => {
            switch (role) {
                case 1:
                    return "student";
                case 2:
                    // return "teacher";
                    return "student";
                default:
                    return "student";
            }
        };
        const {data} = await GET(`/auth/${mapping()}/checkLoggedIn`);
        // const { loggedIn,user } = data;
        return data;
    }

    static async checkAdminLoggedIn() {
        try {
            const {data} = await GET(`/auth/checkAdminLoggedIn`);
            return data;
        } catch (err) {
            return {
                status: 'error',
                msg: err.message
            }
        }
    }

    static async forgotPassword({email, role}) {
        try {
            const {data} = await POST(`/auth/forgotPassword`, {role, email});
            return data;
        } catch (e) {
            return e;
        }
    }
}
