import React, {useState, useEffect} from "react";
import {Button} from "react-bootstrap";
import {StudentMediaController as MediaHandler} from "../../../../";

const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];

function Type1({
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    },
    auto = true
               }) {
    const [loading, setLoading] = useState(true);
    const [answer, setAnswer] = useState([]);
    const [current, setCurrent] = useState(0);
    const [scoreLocal, setScoreLocal] = useState(0);

    const handleButtonClick = (i) => {
        if (current + i >= 0 && current + i < question.questions.length) {
            setCurrent(current + i);
        }
    };

    useEffect(() => {
        setAnswer([...question.questions]);
        setLoading(false);
    }, []);

    const checkAnswer = (index) => {
        if (lock === false) return;

        const copy = [...answer];
        copy[current].options[index] = {
            ...copy[current].options[index], attempted: true, correct: copy[current].options[index].isTrue,
        };
        if (copy[current].options[index].isTrue === false) {
            setScoreLocal(scoreLocal + 1);
            setScore(scoreLocal + 1);
        } else {
            const qLength = question.questions.length;
            let count = 0;
            for (const x of answer) {
                for (const o of x.options) {
                    if (o.attempted === true && o.correct === true) count++;
                }
            }

            if (count === qLength) setLock(false);
        }

        setAnswer(copy);
    };

    const btnStyles = {
        backgroundColor: "#f5f5f5",
        padding: "5px 10px",
        width:"40%", borderRadius: 5,
        color:'#0f2129', outline:"none",
        borderColor:'transparent'

    }
    return ( loading === false ? (<>
                <div className="w-full flex flex-row ">
                    <div className="w-1/12">{""}</div>
                    <div className="w-10/12 bg-white p-4 flex flex-col ">
                        <div>{question.prompt}</div>
                        <div className="flex flex-row w-full divide-x h-full">
                            <div className="flex flex-col w-1/2 p-2 space-y-5 h-full ">

                                   <MediaHandler
                                       auto={auto}
                                       url={question.mediaUrl}
                                       type={question.mediaType}
                                   />

                                <div className="w-full flex flex-col space-y-3">
                                    <span>
                                        <h2>Question {current + 1}/{question.questions.length}</h2>
                                        {question.questions[current].question}</span>
                                    <span className="flex flex-row space-x-3">
                     {(current > 0) && <Button style={btnStyles} onClick={() => handleButtonClick(-1)}>
                         Previous
                     </Button>}
                                        {(current + 1 < question.questions.length) && < Button style={btnStyles}
                                                                                               onClick={() => handleButtonClick(1)}>Next</Button>}
                                        </span>
                                </div>
                            </div>

                            <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">
                                {question.questions[current].options.map((o, i) => (
                                    <div className="flex flex-row space-x-5" key={i}>
                                        <span>{alphabet[i]}.</span>
                                        {!o.attempted ? (<span
                                            className={"p-2  rounded-md "}
                                            style={{backgroundColor: "#f5f5f5"}}
                                        >
                                        <button style={{textAlign: 'start'}} onClick={() => checkAnswer(i)}>
                                    {o.label}
                                        </button>
                                        </span>) : null}
                                        {o.attempted && !o.correct ? (<span
                                            className={"p-2 rounded-md  text-white"}
                                            style={{backgroundColor: "#e3b143"}}
                                        >
                                        <button style={{textAlign: 'start'}} onClick={() => checkAnswer(i)}>
                                    {o.label}
                                        </button>
                                        </span>) : null}
                                        {o.attempted && o.correct ? (<span
                                            className={"p-2 bg-green-500 rounded-md  text-white"}
                                            style={{backgroundColor: "#88b380"}}
                                        >
                                        <button style={{textAlign: 'start'}} onClick={() => checkAnswer(i)}>
                                    {o.label}
                                        </button>
                                        </span>) : null}
                                    </div>))}
                            </div>
                        </div>
                    </div>
                    <div className="w-1/12">{""}</div>
                </div>
            </>) : null);
}

export default Type1;
