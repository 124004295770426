import React from "react";
import "./student_item.css";
import {ReactComponent as PersonIcons} from "../../../assets/svg/person_icon.svg";
import {IconWithRightText} from "../../";
import {PercentDonut as PercentageRingIcon} from "../../";
import {useHistory} from "react-router-dom";
import {uuid4} from '../../../utils';

function StudentItem({
                         name = "Sam",
                         week = "1",
                         percent = Math.round(Math.random() * 100),
                         href = "#",
                         src,
                     }) {
    const history = useHistory();

    return (
        <div className="eap__teacher-student-item-container" key={uuid4()} onClick={() => history.push(href)}>
            <div className="eap__student-item-head">
                <IconWithRightText
                    mkey={uuid4()}
                    svg={<PersonIcons/>}
                    src={src}
                    text={name}
                    // href={href}
                />
            </div>
            <div className="eap__student-item-body">
                <div className="eap__student-item-week-component">
                    <div>Week {week}</div>
                    <PercentageRingIcon percent={percent}/>
                </div>
            </div>
            <div className="eap__student-item-tail">
                <div
                    className="eap__student-item-view-btn"

                >
                    <span style={{width: 100 + "px"}}>View</span>
                </div>
            </div>
        </div>
    );
}

export default StudentItem;
