import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "counter", // very important, would be used for selector
  initialState /* This object defines the initial state */: {
    user: null,
  },
  reducers /* This object defines the functions to use */: {
    setUser: (state, { payload }) => {
      /* Here it has to use "payload" to destructure the comming in value */
      state.user = payload;
    },
    destroyUser: (state) => {
      state.user = null;
      document.cookie = "";
    },
    checkLocalLogin(state) {
      return state.user !== null;
    },
  },
});

export const { setUser, destroyUser, checkLocalLogin } =
  /** Noticed here all those functions are from the actions */
  authSlice.actions;
/** Little bit confused by the singular form "reducer" instead of plural form of "reducers" */
export default authSlice.reducer;
