import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { 
  // AuthReducer, 
  CounterReducer } from "./slices";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// export default

export const store = configureStore({
  reducer: persistReducer(
    {
      key: "eap_root",
      version: 1,
      storage,
      whitelist: ["auth", "counter"],
    },
    combineReducers({
      // auth: AuthReducer,
      counter: CounterReducer,
    })
  ),
  //   {
  //     auth: persistReducer(
  //       {
  //         key: "eap_auth",
  //         version: 1,
  //         storage,
  //       },
  //       AuthReducer
  //     ),
  //     counter: persistReducer(
  //       {
  //         key: "eap_counter",
  //         version: 1,
  //         storage,
  //       },
  //       CounterReducer
  //     ),
  //   }
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// export default store;
