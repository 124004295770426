import React, {useEffect, useRef, useState} from "react";
import styles from './audio.module.css';
import {ReactComponent as PlaySvgIcon} from "./play_icon.svg";
import {ReactComponent as PauseSvgIcon} from "./pause_icon.svg";

function AudioPlayer({
                         audioSrc,
    auto = true
                     }) {
    const audioRef = useRef(new Audio(audioSrc));
    const [volume, setVolume] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(auto);


    useEffect(() => {

        audioRef.current.onloadeddata = () => {
            setDuration(audioRef.current.duration);
            setVolume(audioRef.current.volume * 100);
            // console.log('current', audioRef.current);
            audioRef.current.hidden = true;
            // console.log('volume', audioRef.current.volume, 'duration', audioRef.current.duration);
        }

        audioRef.current.ontimeupdate = () => {
            setCurrentTime(audioRef.current.currentTime);
        }

        return () => {
            audioRef.current.pause();
        }
    }, [])

    useEffect(() => {
        isPlaying ? audioRef.current.play() : audioRef.current.pause();
        return  () => {
            audioRef.current.pause();
        }
    }, [isPlaying]);


    const showMinuteSeconds = (sec) => {
        const min = Math.floor(sec / 60);
        const reSecs = Math.floor(sec - min * 60);
        return `${('00' + min).slice(-2)}:${('00' + reSecs).slice(-2)}`;
    }

    return <div>

        <div style={{
            display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: "1.2em"
        }}>
            <button className={styles.play_btn}
                    onClick={() => setIsPlaying(!isPlaying)}> {isPlaying ? <PauseSvgIcon/> : <PlaySvgIcon/>}</button>

            {/*<input type={'range'} min={0} max={100} value={volume}*/}
            {/*       onChange={(e) => {*/}
            {/*           setVolume(+e.target.value);*/}
            {/*           audioRef.current.volume = +e.target.value / 100;*/}
            {/*       }}/>*/}
            <input type={'range'} min={0} max={duration} value={currentTime}
                   onChange={(e) => {

                       audioRef.current.currentTime = +e.target.value;
                   }}/></div>
        <div style={{
            textAlign: "end", color: '#1B3E4D', fontSize: '14px'
        }}> {showMinuteSeconds(currentTime)}/{showMinuteSeconds(duration)}</div>
    </div>
}

export default AudioPlayer;