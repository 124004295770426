import React, {useEffect} from "react";
import {Route, Redirect} from "react-router-dom";
import {useAuth} from "../../context";
import {AuthAPI} from "../../services";

function AdminRoute({roles = [1], children, ...rest}) {
    const {user, setUser} = useAuth();
    useEffect(() => {
        if (!user) {
            AuthAPI.checkLoggedIn().then((result) => {
                if (result.status === true) {
                    setUser(result.user);
                } else {
                    AuthAPI.logout().then(() => {
                        return <Redirect to={{pathname: '/admin/login'}}/>
                    });
                }
            });
        }
    }, []);
    const renderHandler = () => {
        if (!user || !user?.admin) {
            return (<Redirect
                to={{
                    pathname: "/admin/login",
                }}
            />);
        }
        return children;
    };
    return <Route {...rest} render={renderHandler}/>;
}

export default AdminRoute;
