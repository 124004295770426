import React, {useEffect, useState} from "react";
import {BasicLayout} from "../../";
import {BackButton, SubHeaderBar, WeeklyContentComponent, WeeklyContentHead} from "../../../components";
import styles from "./questions.module.css";
import {
    QuestionMarkSvgIcon,
    QuestionBookmarkSvgIcon,
    ProfileSmallDownArrowSvgIcon,
    NotepadSvgIcon
} from "../../../assets/svg";
import {instructionPicMapper} from './questionInstruction';
import {useAuth} from "../../../context";
import {BookmarkAPI} from "../../../services";

function QuestionWrapperTemplate({
                                     title, children, href = "/student", pagination, dropdown,
                                     questionId = "",
                                     gotoNextHandler = () => {
                                     }, lock, setLock,
                                     currentWeek = -1,
                                     currentWeekDesc = "Loading Description",
                                     weekPercentage = 0,
                                     weekModules = [],
                                     showNotePadBtn = true,
                                     pause = () => {
                                     },
                                     questionType,
                                     currentQuestionProgress = 0,

                                 }) {


    const [showNotePad, setShowNotePad] = useState(showNotePadBtn);
    const [showInstructionPic, setShowInstructionPic] = useState(false);
    const [instruction_pic_url, setInstruction_pic_url] = useState();
    const addBookmark = (id) => {
        BookmarkAPI.add(id).then(({msg}) => {
            showModal(" ", msg, () => {
            }, false);
        });
    }
    const {showModal} = useAuth();

    const addBookmarkHandler = () => {
        showModal(" ", "Bookmark this question?", () => {
            addBookmark(questionId);
        }, false);
    }

    useEffect(() => {

        questionType && setInstruction_pic_url(instructionPicMapper(questionType))
    }, [questionType]);

    return (<BasicLayout subBar={<SubHeaderBar/>}>
        <div className={"eap__management-container-col"}>

            <div className={styles.line_1}>
                <BackButton/>
                <div className={styles.title_holder}>
                    <h1>{title ?? "Vocabulary"}</h1>
                    {/*<div style={{display: "flex", flex: "1"}}></div>*/}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{
                            fontSize: 30 + 'px', display: 'flex', alignItems: 'center', gap: 40
                        }}>WEEK {currentWeek}
                            {/*<ProfileSmallDownArrowSvgIcon/>*/}
                        </div>
                        <div style={{fontSize: 20 + 'px',textTransform:'capitalize'}}>{currentWeekDesc}</div>
                    </div>
                </div>

            </div>

            <div className={styles.line_2}>
                <div className={styles.left_block}>{""}</div>
                <div className={styles.right_block}>
                    <div style={{
                        height: 20,
                        // backgroundColor: "#e4b243",
                        width: "100%",
                        position: 'relative',
                        transform: 'translateY(20px)',
                        borderRadius: '20px 20px 0 0',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: 5,
                            width: `${currentQuestionProgress * 100}%`,
                            zIndex: 1,
                            backgroundColor: "#f4c63a",

                        }}/>
                    </div>
                    <div className={styles.container}>{children}</div>
                    {<div style={{display: "flex", justifyContent: "space-between", gap: 30, marginTop:30}}>
                        {showNotePadBtn &&
                            <div className={styles.notepad_btn} onClick={() => setShowNotePad(!showNotePad)}>
                                <NotepadSvgIcon/>
                            </div>}
                        <div style={{display: "flex", flex: 1}}>  {showNotePad &&
                            <textarea
                                style={{width: 100 + "%", borderRadius: 20, padding: 20, resize: 'none'}}
                                rows={15}
                            >
                            </textarea>
                        }</div>
                        <div className={styles.instruction_btn}
                             onClick={() => showModal(" ",
                                 "See the question instructions?",
                                 () => setShowInstructionPic(!showInstructionPic), false)}>
                            <QuestionMarkSvgIcon/></div>
                        {showInstructionPic && <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 105,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 200
                            }}
                            onClick={() => setShowInstructionPic(false)}
                        >
                            <img src={instruction_pic_url} alt={"instruction pic"}/>
                        </div>}
                        <div className={styles.bookmark_btn} onClick={addBookmarkHandler}>
                            <QuestionBookmarkSvgIcon/></div>
                        <button disabled={lock} className={lock ? styles.next_btn_disabled : styles.next_btn}
                                style={{maxHeight: 51}}
                                onClick={gotoNextHandler}
                        >Next
                        </button>
                    </div>}

                    {/*<div style={{display: 'grid', gridAutoFlow: 'row'}}>*/}
                    {/*    <div style={{gridColumn: "1 / 1"}}>*/}
                    {/*        <WeeklyContentHead weekNum={currentWeek} percent={weekPercentage}/>*/}
                    {/*    </div>*/}
                    {/*    <div style={{gridColumn: "2 / 12"}}>*/}
                    {/*        <WeeklyContentComponent modules={weekModules}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div style={{height: 50}}/>
                </div>
                <div className={styles.left_block}>{""}</div>
            </div>
        </div>
    </BasicLayout>);
}

export default QuestionWrapperTemplate;
