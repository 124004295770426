import React, {useEffect, useRef, useState} from 'react';
import Loading from "../../../../Common/Placeholder/Loading/Loading";
import {ReactComponent as PlaySvgIcon} from "./play_speaking_icon.svg";
import {ReactComponent as PauseSvgIcon} from "./pause_speaking_icon.svg";
import {ReactComponent as RecordSvgIcon} from "./record_speaking_icon.svg";
import styles from './type11.module.css';
import Recorder from 'recorder-js';
import CommonButton from "../../../../Common/Button/CommonButton/CommonButton";

function Type11({
                    question,
                    lock,
                    setLock = (v) => {
                    },
                    score,
                    setScore = (v) => {
                    },
    auto = true
                }) {
    const [answer, setAnswer] = useState([]);
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(true);
    const [scoreLocal, setScoreLocal] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    // const [recordingStream, setRecordingStream] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const [recordBlob, setRecordBlob] = useState(null);
    const [recordUrl, setRecordUrl] = useState(null);


    const [recordData, setRecordData] = useState([...Array(42)].fill(0));
    const [audioAnalyzer, setAudioAnalyzer] = useState(null);
    const [frequencyBandArr, setFrequencyBandArr] = useState([]);
    const [audioFile, setAudioFile] = useState(null);

    const audioRef = useRef(new Audio());
    const recordRef = useRef(new Audio());

    const [recordPlayStatus, setRecordPlayStatus] = useState(false);

    // const [animate, setAnimate] = useState(null);

    // console.log(lock);
    // console.log(score);

    useEffect(() => {

        initAudioAnalyser();
        initRecorder();
        initRecordPlayer();
        setAnswer(question);
        setChecked(question.questions.map((q) => false));
        setLoading(false);
        setFrequencyBandArr([...Array(42).keys()])


    }, []);

    const initRecordPlayer = function () {
        recordRef.current.onended = ev => {
            setRecordPlayStatus(false);
        }

        recordRef.current.onplaying = () => {
            setRecordPlayStatus(true);
        }
        recordRef.current.onpause = () => {
            setRecordPlayStatus(false);
        }

    }

    useEffect(() => {
        console.log(recordBlob);
        if (recordBlob) {
            setRecordUrl(URL.createObjectURL(recordBlob));
        }
    }, [recordBlob]);

    const playRecordedAudio = () => {
        if (!recordBlob || !recordUrl) return;
        initRecordPlayer();
        recordRef.current = new Audio(recordUrl);
        recordRef.current.play();
        setRecordPlayStatus(true);
    }

    const pauseRecordedAudio = () => {
        recordRef.current.pause();
        setRecordPlayStatus(false);
    }

    const initRecorder = () => {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioCtx = new AudioContext();

        navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then((stream) => {
                // setRecordingStream(stream);

                const rec = new Recorder(audioCtx, {
                    onAnalysed: data => {
                        // console.log(data)
                        setRecordData(data.data.slice(0, 42))
                    }
                });
                setRecorder(rec);
                rec.init(stream);
            });
    }

    const initAudioAnalyser = () => {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        audioRef.current.setAttribute('crossorigin', 'anonymous');
        // audioRef.current.setAttribute('crossorigin', 'use-credentials');
        audioRef.current.setAttribute('id', 'type11');
        audioRef.current.setAttribute('src', question.audioSrc);


        // console.log("audioRef current", audioRef.current);

        audioRef.current.onloadeddata = () => {
            const source = audioContext.createMediaElementSource(audioRef.current);
            const analyser = audioContext.createAnalyser();

            analyser.fftSize = 128
            source.connect(audioContext.destination);
            source.connect(analyser);

            setAudioFile(audioRef.current);
            setAudioAnalyzer(analyser);

            audioRef.current.ontimeupdate = () => {
                let amplitudeArray = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(amplitudeArray);
                setFrequencyBandArr([...amplitudeArray].slice(0, 42))
            }
        }

    }

    const getFrequencyData = () => {
        const bufferLength = audioAnalyzer.frequencyBinCount;
        let amplitudeArray = new Uint8Array(bufferLength);
        audioAnalyzer.getByteFrequencyData(amplitudeArray);
        setFrequencyBandArr([...amplitudeArray].slice(0, 42));
    }

    // const handleLock = (copy) => {
    //
    //     const flag = [...copy].some((el) => el === false);
    //     setLock(flag);
    // };

    const startRecord = () => {
        console.log("Start recording...")
        recorder !== null && recorder.start().then(() => setIsRecording(true));
    }
    const stopRecord = () => {
        console.log("Stop recording.")
        recorder !== null && recorder.stop().then(({blob, buffer}) => {
            setIsRecording(false);
            setRecordBlob(blob);

        });
    }

    function downloadRecord() {
        recorder !== null && Recorder.download(recordBlob, `my_record_${Date.now()}`); // downloads a .wav file
    }

    useEffect(() => {
        console.log("isRecording changed...")
        isRecording ? startRecord() : stopRecord();
    }, [isRecording]);

    useEffect(() => {

        function run() {
            audioRef.current.play().then(() => {
                console.log("audio Ref playing...")
            });
            console.log("Playing");
        }

        function pause() {
            audioFile.pause();
            // animate && window.cancelAnimationFrame(animate);
        }

        isPlaying ? (audioFile && run()) : (audioFile && pause());
    }, [isPlaying]);

    useEffect(() => {
        (!isPlaying && !isRecording) && setLock(recordData === null);
    }, [isRecording])

    // const checkAnswer = (option, indexOfQuestion) => {
    //     const copy = [...checked];
    {/*    if (answer.questions[indexOfQuestion].correctOption === option) {*/
    }

    //         copy[indexOfQuestion] = true;
    //         setChecked(copy);
    //         handleLock(copy);
    //     } else {
    //
    //         copy[indexOfQuestion] = false;
    //         setChecked(copy);
    //         setScoreLocal(scoreLocal + 1);
    //         setScore(scoreLocal + 1);
    //     }
    // };

    const [showScript, setShowScript] = useState(false);
    const toggleScript = () => setShowScript(!showScript);


    if (loading) return <Loading/>;
    return (<div>
        {showScript && <div className={styles['overlay']}>
            <pre className={styles['script-content']}>
                <CommonButton text={'CLOSE'} click={() => setShowScript(false)}/>
                <br/>
                {question.script}
            </pre>
        </div>}
        {loading === false ? (<>
            <div className="w-full h-96 flex flex-row ">
                <div className="w-1/12">{""}</div>
                <div className="w-10/12 bg-white p-4 flex flex-col ">
                    <div>{question.prompt ?? ""}</div>
                    <div className="flex flex-row w-full h-full divide-x">
                        <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">
                            {/*<div>Let’s practise saying the schwa in individual word list.</div>*/}
                            {/*<div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>*/}
                            {/*    {question.questions.map((q, index) => {*/}
                            {/*        return <span key={index}>{q.question}</span>;*/}
                            {/*    })}*/}
                            {/*</div>*/}
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexFlow: "row wrap",
                                // justifyContent: "space-between",
                                gap: 30
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: 0,

                                }}>
                                    <button className={styles.play_btn}
                                            disabled={isRecording}
                                            onClick={() => setIsPlaying(!isPlaying)}
                                    >
                                        {isPlaying ? <PauseSvgIcon/> : <PlaySvgIcon/>}
                                    </button>
                                    <div>{isPlaying ? "Playing" : "Play"}</div>
                                    <button
                                        onClick={toggleScript}>Script
                                    </button>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    transform: 'translateY(-20px)',
                                    height: 50,
                                    overflow: 'hidden'
                                }}>{
                                    frequencyBandArr && [...frequencyBandArr].map((el, idx) => (
                                        <div key={'wave1_' + idx}
                                             style={{
                                                 width: 2,
                                                 height: isPlaying ? (el + 1) * 0.5 : (Math.abs(3 - idx % 5) * 10 + 10),
                                                 backgroundColor: "#000",
                                                 borderRadius: 2
                                             }}>{""}</div>))

                                }</div>
                            </div>
                        </div>
                        <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">
                            {/*<div style={{visibility: "hidden"}}>Let’s practise saying the schwa in individual word*/}
                            {/*    list.*/}
                            {/*</div>*/}

                            {/*<div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>*/}
                            {/*    {question.questions.map((q, index) => {*/}
                            {/*        return <span key={index}>{q.question}</span>;*/}
                            {/*    })}*/}
                            {/*</div>*/}
                            <div style={{
                                display: 'flex',
                                gap: 30,
                                // justifyContent: 'space-between',
                                flexFlow: "row wrap",
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: 0
                                }}>
                                    <button
                                        disabled={isPlaying}
                                        className={styles.play_btn} onClick={() => setIsRecording(!isRecording)}>
                                        {isRecording ? <PauseSvgIcon/> : <RecordSvgIcon/>}
                                    </button>
                                    <div>{isRecording ? "Recording" : "Record"}</div>
                                    {/*<audio controls*/}
                                    {/*     style={{visibility: (recordBlob && !isRecording) ? null : "hidden"}}*/}
                                    {/*       >*/}
                                    {/*    <source src={recordBlob ? "https://mdn.github.io/learning-area/html/multimedia-and-embedding/video-and-audio-content/viper.mp3" : null} type={"audio/*"}/>*/}
                                    {/*</audio>*/}
                                    {!recordPlayStatus && <button style={{visibility: (recordBlob && !isRecording) ? null : "hidden"}}
                                             onClick={playRecordedAudio}>{'Play'}
                                    </button>}
                                    {recordPlayStatus && <button style={{visibility: (recordBlob && !isRecording) ? null : "hidden"}}
                                             onClick={pauseRecordedAudio}>{'Pause'}
                                    </button>}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    transform: 'translateY(-20px)',
                                    height: 50,
                                    overflow: 'hidden'
                                }}>
                                    {recordData && [...recordData].map((el, idx) => (
                                        <div key={'wave_' + idx}
                                             style={{
                                                 width: 2,
                                                 height: isRecording ? (el + 1) * 1.5 : (Math.abs(3 - idx % 5) * 10 + 10),
                                                 backgroundColor: "#000",
                                                 borderRadius: 2
                                             }}>{""}</div>))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/12">{""}</div>
            </div>
        </>) : null}
    </div>);
}

export default Type11;