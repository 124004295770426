import React from "react";
import {
    // Follow,
    // Option,
    Type1,
    Type2,
    Type3,
    Type4,
    Type5,
    Type6,
    Type7,
    Type8,
    Type9,
    Type10,
    Type11,
    Type12,
    Type13,
    Type14
} from "../Types";

export const question = {
    question1: {
        prompt: "This is a prompt",
        mediaUrl: "https://picsum.photos/200/300",
        mediaType: "image",
        questions: [
            {
                question: "This is a question1",
                options: [
                    {label: "Location", isTrue: false},
                    {label: "History", isTrue: false},
                    {label: "Culture", isTrue: true},
                    {label: "Food", isTrue: false},
                    {label: "Water", isTrue: false},
                ],
            },
            {
                question: "This is a question2",
                options: [
                    {label: "Location2", isTrue: false},
                    {label: "History", isTrue: false},
                    {label: "Culture", isTrue: true},
                    {label: "Food", isTrue: false},
                    {label: "Water", isTrue: false},
                ],
            },
            {
                question: "This is a question3",
                options: [
                    {label: "Location3", isTrue: false},
                    {label: "History", isTrue: false},
                    {label: "Culture", isTrue: true},
                    {label: "Food", isTrue: false},
                    {label: "Water", isTrue: false},
                ],
            },
        ],
    },
    question3: {
        prompt: "This is a question prompt",
        recordingUrl:
        // "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
            "https://campaign-system-bucket-1910.s3.ap-southeast-2.amazonaws.com/assets/test.mp3",

        options: [
            {label: "Location", isTrue: true},
            {label: "History", isTrue: false},
            {label: "Culture", isTrue: true},
            {label: "Food", isTrue: false},
            {label: "Water", isTrue: true},
            {label: "Earth", isTrue: false},
        ],
        score: 1,
    },
    question2: {
        prompt: "This is a question prompt",
        passage: ` On the other hand, we denounce with righteous indignation and
        dislike men who are so beguiled and demoralized by the charms of
        pleasure of the moment, so blinded by desire, that they cannot
        foresee the pain and trouble that are bound to ensue; and equal
        blame belongs to those who fail in their duty through weakness
        of will, which is the same as saying through shrinking from toil
        and pain. These cases are perfectly simple and easy to
        distinguish. In a free hour, when our power of choice is
        untrammelled and when nothing prevents our being able to do what
        we like best, every pleasure is to be welcomed and every pain
        avoided. But in certain circumstances and owing to the claims of
        duty or the obligations of business it will frequently occur
        that pleasures have to be repudiated and annoyances accepted.
        The wise man therefore always holds in these matters to this
        principle of selection: he rejects pleasures to secure other
        greater pleasures, or else he endures pains to avoid worse
        pains.`,
        words: [
            {word: "An organization such as a company", meaning: "Company"},
            {word: "Something that is not apparent or clear", meaning: "Unclear"},
            {word: "Something that attracts or interest you", meaning: "Interest"},
            {
                word: "To put something into something else per se should make sense",
                meaning: "Include",
            },
            {word: "A written document describing something", meaning: "Report"},
            {
                word: "To do something while something else is happening",
                meaning: "During",
            },
        ],
        options: [
            {label: "Location", isTrue: false},
            {label: "History", isTrue: false},
            {label: "Culture", isTrue: true},
            {label: "Food", isTrue: false},
            {label: "Water", isTrue: false},
        ],
        score: 1,
    },
    question4: {
        prompt: "Rearrange the words correctly",
        options: [
            {word: "For", index: 3, setting: 1},
            {word: "Brodie", index: 0, setting: 0},
            {word: "To School", index: 2, setting: 4},
            {word: "Doesn't Walk", index: 1, setting: 2},
            {word: "Has a car", index: 5, setting: 3},
            {word: "He", index: 4, setting: 5},
        ],
    },
    question8: {
        prompt: "Match to the correct word",
        words: [
            {word: "An organization such as a company", meaning: "Company"},
            {word: "Something that is not apparent or clear", meaning: "Unclear"},
            {word: "Something that attracts or interest you", meaning: "Interest"},
            {
                word: "To put something into something else per se should make sense",
                meaning: "Include",
            },
            {word: "A written document describing something", meaning: "Report"},
            {
                word: "To do something while something else is happening",
                meaning: "During",
            },
        ],
    },
    question9: {
        prompt: "Match to the correct word",
        words: [
            {word: "He", meaning: "Pronoun"},
            {word: "Tree", meaning: "Noun"},
            {word: "Heavy", meaning: "Adjective"},
            {
                word: "calmly",
                meaning: "Adverbs",
            },
            {
                word: "But",
                meaning: "Preposition",
            },
            {
                word: "The",
                meaning: "Articles",
            },
        ],
    },
    question7: {
        prompt: "Find the 1) subject, 2) verb, and 3) object in the sentences",
        sentence: "Pathway programs can help students enter university.",
        words: [
            {word: "Pathway programs", meaning: "Subject"},
            {word: "Can help", meaning: "Verb"},
            {word: "Student enter university", meaning: "Object"},
        ],
    },
    question5: {
        prompt: "Read the passage and answer the following questions",
        passage:
            "Lorem ipsum dolor sit amet, consecrate disciplining elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        questions: [
            {
                prompt: "What is the subject?",
                options: ["Option 1", "Option 2", "Option 3"],
                correctOption: "Option 1",
            },
            {
                prompt: "What is the Object?",
                options: ["Option 1", "Option 2", "Option 3"],
                correctOption: "Option 2",
            },
        ],
    },
    question6: {
        prompt: "Read the passage and answer the following questions",
        asset:
            "https://www.eap-space.com//uploads/20200709/2d4b47e5bfb6162d711a6212e274db08.png",
        question: [
            {
                prompt: "What is the subject?",
                options: ["Option 1", "Option 2", "Option 3"],
                correctOption: "Option 1",
            },
            {
                prompt: "What is the Object?",
                options: ["Option 1", "Option 2", "Option 3"],
                correctOption: "Option 2",
            },
        ],
    },
    question10: {
        prompt: "Read the passage and answer the following questions",
        questions: [
            {
                question: "What is the subject?",
                options: ["Option 1", "Option 2", "Option 3"],
                correctOption: "Option 1",
            },
            {
                question: "What is the subject2?",
                options: ["Option 4", "Option 5", "Option 6"],
                correctOption: "Option 5",
            },
            {
                question: "What is the subject3?",
                options: ["Option 7", "Option 8", "Option 9"],
                correctOption: "Option 9",
            },
        ],
    },
    question11: {
        prompt: "Read these words that you saw earlier out loud and record yourself.",
        questions: [
            {
                question: "Again",
                options: ["Option 1", "Option 2", "Option 3"],
                correctOption: "Option 1",
            },
            {
                question: "About",
                options: ["Option 4", "Option 5", "Option 6"],
                correctOption: "Option 5",
            },
            {
                question: "Sofa",
                options: ["Option 7", "Option 8", "Option 9"],
                correctOption: "Option 9",
            },
        ],
    },

};

export const renderQuestions = (
    {
        q,
        setLock = (val) => {
        },
        lock,
        score,
        setScore = (val) => {
        },
        setQuestions = (values) => {
        },
        pause = () => {
        },
        start = () => {
        },
        auto = true
    }
) => {
    const qComponents = q.map((question) => {
        if (question.type === "1") {
            return (
                <Type1
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                    auto={auto}
                />
            );
        }
        if (question.type === "2") {
            return (
                <Type2
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                    pause={pause}
                    start={start}
                />
            );
        }
        if (question.type === "3") {
            return (
                <Type3
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                    auto={auto}
                />
            );
        }
        if (question.type === "4") {
            return (
                <Type4
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
        if (question.type === "5") {
            return (
                <Type5
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
        if (question.type === "6") {
            return (
                <Type6
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
        if (question.type === "7") {
            return (
                <Type7
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
        if (question.type === "8") {
            return (
                <Type8
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
        if (question.type === "9") {
            return (
                <Type9
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
        if (question.type === "10") {
            return (
                <Type10
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}

                />
            );
        }
        if (question.type === "11") {
            return (
                <Type11
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                    auto={auto}
                />
            );
        }

        if (question.type === "12") {
            return (
                <Type12
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }

        if (question.type === "13") {
            return (
                <Type13
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }

        if (question.type === "14") {
            return (
                <Type14
                    question={question.question}
                    key={question._id}
                    setLock={setLock}
                    lock={lock}
                    score={score}
                    setScore={setScore}
                />
            );
        }
    });
    setQuestions(qComponents);
};
