import React, {useEffect} from "react";
import {GET} from "../services";
import {paginationHelper} from "../utils";

const PER_PAGE = 5;
export default function useWeeklyContentStudent() {
    const [weeklyContent, setWeeklyContent] = React.useState([]);
    const [currentPageNum, setCurrentPageNum] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState([]);
    const [totalPages, setTotalPages] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const currentPageUpdate = () => {
        return paginationHelper(weeklyContent, PER_PAGE, currentPageNum);
    };

    useEffect(() => {
        Promise.resolve(() => {
            setLoading(true)
        }).then(() => GET('/students/weeklyContent')).then(({data}) => {
            setWeeklyContent(data.data);
            setCurrentPage(paginationHelper(data.data, PER_PAGE, currentPageNum));
            setTotalPages(Math.ceil(data.data.length / PER_PAGE));
            setLoading(false);
        }).catch(error=>console.log(error));
    }, []);

    useEffect(() => {
        setCurrentPage(currentPageUpdate());
    }, [currentPageNum]);

    return {
        weeklyContent,
        currentPage,
        setCurrentPageNum,
        totalPages,
        loading
    };
}
