import React from "react";
import "./common_button.css";
// import { LoadingCircle } from "../../../";
// import { LoadingRoundSvgIcon } from "../../../../assets/svg";
import { BeatLoader } from "react-spinners";

function CommonButton({
  text = "Button",
  primary,
  color,
  textColor,
  isLoading = false,
  disabled = false,
  click = () => {},
}) {
  return (
    <div
      disabled={disabled}
      onClick={click}
      className={
        primary
          ? "eap__button eap__shadow eap__button-primary"
          : "eap__button eap__shadow"
      }
      style={{
        backgroundColor: color,
        color: textColor,
        pointerEvents: disabled ? "none" : null,
      }}
    >
      {isLoading ? (
        // <div
        // style={{
        //     width: 21 + "px",
        //     height: 21 + "px",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        // }}
        // className=" loading"
        // >
        //     <LoadingRoundSvgIcon />
        <BeatLoader color="#fff" />
      ) : (
        // </div>
        text
      )}
      {/* <input type="submit" value={text}  /> */}
    </div>
  );
}

export default CommonButton;
