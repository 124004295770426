import React, {useEffect, useState} from "react";
import "./messenger_card.css";
import {TeacherMessengerCardItem} from "../..";
import {ScrollCardView} from "../../../layouts";
import {
    SchoolSvgIcon,
    ClassSvgIcon,
    GroupSvgIcon, PersonSvgIcon,
} from "../../../assets/svg/";
import {Link, useHistory} from "react-router-dom";
import {ChatAPI} from "../../../services";
import {BeatLoader} from "react-spinners";

function ManagementCard({href = "#"}) {
    const [loading, setLoading] = useState(true);
    const [recentChats, setRecentChats] = useState([]);
    const history = useHistory();

    useEffect(() => {
        Promise.resolve(() => setLoading(true))
            .then(() => ChatAPI.getRecent())
            .then(({data}) => {
                setRecentChats(data || []);
            }).finally(() => setLoading(false));
    }, []);

    const iconMapper = (fromRole) => {
        switch (fromRole) {
            case 1:
                return <PersonSvgIcon/>;
            case 2:
                return <PersonSvgIcon/>;
            case 3:
                return <ClassSvgIcon/>;
            case 4:
                return <GroupSvgIcon/>;
            case 5:
                return <SchoolSvgIcon/>;
            default:
                return <PersonSvgIcon/>;
        }
    }

    return (
        <div className="eap__management-card-container eap__shadow">
            <div className="eap__management-card-title">
                <span className="h5">Messenger</span>
                <div>
                    <a href={href}>View all &gt;</a>
                </div>
            </div>
            <div className="eap__management-body">
                {loading && <BeatLoader/>}
                {recentChats.length > 0 && (<ScrollCardView>
                    {
                        recentChats.slice(0, 6).map((el, index) => (
                            <div key={"message_card_" + index}>
                                <div onClick={() => history.push(href)}>
                                    <TeacherMessengerCardItem
                                        name={el.fromName} subject={el.content}
                                        timestamp={new Date(el.timestamp).toLocaleTimeString("en-AU")}
                                        svg={iconMapper(el.fromRole)}/></div>
                            </div>
                        ))
                    }

                </ScrollCardView>)}
                {recentChats.length < 1 && <div>No messages</div>}
            </div>
        </div>
    );
}

export default ManagementCard;
