import React from "react";
import styles from "./vertical_card.module.css";

import {
    YellowLogo,
    GreenLogo,
    AccessLogo,
    // YellowSpaceLogo,
    // GreenSpaceLogo,
    // AccessSpaceLogo,
} from "../../../../assets/landing";

import yellow from "../../../../assets/landing/eap_space_yellow.svg";
import green from "../../../../assets/landing/eap_space_green.svg";
import access from "../../../../assets/landing/eap_space_access.svg";

import yellowSrc from "../../../../assets/img/YELLOW.png";
import greenSrc from "../../../../assets/img/GREEN.png";
import accessSrc from "../../../../assets/img/ACCESS.png";

const textContent = [
    "Preparatory and functional mode to develop a foundation of academic English Skills",
    "A more advanced academic English level competence to further develop academic skills",
    "Specific techniques and strategies plus academic English skills targeted at students soon to be launching into a university environment",
];

function VerticalCard({index}) {
    const mapper = (index) => {
        switch (index) {
            case 0:
                // return <YellowLogo/>;
                return <img src={yellowSrc} alt={"eap yellow"} />;
            case 1:
                // return <GreenLogo/>;
                return <img src={greenSrc} alt={"eap green"} />;

            case 2:
                // return <AccessLogo/>;
                return <img src={accessSrc} alt={"eap access"} />;
            default:
                return null;
        }
    };

    const mapperSpace = (index) => {
        switch (index) {
            case 0:
                return <img src={yellow} alt=""/>;
            case 1:
                return <img src={green} alt=""/>;
            case 2:
                return <img src={access} alt=""/>;
            default:
                return null;
        }
    };
    return (
        <div className={styles.cardStyle}>
            <div className={styles.topC}>{mapper(index)}</div>
            <p>{textContent[index]}</p>
            <div className={styles.botLogo}>{mapperSpace(index)}</div>
        </div>
    );
}

export default VerticalCard;
