export const emitEvents = {
    authenticate: "auth",
    info: "msg",
    test: "test",
    chatInfoRequest: "chat request",
    chatMessage: "chat",
    broadcast: "broadcast",
    disconnect: "disconnect",
    toChannel: "to",
    toGroupChannel:"togroupchannel",
    chatChannel: "chat channel",
  };
  export const listenEvents = {
    info: "msg",
    authenticated: "authed",
    unauthenticated: "unauth",
    test: "test",
    chatInfoResponse: "chat info response",
    chatMessage: "chat",
    broadcast: "broadcast",
  };