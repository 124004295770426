import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter", // very important, would be used for selector
  initialState /* This object defines the initial state */: {
    value: 0,
  },
  reducers /* This object defines the functions to use */: {
    increment: (state) => {
      // have to put "state" into its parameter as a reference to its state
      state.value += 1; // Noticed this part can access "value" we defined from the state
    },

    decrement: (state) => {
      state.value -= 1;
    },

    incrementByAmount: (state, { payload }) => {
      /* Here it has to use "payload" to destructure the comming in value */
      state.value += payload;
    },
  },
});

export const { increment, decrement, incrementByAmount } 
/** Noticed here all those functions are from the actions */
= counterSlice.actions;

/** Little bit confused by the singular form "reducer" instead of plural form of "reducers" */
export default counterSlice.reducer;