import React from "react";

function QuestionOption({handler, label, index, selected}) {
    return (
        <div className={selected === index ? "p-2 bg-yellow-300 rounded-md" : "p-2 bg-gray-200 rounded-md"}>
      <button style={{textTransform: "none"}} onClick={() => handler(index)}> {label}</button>
    </div>
    );
}

export default QuestionOption;
