// import React from "react";

// function Loading() {
//   return <div>Loading...</div>;
// }

// export default Loading;

import React from "react";
import { ShimmerPostItem } from "react-shimmer-effects";
function Loading() {
  return (
    <div>
      <ShimmerPostItem card title cta />
    </div>
  );
}

export default Loading;
