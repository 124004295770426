import React from "react";
import backIcon from "../../../assets/img/back.png";
import { useHistory } from "react-router-dom";
function BackDiv({ moduleName = "No Data", week = {}, weekDesc = "" }) {
  const history = useHistory();
  return (
    <div className="flex flex-row w-ful h-40">
         <div className="w-1/5 flex justify-start items-start p-4">
        {/* <button onClick={() => window.history.back()}> */}
        <button onClick={() => history.goBack()}>
          <img src={backIcon} alt="go back" />
        </button>
      </div>
      <div className="w-3/5 flex justify-center items-center p-4 bg-blobs bg-left">
        <div className="w-1/2 flex flex-row text-5xl">{moduleName}</div>
        <div className="w-1/2 flex flex-col text-lg">
          {/*<span className="flex flex-row">*/}
            <span className="flex flex-row justify-start m-0">{week.weekName}</span>
            {/* <svg*/}
            {/*  class="w-6 h-6"*/}
            {/*  fill="none"*/}
            {/*  stroke="currentColor"*/}
            {/*  viewBox="0 0 24 24"*/}
            {/*  xmlns="http://www.w3.org/2000/svg"*/}
            {/*>*/}
            {/*  <path*/}
            {/*    stroke-linecap="round"*/}
            {/*    stroke-linejoin="round"*/}
            {/*    stroke-width="2"*/}
            {/*    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"*/}
            {/*  ></path>*/}
            {/*</svg>*/}
          {/*</span>*/}
          <span className="flex flex-row justify-start m-0 text-sm">
            {weekDesc}
          </span>
        </div>
      </div>
      <div className="w-1/5 flex justify-center items-center p-4"/>
    </div>
  );
}

export default BackDiv;
