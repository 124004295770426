import React, {useEffect} from 'react';
import {StudentAPI} from "../services";

const PER_PAGE = 6;

function useManageAllStudents() {
    const [loading, setLoading] = React.useState(true);
    const [students, setStudents] = React.useState([]);
    const [classes, setClasses] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [searchResults, setSearchResults] = React.useState([]);
    const [currentPageList, setCurrentPageList] = React.useState([]);
    const [selectedClassIdx, setSelectedClassIdx] = React.useState([]);
    const [selectedGroupIdx, setSelectedGroupIdx] = React.useState([]);
    const [keyword, setKeyword] = React.useState("");

    const getClassIds = () => [...selectedClassIdx].map((idx) => classes[idx]._id);
    const getGroupIds = () => [...selectedGroupIdx].map((idx) => groups[idx]._id);

    const filterClassHandler = (index) => {
        if (selectedClassIdx.includes(index)) {
            setSelectedClassIdx(selectedClassIdx.filter((idx) => idx !== index));
        } else {
            setSelectedClassIdx([...selectedClassIdx, index]);
        }
    };

    const filterGroupHandler = (index) => {
        if (selectedGroupIdx.includes(index)) {
            setSelectedGroupIdx(selectedGroupIdx.filter((idx) => idx !== index));
        } else {
            setSelectedGroupIdx([...selectedGroupIdx, index]);
        }
    }

    const resetSearchHandler = () => {
        setSearchResults(students);
        setCurrentPageList(paginationStudents(students));
        setCurrentPage(-1);
        setCurrentPage(1);
    }

    useEffect(() => {

        if (selectedClassIdx.length < 1 && selectedGroupIdx.length < 1) {
            return resetSearchHandler();
        }
        const filtered = students.filter((student) => {
            // console.log(student.classId);
            if (!student.classId) return false;
            return getClassIds().includes(student.classId._id)
        })
        setSearchResults([...filtered]);
        setCurrentPageList(paginationStudents([...filtered]))
        setCurrentPage(1);
        // console.log('classIdx triggered.', getClassIds(), students[0].classId._id);
    }, [selectedClassIdx]);

    useEffect(() => {

        if (selectedGroupIdx.length < 1 && selectedClassIdx.length < 1) {
            return resetSearchHandler();
        }
        const filtered = students.filter((student) => {
            // console.log(student.groupId);
            if (!student.groupId) return false;
            return [...getGroupIds()].some(id => student.groupId.includes(id));
        });
        setSearchResults([...filtered]);
        setCurrentPageList(paginationStudents([...filtered]))
        setCurrentPage(1);
        // console.log('groupIdx triggered.', getGroupIds(), students[0].groupId);
    }, [selectedGroupIdx]);

    useEffect(() => {
        if (keyword.length < 1) {
            return resetSearchHandler();
        }
        setSearchResults(students.filter((student) => student.studentName
            .toLowerCase()
            .includes(keyword.toLowerCase())));
        setCurrentPageList(paginationStudents(students.filter((student) => student.studentName
            .toLowerCase()
            .includes(keyword.toLowerCase()))));
    }, [keyword])


    useEffect(() => {
        Promise.resolve(() => setLoading(true)).then(() => StudentAPI.get()).then((res) => {
            initData(res);
            setLoading(false);
        });
    }, []);

    const initData = (res) => {
        setStudents(res.students || []);
        setSearchResults(res.students || []);
        setGroups(res.groups || []);
        setClasses(res.classes || []);
        setCurrentPageList(paginationStudents(res.students || []));
    }


    const initFilter = () => {
        setClasses([{className: "Class A", _id: "6198627c596d480cb2eae6b9"}, {
            className: "Not yet assigned",
            _id: ""
        },]);
        setGroups(["Group A", "Group B", "Group C", "Group D", "Group E"]);
    };

    useEffect(() => {
        initFilter();
    }, []);

    useEffect(() => {
        setCurrentPageList(paginationStudents(searchResults));
    }, [currentPage]);

    const paginationStudents = (source) => {
        const start = (currentPage - 1) * PER_PAGE;
        const end = currentPage * PER_PAGE;
        return source.slice(start, end);
    };
    return {
        loading,
        keyword, setKeyword,
        students, setStudents,
        classes, setClasses,
        groups, setGroups,
        currentPage, setCurrentPage,
        searchResults, setSearchResults,
        currentPageList, setCurrentPageList,
        selectedClassIdx, setSelectedClassIdx,
        selectedGroupIdx, setSelectedGroupIdx,
        getClassIds, getGroupIds,
        paginationStudents,
        filterClassHandler,
        filterGroupHandler,
        PER_PAGE
    }
}

export default useManageAllStudents;