import React, {useEffect, useState} from 'react';
import {useStopwatch} from "react-timer-hook";
import {ModuleAPI, TelemetryAPI} from "../services";
import {renderQuestions} from "../components/Student/Questions/QuestionWrapper/question";

function useModuleQuestionHandlerHooks({id, goBackHandler = () => {}}) {
    // loading flag
    const [loading, setLoading] = useState(true);

    // resume question Id
    const [resumeQuestionId, setResumeQuestionId] = useState(null);
    // module question
    const [questions, setQuestions] = useState([]);
    // module question refs
    const [questionsRef, setQuestionsRef] = useState([]);
    // module name
    const [moduleName, setModuleName] = useState('');
    // module id
    const [moduleId, setModuleId] = useState(id);

    // week id
    const [weekId, setWeekId] = useState('');
    // week percentage
    const [weekPercentage, setWeekPercentage] = useState(0);
    // week modules
    const [weekModules, setWeekModules] = useState([]);
    // current week number
    const [currentWeekNum, setCurrentWeekNum] = useState(1);
    // current week description
    const [currentWeekDesc, setCurrentWeekDesc] = useState('');
    // current question index
    const [current, setCurrent] = useState(0);

    // lock next button boolean flag
    const [lock, setLock] = useState(true);

    // count up timer
    const {
        seconds, minutes, hours, days, isRunning, start, pause, reset,
    } = useStopwatch({autoStart: false});
    // count mistakes
    const [score, setScore] = useState(0);

    //initialize
    useEffect(() => {
        Promise.resolve(() => {
            setLoading(true);
        })
            .then(() => ModuleAPI.getDetailById(id))
            .then(({data}) => {
                const {module, week} = data;
                initModule(module);
                initWeek(week);
            })
            .catch((err) => {
                console.log(`err > ${err}`)
            }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (resumeQuestionId) {
            // console.log("useEffect ", resumeQuestionId);
            const idx = questionsRef.map((el) => el._id).indexOf(resumeQuestionId);
            // console.log("useEffect ", idx);
            setCurrent(idx);
        }
    }, [resumeQuestionId]);

    const initModule = (module) => {
        // setModuleId(module._id);
        setModuleName(module.moduleName);
        setQuestionsRef([...module.questions]);
        setResumeQuestionId(module.resumeId || null);

        setCurrentWeekDesc(module.learningContent);
        // console.log(module.resumeId);
        renderQuestions({
            q: module.questions, setQuestions,
            setLock, lock,
            score, setScore,
            current, setCurrent,
            pause,
            start
        });
    }

    const initWeek = (week) => {
        setCurrentWeekNum(week.weekNumber);
        setWeekId(week._id);
        setWeekModules(week.modules);
        setWeekPercentage(week.percerntage);
    }

    const sendTelemetry = () => {
        console.log("invoke send telemetry...");
        const data = {
            score,
            timeTaken: days * 86400 + hours * 3600 + minutes * 60 + seconds,
            questionId: questionsRef[current]._id,
            moduleId,
            weekId,
            questionType: questionsRef[current].type
        };
        // console.log(data);
        reset();
        TelemetryAPI.sendTelemetry(data).then(res => {
            console.log("send to telemetry...", res);
        });
    }

    const gotoNextQuestion = () => {
        if (current + 1 === questions.length) {
            setCurrent(current + 1);
            return goBackHandler();
        }
        if (current + 1 > questions.length) {
            return; // outbound;
        }
        return setCurrent(current + 1);
    }

    const getCurrentQuestionProgress = () => {
        return Number(current / questions.length).toFixed(2);
    }

    const resetStatus = () => {
        setScore(0); // set mistakes to 0;
        setLock(true); // set lock to true;
        reset(); // reset timer;
    }

    const gotoNextQuestionHandler = () => {
        sendTelemetry();
        resetStatus();
        gotoNextQuestion();
    }

    const isCurrentQuestionListening = () => {
        const listening = ['3', '1'];
        // console.log('questions ref > ', questionsRef);
        // console.log('current ', current);
        // console.log('listening? ', result);
        return questionsRef[current] ? listening.includes(questionsRef[current].type) : false;
    }

    const getCurrentQuestionId = () => questionsRef[current] ? questionsRef[current]._id : "";

    const getCurrentQuestionType = () => questionsRef[current] ? questionsRef[current].type : "";

    return {
        weekModules,
        loading,
        moduleName,
        currentWeekNum,
        currentWeekDesc,
        weekPercentage,
        setLock,
        lock,
        score,
        setScore,
        questions,
        questionsRef,
        setQuestions,
        current,
        setCurrent,
        start,
        pause,
        reset,
        isCurrentQuestionListening,
        gotoNextQuestionHandler,
        getCurrentQuestionId,
        getCurrentQuestionType,
        getCurrentQuestionProgress
    }
}

export default useModuleQuestionHandlerHooks;