import React, {useState, useEffect} from "react";
import QuestionWithOptions from "../Option/QuestionWithOptions";

function Type5({
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
               }) {
    const [answer, setAnswer] = useState([]);
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(true);
    const [localScore, setLocalScore] = useState(0);

    useEffect(() => {
        setAnswer(question);
        setChecked(question.questions.map((q) => -1));
        setLoading(false);
    }, []);
    const checkAnswer = (option, indexOfQuestion) => {
        if (answer.questions[indexOfQuestion].correctOption === option) {
            // 1 correct, then
            const copy = [...checked];
            copy[indexOfQuestion] = 1;
            setChecked([...copy]);
        } else {
            // 2 incorrect , then
            incrementScore();
            const copy = [...checked];
            copy[indexOfQuestion] = 0;
            setChecked([...copy]);
        }
    };

    const incrementScore = () => {
        setLocalScore(localScore + 1);
        setScore(localScore + 1);
    }

    const giveBgColor = (index) => {
        switch (checked[index]) {
            case 1:
                return '#89B481';
            case 0:
                return '#E5B343';
            default:
                return '#f7f7f7';
        }
    }

    const checkFinished = () => {

        const allFinished = checked.every((el) => el === 1);
        console.log("answer questions >", allFinished, checked);
        setLock(!allFinished);

    }

    useEffect(() => {
        checkFinished()
    }, [checked]);

    return (
        <div>
            {loading === false ? (
                <>
                    <div className="w-full flex flex-row ">
                        <div className="w-1/12"/>
                        <div className="w-10/12 bg-white p-4 flex flex-col ">
                            <div>{question.prompt}</div>
                            <div className="flex flex-row w-full h-full divide-x">
                                <div className="flex flex-col w-1/2 p-2 space-y-5">
                                    {question.passage}
                                </div>

                                <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">
                                    {answer.questions.map((item, index) =>
                                        (<QuestionWithOptions
                                            key={index}
                                            prompt={item.prompt}
                                            options={item.options}
                                            index={index}
                                            checked={checked[index]}
                                            checkAnswer={checkAnswer}
                                            bgColor={giveBgColor(index)}
                                        />)
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="w-1/12"/>
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default Type5;
