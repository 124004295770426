import React from "react";
import "./create_btn.css";
import { ReactComponent as PlusIcon } from "../../../../assets/svg/plus_icon.svg";
import WideButtonWithIcon from "../../WideButtonWithIcon/WideButtonWithIcon";
import {Link} from "react-router-dom";

function CreateButton({ text, href = "/" }) {
  return (
     <Link to={href}> <WideButtonWithIcon text={text} svg={<PlusIcon />} /></Link>
  );
}

export default CreateButton;
