import React from 'react';
import styles from "./switch.module.css";

function Switch({
                    value = false,
                    click = (v) => {
                    }
                }) {

    return <div className={styles["switch"]}>
        <input id="switch-1" type="checkbox" value={value} onClick={(e) => click(e.target.value)}
               className={styles["switch-input"]}/>
        <label htmlFor="switch-1" className={styles["switch-label"]}>Switch</label>
    </div>
}

export default Switch;