import React from "react";
import {useEffect, useState} from "react";
import {BasicLayout} from "../../../layouts";
import {
    StudentContentBar as ContentBar,
    StudentContinueModule as Continue,
    HeaderBar,
    Slider,
    SubHeaderBar, Loading,
} from "../../../components";
import SimpleBar from "simplebar-react";
import {ProgressionAPI, VideoContentAPI} from "../../../services";
import MessengerCard from "../../../components/Teacher/MessengerCard/MessengerCard";
import {Announcement} from "../../../assets/svg";
import AnnouncementsCard from "../../../components/Teacher/AnnouncementsCard/AnnouncementsCard";
import CreativeVideoCard from "../../../components/Student/CreativeVideo/CreativeVideoCard";
import styles from './mainpage.module.css';

const cons = [
    {
        week: "Week 2",
        m: "Reading",
        msg: "Narrating a story spotting the signal",
    },
    {week: "Week 2", m: "Writing", msg: "Writing a story"},
    {week: "Week 2", m: "Listening", msg: "Listening to a story"},
];

function MainPage() {
    const [emods, setMods] = useState({
        current: null,
        past: null,
        currentWeek: "Week2",
        pastWeek: "Week1",
    });
    const [currentSliderMods, setCurrentSliderMods] = useState([]);
    const [currentMods, setCurrentMods] = useState([]);
    const [completedMods, setCompletedMods] = useState([]);
    const [videoContents, setVideoContents] = useState([]);


    useEffect(() => {
        ProgressionAPI.getLandingPage()
            .then((data) => {
                console.log(data);
                const current = data?.current ?? [];
                const past = data?.past ?? [];
                setMods({
                    current,
                    past,
                    currentWeek: data?.current[0]?.['week'] ?? "No data",
                    pastWeek: (data?.past && data?.past.length > 0) ? data?.past[0]['week'] : 'No data'
                });

                setCurrentMods(data?.current || []);
                setCompletedMods(data?.past || []);

                setCurrentSliderMods([...getSliderMods(data?.current || [])]);
            })
            .then(() => VideoContentAPI.getLanding())
            .then(({data}) => {
                setVideoContents(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getSliderMods = (current) => {
        return current.map((emod) => {
            return {
                week: `Week ${emod.week}`,
                m: emod.moduleName,
                msg: `Do questions about ${emod.moduleName}`,
                _id: emod._id
            }
        })
    }
    return (
        <BasicLayout subBar={<SubHeaderBar baseUrl="/student"/>}>
            <div
                className={styles.container}
                // className="flex flex-col space-y-10"

                style={{marginTop: 20 + "px", marginBottom: 50}}
            >
                <Slider mods={currentSliderMods}/>

                <div
                    className={styles.sub_container}
                >
                    <div

                        className={styles.left_panel}
                    >
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    gap: 20
                                }}
                            >

                                {emods.current ? (
                                    <Continue
                                        mods={currentMods}
                                        label="Continue Learning"
                                        future={true}
                                        week={emods.currentWeek}
                                    />
                                ) :<Loading />}
                                {emods.past ? (
                                    <Continue
                                        mods={completedMods}
                                        label="Completed Learning"
                                        future={false}
                                        week={emods.pastWeek}
                                    />
                                ): <Loading />}
                                {(videoContents && videoContents.length > 0) ? <CreativeVideoCard videoContents={videoContents} label={"Latest Creative Video"}/>

                                :<Loading />}
                            </div>
                        </div>

                    </div>

                    <div
                        className={styles.right_panel}
                        style={{gap: 20}}
                    >
                        <div>
                            <MessengerCard href={'/student/messenger'}/>
                        </div>
                        <div>
                            <AnnouncementsCard/>
                        </div>
                    </div>

                </div>


                {/* <ContentBar /> */}
            </div>
        </BasicLayout>
    );
}

export default MainPage;
