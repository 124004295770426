import React, {useEffect, useState} from "react";
import "./announcements.css";
import {ScrollCardView} from "../../../layouts";
import {SchoolSvgIcon} from "../../../assets/svg";
import {useHistory} from "react-router-dom";
import {AnnounceModal} from "../../index";
import {AnnouncementAPI} from "../../../services";
import {useAuth} from "../../../context";
import {BeatLoader} from "react-spinners";


function ManagementCard() {

    const {user} = useAuth();
    const [loading, setLoading] = useState(false);
    const [recentAnnouncements, setRecentAnnouncements] = useState([]);
    const [showAnnounceModal, setAnnounceModal] = useState(false);
    const history = useHistory();
    const gotoHandler = (url) => history.push(url);

    useEffect(() => {
        document.documentElement.style.pointerEvents = showAnnounceModal
            ? "none"
            : "auto";
        document.body.style.overflowY = showAnnounceModal ? "hidden" : "auto";
    }, [showAnnounceModal]);

    useEffect(() => {
        Promise.resolve(() => setLoading(true))
            .then(() => user.role === 1 ? AnnouncementAPI.forStudent() : AnnouncementAPI.forTeacher())
            .then(({data}) => {
                // console.log(data);
                setRecentAnnouncements([...processRecentAnnouncements(data)]);
            });
    }, []);

    const processRecentAnnouncements = (originAnnounces = []) => {
        // filter 7 days
        const untilDate = Date.now();
        const fromDate = new Date().setDate(new Date().getDate() - 7);

        const filtered = originAnnounces.filter((el) => {
            const time = new Date(el?.createAt).getTime();
            return time <= untilDate && time >= fromDate;
        });
        // return the most 5 ones
        return [...filtered.slice(0, 6)]
    }

    const badge = (content) => {
        return <span style={{
            borderRadius: 5,
            background: "#333",
            padding: "2px 5px",
            color: "#f7f7f7"
        }}>{content}</span>
    }

    const contentHandler = () => (<>
            {
                recentAnnouncements.length > 0 && recentAnnouncements
                    .map((el, idx) => (<div key={"ac_card_" + idx}

                                            className={"clickable"}
                                            style={{


                                                display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center'
                                            }}
                    >
                        <div className="eap__icon-item-wrapper eap__shadow">
                            <SchoolSvgIcon/>
                        </div>
                        <div>
                            <h1 className="announcement-title">{el.title}</h1>
                            <p>{el.message.substring(0, 30) + "..."}</p>
                        </div>
                    </div>))
            }
            {
                recentAnnouncements.length < 1 && <div>No recent announcements.</div>
            }
        </>
    );

    return (
        <>
            <div
                onClick={() => setAnnounceModal(true)}
                className="eap__management-card-container eap__shadow"
                style={{minHeight: 440 + "px"}}
            >
                <div className="eap__management-card-title">
                    <span className="h5">Announcements</span>

                </div>
                <ScrollCardView>
                    <div className="eap__management-body-announcements flex-container wrap">
                        {/* <div>View all &gt;</div> */}
                        {loading && <div><BeatLoader/></div>}
                        {!loading && contentHandler()}   </div>
                </ScrollCardView>

            </div>
            {showAnnounceModal && (
                <AnnounceModal close={() => setAnnounceModal(false)}/>
            )}
        </>);
}

export default ManagementCard;
