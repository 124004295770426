import React, {useState, useEffect} from "react";
import "./create_student.css";
import styles from './styles.module.css';
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {PersonSvgIcon} from "../../../assets/svg";
import {
    Button, PhoneTextField, TextField, Dropdown,
} from "../../../components";
import {useAuth} from "../../../context";
import {getCountryCodeByName} from "../../../utils";
import {PrincipalAPI, TeacherAPI} from "../../../services";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";

function EditTeacher() {
    const history = useHistory();
    const {user, showModal} = useAuth();
    const {id = ""} = useParams();
    const [imageFile, setImageFile] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [teacherName, setTeacherName] = useState("");
    const [teacherEmail, setTeacherEmail] = useState("");
    const [teacherPhone, setTeacherPhone] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");

    const countryCode = getCountryCodeByName(country);

    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload_student_avatar").click();
    };

    const teacherInfoHandler = () => {
        /**
         *
         {
    "studentName":"Test Student",
    "city":"Test S City",
    "country":"Test Country",
    "schoolId":"60c09abd6e1e360db761de97",
    "programId":"60c097b8b14f2e0d239c305e",
    "password":"testPassword",
    "email":"student@gmail.com",
    "mobile":"+61424341505"
    }
         */
        const info = {
            _id: id,
            teacherName: teacherName || "",
            city: city || "",
            country: country || "",
            // schoolId: user.schoolId._id,
            // programId: programList[programIndex]._id,
            // password: "testPassword",
            email: teacherEmail || "",
            mobile: `${countryCode}${teacherPhone}`,
        };
        return JSON.stringify(info);
    };

    const onFileSelected = (e) => {
        setImageFile(e.target.files[0]);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = function (evt) {
            // document.getElementById("uploadPreview").src = evt.target.result;
            setAvatarUrl(evt.target.result);
        };
    };


    const editHandler = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile, imageFile.name);
        }
        const info = teacherInfoHandler();
        formData.append("data", info);
        const response = await PrincipalAPI.editTeacher(formData);

        console.log(response);

        showModal(" ", response.msg || "Error", () => {
            // window.location.reload();
        }, false);
    };

    const deleteHandler = () => {
        showModal("Warning",
            `Really delete teacher ${teacherName}? This action can NOT be undone`, () => PrincipalAPI.deleteTeacher(id).then((data) => {
                showModal("Success", (data.msg), () => {
                }, false);
            }).then(() => history.goBack()),
            true);
    };

    useEffect(() => {
        TeacherAPI.query({_id: id})
            .then(({data = []}) => {
                setTeacherName(data?.[0]?.teacherName);
                setTeacherEmail(data?.[0]?.email);
                setTeacherPhone(data?.[0]?.mobile);
                setAvatarUrl(data?.[0]?.avatar);
            });
    }, []);

    return (<form onSubmit={editHandler}>
        <CreateLayout title="Edit Teacher Account" href="/principal">
            <div className="eap__create-student-main-panel-1">
                <div className="student-manage-elem1">
                    <div className="eap__create-student-avatar-wrapper eap__shadow ">
                        {(avatarUrl || imageFile) ? (<img
                            alt={"upload preview"}
                            id="uploadPreview"
                            style={{
                                width: 128 + "px", height: 128 + "px", // overflow: "hidden"
                                borderRadius: 128 + "px",
                            }}
                            className={styles['avatar']}
                            src={avatarUrl}
                        />) : (<PersonSvgIcon/>)}
                    </div>
                </div>
                <div className="student-manage-elem2">
                    <input
                        type="file"
                        id="upload_student_avatar"
                        onChange={onFileSelected}
                        hidden
                    />
                    <Button text="Upload Picture" click={uploadHandler}/>
                </div>
                <div className="student-manage-elem3">
                    <label>Name</label>
                </div>
                <div className="student-manage-elem4">
                    <TextField
                        required
                        value={teacherName}
                        inputHandler={(e) => setTeacherName(e.target.value)}
                    />
                </div>
                <div className="student-manage-elem5">
                    <label>Email</label>
                </div>
                <div className="student-manage-elem6">
                    <TextField
                        required
                        type="email"
                        value={teacherEmail}
                        inputHandler={(e) => setTeacherEmail(e.target.value)}
                    />
                </div>
                <div className="student-manage-elem7">
                    <label>Phone</label>
                </div>
                <div className="student-manage-elem8">
                    <PhoneTextField
                        required
                        value={teacherPhone}
                        inputHandler={(e) => setTeacherPhone(e.target.value)}
                        countryCode={countryCode}
                    />
                </div>
                <div className="student-manage-elem9">
                    <Dropdown
                        placeHolder={user ? user.country : "Select Country"} disabled/>
                </div>
                <div className="student-manage-elem10">
                    <Dropdown
                        placeHolder={user ? user.city : "Select City"} disabled/>
                </div>
                <div className="student-manage-elem11">
                    <Dropdown
                        placeHolder={user ? user.schoolId.schoolName : "Select School"} disabled/>
                </div>
                <div className="student-manage-elem12">
                    <Button text="Edit" color="#eabc7f" textColor="#ffffff" click={() => {
                        document.getElementById("create_student_btn").click();
                    }}/>
                    <input
                        type="submit"
                        id="create_student_btn"
                        value="Submit"
                        hidden
                    />
                </div>
                <div className="student-manage-elem13">
                    <Button text="Delete" color="#eabc7f" textColor="#ffffff" click={deleteHandler}/>
                </div>

                <div className="student-manage-program-dropdown">


                </div>


                <div
                    className="student-manage-elem14"
                >

                </div>

            </div>
        </CreateLayout>
    </form>);
}

export default EditTeacher;
