import React from "react";
import styles from "./bookmark.module.css";
import { BasicLayout, TeacherCreateLayout } from "../../layouts/";
import { useBookmarks } from "../../hooks";
import Loading from "../../components/Common/Placeholder/Loading/Loading";
import { Button } from "../../components";

function BookmarkPage() {
  const { questions, loading, removeBookmark, questionsRef } = useBookmarks();
  return (
    <TeacherCreateLayout title="Saved questions">
      <div style={{ padding: "4em 0" }}>
        {loading && <Loading />}
        {!loading && questions.length < 1 && (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              fontSize: 30,
            }}
          >
            Empty
          </div>
        )}
        {!loading &&
          questions.length > 0 &&
          questions.map((el, index) => (
            <div key={"bookmark_q_" + index} style={{ paddingBottom: "4em" }}>
              <div className={styles.container}>
                <div
                  style={{
                    position: "absolute",
                    top: "-2em",
                    left: 0,
                  }}
                >
                  {/*{questionsRef[index].displayName ?? "Question name"}*/}
                  <span style={{marginRight: "0.2em"}}>Question {index + 1}</span>
                  <a href={"#"} onClick={() => removeBookmark(el.key)}>
                    Remove
                  </a>
                </div>
                {el}
              </div>
            </div>
          ))}
      </div>
    </TeacherCreateLayout>
  );
}

export default BookmarkPage;
