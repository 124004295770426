import {POST, GET, DELETE} from "..";

export default class TeacherAPI {
    static async create(payload = new FormData()) {
        try {
            const {data} = await POST(`/teachers/create`, payload);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async get() {
        try {
            const {data} = await GET(`/teachers/get`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async query(query = {}) {
        try {
            const {data} = await POST(`/teachers/query`, query);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async getLanding() {
        try {
            const {data} = await GET(`/teachers/getLandingStudents`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async getInfoById(id) {
        try {
            const {data} = await GET(`/teachers/info/${id}`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async deleteById(id) {
        try {
            const {data} = await DELETE(`/teachers/delete/${id}`);
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }

    static async changePassword({currentPassword, newPassword}) {
        try {
            const {data} = await POST(`/teachers/changePassword`, {
                password: currentPassword,
                newPassword,
            });
            return data;
        } catch (err) {
            return {
                msg: err.message,
            };
        }
    }
}
