import React from "react";
import "./icon_button.module.css";
function WideButtonWithIcon({
  text = "Not set",
  svg,
  //   btnW = "291",
  iconW = "33",
  iconH = "33",
}) {
  return (
    <div className="eap__wide-btn-container">
      <div style={{ maxWidth: iconW + "px", maxHeight: iconH + "px" }}>
        {svg}
      </div>
      <div>
        <span>{text}</span>
      </div>
    </div>
  );
}

export default WideButtonWithIcon;
