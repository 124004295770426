import React from "react"

export function Catch(
    component,
    errorHandler) {
    return class extends React.Component {
        state = {
            error: undefined
        }

        static getDerivedStateFromError(error) {
            return {error}
        }

        componentDidCatch(error, info) {
            if (errorHandler) {
                errorHandler(error, info)
            }
        }

        render() {
            return component(this.props, this.state.error)
        }
    }
}

const ErrorBoundary = Catch(function ErrorBoundary(props, error) {
    if (error) {
        return (
            <div className="error-screen">
                <h2>An error has occurred</h2>
                <h4>{error.message}</h4>
                <a href={'/'}>Go Home Page</a>
            </div>
        )
    } else {
        return <React.Fragment>{props.children}</React.Fragment>
    }
});

export default ErrorBoundary;