import React, {useState, useEffect} from "react";
import "./teacher_main_page.css";

import {BasicLayout as TeacherPageLayout} from "../../../layouts";
import {SubHeaderBar as TeacherSubHeaderBar} from "../../../components";
import {
    TeacherStudentManagementCard as TeacherManagementCard,
    TeacherClassManagementCard as ClassManagementCard,
    TeacherGroupManagementCard as GroupManagementCard,
    TeacherMessengerCard as MessengerCard,
    TeacherAnnouncementCard as AnnouncementCard,
} from "../../../components";

import {TeacherAPI, StudentAPI, ClassAPI} from "../../../services";

function MainPage() {
    const [studentList, setStudentList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const asyncCall = async () => {
            setLoading(true);
            const response = await StudentAPI.get();
            const {groups = []} = response;
            const {data = []} = await TeacherAPI.getLanding();
            setStudentList(data);
            const classRes = await ClassAPI.getAll();
            console.log("class",classRes);
            setClassList(classRes.data);
            setGroupList(groups);
            setLoading(false);
        };
        asyncCall().then(console.log);
    }, []);
    return (
        <>
            <TeacherPageLayout
                subBar={<TeacherSubHeaderBar/>}
                className="main__container"
            >
                <div className="eap__management-container-row eap__management-container">
                    <div className="eap__management-container-col eap__landing-left">
                        <TeacherManagementCard
                            loading={loading}
                            studentList={[...studentList||[]]
                                .reverse()
                                .slice(0, 5)
                                .map((student, index) => {
                                    /** Try to get the 5 most recent created students */
                                    return {
                                        ...student,
                                        name: student.studentName,
                                        src: student.avatar ,
                                    };
                                })}
                            href="/teacher/manage/student/"
                        />
                        <ClassManagementCard
                            loading={loading}
                            classList={[...classList||[]].map((classes, index) => {
                                return {
                                    ...classes,
                                    name: classes.name,
                                    src: classes.avatar,
                                    href: classes._id,
                                };
                            })}
                        />
                        <GroupManagementCard
                            loading={loading}
                            groupList={[...groupList||[]].map((group, index) => {
                                return {
                                    ...group,
                                    name: group.name,
                                    src: group.avatar,
                                    href: group._id,
                                };
                            })}
                        />
                    </div>
                    <div className="eap__management-container-col eap__landing-right">
                        <MessengerCard href={'/teacher/messenger'}/>
                        <AnnouncementCard/>
                        {/* <TestElement text="3" /> */}
                    </div>
                </div>
            </TeacherPageLayout>
        </>
    );
}

export default MainPage;
