import React from 'react'
import styles from "./vertical_content.module.css";
import VerticalCard from "../Card/Vertical/VerticalCard";
import ImageCard from "../Card/Image/ImageCard";

function VerticalContent() {
    return (
        <div className={styles.withBackground}>
          <div className={styles.main}>
            <div className={styles.container}>
              <VerticalCard index={0} />
              <VerticalCard index={1} />
              <VerticalCard index={2} />
            </div>
          </div>
          <div className={styles.imageContainer}>
            <ImageCard index={0} />
            <ImageCard index={1} />
            <ImageCard index={2} />
          </div>
        </div>
      );
}

export default VerticalContent
