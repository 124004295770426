import React, { useEffect, useRef, useState } from "react";
import { emitEvents, listenEvents } from "../pages/MessengerPage/helper";
import { ChatAPI } from "../services";
import { io } from "socket.io-client/build/esm-debug";
import { socketURL } from "../config";
import { useAuth } from "../context";

function useChatHooks() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(""); // message to send
  const [toType, setToType] = useState(1); // type of the receiver
  const [messages, setMessages] = useState([]); // messages received
  const [searchKeyword, setSearchKeyword] = useState(""); // search keyword
  const [searchList, setSearchList] = useState([]); // list of users to search
  const [showSearchList, setShowSearchList] = useState(false); // show search list
  const [currentTo, setCurrentTo] = useState("all"); // current chat to
  const [client, setClient] = useState(null);
  const [individualChatList, setIndividualChatList] = useState([]);
  const [currentChatName, setCurrentChatName] = useState("My Chat");
  const [myClasses, setMyClasses] = useState([]);
  const [myGroups, setMyGroups] = useState([]);
  const [chatsId, setChatsId] = useState("");

  const scrollRef = useRef();
  const currentToRef = useRef("all");
  //   const chatsData = useMemo(() => fetchGroupChatById(chatsId), [chatsId]);
  useEffect(() => {
    initChat();
  }, []);

  useEffect(
    () => scrollRef.current?.scrollIntoView({ behavior: "smooth" }),
    [messages]
  );

  useEffect(() => {
    searchStudentHandler();
  }, [searchKeyword]);

  // useEffect(() => {
    //   console.log('refresh chats triggered enter')
  //     if (!currentToRef.current) return;
  //     if(currentToRef.current === 'all') return setMessages([ {from: "1", fromName: "EAP Space", content: "Welcome to messenger!"},]);
  //     fetchIndividualChatById(currentToRef.current);
    //   console.log('refresh chats triggered')
  // })
  // useEffect(() => {
    //   console.log('refresh chats triggered enter')
  //     if (!currentToRef.current) return;
  //     if(currentToRef.current === 'all') return setMessages([ {from: "1", fromName: "EAP Space", content: "Welcome to messenger!"},]);
  //     fetchIndividualChatById(currentToRef.current);
    //   console.log('refresh chats triggered')
  // }, [currentToRef.current, currentTo])

  /**
   *
   */
  const handleChangeChannel = (to) => {
    // console.log("to triggered > ", to);
    // 2. fetch previous chat in that channel
    fetchIndividualChatById(to);

    //TODO: fetch previous chat
    // 3. set current chat to that channel

    setCurrentTo(to);
    currentToRef.current = to;
    if (toType === 1 || toType === 2) {
      client.emit(emitEvents.toChannel, { from: user._id, to });
    } else {
      client.emit(emitEvents.toGroupChannel, { from: user._id, to });
    }
    // console.log("currentTo", currentToRef.current);
  };

  useEffect(() => {
    if (!currentTo || currentTo === "all") return;
    fetchIndividualChatById(currentTo);
  }, [currentTo]);

  const handleMessageReceived = (message) => {
    // console.log(message);
    // console.log(currentToRef.current, currentToRef.current === message.from);
    if (
      String(message.from).toLowerCase() !==
        String(currentToRef.current).toLowerCase() &&
      String(message.to).toLowerCase() !==
        String(currentToRef.current).toLowerCase()
    )
    //   return console.log("Not equal");
    setMessages((messages) => [...messages, message]);
  };

  const handleMessageChange = (e) => {
    setMessages(e.target.value);
  };
  const searchStudentHandler = () => {
    if (searchKeyword.length < 1) {
      return;
    }
    ChatAPI.searchStudentsByName(searchKeyword).then((res) => {
    //   console.log("search results", res);
      setSearchList([...(res.results ?? [])]);
    });
  };

  const initChat = () => {
    fetchIndividualChatList();
    const client = io(socketURL, {
      rejectUnauthorized: false,
      path: "/socket.io",
      transports: ["websocket"],
    });
    setClient(client);

    client.on("connect", () => {
    //   console.log("connected");
      client.emit(emitEvents.authenticate, { _id: user._id, role: user.role });
    });

    client.on("disconnect", () => {
    //   console.log("disconnected");
    });

    client.on(listenEvents.authenticated, () => {
    //   console.log("authenticated");
    });

    client.on(listenEvents.chatMessage, handleMessageReceived);
    client.on(listenEvents.broadcast, handleMessageReceived);
  };

  const handleMessageSend = () => {
    if (client && message) {
      client.emit(emitEvents.chatMessage, {
        from: user._id,
        fromRole: user.role,
        to: currentToRef.current || user._id,
        toType:
          toType /** 1 for student, 2 for teacher, 3 for class, 4 for group  5 for school */,
        contentType: 1 /** 1 for text, 2 for image */,
        content: message,
        fromName: user.studentName ?? user.teacherName,
        toName: currentChatName ?? currentToRef.current,
      });
      setMessages([...messages]);
      setMessage("");
    } else {
    //   console.log("not connected");
    }
  };

  const fetchIndividualChatList = () => {
    Promise.resolve(() => {
      setLoading(true);
    })
      .then(() => ChatAPI.getIndividualChatList())
      .then((data) => {
        // console.log(data);
        const list = data.data ? data.data : [];

        const processed = list.map((item) => {
          item._id = item.chattingWithId;
          item.name = item.chattingWithName;
          item.role = item.chattingWithRole;
          return item;
        });
        const filteredList = processed.filter((item) => item._id !== user._id);
        setIndividualChatList(filteredList);
        setLoading(false);
      });

    user.role === 1 &&
      ChatAPI.getStudentChatGroups().then(({ classes = [], groups = [] }) => {
        // console.log(classes);
        setMyClasses([...classes]);
        setMyGroups([...groups]);
      });

    user.role === 2 &&
      ChatAPI.getTeacherChatGroups().then(({ classes = [], groups = [] }) => {
        // console.log(classes);
        setMyClasses([...classes]);
        setMyGroups([...groups]);
      });
  };

  const sortByDate = (a, b) =>
    new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();

  const fetchGroupChatById = (id) => {
    Promise.resolve(() => setLoading(true))
      .then(() => ChatAPI.getGroupChatById(id))
      .then((res) => {
        setMessages([...res].sort(sortByDate));
        // console.log(res);
      })
      .then(() => setLoading(false));
  };

  const fetchIndividualChatById = (id) => {
    Promise.resolve(() => setLoading(true))
      .then(() => ChatAPI.getIndividualChatById(id))
      .then((res) => {
        setMessages([...res].sort(sortByDate));
        // console.log(res);
      })
      .then(() => setLoading(false));
  };

  const searchListChatHandler = (individual) => {
    const { _id, role, name } = individual;
    handleChangeChannel(_id);
    setCurrentChatName(name);
    setToType(role);
    // console.log(individualChatList);
    const filtered = individualChatList.filter((item) => {
      if (item._id !== _id) return item;
    });
    setIndividualChatList([]);
    setIndividualChatList([...filtered, individual]);
    console.table(individualChatList);
    setShowSearchList(false);
  };

  const fetchChatHandler = (item, type) => {
    //
    const to = item._id;
    const id = item._id;
    //
    Promise.resolve(() => {
      setLoading(true);
      setCurrentTo(to);

      currentToRef.current = to;
      if (toType === 1 || toType === 2) {
        client.emit(emitEvents.toChannel, { from: user._id, to });
      } else {
        client.emit(emitEvents.toGroupChannel, { from: user._id, to });
      }
    })
      .then(() => ChatAPI.getGroupChatById(id))
      .then((res) => {
        setMessages([...res].sort(sortByDate));
      })
      .then(() => {
        setToType(type);
        setCurrentChatName(item.name);
        setShowSearchList(false);
        setLoading(false);
      });
  };

  return {
    loading,
    message,
    setMessage,
    messages,
    setMessages,
    handleMessageReceived,
    handleMessageChange,
    handleChangeChannel,
    handleMessageSend,
    fetchIndividualChatList,
    fetchGroupChatById,
    fetchIndividualChatById,
    searchListChatHandler,
    toType,
    setToType,
    searchKeyword,
    setSearchKeyword,
    searchList,
    setSearchList,
    showSearchList,
    setShowSearchList,
    client,
    setClient,
    individualChatList,
    setIndividualChatList,
    currentChatName,
    setCurrentChatName,
    myClasses,
    setMyClasses,
    myGroups,
    setMyGroups,
    chatsId,
    setChatsId,
    // chatsData,
    scrollRef,
    currentToRef,
    fetchChatHandler,
  };
}

export default useChatHooks;
