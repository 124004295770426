import React, { useEffect, useRef, useState } from "react";
import Recorder from "recorder-js";
import Loading from "../../../../Common/Placeholder/Loading/Loading";
import styles from "../Type14/type14.module.css";
import { ReactComponent as PauseSvgIcon } from "../Type14/pause_speaking_icon.svg";
import { ReactComponent as RecordSvgIcon } from "../Type14/record_speaking_icon.svg";
import Simplebar from "simplebar-react"

function Type14({
  question,
  lock,
  setLock = (v) => {},
  score,
  setScore = (v) => {},
}) {
  const [answer, setAnswer] = useState([]);
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scoreLocal, setScoreLocal] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  // const [recordingStream, setRecordingStream] = useState(null);

  const [recorder, setRecorder] = useState(null);
  const [recordBlob, setRecordBlob] = useState(null);
  const [recordUrl, setRecordUrl] = useState(null);

  const [recordData, setRecordData] = useState([...Array(42)].fill(0));
  const [audioAnalyzer, setAudioAnalyzer] = useState(null);
  const [frequencyBandArr, setFrequencyBandArr] = useState([]);
  const [audioFile, setAudioFile] = useState(null);

  const audioRef = useRef(new Audio());
  const recordRef = useRef(new Audio());

  const [recordPlayStatus, setRecordPlayStatus] = useState(false);

  useEffect(() => {
    initAudioAnalyser();
    initRecorder();
    initRecordPlayer();
    setAnswer(question);
    setChecked(question.questions.map((q) => false));
    setLoading(false);
    setFrequencyBandArr([...Array(42).keys()]);
  }, []);

  const initRecordPlayer = function () {
    recordRef.current.onended = (ev) => {
      setRecordPlayStatus(false);
    };

    recordRef.current.onplaying = () => {
      setRecordPlayStatus(true);
    };
    recordRef.current.onpause = () => {
      setRecordPlayStatus(false);
    };
  };

  useEffect(() => {
    console.log(recordBlob);
    if (recordBlob) {
      setRecordUrl(URL.createObjectURL(recordBlob));
    }
  }, [recordBlob]);

  const playRecordedAudio = () => {
    if (!recordBlob || !recordUrl) return;
    initRecordPlayer();
    recordRef.current = new Audio(recordUrl);
    recordRef.current.play();
    setRecordPlayStatus(true);
  };

  const pauseRecordedAudio = () => {
    recordRef.current.pause();
    setRecordPlayStatus(false);
  };

  const initRecorder = () => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        // setRecordingStream(stream);

        const rec = new Recorder(audioCtx, {
          onAnalysed: (data) => {
            // console.log(data)
            setRecordData(data.data.slice(0, 42));
          },
        });
        setRecorder(rec);
        rec.init(stream);
      });
  };

  const initAudioAnalyser = () => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    audioRef.current.setAttribute("crossorigin", "anonymous");
    // audioRef.current.setAttribute('crossorigin', 'use-credentials');
    audioRef.current.setAttribute("id", "type11");
    audioRef.current.setAttribute("src", question.audioSrc);

    // console.log("audioRef current", audioRef.current);

    audioRef.current.onloadeddata = () => {
      const source = audioContext.createMediaElementSource(
        audioRef.current || ""
      );
      const analyser = audioContext.createAnalyser();

      analyser.fftSize = 128;
      source.connect(audioContext.destination || "");
      source.connect(analyser);

      setAudioFile(audioRef.current || "");
      setAudioAnalyzer(analyser);

      audioRef.current.ontimeupdate = () => {
        let amplitudeArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(amplitudeArray);
        setFrequencyBandArr([...amplitudeArray].slice(0, 42));
      };
    };
  };

  const startRecord = () => {
    console.log("Start recording...");
    recorder !== null && recorder.start().then(() => setIsRecording(true));
  };

  const stopRecord = () => {
    console.log("Stop recording.");
    recorder !== null &&
      recorder.stop().then(({ blob, buffer }) => {
        setIsRecording(false);
        setRecordBlob(blob);
      });
  };

  useEffect(() => {
    console.log("isRecording changed...");
    isRecording ? startRecord() : stopRecord();
    !isRecording && setLock(recordData === null);
  }, [isRecording]);

  useEffect(() => {}, [isRecording]);

  if (loading) return <Loading />;

  return (
    <div className="w-100">
      <div className={"px-2 pt-2 font-bold"}>{question.prompt ?? ""}</div>
      <div className="flex flex-row w-full h-full divide-x pt-4 space-x-5">
        <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
              // justifyContent: "space-between",
              gap: '1.5em',
            }}
          >
            <div className={"bg-gray-100 w-full p-3 rounded rounded-2 min-w-max"} >
                <Simplebar style={{minHeight:'13em', maxHeight:'13em', verticalAlign:'middle'}}>
                    <span className="font-normal">{question.script}</span>
                </Simplebar>
                
                </div>
          </div>
        </div>
        <div className="flex flex-col w-1/2 px-2 py-5 space-y-5 justify-center vertical-center">
          <div
            style={{
                paddingTop:'3em',
              display: "flex",
              gap: 10,
                flexDirection:'column',
              alignItems:'center'
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                transform: "translateY(-20px)",
                height: 50,
                overflow: "hidden",
              }}
            >
              {recordData &&
                [...recordData].map((el, idx) => (
                  <div
                    key={"wave_" + idx}
                    style={{
                      width: 2,
                      height: isRecording
                        ? (el + 1) * 1.5
                        : Math.abs(3 - (idx % 5)) * 10 + 10,
                      backgroundColor: "#706767",
                      borderRadius: 2,
                    }}
                  >
                    {""}
                  </div>
                ))}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flex: 0,
              }}
            >
              <button
                className={styles.play_btn}
                onClick={() => setIsRecording(!isRecording)}
                style={{ alignItems: "center" }}
              >
                {isRecording ? <PauseSvgIcon /> : <RecordSvgIcon />}
              </button>
              <div style={{ alignItems: "center" }}>
                {isRecording ? "Recording..." : "Click to record."}
              </div>
    
              {!recordPlayStatus && (
                <button
                  style={{
                    visibility: recordBlob && !isRecording ? null : "hidden",
                  }}
                  onClick={playRecordedAudio}
                >
                  {"Play"}
                </button>
              )}
              {recordPlayStatus && (
                <button
                  style={{
                    visibility: recordBlob && !isRecording ? null : "hidden",
                  }}
                  onClick={pauseRecordedAudio}
                >
                  {"Pause"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Type14;
