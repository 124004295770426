import React from "react";
import styles from "./footer.module.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {FacebookSvgIcon, YoutubeSvgIcon} from '../../../assets/svg';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.main}>
                    <div className={styles.first}>
                        Launch into Orbit with EAP space
                        <Link to={'/placement-test'}>
                            <button className={styles.btn}>Get Started</button>
                        </Link>
                    </div>
                    <div className={styles.second}>
                        <div className={styles.vert}>
                            More About EAP space
                            <ul className={styles.list}>
                                <li>
                                    <a href="https://www.origoeducationgroup.com/eap-space ">
                                        Team
                                    </a>
                                </li>
                                <li>
                                    <a href="#">Research</a>
                                </li>
                                <li>
                                    <a href="mailto:australia@origoeducationgroup.com?subject=EAP Space Enquiry">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.follow}>
                        <p className={styles.pLeft}>Follow Us</p>
                        <a
                            href="https://www.facebook.com/EAPSpace"
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px'
                            }}
                            className={styles.pLeft}
                        >
                            {/* <FontAwesomeIcon icon={faFacebook} color="white" size="2x" /> */}
                            <FacebookSvgIcon/>
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCuyI7HtgrQU3GWbl2WNv5UQ"
                            className={styles.pLeft}
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px'
                            }}

                        >
                            {/* <FontAwesomeIcon icon={faYoutube} color="white" size="2x" /> */}
                            <YoutubeSvgIcon/>
                        </a>
                    </div>
                    <div>
                        ©2021 by EAP Space , OEG Int. All rights reserved
                    </div>
                </div>
                <div className={styles.custom}>
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            className={styles.shape}
                        ></path>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default Footer;
