import React, {useEffect} from "react";

import {StudentQuestionWrapper} from "../../../../layouts";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../../context";
import {useModuleQuestions} from "../../../../hooks";
import {useHistory} from "react-router-dom";
import {BeatLoader} from "react-spinners";

function QuestionWrapper() {
    const history = useHistory();
    const {roleMapper, showModal} = useAuth();
    const {id} = useParams();
    const {
        loading,
        setLock,
        lock,
        questions,
        current,
        start,
        pause,
        moduleName,
        weekModules,
        weekPercentage,
        currentWeekNum,
        currentWeekDesc,
        gotoNextQuestionHandler,
        isCurrentQuestionListening,
        getCurrentQuestionType,
        getCurrentQuestionId,
        getCurrentQuestionProgress
    } = useModuleQuestions({
        id: id, goBackHandler: () => {
            showModal(" ", "Completed this module.", () => {
                history.push(`/${roleMapper()}`)
            }, false)
        }
    });

    useEffect(() => {
        start();
    }, []);

    return (<StudentQuestionWrapper
        title={moduleName}
        gotoNextHandler={gotoNextQuestionHandler}
        setLock={setLock}
        lock={lock}
        pause={pause}
        currentWeek={currentWeekNum}
        weekModules={weekModules}
        weekPercentage={weekPercentage}
        showNotePadBtn={isCurrentQuestionListening()}
        questionId={getCurrentQuestionId()}
        questionType={getCurrentQuestionType()}
        currentQuestionProgress={getCurrentQuestionProgress()}
        currentWeekDesc={currentWeekDesc}
    >
        {loading ? <BeatLoader/> : questions[current]}
    </StudentQuestionWrapper>)
}

export default QuestionWrapper;
