import {DELETE, GET, POST} from '../';

export default class AdminAPI {
    static async getPrograms() {
        try {
            const {data} = await GET(`/programs/getForAdmin`);
            return data;
        } catch (error) {
            return {
                msg: error.message
            };
        }
    }

    static async getSchools() {
        try {
            const {data} = await GET(`/admin/schools`);
            return data;
        } catch (error) {
            return {
                msg: error.message
            };
        }
    }

    static async getSchoolById(id) {
        try {
            const {data} = await GET(`/admin/schools/${id}`);
            return data;
        } catch (error) {
            return {
                msg: error.message
            };
        }
    }

    static async createSchool(payload = new FormData()) {
        try {
            const {data} = await POST(`/admin/createSchool`, payload);
            return data;
        } catch (error) {
            return {
                msg: error.message
            };
        }
    }

    static async editSchool(payload = new FormData()) {
        try {
            const {data} = await POST(`/admin/editSchool`, payload);
            return data;
        } catch (error) {
            return {
                msg: error.message
            };
        }
    }

    static async createPrincipal(payload = new FormData()) {
        try {
            const {data} = await POST(`/admin/createPrincipal`, payload);
            return data;
        } catch (error) {
            return {
                msg: error.message
            };
        }
    }

    static async getAllStudents() {
        try {
            const {data} = await POST('/admin/students/all');
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async getAllQuestions({page = 1, pagesize = 10}) {
        try {
            const {data} = await POST(`/admin/questions/all?page=${page}&pagesize=${pagesize}`);
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async createQuestion(newQuestion) {
        try {
            const {data} = await POST(`/admin/question/create`, {...newQuestion});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async updateQuestion(updateQuestion) {
        try {
            const {data} = await POST(`/admin/question/update`, {...updateQuestion});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async deleteQuestion({_id, moduleId}) {
        try {
            const {data} = await POST(`/admin/question/delete`, {_id, moduleId});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async getQuestionById({id = "", moduleId = ""}) {
        try {
            const {data} = await POST(`/admin/question/get`, {id, moduleId});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async getAllFiles() {
        try {
            const {data} = await GET(`/admin/file/all`);
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async uploadFile(payload = new FormData()) {
        try {
            const {data} = await POST(`/admin/file/upload`, payload);
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }
    static async deleteFile({key = ""}) {
        try {
            const {data} = await POST(`/admin/file/delete`, {key});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async forceChangeStudentPass({
                                            id, password
                                        }) {
        try {
            const {data} = await POST('/admin/student/changePassword', {id, password});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async forceChangeStudentEmail({
                                             id, email
                                         }) {
        try {
            const {data} = await POST('/admin/student/changeEmail', {id, email});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async forceChangeTeacherPass({
                                            id, password
                                        }) {
        try {
            const {data} = await POST('/admin/teacher/changePassword', {id, password});
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async getAllTeachers() {
        try {
            const {data} = await POST('/admin/teachers/all');
            return data
        } catch (error) {
            return {
                msg: error.message
            }
        }
    }

    static async disableSchool({
                                   id, isDisabled
                               }){
        try{
            const {data} = await POST('/admin/toggleSchool', {id, isDisabled});
            return data

        } catch (error){
            return{
                msg: error.message
            }
        }
    }
}