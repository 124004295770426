import React, {useState} from 'react';
import styles from './login.module.css';
import {AuthAPI} from "../../../services";
import {useAuth} from "../../../context";
import {useHistory} from "react-router-dom";

function AdminLoginPage(props) {
    const inputStyles = {
        borderRadius: 5,
        width: "100%",
    };

    const submitStyles = {
        borderRadius: 5,
        width: "100%",
        textIndent: 0
    };

    const history = useHistory();
    const {setUser} = useAuth();
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("");

    const submitHandler = (event) => {
        event.preventDefault();
        Promise.resolve()
            .then(() => AuthAPI.loginAdmin({
                username, password, errorHandler: setError
            }))
            .then((data) => {
                if (data?.admin === true) {
                    setUser(data || {});
                    setSuccess('Login Successfully');
                    return setTimeout(() => {
                        history.push('/admin');
                    }, 2000);
                }
                setError(data?.msg || "Error");
            });
    }

    return (
        <div className={styles['container']}>
            <form className={styles['form']}
                  onSubmit={submitHandler}>
                <h1 className={styles['title']}>Admin Login</h1>
                {error && <div className={styles['error-msg']}>{error}</div>}
                {success && <div className={styles['success-msg']}>{success}</div>}
                <input
                    className={styles['input-box']}
                    style={inputStyles} type={'text'}
                    required placeholder={'Username...'}
                    onInput={e=>setUserName(e.target?.value+"")}
                    value={username}/>
                <input
                    className={styles['input-box']}
                    style={inputStyles}
                    required placeholder={'Password...'} type={'password'}
                    onInput={e=>setPassword(e.target?.value+"")}
                    value={password}/>
                <div/>
                <input
                    className={styles['btn']}
                    style={submitStyles} type={'submit'} value={'LOGIN'}/>
            </form>
        </div>
    );
}

export default AdminLoginPage;