import React, {useEffect, useState} from "react";

import styles from "./subheaderbar.module.css";
import {
    WeeklyContent,
    Announcement,
    BookmarkSvgIcon,
    NoticeboardSvgIcon,
} from "../../../assets/svg";
import {AnnounceModal} from "../../../components";
import {IconWithRightText} from "../../";
import {useHistory} from "react-router-dom";

function SubHeaderBar({baseUrl = "/teacher"}) {
    const [showAnnounceModal, setAnnounceModal] = useState(false);
    const history = useHistory();
    const gotoHandler = (url) => history.push(url);
    useEffect(() => {
        document.documentElement.style.pointerEvents = showAnnounceModal
            ? "none"
            : "auto";
        document.body.style.overflowY = showAnnounceModal ? "hidden" : "auto";
    }, [showAnnounceModal]);
    return (
        <div className={styles["eap__subheader"]}>
            <div className={styles["eap__subheader-item-container"]}>
                <div className={styles["eap__subheader-item"]}

                     onClick={() => gotoHandler(`${baseUrl}/weekly-content/`)}>
                    <IconWithRightText

                        svg={<WeeklyContent/>}
                        iconW="36"
                        iconH="32"
                        text="Weekly Content"
                    />
                </div>
                <div className={styles["eap__subheader-item"]}
                     onClick={() => gotoHandler(`${baseUrl}/bookmark/`)}>
                    <IconWithRightText
                        svg={<BookmarkSvgIcon/>}
                        iconW="30"
                        iconH="30"
                        text="Bookmark"
                    />
                </div>
                <div className={styles["eap__subheader-item"]}
                >
                    <IconWithRightText
                        // href={`${baseUrl}/announcement/`}
                        handler={() => {
                            setAnnounceModal(true);
                        }}
                        svg={<Announcement/>}
                        iconW="30"
                        iconH="33"
                        text="Announcements"
                    />
                </div>
                <div className={styles["eap__subheader-item"]}
                     onClick={() =>
                         window.open('https://www.onestudyspace.com/eapspacecollbrativeblog', '_blank')}>
                    <IconWithRightText
                        svg={<NoticeboardSvgIcon/>}
                        iconW="30"
                        iconH="33"
                        text="Notice Board"
                    />
                </div>
            </div>
            {showAnnounceModal && (
                <AnnounceModal close={() => setAnnounceModal(false)}/>
            )}
        </div>
    );
}

export default SubHeaderBar;
