import React, {useEffect} from "react";
import styles from "./manage_students.module.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {WideButtonWithIcon, SearchBox, Pagination, TeacherStudentManagementItem, Loading} from "../../../components";
import {TeacherStudentInfoItem as StudentInfoItem} from "../../../components";
import {PlusSvgIcon, EditSvgIcon} from "../../../assets/svg";
import {Link} from "react-router-dom";
import {StudentAPI} from "../../../services";
import {current} from "immer";
import personLogoSrc from "../../../assets/svg/person_icon.svg";
import SimpleBar from "simplebar-react";
import {useManageAllStudents} from "../../../hooks";

const PER_PAGE = 6;

function ManageStudent() {

    const {
        students, setStudents,
        classes, setClasses,
        groups, setGroups,
        currentPage, setCurrentPage,
        searchResults, setSearchResults,
        currentPageList, setCurrentPageList,
        selectedClassIdx, setSelectedClassIdx,
        selectedGroupIdx, setSelectedGroupIdx,
        getClassIds, getGroupIds,
        filterClassHandler,
        filterGroupHandler,
        paginationStudents,
        keyword,
        setKeyword,
        loading
    } = useManageAllStudents();

    return (<CreateLayout title="Student Management" href="/teacher">
        {loading ? <Loading/>
         : <div className={styles.container}>
            <div className={styles.btn1}>
                {" "}
                <Link to="/teacher/create/student">
                    <WideButtonWithIcon
                        text="Create student account"
                        svg={<PlusSvgIcon/>}
                    />
                </Link>
            </div>
            <div className={styles.btn2}>
                {" "}
                <Link to="/teacher/create/class">
                    <WideButtonWithIcon text="Create class" svg={<PlusSvgIcon/>}/>
                </Link>
            </div>
            <div className={styles.btn3}>
                {" "}
                <Link to="/teacher/create/group">
                    <WideButtonWithIcon text="Create group" svg={<PlusSvgIcon/>}/>
                </Link>
            </div>
            <div id="search-box-manage-students" className={styles.searchbar}>
                <SearchBox
                    placeholder="Search student"
                    inputHandler={(e) => setKeyword(e.target.value)}
                />
            </div>
            <div className={styles.filters}>
                <table style={{height: 100 + "%"}}>
                    <tbody>
                    <tr>
                        <td>Class</td>
                        <td
                            width="100%"
                        >
                            <SimpleBar style={{maxWidth: 100 + "%"}}>{" "}
                                <div style={{
                                    display: "flex", alignItems: "center", height: "100%", justifyContent: "flex-start",
                                }}>
                                    {classes.map((item, index) => (<div
                                        key={'_class_' + index}
                                        onClick={() => filterClassHandler(index)}
                                        className={styles.filter_class}
                                        style={{
                                            backgroundColor: selectedClassIdx.includes(index) ? "#628290" : "",
                                            color: selectedClassIdx.includes(index) ? "white" : "",
                                        }}
                                    >
                                        {item.name ?? item.className}
                                    </div>))}{" "}
                                </div>
                                {" "}
                            </SimpleBar>
                        </td>
                    </tr>
                    <tr>
                        <td>Group</td>
                        <td
                            width="100%"
                        >
                            <SimpleBar style={{maxWidth: 100 + "%"}}>{" "}
                                <div style={{
                                    display: "flex", alignItems: "center", height: "100%", justifyContent: "flex-start",
                                }}>
                                    {groups.map((item, index) => (<div
                                        key={'_group_' + index}
                                        onClick={() => filterGroupHandler(index)}
                                        className={styles.filter_group}
                                        style={{
                                            backgroundColor: selectedGroupIdx.includes(index) ? "#628290" : "",
                                            color: selectedGroupIdx.includes(index) ? "white" : "",
                                        }}
                                    >
                                        {item.name ?? item.groupName}
                                    </div>))}{" "}
                                </div>
                                {" "}
                            </SimpleBar>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className={styles.line}>{""}</div>
            </div>
            <label className={styles.search_outcome_label}>
                Searching outcome
                "{keyword && keyword + ", "}{(selectedClassIdx.map(elem => classes[elem].name + ", "))}{(selectedGroupIdx.map(elem => groups[elem].name + ", "))}"
            </label>
            <div className={styles.search_outcome}>
                {currentPageList.map((student) => (
                    <div key={student._id}>
                        <TeacherStudentManagementItem student={student} pKey={student._id}/></div>))}
                {/* <div className={styles.edit_btn}>
            <div className={styles.edit_btn_body}>
              <EditSvgIcon />
              edit
            </div>
          </div> */}
            </div>
            <div className={styles.pagination}>
                <Pagination
                    min={1}
                    max={Math.ceil(students.length / PER_PAGE)}
                    setPage={setCurrentPage}
                />{" "}
            </div>
        </div>}
    </CreateLayout>);
}

export default ManageStudent;
