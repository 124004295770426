import React, { useEffect, useState } from "react";
import { TeacherCreateLayout } from "../../../layouts";
import { PersonSvgIcon } from "../../../assets/svg";
import styles from "./profile.module.css";
import { useAuth } from "../../../context";
import { FillTextField, PercentDonut, Button } from "../../../components";
import { useParams, useHistory } from "react-router-dom";
import { StudentAPI } from "../../../services";
import { donatChart, radarChart, originDataSet } from './chart.helper'

// added dropdwon arrow  and beatloader for Student Score
import { ReactComponent as SmallDropdownArrow } from "./small_down_arrow_icon.svg";
import { BeatLoader } from "react-spinners";
import { mapperStudentEvaluation } from "../../../utils";

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

Chart.register(ArcElement, LineElement, BarElement, PointElement, BarController,
    BubbleController, DoughnutController, LineController, PieController,
    PolarAreaController, RadarController, ScatterController, CategoryScale,
    LinearScale, LogarithmicScale, RadialLinearScale, TimeScale, TimeSeriesScale,
    Decimation, Filler, Legend, Title, Tooltip, SubTitle);

function ProfilePage() {
    const { id } = useParams();
    const [rawStudent, setRawStudent] = React.useState({});
    const history = useHistory();
    const { user, showModal } = useAuth();
    const [name, setName] = React.useState("Student Name");
    const [mobile, setMobile] = React.useState("4123123123");
    const [email, setEmail] = React.useState("student@email.com");
    const [avatar, setAvatar] = React.useState("");
    const [weeklyProgress, setWeeklyProgress] = React.useState({});

    // displaying the student score week count
    const [performance, setPerformance] = useState([]);
    const [performanceWeekCounts, setPWeekCounts] = useState(1);
    const [performanceWeek, setPerformanceWeek] = useState(1);

    const [showWeekDropdown, setShowWeekDropdown] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const [timeSpend, setTimeSpend] = React.useState([{
        timeSpend: "Writing",
        val: 5,
        color: "#E46262"
    }, { timeSpend: "Grammar", val: 5, color: "#6FB8D5" }, {
        timeSpend: "Review",
        val: 5,
        color: "#3A9C6E"
    }, { timeSpend: "Vocabulary", val: 5, color: "#F27F4E" }, {
        timeSpend: "Reading",
        val: 25,
        color: "#516CBE"
    }, { timeSpend: "Listening", val: 22, color: "#8BC370" }, {
        timeSpend: "Speaking", val: 3, color: "#F0C055"
    },]);
    const [className, setClassName] = React.useState(user.className ?? "Class Name");
    const [schoolName, setSchoolName] = React.useState(user.schoolName ?? "School Name");
    const [editMode, setEditMode] = React.useState(false);
    const [imageFile, setImageFile] = React.useState(null);

    const onFileSelected = (e) => {
        setImageFile(e.target.files[0]);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = function (evt) {
            document.getElementById("uploadPreview").src = evt.target.result;
        };
    };

    const renderChart = () => {
        const dataSet = timeSpend.map((item) => item.val);
        const backgroundColors = timeSpend.map((item) => item.color);

        const ctx = document.getElementById("time_pie_chart");
        /** const myChart = */ new Chart(ctx, {
            type: "doughnut", data: {
                datasets: [{
                    label: "My First Dataset", data: [...dataSet], backgroundColor: [...backgroundColors],

                    hoverOffset: 4,
                },],
            }, options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                    },],
                },
            },
        });
    };

    const progressWords = () => {
        // TODO: test optimize readibility
        const process = Math.round((weeklyProgress.questionsCompletedForWeek / weeklyProgress.questionsIncurrentWeek) * 100);
        if (process < 20) {
            return <span style={{ color: "red" }}> Far behind</span>;
        } else if (process < 40) {
            return "Behind schedule";
        } else if (process < 60) {
            return "Slightly behind";
        } else if (process < 80) {
            return "Good";
        } else {
            return <span style={{ color: "green" }}> Excellent</span>;
        }
    };

    const [currentWeek, setCurrentWeek] = useState(1);
    const [completedNum, setCompletedNum] = useState(0);
    const [totalNum, setTotalNum] = useState(1);
    const [progressDesc, setProgressDesc] = useState("");

    const [currentClassWeek, setCurrentClassWeek] = useState(1);
    const [totalClassWeek, setTotalClassWeek] = useState(1);

    useEffect(() => {
        StudentAPI.getInfoById(id).then(({ student = {}, stats = {} }) => {

            console.log(student, stats);
            setRawStudent(student || {});
            setName(student?.studentName ?? "Anonymous");
            setMobile(student?.mobile ?? "Not Found");
            setEmail(student?.email ?? "Not Found");
            setAvatar(student?.avatar);
            setClassName(student?.class ?? "Not Assigned");
            setSchoolName(student?.school ?? "Student School Name");
            setWeeklyProgress(student?.weeklyProgress);

            setCurrentClassWeek(student.classCurrentWeek ?? 1);
            setTotalClassWeek(student.totalWeeks ?? 1);

            const { weeklyProgress } = student;
            setCurrentWeek(weeklyProgress.currentWeek ?? 1);
            setProgressDesc(weeklyProgress.evaluation ?? "No data");
            setCompletedNum(weeklyProgress.questionsCompletedForWeek ?? 100);
            setTotalNum(weeklyProgress.questionIncurrentWeek ?? 100);
            const map = {};

            console.log(stats.data);
            for (const item of stats.data) {
                map[item._id] = item.totalTime;
            }

            const processed = timeSpend.map((item) => {
                item.val = map[item.timeSpend];
                return item;
            });
            setTimeSpend(processed);

            renderChart();
        });


        Promise.resolve(() => setLoading(true)).then(() => StudentAPI.getPerformance(user._id, 1)).then(({
            data,
            numberOfWeeks
        }) => {
            // console.log(data, numberOfWeeks);
            setPWeekCounts(numberOfWeeks);
            initPerformance(data);
        }).finally(() => setLoading(false));

    }, []);



    useEffect(() => {
        Promise.resolve(() => setLoading(true))
            .then(() => StudentAPI.getPerformance(id, performanceWeek))
            .then(({ data, numberOfWeeks }) => {
                // console.log(data, numberOfWeeks);
                initPerformance(data);
            }).finally(() => setLoading(false));
    }, [performanceWeek]);



    const initPerformance = (performance) => {
        setPerformance(performance || []);
        radarChart(performance || []);
        // donatChart(performance);
        console.log(performance)
    }

    const initStats = (stats = []) => {
        // stats = stats || [];
        const dataArray = stats.data || [];
        // const processed = [...stats.data].map((el) => {
        const processed = [...dataArray].map((el) => {
            return {
                moduleName: el._id,
                percentage: Number(el.totalTime / 60).toFixed(2)
            }
        })
        donatChart(processed);
    }

    const studentInfoHandler = () => {
        const info = {
            studentName: name,
            city: rawStudent.city,
            country: rawStudent.country, // schoolId: rawStudent.schoolId,
            email: email, mobile: mobile, id: id,
        };

        return JSON.stringify(info);
    };

    const editHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile, imageFile.name);
        }
        const info = studentInfoHandler();
        formData.append("data", info);

        for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
        }
        const response = await StudentAPI.edit(formData);
        console.log(response);
        showModal(" ", response.msg, () => {
            window.location.reload();
        }, false);
    };

    return (<form onSubmit={editHandler}>
        <input type="submit" hidden id="edit-submit" />
        <TeacherCreateLayout title="Student profile">
            <div className={styles.container}>
                <div className={styles.avatar}>
                    <div className={styles.avatar_container}>
                        <div
                            className={styles.avatar_holder}
                            onClick={editMode ? () => {
                                document.getElementById("upload").click();
                            } : () => {
                            }}
                        >
                            <input type="file" id="upload" onChange={onFileSelected} />
                            <img
                                id="uploadPreview"
                                src={avatar}
                                alt="portait"
                                onError={(e) => (e.target.src = "/img/broken-image.png")}
                            />
                            {/* <PersonSvgIcon /> */}
                        </div>
                        {" "}
                        <div className={styles.name}>{name}</div>
                    </div>
                </div>
                <label className={styles.phone_label}>Phone(ID)</label>
                <div className={styles.phone}>
                    <FillTextField
                        value={mobile}
                        readOnly={!editMode}
                        onInput={editMode ? (e) => setMobile(e.target.value) : () => {
                        }}
                    />{" "}
                </div>
                <label className={styles.email_label}>Email</label>
                <div className={styles.email}>
                    {" "}
                    <FillTextField
                        value={email}
                        readOnly={!editMode}
                        onInput={editMode ? (e) => setEmail(e.target.value) : () => {
                        }}
                    />
                </div>
                <label className={styles.class_label}>Class</label>
                <div className={styles.class_name}>
                    {" "}
                    <FillTextField
                        value={className}
                        readOnly={!editMode}
                        onInput={editMode ? (e) => setClassName(e.target.value) : () => {
                        }}
                    />
                </div>
                <label className={styles.school_label}>School</label>
                <div className={styles.school_name}>
                    {" "}
                    <FillTextField value={schoolName} readOnly={!editMode} />
                </div>
                {/*<label className={styles.change_pass_label}></label>*/}
                <label className={styles.current_pass_label}>
                    <Button
                        color="#1B3D4D"
                        textColor="#fff"
                        text="Message"
                        click={() => {
                            showModal(" ", "Sending message?", () => {
                                //TODO: send student message

                            }, false);
                        }}
                    />
                    <Button
                        color="#1B3D4D"
                        textColor="#fff"
                        text="Send student handbook"
                        click={() => {
                            showModal(" ", `Sending student handbook to "(${email})"?`, () => {
                                //TODO: send student handbook
                                StudentAPI.sendHandbook({
                                    studentIds: [rawStudent._id]
                                }).then(console.log);
                            }, false);
                        }}
                    />
                    <Button
                        color="#1B3D4D"
                        textColor="#fff"
                        text="Send login details"
                        click={() => {
                            showModal(" ", `Sending student login details to "(${email})"?`, () => {
                                //TODO: send student handbook
                                StudentAPI.sendLoginDetails({
                                    studentIds: [rawStudent._id]
                                }).then(console.log);
                            }, false);
                        }}
                    />
                </label>

                <label className={styles.new_pass_label}>
                    {editMode ? (<Button
                        color="#EABC7F"
                        textColor="#fff"
                        text="Done"
                        click={() => {
                            showModal(" ", "Submit edit?", () => {
                                document.getElementById("edit-submit").click();
                                setEditMode(false);
                            }, false)
                        }}
                    />) : (<Button
                        color="#EABC7F"
                        textColor="#fff"
                        text="Edit"
                        click={() => {
                            showModal(" ", "Enable edit mode?", () => {
                                setEditMode(true);
                            }, false)
                        }}
                    />)}
                    <Button
                        color="#EABC7F"
                        textColor="#fff"
                        text="Delete"
                        click={() => {
                            showModal("Delete student", "Are you sure you want to delete this student?", () => {
                                StudentAPI.deleteById(id).then(() => {
                                    history.goBack();
                                });
                            }, true);
                        }}
                    />
                </label>

                <label className={styles.class_overview_label}>Time spent</label>
                <label className={styles.class_overview_label_1}>
                    Reflecting your time working on each content section.
                </label>
                <label className={styles.student_progression_label}>
                    Student progression
                </label>
                <label className={styles.student_score_label}>Student score</label>
                <div className={styles.class_overview}>{""}</div>
                <div className={styles.time_spend}>
                    <div style={{ display: "flex" }}>
                        <canvas
                            id="time_pie_chart"
                            style={{ width: 180 + "px" }}
                        >{""}</canvas>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexFlow: "column wrap-reverse",
                            gap: 15 + "px",
                            columnGap: "85px",
                            height: "230px",
                            padding: "20px 0",
                        }}
                    >
                        {[...timeSpend].map((item, index) => (<div

                            key={"_time_spend_" + index}
                            style={{ display: "flex", alignItems: "center", gap: "20px" }}
                        >
                            <div
                                style={{
                                    width: 35 + "px",
                                    height: 35 + "px",
                                    backgroundColor: item.color,
                                    borderRadius: "10px",
                                }}
                            />
                            {" "}
                            <span>{item.timeSpend}</span>
                        </div>))}
                    </div>
                </div>
                <div className={styles.student_progression}>
                    <div className={styles.progress1}>
                        Student progression:{"    "}
                        <span style={{ color: "#FCB216", marginLeft: "10%" }}>
                            Week {currentWeek}
                        </span>
                    </div>
                    <div className={styles.progress2}>
                        Current class progression: Week {currentClassWeek}/{totalClassWeek}
                    </div>
                    <div className={styles.progress_line} />
                    <div className={styles.progress3}>
                        Weekly learning progression:{" "}
                        <PercentDonut
                            percent={Math.floor((completedNum / totalNum) * 100)}
                        />
                    </div>
                    <div className={styles.progress4}> {progressDesc}</div>
                </div>

                <div className={styles.student_score}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: 'flex', gap: 50 }}>
                            <div>
                                WEEK {performanceWeek}
                            </div>
                            <div style={{ position: "relative" }}>
                                <div onClick={() => setShowWeekDropdown(!showWeekDropdown)}><SmallDropdownArrow /></div>
                                {showWeekDropdown && <div
                                    className={styles.week_dropdown_item_list}
                                >
                                    {[...new Array(performanceWeekCounts)]
                                        .map((el, idx) => (<div key={"week_drop_" + idx}
                                            className={styles.week_dropdown_item}

                                            onClick={() => {
                                                setPerformanceWeek(idx + 1);
                                                setShowWeekDropdown(false);
                                            }}
                                        >Week {idx + 1}</div>))}
                                </div>}
                            </div>
                        </div>

                        <div style={{ paddingRight: "100px" }}>
                            {!loading && <canvas id={'radar_chart'} style={{ width: 211 + "px", height: "321px" }} />}
                            {loading && <BeatLoader />}
                        </div>
                    </div>

                    <div style={{ borderBottom: "1px solid #000", width: '100%', margin: "50px 0" }} />

                    <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%" }}>
                        {performance.map((t, index) => (<details key={index + t.moduleName}>
                            <summary style={{ position: "relative" }}>

                                <span>
                                    {(t.moduleName)}
                                </span>

                                <span style={{
                                    color: mapperStudentEvaluation(t.evaluation).color,
                                    position: "absolute",
                                    left: 20 + "%"
                                }}>{mapperStudentEvaluation(t.evaluation).msg}</span>
                            </summary>
                            <div style={{ marginBottom: "1em" }}><p style={{ marginTop: "1em" }}> {t.comment}</p>
                                <p style={{ marginTop: "1em" }}>{t.longComment}</p></div>
                        </details>))}
                    </div>

                </div>
            </div>
        </TeacherCreateLayout>
    </form>);
}

export default ProfilePage;
