import React, {useState, useEffect} from "react";
import {StudentAssetViewer as AssetViewer} from "../../../../";
import QuestionWithOptions from "../Option/QuestionWithOptions";

function Type6({
                   question, setLock = () => {
    }, lock, score, setScore = () => {
    }
               }) {
    const [answer, setAnswer] = useState([]);
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(true);
    const [scoreLocal, setScoreLocal] = useState(0);
    useEffect(() => {
        setAnswer(question);
        setChecked(question.question.map((q) => false));
        setLoading(false);
    }, []);
    const handleLock = (copy) => {
        // let flag = false;
        // for (const x of copy) {
        //     if (x === false) flag = true;
        // }
        const flag = copy.every(el => el === 1);
        console.log("flag > ", flag);
        setLock(!flag);
    };
    const checkAnswer = (option, indexOfQuestion) => {
        if (lock === false) return;
        const copy = [...checked];
        if (answer.question[indexOfQuestion].correctOption === option) {

            copy[indexOfQuestion] = 1;
            setChecked(copy);

        } else {

            copy[indexOfQuestion] = 0;
            setChecked(copy);
            setScoreLocal(scoreLocal + 1);
            setScore(scoreLocal + 1);
        }
        handleLock(copy);
    };

    const getBgColor = (index) => {
        switch (checked[index]) {
            case 1:
                return "#89B481";
            case 0:
                return "#E5B343";
            default :
                return "#f7f7f7";
        }
    }


    return (<div>
        {loading === false ? (<>
            <div className="w-full flex flex-row ">
                <div className="w-1/12">{""}</div>
                <div className="w-10/12 bg-white p-4 flex flex-col ">
                    <div>{question.prompt}</div>
                    <div className="flex flex-row w-full h-full divide-x">
                        <div className="flex flex-col w-1/2 h-full p-2 space-y-5 align-middle">
                            <AssetViewer url={question.asset}/>
                        </div>

                        <div className="flex flex-col w-1/2 p-2 space-y-5 justify-center">
                            {answer.question.map((item, index) => {
                                return (<QuestionWithOptions
                                    key={index}
                                    prompt={item.prompt}
                                    options={item.options}
                                    index={index}
                                    checked={checked[index]}
                                    checkAnswer={checkAnswer}
                                    bgColor={getBgColor(index)}
                                />);
                            })}
                        </div>
                    </div>
                </div>
                <div className="w-1/12">{""}</div>
            </div>
        </>) : null}
    </div>);
}

export default Type6;
