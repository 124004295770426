import React, { useState } from "react";
import { MessengerChatAvatar } from "../..";
import {
  ProfileSmallDownArrowSvgIcon,
  ProfileSmallRightArrowSvgIcon,
} from "../../../assets/svg";
import SimpleBar from "simplebar-react";
import { uuid4 } from "../../../utils";
import "./messenger.modules.css";
function MessengerGroup({
  chatList = ["Ben", "Willy", "Tim"],
  chatName = "My Group Chat",
  isSelect = false,
}) {
  const [toggle, setToggle] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1.5rem",
        }}
        onClick={() => setToggle(!toggle)}
      >
        {toggle ? (
          <ProfileSmallDownArrowSvgIcon />
        ) : (
          <ProfileSmallRightArrowSvgIcon />
        )}
        <div>{chatName}</div>
      </div>
      {toggle ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15 + "px",
            padding: "10px 5px",
          }}
        >
          {[...chatList].map((elem) => (
            <div
              key={uuid4()}
              onClick={elem?.handler}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                paddingLeft: 20 + "px",
                backgroundColor: isSelect ? "#333" : "",
                borderRadius: "20px",
              }}
            >
              <MessengerChatAvatar
                src={elem.logoUrl ?? null}
                online={elem.isOnline ?? false}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "60%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                {/* <SimpleBar style={{ maxWeight: "60%" }}> */}
                {elem.toName ??
                  elem.name ??
                  elem.schoolName ??
                  elem.className ??
                  elem.studentName ??
                  elem.teacherName ??
                  "No Data"}{" "}
                {/* </SimpleBar> */}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default MessengerGroup;
