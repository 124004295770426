import React, {useEffect, useState, useRef} from "react";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import {
    HeaderBar,
    SearchBox,
    MessengerChatGroup,
    MessengerChatBubble,
    TextField,
} from "../../components";
import {
    BackSvgIcon,
    SendMessageSvgIcon,
    SchoolSvgIcon,
    InfoSvgIcon,
} from "../../assets/svg";

import {useAuth} from "../../context";
import "./messenger_page.css";
import {uuid4} from "../../utils/";
import {useChats} from "../../hooks";
import {BeatLoader} from "react-spinners";

function MessengerPage() {
    const {user, roleMapper} = useAuth();
    const {
        loading,
        message,
        setMessage,
        messages,
        handleChangeChannel,
        handleMessageSend,
        fetchGroupChatById,
        fetchIndividualChatById,
        searchListChatHandler,
        toType, setToType,
        searchKeyword, setSearchKeyword,
        searchList, setSearchList,
        showSearchList, setShowSearchList,
        client, setClient,
        individualChatList, setIndividualChatList,
        currentChatName, setCurrentChatName,
        myClasses, setMyClasses,
        myGroups, setMyGroups,
        setChatsId,
        chatsData,
        scrollRef,
        currentToRef,
        fetchChatHandler
    } = useChats();
    /**
     *
     */

    // console.log('rendered');
    return (
        <div style={{maxHeight: 100 + "vh", minHeight: 100 + "vh"}}>
            <HeaderBar homeUrl={`/${roleMapper()}`}/>
            <div style={{maxHeight: 105 + "px", minHeight: `${105}px`}}>{""}</div>
            <div className="eap__messenger-page-container">
                <div
                    className="chatlist-panel"
                    style={{padding: "10 5 25 30", width: 435 + "px"}}
                >
                    {/* chat list */}
                    <div className="chatlist-header" style={{height: 105 + "px"}}>
                        <Link to={`/${roleMapper()}`}>
                            <BackSvgIcon/>
                        </Link>{" "}
                        Messenger
                    </div>
                    <div className="chatlist-search-box">
                        <SearchBox
                            inputHandler={(e) => {
                                setShowSearchList(true);
                                setSearchKeyword(e.target.value);
                            }}
                            onBlurhandler={() => {
                                setTimeout(() => {
                                    setShowSearchList(false);
                                }, 500);
                            }}
                            onFocushandler={() => {
                                setShowSearchList(true);
                            }}
                        />
                        {showSearchList ? (
                            <div className="chatlist-search-auto-fill eap__shadow">
                                {searchList.length > 0 ? (
                                    searchList.map((item, index) => (
                                        <div
                                            onClick={() => {
                                                searchListChatHandler(item);
                                            }}
                                            key={uuid4()}
                                        >
                                            {item.name}
                                        </div>
                                    ))
                                ) : (
                                    <div
                                        onClick={() => {
                                            setShowSearchList(false);
                                        }}
                                    >
                                        Empty
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <SimpleBar
                            style={{
                                maxHeight: "calc(100vh - 105px - 105px - 50px)",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: 10 + "px",
                                }}
                            >
                                {user.schoolId && (
                                    <div>
                                        <MessengerChatGroup
                                            chatName="My School"
                                            chatList={[user.schoolId].map((school) => {
                                                school.isOnline = true;

                                                // school.handler = () => {
                                                //     // handleChangeChannel(school._id);
                                                //     // fetchGroupChatById(school._id);
                                                //     // setToType(5); // 3 represents school
                                                //     // setCurrentChatName(school.schoolName ?? "Loading");
                                                //     // setShowSearchList(false);

                                                //     fetchChatHandler(school, 5);
                                                // };
                                                school.handler = () => fetchChatHandler(school, 5);
                                                return school;
                                            })}
                                        />
                                    </div>
                                )}
                                {user.role === 2 && (
                                    <div>
                                        <MessengerChatGroup
                                            chatName="My Classes"
                                            chatList={[...myClasses].map((classItem) => {
                                                classItem.isOnline = true;

                                                // classItem.handler = () => {
                                                //     // setCurrentTo(classItem._id);                                                    currentToRef.current = classItem._id;
                                                //     currentToRef.current = classItem._id;

                                                //     // handleChangeChannel(classItem._id);
                                                //     // fetchGroupChatById(classItem._id);
                                                //     // setToType(4); // 3 represents classItem
                                                //     // setCurrentChatName(classItem.name);
                                                //     // setShowSearchList(false);

                                                //     fetchChatHandler(classItem, 4);
                                                // };
                                                classItem.handler = () => fetchChatHandler(classItem, 4);
                                                return classItem;
                                            })}
                                        />
                                    </div>
                                )}
                                {user.role === 1 && (
                                    <div>
                                        <MessengerChatGroup
                                            chatName="My Classes"
                                            chatList={[...myClasses].map((classItem) => {
                                                classItem.isOnline = true;

                                                // classItem.handler = () => {
                                                //     // setCurrentTo(classItem._id);                                                    currentToRef.current = classItem._id;
                                                //     currentToRef.current = classItem._id;

                                                //     // handleChangeChannel(classItem._id);
                                                //     // fetchGroupChatById(classItem._id);
                                                //     // setToType(4); // 3 represents classItem
                                                //     // setCurrentChatName(classItem.name);
                                                //     // setShowSearchList(false);

                                                //     fetchChatHandler(classItem, 4);
                                                // };
                                                classItem.handler = () => fetchChatHandler(classItem, 4);
                                                return classItem;
                                            })}
                                        />
                                    </div>
                                )}
                                {user.groupId && (
                                    <div>
                                        <MessengerChatGroup
                                            chatName="My Groups"
                                            chatList={[...myGroups].map((group) => {
                                                group.isOnline = true;
                                                // group.handler = () => {
                                                //     // setCurrentTo(group._id);                                                    currentToRef.current = group._id;
                                                //     currentToRef.current = group._id;

                                                //     // handleChangeChannel(group._id);
                                                //     // fetchGroupChatById(group._id);
                                                //     // setToType(3); // 3 represents group
                                                //     // setCurrentChatName(group.name);
                                                //     // setShowSearchList(false);

                                                //     fetchChatHandler(group, 3);
                                                // };
                                                group.handler = () => fetchChatHandler(group, 3);
                                                return group;
                                            })}
                                        />
                                    </div>
                                )}
                                <div>
                                    <MessengerChatGroup
                                        chatName="Direct Messages"
                                        chatList={[...individualChatList].map((chat) => {
                                            chat.handler = () => {
                                                // setCurrentTo(chat._id);
                                                currentToRef.current = chat._id;
                                                // 1 represents individual
                                                setCurrentChatName(
                                                    chat.name ?? chat.chattingWithName ?? "Anonymous"
                                                );
                                                setToType(
                                                    chat.toType ?? chat.fromRole ?? chat.role ?? 1
                                                );
                                                handleChangeChannel(chat._id);
                                                fetchIndividualChatById(chat._id);
                                                setShowSearchList(false);
                                            };
                                            chat.name =
                                                chat.name ?? chat.chattingWithName ?? "Anonymous";
                                            return chat;
                                        })}
                                    />
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                    <div></div>
                </div>
                <div className="chat-panel">
                    <div
                        className="chat-name"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            minHeight: "105px",
                            backgroundColor: "#f5f5f5",
                        }}
                    >
                        {/* chat name title */}
                        <div
                            className="eap__shadow"
                            style={{
                                minHeight: 58 + "px",
                                maxHeight: 58 + "px",
                                minWidth: 58 + "px",
                                maxWidth: 58 + "px",
                                borderRadius: 58 + "px",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                display: "flex",
                                marginLeft: 48 + "px",
                            }}
                        >
                            <SchoolSvgIcon/>
                        </div>
                        <div>{currentChatName}</div>
                        <div
                            style={{
                                maxWidth: 30 + "px",
                                maxHeight: 30 + "px",
                                display: "flex",
                                marginRight: 84 + "px",
                                alignItems: "center",
                            }}
                        >
                            <InfoSvgIcon/>
                        </div>
                    </div>

                    <div
                        className="chat-content"
                        style={{
                            backgroundColor: "#EDEDED",
                            display: "flex",
                            flexDirection: "column",

                            maxHeight: "calc(100% - 105px)",
                            gap: 10 + "px",
                        }}
                    >
                        <SimpleBar style={{display: "flex", flex: "1", height: "200px"}}>
                            <div
                                id="chat-content-window"
                                style={{
                                    backgroundColor: "#EDEDED",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10 + "px",
                                    padding: "10px",
                                }}
                            >
                                {!loading && messages.map((message, index) => {

                                    return (
                                        <div
                                            key={uuid4()}
                                            style={{
                                                display: "flex",
                                                justifyContent:
                                                    message.from === user._id ? "end" : "start",
                                            }}
                                            ref={scrollRef}
                                        >
                                            <MessengerChatBubble
                                                name={message.fromName}
                                                me={message.from === user._id}
                                                message={message.content}
                                                timestamp={message.timestamp}
                                            />
                                        </div>
                                    );
                                })}
                                {
                                    loading && <BeatLoader/>
                                }
                            </div>
                        </SimpleBar>
                    </div>
                    <div
                        className="chat-input"
                        style={{
                            padding: 10 + "px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {/*<div*/}
                        {/*    style={{cursor: "pointer"}}*/}
                        {/*    onClick={() => {*/}
                        {/*        document.getElementById("chat_attach_file").click();*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <input type="file" id="chat_attach_file" hidden/>*/}
                        {/*    <PaperClipSvgIcon/>*/}
                        {/*</div>*/}
                        <div style={{width: "100%", height: "", margin: "0 13px"}}>
                            <TextField
                                value={message}
                                keydownHandler={(e) => {
                                    if (e.keyCode === 13) {
                                        // handleBroadcastSend();
                                        handleMessageSend();
                                    }
                                }}
                                inputHandler={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <div onClick={handleMessageSend}>
                            {/* <div onClick={handleBroadcastSend}> */}
                            <SendMessageSvgIcon/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessengerPage;
