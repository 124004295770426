import React, {useEffect, useLayoutEffect, useState} from 'react';
import {BasicAdminBackBtnLayout as BasicLayout} from "../../../layouts";
import {PersonSvgIcon} from "../../../assets/svg";
import {AdminAPI} from "../../../services";
import {Button, Dropdown, PhoneTextField, TextField} from "../../../components";
import {useAuth} from "../../../context";
import {getCountryCodeByName} from "../../../utils";
import {useParams} from 'react-router-dom';

function CreatePrincipalPage() {
    const {user, showModal} = useAuth();
    const {id = ""} = useParams();
    const [imageFile, setImageFile] = useState(null);
    const [teacherName, setTeacherName] = useState("");
    const [teacherEmail, setTeacherEmail] = useState("");
    const [teacherPhone, setTeacherPhone] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");

    const [school, setSchool] = useState({});
    const countryCode = () => getCountryCodeByName(country);

    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload_student_avatar").click();
    };

    const teacherInfoHandler = () => {
        /**
         *
         {
    "studentName":"Test Student",
    "city":"Test S City",
    "country":"Test Country",
    "schoolId":"60c09abd6e1e360db761de97",
    "programId":"60c097b8b14f2e0d239c305e",
    "password":"testPassword",
    "email":"student@gmail.com",
    "mobile":"+61424341505"
    }
         */
        const info = {
            teacherName: teacherName || "",
            city: city || "",
            country: country || "",
            schoolId: id,
            // programId: programList[programIndex]._id,
            // password: "testPassword",
            email: teacherEmail || "",
            mobile: `${countryCode()}${teacherPhone}`,
        };
        return JSON.stringify(info);
    };

    const onFileSelected = (e) => {
        setImageFile(e.target.files[0]);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = function (evt) {
            document.getElementById("uploadPreview").src = evt.target.result;
        };
    };

    const createHandler = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile, imageFile.name);
        }
        const info = teacherInfoHandler();
        formData.append("data", info);
        const response = await AdminAPI.createPrincipal(formData);

        console.log(response);

        showModal(" ", response.msg || "Error", () => {
            // window.location.reload();
        }, false);
    };

    useLayoutEffect(() => {
        Promise.resolve()
            .then(() => AdminAPI.getSchoolById(id))
            .then(({data = {}}) => {
                setSchool(data);
                setCity(data.city);
                setCountry(data.country);
            });
    }, [])

    if (!school) return <div>Loading...</div>

    return (
        <form onSubmit={createHandler}>
            <BasicLayout title={'Add Principal'}>
                <div className="eap__create-student-main-panel-1">
                    <div className="student-manage-elem1">
                        <div className="eap__create-student-avatar-wrapper eap__shadow ">
                            {imageFile ? (<img
                                alt={"upload preview"}
                                id="uploadPreview"
                                style={{
                                    width: 128 + "px", height: 128 + "px", // overflow: "hidden"
                                    borderRadius: 128 + "px",
                                }}
                            />) : (<PersonSvgIcon/>)}
                        </div>
                    </div>
                    <div className="student-manage-elem2">
                        <input
                            type="file"
                            id="upload_student_avatar"
                            onChange={onFileSelected}
                            hidden
                        />
                        <Button text="Upload Picture" click={uploadHandler}/>
                    </div>
                    <div className="student-manage-elem3">
                        <label>Name</label>
                    </div>
                    <div className="student-manage-elem4">
                        <TextField
                            required
                            value={teacherName}
                            inputHandler={(e) => setTeacherName(e.target.value)}
                        />
                    </div>
                    <div className="student-manage-elem5">
                        <label>Email</label>
                    </div>
                    <div className="student-manage-elem6">
                        <TextField
                            required
                            type="email"
                            value={teacherEmail}
                            inputHandler={(e) => setTeacherEmail(e.target.value)}
                        />
                    </div>
                    <div className="student-manage-elem7">
                        <label>Phone</label>
                    </div>
                    <div className="student-manage-elem8">
                        <PhoneTextField
                            required
                            value={teacherPhone}
                            inputHandler={(e) => setTeacherPhone(e.target.value)}
                            countryCode={countryCode()}
                        />
                    </div>
                    <div className="student-manage-elem9">
                        <Dropdown
                            disabled
                            placeHolder={school.country}/>
                    </div>
                    <div className="student-manage-elem10">
                        <Dropdown
                            disabled
                            placeHolder={school.city}/>
                    </div>
                    <div className="student-manage-elem11">
                        <Dropdown
                            placeHolder={school.schoolName} disabled/>
                    </div>
                    <div className="student-manage-elem12">

                    </div>
                    <div className="student-manage-elem13">

                    </div>

                    <div className="student-manage-program-dropdown">


                    </div>


                    <div
                        className="student-manage-elem14"
                    >
                        <Button text="Create" color="#eabc7f" textColor="#ffffff" click={() => {
                            document.getElementById("create_student_btn").click();
                        }}/>
                        <input
                            type="submit"
                            id="create_student_btn"
                            value="Submit"
                            hidden
                        />
                    </div>
                </div>
            </BasicLayout>
        </form>
    );
}

export default CreatePrincipalPage;