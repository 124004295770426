import React from "react";
import "./teacher_member_wrapper_card.css";
import { IconWithRightText } from "../../../components";
import { PersonSvgIcon } from "../../../assets/svg";
import { uuid4 } from "../../../utils";
import SimpleBar from "simplebar-react";
function MemberWrapperCard({ list }) {
  return (
    <SimpleBar
      style={{
        autoHide: true,
        minHeight: 157 + "px",
        maxHeight: 157 + "px",
        borderRadius: 20 + "px",
      }}
    >
      <div className="eap__teacher-member-wrapper-card member-wrapper">
        {/* <div> */}

        {[...list].map((elem, index) => {
          if (elem)
            return (
              <div
                  key={"member_"+index}
                  style={{padding:"8px 2px",overflow:"visible"}}>
                <IconWithRightText
                  key={uuid4()}
                  svg={<PersonSvgIcon />}
                  text={elem.name ?? elem.studentName ?? "Name"}
                  handler={elem.handler ?? console.log}
                />
              </div>
            );
        })}

        {/* </div> */}
      </div>
    </SimpleBar>
  );
}

export default MemberWrapperCard;
