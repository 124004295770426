import React from "react";
import styles from "./weeklyhead.module.css";
import {PercentDonut} from "../../";
import {LockSvgIcon} from "../../../assets/svg";

function WeeklyHead({lock = false, weekNum, percent, hide = false}) {
    return (
        <div className={styles.head}>
            {" "}
            <div className={styles.divider1}>{""}</div>
            <span style={{textAlign: 'center'}}>Week {weekNum}</span>
            {!hide && (
                <div style={{display: "flex", justifyContent: 'center'}} className={styles.donut_chart}>
                    {/* {percent}% students has completed W{weekNum} */}
                    {lock ? <LockSvgIcon/> : <PercentDonut percent={percent}/>}{" "}
                </div>
            )}
        </div>
    );
}

export default WeeklyHead;
