import React from "react"
import {useAuth} from "../context";
import {StudentAPI} from "../services";

export default function useStudentManagement() {
    const {showModal} = useAuth();
    const sendHandbookHandler = ({name, studentIds}) => {
        showModal("Send Handbook", `send handbook to the members of ${name}?`, () => {
            return StudentAPI.sendHandbook({studentIds});
        }, false)
    }
    const sendLoginDetailsHandler = ({name, studentIds}) => {
        showModal("Important notice", `send login details to the members of ${name}?  
            This action cannot be reversed and are you sure to do it?`, () => {
            return StudentAPI.sendLoginDetails({studentIds});
        }, false)
    }
    return {
        sendLoginDetailsHandler, sendHandbookHandler
    }
}