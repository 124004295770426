import React, {useEffect} from "react";
import styles from "./editclass.module.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {ClassSvgIcon} from "../../../assets/svg";
import {useStudentManagement} from "../../../hooks";
import {
    TeacherStudentSearchItem as StudentSearchItem, Button, TextField, Pagination, SearchBox, TeacherStudentWrapper,
} from "../../../components";
import {uuid4, moveElements} from "../../../utils";

import {useParams} from "react-router-dom";
import {ClassAPI} from "../../../services";
import cs from "classnames";
import {useAuth} from "../../../context";

function EditClass() {

    const {showModal} = useAuth();
    const {sendHandbookHandler, sendLoginDetailsHandler} = useStudentManagement();
    const [imageFile, setImageFile] = React.useState(null);
    const [fetchedList, setFetchedList] = React.useState([]);
    const {id = ""} = useParams();
    const [groupName, setGroupName] = React.useState(id);
    const [searchList, setSearchList] = React.useState([]);
    const [selectedList, setSelectedList] = React.useState([]);
    const [overallScore, setOverallScore] = React.useState(0);
    const [currentWeek, setCurrentWeek] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState(1);

    const [currentPageList, setCurrentPageList] = React.useState([]);

    const processSelectedList = () => {
        const tempList = [];
        for (const item of selectedList) {
            tempList.push({
                name: item.studentName,
                handler: () => moveElements(selectedList, setSelectedList, searchList, setSearchList, item._id),
            });
        }
        return tempList;
    };

    const getStudentIds = () => {
        return selectedList.map(elem => elem._id);
    }

    const uploadHandler = (e) => {
        e.stopPropagation();
        document.getElementById("upload").click();
    };

    useEffect(() => {
        asyncCall();

        document.getElementById("uploadPreview").src = "";
    }, []);

    const asyncCall = async () => {
        const foundClass = await ClassAPI.getById(id);
        console.log('programId', foundClass?.data?.programId)
        const {data: students = []} = await ClassAPI.getClassForCreation(foundClass?.data?.programId);
        const res = await ClassAPI.getScoreById(id);
        console.log("class score > ", res);
        setCurrentWeek(res?.data?.currentWeek ?? 1);
        setOverallScore(res?.data?.score ?? 0);
        setFetchedList(students?.filter((item) => !item.classId));
        // setFetchedList(students);

        setSearchList(students.filter((item) => !item.classId));
        const {data = {}} = await ClassAPI.getById(id);
        setSelectedList(data.students ?? []);
        setGroupName(data.name ?? "No data");
        setImageFile(data.avatar);
        document.getElementById("uploadPreview").src = data.avatar;
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (imageFile) {
            formData.append("image", imageFile);
        }
        const groupData = {
            name: groupName, students: selectedList, _id: id,
        };

        formData.append("data", JSON.stringify(groupData));
        const response = await ClassAPI.edit(formData);
        if (response.code === 200) {
            // alert("Group Created Successfully");
            showModal(" ", "Class Updated Successfully", () => {
            }, false);
            return;
        }
        // alert(JSON.stringify(response));
    };

    return (<form onSubmit={submitHandler}>
        {" "}
        <CreateLayout title="Class Detail" href="/teacher">
            <div className={styles.eap__create_student_main_panel}>
                <div className={styles.elem1}>
                    <div
                        className={cs(styles.eap__create_student_avatar_wrapper, "eap__shadow")}
                    >
                        {<img
                            id="uploadPreview"
                            alt=""
                            style={{
                                width: 128 + "px",
                                height: 128 + "px",
                                borderRadius: 128 + "px",
                                objectFit: "contain",
                                display: imageFile ? "" : "none",
                            }}
                        />}
                        {imageFile ? null : <ClassSvgIcon/>}
                    </div>
                </div>
                <div className={styles.elem2} onClick={uploadHandler}>
                    <Button text="Upload Picture"/>
                    <input
                        type="file"
                        id="upload"
                        hidden
                        onChange={(e) => {
                            setImageFile(e.target.files[0]);
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.target.files[0]);
                            fileReader.onload = function (evt) {
                                document.getElementById("uploadPreview").src = evt.target.result;
                            };
                        }}
                    />
                </div>
                <div className={styles.elem3}>
                    <label>Select member</label>
                </div>
                <div className={styles.elem4}>
                    <SearchBox inputHandler={(e) => {
                        setSearchList([...fetchedList].filter((elem) => String(elem.studentName).includes(e.target.value)));
                    }}/>
                </div>
                <div className={styles.elem15}>
                    <Pagination setPage={setCurrentPage}/>
                </div>
                {/*<div className={styles.elem5}></div>*/}
                <div className={styles.elem6}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 100 + "%",
                            gap: "18px",
                            minHeight: 350 + "px",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        {searchList.length < 1 ? (<>Empty</>) : searchList.length > 4 ? ([...searchList]
                            .slice((currentPage - 1) * 4, 4 * currentPage)
                            .map((elem, index) => (<div
                                key={"searched_" + index}
                            >
                                <StudentSearchItem
                                    status={elem.status}
                                    ekey={uuid4() + "s1"}
                                    name={elem.studentName}
                                    click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}
                                />
                            </div>))) : ([...searchList].map((elem, index) => (
                            <div key={index + "searched_item"}><StudentSearchItem
                                ekey={uuid4() + "s2"}
                                name={elem.studentName}
                                click={() => moveElements(searchList, setSearchList, selectedList, setSelectedList, elem._id)}
                            /></div>)))}
                    </div>
                </div>
                <div className={styles.button_wrapper}>
                    <Button
                        text="Sent student handbook"
                        color="#1B3D4D"
                        textColor="#fff"
                        click={() => {
                            sendHandbookHandler({name: groupName, studentIds: getStudentIds()})
                        }}
                    />
                    <Button
                        text="Send login details"
                        color="#1B3D4D"
                        textColor="#fff"
                        click={() => {
                            sendLoginDetailsHandler({name: groupName, studentIds: getStudentIds()})
                        }}
                    />
                </div>
                <div className={styles.elem7}>
                    <label>Class member</label>
                </div>

                <div className={styles.elem9}>
                    <label>Name</label>
                </div>
                <div className={styles.elem12}>
                    <div className={styles.member_wrapper}>
                        <TeacherStudentWrapper list={processSelectedList()}/>
                    </div>
                </div>
                <div className={styles.elem14}/>
                <div className={styles.elem16}>
                    <TextField
                        value={groupName}
                        required
                        inputHandler={(e) => {
                            setGroupName(e.target.value);
                        }}
                    />
                </div>
                <div className={styles.elem17}>{/* <label>Durantion</label> */}</div>
                <div className={styles.elem18}>
                    <Button
                        text="Done"
                        primary
                        click={() => {
                            document.getElementById("edit-group-submit").click();
                        }}
                    />
                    <input type="submit" hidden id="edit-group-submit"/>
                </div>
                <label className={styles.chart_wrapper_label}>
                    Class progression
                </label>
                <div className={styles.chart_wrapper}>
                    <div className={styles.item1}> Overall Score</div>
                    <div className={styles.item2}>Current Week</div>
                    <div className={styles.item3}>{overallScore}</div>
                    <div className={styles.item4}>week {currentWeek}</div>
                </div>
            </div>
        </CreateLayout>
    </form>);
}

export default EditClass;
