import React from "react";
import "./pagination.css";
import {
  LeftArrowSvgIcon,
  RightArrowSvgIcon,
  SmallDownArrowSvgIcon,
} from "../../../assets/svg";

function Pagination({ setPage = (num) => {}, min=1, max=1 }) {
  const [pageNum, setPageNum] = React.useState(1);
  return (
    <div className="eap__pagination-wrapper">
      {/* <div className="eap__pagination-previous">&lt;</div> */}
      <div
        className="eap__pagination-previous"
        onClick={() => {
          if (pageNum === 1) return;
          if (pageNum <= min) return;
          setPage(pageNum - 1);
          setPageNum(pageNum - 1);
        }}
      >
        <LeftArrowSvgIcon />
      </div>
      <div className="eap__pagination-current-page">
          Page {pageNum}
        <div className="eap__pagination-current-page-dropdown">
          {/*<SmallDownArrowSvgIcon />*/}
        </div>

      </div>
      {/* <div className="eap__pagination-next">&gt;</div> */}
      <div
        className="eap__pagination-next"
        onClick={() => {
          if (pageNum >= max) return;
          setPage(pageNum + 1);
          setPageNum(pageNum + 1);
        }}
      >
        <RightArrowSvgIcon />
      </div>
    </div>
  );
}

export default Pagination;
