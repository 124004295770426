import { POST, GET } from "..";
export default class GroupAPI {
  static async getAll() {
    try {
      const { data } = await GET(`/groups/get`);
      return data;
    } catch (err) {
      return [];
    }
  }
  static async getById(id) {
    try {
      const { data } = await GET(`/groups/get/${id}`);
      return data;
    } catch (err) {
      return [];
    }
  }
  static async create(payload = new FormData()) {
    try {
      const { data } = await POST(`/groups/createGroup`, payload);
      console.log(data);
      return data;
    } catch (err) {
      return [];
    }
  }
  static async edit(payload = new FormData()) {
    try {
      const { data } = await POST(`/groups/edit`, payload);
      console.log(data);
      return data;
    } catch (err) {
      return [];
    }
  }
}
