import React, {useEffect, useRef, useState} from "react";
import styles from './type8.module.css';
import {SwitchSvgIcon} from "../../../../../assets/svg";
import {Loading} from "../../../../index";


function shuffleArray(incomingArray) {
    let array = [...incomingArray];
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}


function Type8({question, lock, setLock=(val)=>{}, score, setScore=(v)=>{}}) {

    const [loading, setLoading] = useState(true);

    const [questionTitle, setQuestionTitle] = useState("Question...");
    const [currentKeysIdx, setCurrentKeysIdx] = useState(0);
    const [currentValues, setCurrentValues] = useState([]);
    const [currentKeys, setCurrentKeys] = useState([]);
    const [colorMap, setColorMap] = useState(new Map());
    const [shuffleArr, setShuffleArr] = useState([]);
    const [localScore, setLocalScore] = useState(score);

    const wordsRef = useRef(new Map());
    // const colorRef = useRef(new Map());
    useEffect(() => {
        const {words, prompt} = question;
        setQuestionTitle(prompt);
        const _colorMap = new Map();
        for (const word of words) {
            wordsRef.current.set(word.meaning, word.word);
            // colorRef.current.set(word.word, -1);
            _colorMap.set(word.word, -1);
        }
        setColorMap(new Map(_colorMap));
        // console.log(getMapValues());
        // console.log(Array.from(_colorMap.keys()));
        setShuffleArr(shuffleArray(Array.from(_colorMap.keys())))
        setLoading(false);

    }, []);

    useEffect(() => {
        setCurrentValues(shuffleArray(getMapValues()));
        setCurrentKeys(getMapKeys());
    }, [wordsRef.current])

    const checkIfFinished = () => {
        return getMapKeys().length < 1;
    }

    const isIndexOutbound = () => {
        const keys = getMapKeys();
        if (currentKeysIdx >= keys.length - 1) {
            return true;
        }
        return currentKeysIdx < 0;
    }

    const switchCardHandler = () => {
        if (isIndexOutbound()) {
            return setCurrentKeysIdx(0);
        }
        ;const nextIdx = currentKeysIdx + 1;
        setCurrentKeys(getMapKeys());
        setCurrentKeysIdx(nextIdx);
    }

    const checkAnswerHandler = (wordsMapValue) => {
        console.log("started");
        const key = getCurrentQuestion();
        // if not correct
        if (wordsRef.current.get(key) !== wordsMapValue) {
            // colorRef.current.set(wordsMapValue, 0);
            setLocalScore(localScore + 1);
            setScore(localScore + 1);
            setColorMap(new Map(colorMap.set(wordsMapValue, 0)));
            return;
        }
        for (const key of getMapKeys()) {
            if ((wordsRef.current.get(key)) === wordsMapValue) {
                wordsRef.current.delete(key);
                // colorRef.current.set(wordsMapValue, 1);
                setColorMap(new Map(colorMap.set(wordsMapValue, 1)));
                break;
            }
        }
        setCurrentValues(getMapValues());
        setCurrentKeys(getMapKeys());
        switchCardHandler();
        // console.log("now", wordsRef.current);
        handleLock();
    }

    const getMapKeys = () => Array.from(wordsRef.current.keys());

    const getMapValues = () => Array.from(wordsRef.current.values());

    const getCurrentQuestion = () => {
        if (currentKeys.length < 1) return null;
        return currentKeys[currentKeysIdx]
    };
    const handleLock = () => {
        setLock(!checkIfFinished());
    };

    useEffect(() => {
        handleLock();
    }, [wordsRef.current])
    const checkState = (val) => {
        return wordsRef.current.values().hasOwnProperty(val);
    }

    const getStatusColor = (val) => {
        switch (val) {
            case 0:
                return "#E5B343";
            case 1:
                return "#89B481";
            default :
                return "#f7f7f7";
        }
    }
    return (<div>
        {loading === false ? (<>
            <div className="w-full flex flex-row ">
                <div className="w-1/12"/>
                <div className=" bg-white p-4 flex flex-col h-full w-full">
                    <div>{questionTitle}</div>
                    <div className="flex flex-row w-full h-full divide-x">
                        <div className="flex flex-col w-1/2 h-full p-5 space-y-5 items-end justify-center">
                            {/* Question */}
                            <div className={styles.q_card}>
                                {getCurrentQuestion() && <div className={styles.q_card_main}>{getCurrentQuestion()}
                                </div>}
                            </div>

                            {getCurrentQuestion() &&
                                <button className="flex-row flex justify-self-end items-end space-x-5"
                                        id={"switch_component"}
                                        onClick={switchCardHandler}>
                                    <SwitchSvgIcon/>
                                    <span>Switch cards</span>
                                </button>}
                        </div>

                        <div className="flex flex-col w-1/2 p-2 justify-center">
                            <div style={{display: 'flex', flexFlow: 'row wrap', gap: 10}}>
                                {shuffleArr.map((word, i) => {
                                    return (<div key={i} style={{
                                        width: "48%"
                                    }}>
                                        <button
                                            onClick={() => {
                                                checkAnswerHandler(word);
                                            }}
                                            disabled={colorMap.get(word) === 1}
                                            style={{
                                                backgroundColor: getStatusColor(colorMap.get(word)),
                                                color: colorMap.get(word) !== -1 ? "#fff" : "",
                                                padding: "5px 20px",
                                                borderRadius: 10
                                            }}
                                        >{word}</button>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/12"/>
            </div>
        </>) : <Loading/>}
    </div>);

}

export default Type8;
