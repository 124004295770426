import React, {useEffect, useState} from "react";
import QuestionOption from "../Option";
import EmptyOption from "../Option/EmptyOption";
import styles from './type4.module.css';

function Type4({
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
               }) {
    const [answer, setAnswer] = useState({});
    const [selected, setSelected] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [scoreLocal, setScoreLocal] = useState(0);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        let sorted = question.options.sort((x, y) => x.setting - y.setting);
        sorted = sorted.map((o) => {
            return {...o, selected: false};
        });
        let first = "";
        const empty = question.options.map((o) => {
            return {label: "               ", shake: false, correct: false};
        });
        // empty[0] = { label: first, shake: false };
        const copy = {...question, options: sorted, answers: empty};
        setAnswer(copy);
        setOptions(sorted);
        setLoading(false);
    }, []);
    const handleSelect = (index) => {
        setSelected(index);
    };
    const handleLock = (copy) => {
        // let lockOption = false;
        // for (const x of copy.answers) {
        //     if (x.correct === false) {
        //         lockOption = true;
        //     }
        // }
        const lockOption = copy.answers.every((el) => el.correct === true);
        setLock(!lockOption);
    };
    const checkAnswer = (index) => {
        if (selected === -1) return;
        let selectedOption = answer.options[selected];
        if (selectedOption.index === index) {
            // correct
            const optionsCopy = [...options];
            optionsCopy[selected]["correct"] = true;
            setOptions([...optionsCopy]);

            let copy = {...answer};
            copy.answers[index]["label"] = selectedOption.word;
            copy.answers[index]["correct"] = true;
            setAnswer(copy);
            setSelected(-1);
            handleLock(copy);
        } else {
            // incorrect
            let copy = {...answer};
            copy.answers[index]["shake"] = true;
            setAnswer(copy);
            setSelected(-1);
            setScoreLocal(scoreLocal + 1);
            setScore(scoreLocal + 1);
        }
    };
    return (<div>
        {loading === false ? (<>
            <div className="w-full flex flex-row ">
                <div className="w-1/12">{""}</div>
                <div className="w-10/12 bg-white p-4 flex flex-col ">
                    <div>{question.prompt}</div>
                    <div className="flex flex-row w-full h-full divide-x">
                        <div className="flex flex-col w-1/2 p-2 space-y-1">
                            {/*{answer.options.map((o, i, arr) =>*/}
                            {/*  i % 2 === 0 ? (*/}
                            {/*    <div className="flex flex-row" key={i}>*/}
                            {/*      <QuestionOption*/}
                            {/*        label={o.word}*/}
                            {/*        index={i}*/}
                            {/*        selected={selected}*/}
                            {/*        handler={handleSelect}*/}
                            {/*      />*/}
                            {/*      {answer.options[i + 1] ? (*/}
                            {/*        <QuestionOption*/}
                            {/*          label={arr[i + 1].word}*/}
                            {/*          index={i + 1}*/}
                            {/*          selected={selected}*/}
                            {/*          handler={handleSelect}*/}
                            {/*        />*/}
                            {/*      ) : null}*/}
                            {/*    </div>*/}
                            {/*  ) : null*/}
                            {/*)}*/}
                            {/*{options.map((o, i, arr) => i % 2 === 0 ? (<div className="flex flex-row" key={i}>*/}
                            {/*    <QuestionOption*/}
                            {/*        label={o.word}*/}
                            {/*        index={i}*/}
                            {/*        selected={selected}*/}
                            {/*        handler={handleSelect}*/}
                            {/*    />*/}
                            {/*    {options[i + 1] ? (<QuestionOption*/}
                            {/*        label={arr[i + 1].word}*/}
                            {/*        index={i + 1}*/}
                            {/*        selected={selected}*/}
                            {/*        handler={handleSelect}*/}
                            {/*    />) : null}*/}
                            {/*</div>) : null)}*/}
                            <div className={styles.flex_container}>
                                {options.map((op, idx) => (<div key={'op_type4_' + idx}
                                                                className={styles.flex_item}
                                                                onClick={() => handleSelect(idx)}
                                                                style={{
                                                                    visibility: options[idx].correct ? "hidden" : "",
                                                                    backgroundColor: selected === idx ? "#e4b243" : "#f7f7f7",
                                                                    color: selected === idx ? "#fff" : "",
                                                                    // minWidth: "45%", maxWidth: "45%"
                                                                }}
                                >
                                    {op.word}
                                    {/*<QuestionOption*/}
                                    {/*    label={op.word}*/}
                                    {/*    index={idx}*/}
                                    {/*    selected={selected}*/}
                                    {/*    handler={handleSelect}*/}
                                    {/*/>*/}
                                </div>))}
                            </div>

                        </div>

                        <div className="flex flex-col w-1/2 p-2 space-y-0 justify-center">
                            {answer.answers.map((a, i) => {
                                return (<div key={i} style={{display: 'flex', alignItems: 'flex-start'}}>
                                    <span style={{width: 10}}>{String.fromCharCode(97 + i)}.
                                    </span>
                                    <EmptyOption
                                        label={a.label}
                                        handler={checkAnswer}
                                        index={i}
                                        shake={a.shake}
                                        bgColor={a.correct === true ? "#88b380" : ""}
                                    />
                                </div>);
                            })}
                        </div>
                    </div>
                </div>
                <div className="w-1/12">{""}</div>
            </div>
        </>) : null}
    </div>);
}

export default Type4;
