import React, {useEffect, useState} from 'react';
import {BasicAdminLayout} from "../../../layouts";
import {WideButtonWithIcon} from "../../../components";
import {Table} from 'react-bootstrap';
import {useHistory, useParams} from "react-router-dom";
import styles from './main.module.css';
import {AdminAPI} from "../../../services";
import {BeatLoader} from "react-spinners";
import {JsonEditor} from "jsoneditor-react";
import CommonButton from "../../../components/Common/Button/CommonButton/CommonButton";

function EditQuestionPage(props) {
    const history = useHistory();
    const {id, moduleId} = useParams();
    const Card = ({
                      title = "Management",
                      createBtnText = "Create",
                      createUrl = "/admin",
                      children
                  }) => {
        return (<div className={styles['card']}>
            <div style={{display: 'flex'}}>
                <h3>{title}</h3>
                <div style={{display: 'flex', flex: 1}}/>
                <div style={{display: 'flex', marginLeft: "auto"}}
                     onClick={() => history.push(createUrl)}
                ><WideButtonWithIcon
                    text={createBtnText}/></div>
            </div>
            <div>{children}</div>
        </div>)
    }

    const [question, setQuestion] = useState({});
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [pagesize, setPagesize] = useState(5);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        id && Promise.resolve()
            .then(() => setLoading(true))
            .then(() => AdminAPI.getQuestionById({id, moduleId}))
            .then(({result, page, pages, pagesize}) => {
                setQuestion(result || {});
                // setPage(page);
                setPages(pages);
                setPagesize(pagesize)
            })
            .then(() => setLoading(false))
    }, []);

    if (loading) return <div
        style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        <BeatLoader color={"#1b3e4d"}/></div>;

    const handleChange = (newJson = {}) => {
        const newQuestion = {...question, question: newJson};
        setQuestion(newQuestion);
    }

    const handleNonJsonChange = (keyName, newValue) => {
        const newQuestion = {...question, [keyName]: newValue};
        setQuestion(newQuestion);
    }

    const handleUpdate = () => Promise.resolve()
        .then(() => AdminAPI.updateQuestion({...question, moduleId}))
        .then((response) => window.alert(JSON.stringify(response, null, 2)))
        .then(() => window.location.reload());

    const handleDelete = () => {
        const confirmed = window.confirm(`Sure to delete this question? This action is not reversible.`);
        confirmed && Promise.resolve()
            .then(() => AdminAPI.deleteQuestion({_id: question._id, moduleId: moduleId}))
            .then((response) => window.alert(JSON.stringify(response, null, 2)))
            .then(() => window.location.reload());
    }

    return (
        <BasicAdminLayout>
            <div style={{height: 105}}/>

            <div className={styles['title']}>Question Panel</div>

            <div className={styles['main-panel']}>
                <Table responsive>
                    <thead>
                    <tr>
                        <td colSpan={1}>Key Name</td>
                        <td colSpan={5}>Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    {[...Object.keys(question)].map((elem, index) => (
                        <tr key={'question_' + index}>
                            <td colSpan={1}>{elem}</td>
                            <td colSpan={5}>
                                {/*<textarea defaultValue={typeof question[elem] === "object" ? JSON.stringify(question[elem]) : question[elem]} style={{width:'100%',minWidth:'100%'}}*/}
                                {/*rows={4}*/}
                                {/*/>*/}
                                {typeof question[elem] === "object" &&
                                    <JsonEditor value={question[elem]} onChange={handleChange}/>}
                                {typeof question[elem] === "string" && <input value={question[elem]}
                                                                              onInput={(event) => handleNonJsonChange(elem, event.target.value)}/>}
                                {typeof question[elem] === "number" && <input type={'number'} value={question[elem]}
                                                                              onInput={(event) => handleNonJsonChange(elem, event.target.value)}/>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            <div/>
            <div><CommonButton text={'Update'} click={() => handleUpdate()}/>
            <CommonButton text={'Delete'} color={'red'} textColor={'#ffffff'} click={() => handleDelete()}/></div>
        </BasicAdminLayout>
    );
}

export default EditQuestionPage;