import React, {useEffect} from "react";
import styles from "./weekly_content.module.css";
import {TeacherCreateLayout as CreateLayout} from "../../../layouts";
import {LockSvgIcon} from "../../../assets/svg";
import {
    Pagination,
    WeeklyContentComponent,
    WeeklyContentHead,
} from "../../../components";
import SimpleBar from "simplebar-react";
import {useWeeklyContentStudent} from "../../../hooks";
import {BeatLoader} from "react-spinners";

function WeeklyContent() {
    const {currentPage, setCurrentPageNum, totalPages, loading} =
        useWeeklyContentStudent();

    const getPercentage = (index) => {
        return (currentPage[index]["percentage"]) === 100 ? 100 : Math.floor((currentPage[index]["percentage"]) * 100)
    }

    return (
        <CreateLayout
            title="Weekly Content"
            href="/teacher/"
            pagination={<Pagination max={totalPages} setPage={setCurrentPageNum}/>}
        > {loading && <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}><BeatLoader /></div>}
            <div className={styles.container}>


                {currentPage.length > 0 && [
                    {
                        line: styles.line1,
                        label: styles.first_week_label,
                        week: styles.first_week,
                    },
                    {
                        line: styles.line2,
                        label: styles.second_week_label,
                        week: styles.second_week,
                    },

                    {
                        line: styles.line3,
                        label: styles.third_week_label,
                        week: styles.third_week,
                    },
                    {
                        line: styles.line4,
                        label: styles.four_week_label,
                        week: styles.four_week,
                    },
                    {
                        line: styles.line5,
                        label: styles.five_week_label,
                        week: styles.five_week,
                    },
                ].map((el, index) => {
                    return (
                        currentPage[index] && (
                            <div key={'weekly_content_' + index} className={el.line}>
                                <div className={el.label}>
                                    <WeeklyContentHead
                                        lock={currentPage[index]["locked"]}
                                        weekNum={currentPage[index]["weekNumber"]}
                                        percent={getPercentage(index)}
                                    />
                                </div>
                                <div className={el.week}>
                                    <WeeklyContentComponent
                                        active={!currentPage[index]["locked"]}
                                        modules={
                                            currentPage[index] ? currentPage[index].modules ?? [] : []
                                        }
                                    />
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
        </CreateLayout>
    );
}

export default WeeklyContent;
