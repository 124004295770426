import React, {useEffect, useState} from "react";
import {
    StudentBackDivision as BackDiv,
    StudentContentBar as ContentBar,
    HeaderBar,
    SubHeaderBar,
    StudentWeekComponent as Week,
    StudentQuestionPreview as QuestionPreview,
    WeeklyContentComponent, Loading, WeeklyContentHead, TextField, Switch, PlacementQuestionWrapper,
} from "../../components";
import {BasicLayout} from "../../layouts";
import {useParams} from "react-router-dom";
import {ModuleAPI} from "../../services";
import {Link} from "react-router-dom";
import styles from "./modulepage.module.css";
import "./temp.css";
import {BeatLoader} from "react-spinners";
import bg from "../../assets/img/planet&stars.png";
import bg2 from "../../assets/img/planet.png";
import logo from "../../assets/img/logo1.png";
import SimpleBar from "simplebar-react";
import {useAuth} from "../../context";
import usePlacementHook from "../../hooks/placement.test.hook";


function PlacementTestPage() {
    // const {id} = useParams();
    const countDown = new Date();
    countDown.setSeconds(countDown.getSeconds() + 20 * 60); // 20 minutes later
    const placementHooks = usePlacementHook({expiryTimestamp: countDown});
    if (placementHooks.showTest) return (
        <PlacementQuestionWrapper hooks={placementHooks}/>
    )


    return (
        <div>
            {/* <div style={{height:105+'px'}}></div> */}
            {placementHooks.loading === false ? (
                <div

                    className={styles.container}
                    style={{
                        margin: "0 auto",
                    }}>
                    <div
                        className={styles.left_panel}
                        style={{
                            display: 'flex',
                            height: 100 + "%", flexDirection: 'column', justifyContent: 'center'
                        }}>
                        <div style={{
                            height: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 20,
                            color: "#fff",
                            textAlign: 'center'
                        }}>
                            <div style={{textAlign: "end"}} className={styles.main_title}>
                                <span style={{whiteSpace: "nowrap"}}>Welcome to</span> <a href={"/"}
                                                                                          style={{
                                                                                              whiteSpace: "nowrap",
                                                                                              color: "#32b1c4"
                                                                                          }}>EAP Space®</a> <span
                                style={{whiteSpace: "nowrap"}}>Placement Test</span>
                            </div>

                            <div
                                className={styles.sub_title}
                                style={{fontSize: 20, marginTop: 50}}>Please
                                complete this form
                                before proceeding to the
                                EAP Space
                                Placement Test.
                            </div>
                        </div>

                        {/* <ContentBar /> */}
                    </div>

                    <div
                        className={styles.right_panel}
                        style={{
                            display: 'flex', flexDirection: 'column',
                            height: 100 + "%", justifyContent: 'center',
                        }}>
                        <form
                            onSubmit={placementHooks.submitHandler}
                            className={styles.form}
                            style={{

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                margin: "0 auto",
                                gap: 15,
                                backgroundColor: '#3d6370',
                                borderRadius: 20
                            }}

                            id={"place_form"}>
                            <a href={'/'} style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: 'center',
                                marginBottom: 20
                            }}

                            >
                                <img className={styles.logo} src={logo} style={{width: "30%", height: "auto"}}
                                     alt={"logo"}/>
                            </a>

                            <input style={placementHooks.inputStyle} required
                                   name={"first name"}
                                   placeholder={'First Name...'} value={placementHooks.firstName}
                                   onInput={e => placementHooks.setFirstName(e.target.value)}/>

                            <input style={placementHooks.inputStyle} required
                                   name={"last name"}
                                   placeholder={'Last Name...'} value={placementHooks.lastName}
                                   onInput={e => placementHooks.setLastName(e.target.value)}/>

                            <input style={placementHooks.inputStyle} required
                                   name={"mobile"}

                                   placeholder={'Mobile...'} value={placementHooks.mobile}
                                   onInput={e => {
                                       if (isNaN(e.target.value)) return;
                                       if (e.target.value.length > 10) return;
                                       placementHooks.setMobile(e.target.value)
                                   }}/>
                            <input type={"email"} style={placementHooks.inputStyle} required
                                   name={"email"}

                                   placeholder={'Email...'} value={placementHooks.email}
                                   onInput={e => placementHooks.setEmail(e.target.value)}/>
                            <div style={{position: "relative"}}>
                                <input style={placementHooks.inputStyle} required
                                       name={"search box"}
                                       placeholder={'School / Partner Code...'}
                                       type={'text'}
                                       onFocus={() => placementHooks.setShowMenuList(true)}
                                       onBlur={() => setTimeout(() => placementHooks.setShowMenuList(false), 100)}
                                       onInput={(e) => placementHooks.setKeyword(e.target.value || "")}
                                       value={placementHooks.keyword}
                                />
                                {
                                    placementHooks.showMenuList && <div style={{
                                        position: "absolute",
                                        zIndex: 20,
                                        top: "-355px",
                                        left: 0,
                                        right: 0,
                                        backgroundColor: "#164559",
                                        color: "#fdfdfd",
                                        borderRadius: 10,
                                        overflow: "hidden",
                                    }}>
                                        <SimpleBar style={{maxHeight: 340, height: 340}}>
                                            <ul style={{display: 'flex', flexDirection: 'column'}}>
                                                {placementHooks.searchResult.length > 0 && placementHooks.searchResult.map((el, idx) => (
                                                    <li key={idx + "school_option"}
                                                        className={styles.option}
                                                        onClick={() => {
                                                            placementHooks.setKeyword(el.name);
                                                            placementHooks.setSelectSchoolCode(el.value);
                                                        }}
                                                    >{el.name}</li>))}
                                                {placementHooks.searchResult.length < 1 && <li style={{
                                                    minHeight: "100%",
                                                    minWidth: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "100%",
                                                    padding: "10px 10px"
                                                }}>No Result</li>}
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                }
                            </div>
                            <div style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'flex-start',
                                flexFlow: 'row',
                                color: '#9ba2ae'
                            }}>

                                <div style={{height: 100 + "%", marginTop: 2}}>
                                    <Switch value={placementHooks.checked}
                                            click={(v) => placementHooks.setChecked(!placementHooks.checked)}/>
                                </div>
                                <small>By clicking button below, I agree that I have read and accepted the <a
                                    href={'/'} style={{color: "#32b1c4"}}>Terms of Use </a>
                                    and <a href={'/'} style={{color: "#32b1c4"}}>Privacy Policy</a>.
                                </small>
                            </div>
                            <input type={"submit"} name={'submit button'} className={styles.button} style={{

                                textAlign: 'center',
                                pointerEvents: placementHooks.checked ? "auto" : "none",
                                backgroundColor: placementHooks.checked ? "" : "grey",
                                color: placementHooks.checked ? "" : "#9aa1ad"
                            }}

                                   value={"Start Placement Test"}
                            />
                            <small style={{color: '#9ba2ae'}}>© All rights reserved by EAP Space® and Origo Education
                                Group International 2021.</small>
                        </form>
                    </div>
                </div>
            ) : <div style={{
                display: 'flex',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: 'absolute',
                alignItems: "center",
                justifyContent: "center"
            }}><BeatLoader/></div>}
        </div>
    );
}

export default PlacementTestPage;
