import React from 'react';
import styles from './styles.module.css';

function QuestionBody({
    current =0,
                          question,
                          answerQuestionHandler = (v) => {
                          }
                      }) {
    return (
        <div className={styles['container']}>
            <div className={styles['left-panel']}>
                {current+1}) {question?.question}
            </div>
            <div className={styles['right-panel']}>
                {question.A && <div className={styles['option']}
                                    style={{
                                        backgroundColor: question?.answer === "A" ? "#64929F" : null,
                                    color:question?.answer === "A" ? "#ffffff" : null}}
                                    onClick={() => answerQuestionHandler("A")}>{question?.A}</div>}
                {question.B && <div className={styles['option']}
                                    style={{
                                        backgroundColor: question?.answer === "B" ? "#64929F" : null,
                                        color:question?.answer === "B" ? "#ffffff" : null}}
                                    onClick={() => answerQuestionHandler("B")}>{question?.B}</div>}
                {question.C && <div className={styles['option']}
                                    style={{
                                        backgroundColor: question?.answer === "C" ? "#64929F" : null,
                                        color:question?.answer === "C" ? "#ffffff" : null}}
                                    onClick={() => answerQuestionHandler("C")}>{question?.C}</div>}
                {question.D && <div className={styles['option']}
                                    style={{
                                        backgroundColor: question?.answer === "D" ? "#64929F" : null,
                                        color:question?.answer === "D" ? "#ffffff" : null}}
                                    onClick={() => answerQuestionHandler("D")}>{question?.D}</div>}
            </div>
        </div>
    )
}

export default QuestionBody;