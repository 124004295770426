import React from "react";
import "./messenger_item.css";
import { ReactComponent as PersonIcons } from "../../../assets/svg/person_icon.svg";

function StudentItem({
  name = "My Class",
  subject = "Hi there",
  timestamp = "14:03pm",
  svg,
  iconW = "33",
  iconH = "33",
  href = "#",
}) {
  return (
    <a className="eap__icon-link" href={href}>
      <div className="eap__icon-btn-container">
        <div className="eap__icon-btn eap__shadow eap__icon-btn-holder">
          <div
            className="eap__svg-icon"
            style={{ width: iconW + "px", height: iconH + "px" }}
          >
            {svg || <PersonIcons />}
          </div>
        </div>
        <div className="eap__icon-btn-text eap__messenger-text-wrapper">
          <div className="eap__messenger-text-item">{name}</div>
          <div className="eap__messenger-text-item eap__messenger-text-inline">
            <div>{subject}</div>
            <div>{timestamp}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default StudentItem;
