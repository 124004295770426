import React, {useEffect, useState} from "react";
import styles from "./type2.module.css";

import {SwitchSvgIcon} from "../../../../../assets/svg";

const alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];

function Type2({
                   start = () => {
                   },
                   pause = () => {
                   },
                   question, lock, setLock = (v) => {
    }, score, setScore = (v) => {
    }
               }) {
    const [answer, setAnswer] = useState([]);
    const [state, setState] = useState(true);
    const [scoreLocal, setScoreLocal] = useState(0);
    useEffect(() => {
        const answers = question.options.map((o) => {
            return {...o, attempted: false, correct: false};
        });
        setAnswer(answers);
    }, []);
    const handleLock = () => {
        setLock(false);
    };
    const checkAnswer = (index) => {
        if (lock === false) return;
        const copy = [...answer];
        copy[index] = {
            ...copy[index], attempted: true, correct: copy[index].isTrue,
        };
        if (copy[index].isTrue) handleLock(); else {
            setScoreLocal(scoreLocal + 1);
            setScore(scoreLocal + 1);
        }

        setAnswer(copy);
    };

    useEffect(() => {
        if (state === true) start();
        else pause();
    }, [state])

    return (

        <div className="w-full flex flex-row">
            <div className="w-1/12">{""}</div>
            <div className="w-10/12 bg-white p-4 flex flex-col  space-y-8">
                <div>{question.prompt}</div>
                <div className="flex flex-row w-full h-full divide-x">
                    <div className="flex flex-col w-1/2 p-2 space-y-5">
                        {(<div className="bg-gray shadow-lg w-full h-52 overflow-y-scroll p-5 round"
                               style={{backgroundColor: "#f5f5f5"}}
                        >
                            {state ? question.passage : (<table>
                                {question.words.map((word, idx) => (
                                    <tr key={`word_type2_${idx}`} style={{borderBottom: '1px solid #000'}}>
                                        <td style={{
                                            width: 100,
                                            marginRight: 30
                                        }}>{word.meaning ?? "Null"}</td>
                                        <td>
                                            {word.word ?? "Null"}
                                        </td>
                                    </tr>
                                ))}
                            </table>)}
                        </div>)}
                        <div className={styles.card_switch_btn} style={{
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: 20
                        }} onClick={() => setTimeout(() => setState(!state), 300)}>
                            <span><SwitchSvgIcon/></span><span>{"Go to "}{state ? "vocabulary" : "passage"}</span>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2 p-2 space-y-4 justify-center">
                        {answer.map((o, i) => (<div className="flex flex-row space-x-5" key={i}>
                            <span>{alphabet[i]}.</span>
                            {!o.attempted ? (<span className={"p-2  rounded-md"}
                                                   style={{backgroundColor: "#f7f7f7"}}
                            >
                      <button onClick={() => checkAnswer(i)}>{o.label}</button>
                    </span>) : null}
                            {o.attempted && !o.correct ? (<span
                                className={"p-2  rounded-md  text-white"}
                                style={{backgroundColor: "#e4b243"}}
                            >
                      <button onClick={() => checkAnswer(i)}>{o.label}</button>
                    </span>) : null}
                            {o.attempted && o.correct ? (<span
                                className={"p-2 rounded-md text-white"}
                                style={{backgroundColor: "#88b380"}}
                            >
                      <button onClick={() => checkAnswer(i)}>{o.label}</button>
                    </span>) : null}
                        </div>))}
                    </div>
                </div>
            </div>
            <div className="w-1/12">{""}</div>
        </div>

    );
}

export default Type2;
